import React, { useContext, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { Button, TextArea } from "features/common/ComposedComponents";
import { FormattedMessage } from "locale/langUtils";
import { piggavService } from "api";
import { useIntl } from "react-intl";
import { getState } from "state/Auth/Auth";
import { useSelector } from "react-redux";
import { BulkBuyContext } from "./BuyStickersBulk";
interface UploadTextareaListSectionProps extends React.HTMLProps<HTMLDivElement> {
  submit?: string;
}
export default function UploadTextareaListSection({
  submit,
  className,
  ...rest
}: UploadTextareaListSectionProps): JSX.Element {
  const [isUploadingList, setIsUploadingList] = useState(false);
  const { formatMessage } = useIntl();

  const { userInfo } = useSelector(getState);

  const { dispatch, checkAndValidateListAndAddToBasket } = useContext(BulkBuyContext);

  const schema = yup.object().shape({
    vehicleList: yup.string()
      .required(formatMessage({ id: "common.error.requiredfield" }))
      .test("unique", formatMessage({ id: "buystickersbulkpage.error.not.unique" }) , value => {
        var list = (value as string).trim()
        .split(/\r?\n/)
        .map(reg => reg.replace(/\s/, ""));

        return list.length === new Set(list).size;
      })
  });
  type FormType = yup.InferType<typeof schema>;

  const { errors, handleSubmit, register } = useForm<FormType>({
    resolver: yupResolver(schema),
    criteriaMode: "firstError",
    mode: "onSubmit",
    reValidateMode: "onChange"
  });

  const uploadAndCheckTheList: SubmitHandler<FormType> = async formData => {
    try {
      if(!userInfo?.brukerId) return;
      setIsUploadingList(true);
      const kjoretoyListe = formData.vehicleList
        .trim()
        .split(/\r?\n/)
        .map(reg => reg.replace(/\s/, ""));
      const {
        data: { result: kjoretoyList }
      } = await piggavService.postKjoretoyListe(userInfo?.brukerId, kjoretoyListe);
      dispatch({
        type: "BUY_BULK_SET_BEKREFTEDE_AND_UBEKREFTEDE_VEHICLES",
        ...kjoretoyList
      });
      await checkAndValidateListAndAddToBasket(kjoretoyList.ubekreftedeKjoretoy, kjoretoyList.bekreftedeKjoretoy);
    } catch (e) {
      // TODO handle error
    } finally {
      setIsUploadingList(false);
    }
  };

  return (
    <div className={`bg-grey-light p-4 ${className}`} {...rest}>
      <h2 className="heading-4 mb-4">
        <FormattedMessage id="buystickersbulkpage.heading.manualinput" />
      </h2>
      <p className="mb-1">
        <FormattedMessage id="buystickersbulkpage.howToUseInstructions.manually" />
      </p>
      <form className="inline-flex flex-col" onSubmit={handleSubmit(uploadAndCheckTheList)}>
        <TextArea
          rootClassName="self-start inline-block"
          label="Liste med kjøretøy"
          name="vehicleList"
          ref={register}
          placeholder={formatMessage({
            id: "buystickersbulkpage.textarea.placeholder"
          })}
          rows={10}
          cols={30}
          error={errors.vehicleList?.message}
        />
        <Button type="submit" disabled={isUploadingList} isLoading={isUploadingList} className="self-start">
          <FormattedMessage id="buystickersbulkpage.button.addToBasket" />
        </Button>
      </form>
    </div>
  );
}
