import React, { useLayoutEffect, useState } from "react";
import { FormattedMessage } from "locale/langUtils";
import OutgoingLink from "features/common/BaseComponents/LinkWIcon/LinkWIcon";
import { useIntl } from "react-intl";
import { isEmpty } from "lodash-es";

export default function IENotification(): JSX.Element {
  const [showIENotification, setShowIeNotification] = useState(false);
  const { locale } = useIntl();

  useLayoutEffect(() => {
    const isIe11OrBelow = !isEmpty(window.navigator.userAgent.match("Trident/|MSIE"));
    if (isIe11OrBelow) setShowIeNotification(true);
  }, []);

  if (showIENotification)
    return (
      <div className="flex justify-center w-screen osg-u-color-primary">
        <OutgoingLink
          icon="new-window"
          className="flex items-center justify-center w-full widescreen:w-widescreen-content-max-width osg-u-padding-3 text-5 visited:text-black hover:text-blue-hover"
          href={locale === "nb" ? "https://www.oslo.kommune.no/ie-varsel/" : "https://www.oslo.kommune.no/ie-varsel/"}
          rel="noopener noreferrer"
          target="_"
        >
          <h2 className="osg-u-heading-5 p-0">
            <FormattedMessage id="ieNotification.heading" />
          </h2>
          <span aria-hidden="true">&nbsp;-&nbsp;</span>
          <FormattedMessage id="ieNotification.message" />
        </OutgoingLink>
      </div>
    );
  return <></>;
}
