import {
  format,
  differenceInMinutes,
  addMinutes,
  isWithinInterval,
  eachMonthOfInterval,
  parseISO,
  isAfter,
  isSameDay,
  differenceInMilliseconds
} from "date-fns";
import { enUS, nb } from "date-fns/locale";

export function formatDate(date: string | Date, locale = "nb", formatString?: string): string {
  const dateObj = new Date(date);
  const l = locale === "nb" ? nb : enUS;
  return format(dateObj, formatString ?? "dd.MM.yyyy", { locale: l });
}

export function getMonthsInInterval(startDate: string | Date, endDate: string | Date): Date[] {
  return eachMonthOfInterval({
    start: new Date(startDate),
    end: new Date(endDate)
  });
}

export function isTimeLessThenOneMinuteInTheFuture(date: string): boolean {
  return differenceInMinutes(new Date(date), addMinutes(new Date(), 1)) < 1;
}

export function isWithinTimeRange(date: string | Date, start: string | Date, end: string | Date): boolean {
  const compareDate = new Date(date);
  const s = new Date(start);
  const e = new Date(end);
  return isWithinInterval(compareDate, { start: s, end: e });
}

export const erIdagEllerSenere = (dateToCheck: string | Date) => {
  let date = null;
  if (typeof dateToCheck === "string") date = parseISO(dateToCheck);
  else date = dateToCheck;
  const now = new Date();
  return isSameDay(now, date) || isAfter(now, date);
};

export const erIFremtiden = (dateToCheck: string | Date) => {
  let date = null;
  if (typeof dateToCheck === "string") date = parseISO(dateToCheck);
  else date = dateToCheck;
  const now = new Date();
  return differenceInMilliseconds(date, now) > 0;
};

export const erIFortiden = (dateToCheck: string | Date) => {
  let date = null;
  if (typeof dateToCheck === "string") date = parseISO(dateToCheck);
  else date = dateToCheck;
  const now = new Date();
  return differenceInMilliseconds(date, now) < 0;
};
