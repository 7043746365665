import React from "react";

export interface ProfileDetail {
  name: string;
  value: string;
}
interface ProfileDetailsProps extends React.HTMLProps<HTMLDivElement> {
  details: ProfileDetail[];
}
export default function ProfileDetails({ details, ...rest }: ProfileDetailsProps): JSX.Element {
  return (
    <div className="flex flex-col" {...rest}>
      {details.map(({ name, value }) => {
        if (!value) return null;
        return (
          <div key={`${name}-${value}`} className="flex tablet:justify-between ">
            <span className="w-2/5 tablet:w-1/3 osg-u-text-is-medium">{`${name}:`}</span>
            <span className="w-3/5 osg-u-padding-left-3 tablet:w-2/3  tablet:align-left truncate">{`${value}`}</span>
          </div>
        );
      })}
    </div>
  );
}
