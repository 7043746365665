import React, { useMemo, useState } from "react";

import InputField from "features/common/ComposedComponents/Inputfield/Inputfield";
import { useIntl } from "react-intl";
import { Button } from "features/common/ComposedComponents";
import { FormattedMessage } from "locale/langUtils";
import { useAuth } from "react-oidc-context";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { putVerifiserEpost } from "api/piggavService";
import { yupResolver } from "@hookform/resolvers";

export const sessionStorageUpdatedEmail = "updated-email";

export interface VerifyNewMailFormProps {
  onClose: () => void;
}

export default function VerifyNewMailForm({ onClose }: VerifyNewMailFormProps): JSX.Element {

  const [isSendingUpdateEmailRequest, setIsSendingUpdateEmailRequest] = useState(false);
  const [isEmailChanged, setIsEmailChanged] = useState(false);
  const { formatMessage } = useIntl();
  const auth = useAuth();

  const verifyEmailScheme = useMemo(
    () =>
      yup.object().shape({
        kode: yup.number().typeError("Kun tall").min(1000, "Minimum 4 sifre").max(9999, "Maks 4 sifre")
    }),
    []
  )


  const { handleSubmit, register, errors, setError } = useForm<yup.InferType<typeof verifyEmailScheme>>({
    mode: "onSubmit",
    resolver: yupResolver(verifyEmailScheme)
  });

  const handleSendVerifikasjonsKode = handleSubmit(async ({ kode }) => {
    try {
      setIsSendingUpdateEmailRequest(true);
      await putVerifiserEpost(kode);
      setTimeout(() => {
        auth.signinSilent(); // to refresh id token
      }, (3_000));
      setIsEmailChanged(true);
    } catch (e: any) {
      if (e.response?.data?.ErrorMessage) {
        setError("kode", {
          type: "errorWhenUpdating",
          message: e.response?.data?.ErrorMessage
        });
      }
      else {
        setError("kode", {
          type: "errorWhenUpdating",
          message: formatMessage({
            id: "profile.changeemail.errorwhenupdating"
          })
        });
      }

    } finally {
      setIsSendingUpdateEmailRequest(false);
    }
  })

  return (
    <form id="new-email-verifikasjon-form" className="mt-3" onSubmit={handleSendVerifikasjonsKode}><div className="flex justify-between flex-wrap mt-4">
        <div className="mb-2">
          <FormattedMessage id="profile.changeemail.verify.use.code" />
        </div>
        <InputField
          name="kode"
          autoComplete="one-time-code"
          label={formatMessage({ id: "profile.changeemail.verify.code", defaultMessage: "Verifiseringskode" })}
          type="text"
          error={errors.kode?.message}
          ref={register}
          />
        {isEmailChanged && (
          <div className="p-3 mt-3 bg-state-success">
            <FormattedMessage id="profile.changeemail.success" />
          </div>
        )}
        <div className="flex justify-between flex-wrap mt-4 w-full">
          <Button
            className="w-full tablet:w-1/2 mobile-only:mb-2"
            modifier="outline"
            disabled={isSendingUpdateEmailRequest}
            onClick={() => { onClose();}}
          >
            {isEmailChanged ? (
              <FormattedMessage id="profile.button.close" defaultMessage="Lukk" />
            ) : (
              <FormattedMessage id="common.cancel" defaultMessage="Avbryt" />
            )}
          </Button>
          <Button
            className="w-full tablet:w-1/2 "
            form="new-email-verifikasjon-form"
            type="submit"
            disabled={isSendingUpdateEmailRequest || isEmailChanged}
            isLoading={isSendingUpdateEmailRequest}
          >
            <FormattedMessage id="common.save" defaultMessage="Lagre epost" />
          </Button>
        </div>
      </div>
    </form>
  );
}


