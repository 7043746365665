/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-redeclare */
export const StickerPeriodId = {
  Day: 1,
  Month: 2,
  Season: 3
} as const;
export type StickerPeriodId = typeof StickerPeriodId[keyof typeof StickerPeriodId];

export const VehicleWeightId = {
  Light: 1,
  Heavy: 2
} as const;
export type VehicleWeightId = typeof VehicleWeightId[keyof typeof VehicleWeightId];

// Oblatstatuser
export const StickerStatus = {
  All: 0,
  Active: 1,
  Inactive: 2,
  Refunded: 3,
  AwaitingPayment: 4,
  Credited: 5,
  Canceled: 6,
  Transferred: 8, // Oblater med denne status iden er fake og eksisterer bare i endepunkt resultatet til get-Alle-Oblater-For-Kunde-endpunket
  Expired: 10,
  NotStartedFrontendOnly: 1001, // Reflects all stickers that has not yet startet.
  CanceledFrontendOnly: 10000 // It is used to reflects oblater with status id 3, 5 and 6, see above or in EntityConstanst.cs in backend.
} as const;
export type StickerStatus = typeof StickerStatus[keyof typeof StickerStatus];

export const CustomerTypeId = {
  Private: 1,
  Company: 2,
  Undefined: -1
} as const;
export type CustomerTypeId = typeof CustomerTypeId[keyof typeof CustomerTypeId];

// Error koder for kansellering av oblater betalt via NETS
export const bestillingIkkeGjonomfort = -1;
export const oblatErPabegynt = -2;
export const gjenvaerendeBelopForLite = -3;
export const refusjonMotNestsFeilet = -4;

// Error codes for cancelling of sticker bought with invoice
export const KREDITERINGS_ERRORS = {
  BESTILLING_IKKE_GJENNOMFØRT: -1,
  OBLATET_ER_PAABEGYNT: -2,
  GJENVAERENDE_EBELOP_FOR_LITE: -3,
  KREDITNOTA_MOT_AGRESSO_FEILET: -4
} as const;
export type KREDITERINGS_ERRORS = typeof KREDITERINGS_ERRORS[keyof typeof KREDITERINGS_ERRORS];

// Purchase status codes
export const PURCHASE_STATES = {
  NEW_PARCHASE: 100,
  INVOICE_SENT: 102,
  NETS_SENDT_TO_TERMINAL: 103,
  OK: 110,
  FAILED: 120
} as const;
export type PURCHASE_STATES = typeof PURCHASE_STATES[keyof typeof PURCHASE_STATES];

export const oblattyper = {
  dayStickerLight: {
    id: "a46cf3ea-55e1-4486-86e9-2899f2de2cd9",
    navn: "Dagsoblat (L)",
    periodeId: 1,
    kjoretoytypeId: 1
  },
  monthStickerLight: {
    id: "eba82886-02a4-4138-bc01-fb7c5d13d8d1",
    navn: "Månedsoblat (L)",
    periodeId: 2,
    kjoretoytypeId: 1
  },
  seasonStickerLight: {
    id: "c86b1531-3b24-485c-b5ee-f912486fc525",
    navn: "Sesongoblat (L)",
    periodeId: 3,
    kjoretoytypeId: 1
  },
  dayStickerHeavy: {
    id: "48b8e109-6af3-43c8-9e17-ed8e9c5bc2d2",
    navn: "Dagsoblat (T)",
    periodeId: 1,
    kjoretoytypeId: 2
  },
  monthStickerHeavy: {
    id: "93f28553-a9e1-467b-9a6c-847663821938",
    navn: "Månedsoblat (T)",
    periodeId: 2,
    kjoretoytypeId: 2
  },
  seasonStickerHeavy: {
    id: "4476b0ba-d879-4a36-b8f2-7c89b3173bb9",
    navn: "Sesongoblat (T)",
    periodeId: 3,
    kjoretoytypeId: 2
  }
} as const;
export type oblattyper = typeof oblattyper[keyof typeof oblattyper];

export enum OneLoginBrukerOppdateringStatus {
  Opprettet,
  Oppdatert
}
