import { useEffect, useState, useMemo } from "react";
import { useSelector } from "react-redux";
import { useReduxDispatch } from "utils/Storeutils";
import { getKjoretoyForBruker } from "state/Kjoretoy/kjoretoyActions";
import { getOblaterForBruker } from "state/Oblat/oblatActions";
import { getOblattyper } from "state/Oblattype/oblattypeActions";
import { createSeasonString, setStickerInfo } from "utils/oblatHelpers";
import { getState as getConfigState } from "state/Konfigurasjoner/konfigurasjonerReducer";
import { getState as getAuthState } from "state/Auth/Auth";
import { ApplicationState } from "state/reducers";
import { Configurations, StickerWInfo } from "interfaces";

export interface StickerWInfoAndSeason extends StickerWInfo {
  season: string;
}

interface OblaterHookApi {
  isLoading: boolean;
  oblater: StickerWInfoAndSeason[];
  currentSeason?: string;
}

export default function OblaterHook(): OblaterHookApi {
  const [isLoading, setIsLoading] = useState(true);
  const { userInfo } = useSelector(getAuthState);
  const { brukerId } = { ...userInfo };
  const { oblater, lasterOblater } = useSelector<ApplicationState, any>(state => state.oblater);
  const { sesongSlutt } = useSelector<ApplicationState, Configurations>(getConfigState);
  const dispatch = useReduxDispatch();

  useEffect(() => {
    if (!brukerId) return;
    (async () => {
      try {
        setIsLoading(true);
        dispatch(getKjoretoyForBruker(brukerId));
        dispatch(getOblattyper());
        await dispatch(getOblaterForBruker(brukerId));
      } finally {
        setIsLoading(false);
      }
    })();
  }, [brukerId, dispatch]);

  const currentSeason = useMemo(() => {
    return sesongSlutt?.verdi === undefined ? undefined : createSeasonString(new Date(sesongSlutt?.verdi));
  }, [sesongSlutt]);

  const stickersWInfo = useMemo(() => {
    const stWInfo = setStickerInfo(oblater);
    return stWInfo.map(o => {
      const validTo = new Date(o.oblat.gyldigTil);
      const season = createSeasonString(validTo);
      return { ...o, season };
    });
  }, [oblater]);

  return {
    oblater: stickersWInfo,
    isLoading: lasterOblater,
    currentSeason
  };
}
