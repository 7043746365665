import { useState, ReactElement } from "react";
import useSamtykkeHook from "features/Hooks/SamtykkeHook";

import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { Button } from "features/common/OSG/";
import { FormattedMessage, useIntl } from "locale/langUtils";
import { isEmpty } from "lodash-es";
import VilkaarText from "./VilkaarText";

interface VilkaarPopupOnlyTextProps {
  id?: string;
  toggleOpen?: () => void;
  isOpen?: boolean;
  children?: {
    openModalElement: (openModal: (open: boolean) => void, isOpen: boolean) => ReactElement;
  };
}

export default function VilkaarPopupOnlyText({
  isOpen,
  toggleOpen,
  children,
  id
}: VilkaarPopupOnlyTextProps): JSX.Element {
  const intl = useIntl();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    state: { vilkaar }
  } = useSamtykkeHook();
  const toggleOpeninside = children?.openModalElement ? () => setIsModalOpen(prev => !prev) : toggleOpen;
  return (
    <>
      {children?.openModalElement ? children?.openModalElement(setIsModalOpen, isModalOpen) : null}
      <Modal
        id={id}
        aria-label={intl.formatMessage({ id: "termsandconditions.heading" })}
        toggle={toggleOpeninside}
        isOpen={children?.openModalElement ? isModalOpen : isOpen}
        modalSize="large"
      >
        <ModalHeader
          className="sr-only"
          children={{
            heading: <FormattedMessage id="termsandconditions.heading" />
          }}
        ></ModalHeader>
        <ModalSection>
          {vilkaar && !isEmpty(vilkaar.vilkaarTekster) && <VilkaarText vilkaar={vilkaar.vilkaarTekster[0]} />}
        </ModalSection>
        <ModalSection>
          <Button modifier="outline" onClick={toggleOpeninside}>
            <FormattedMessage id="termsandconditions.button.close" />
          </Button>
        </ModalSection>
      </Modal>
    </>
  );
}
