import { useEffect } from "react";
import { Switch, useHistory } from "react-router-dom";
import PublicRoute from "features/Routes/PublicRoute";
import PrivatRoute from "features/Routes/PrivateRoute";
import Navbar from "features/navbar/Navbar";
import Footer from "features/footer/Footer";
import LandingPage from "features/landingpage/LandingPage";
import RegisterPage from "features/RegisterPage/RegisterPage";
import RegisterCompany from "features/RegisterCompanyPage/RegisterCompanyPage";
import ForgotPasswordPage from "features/ForgotPasswordPage/ForgotPasswordPage";
import NewPasswordPage from "features/NewPasswordPage/NewPasswordPage";
import RegisteredUserSuccessPage from "features/RegisterUserSuccessPage/RegisteredUserSuccessPage";
import ActivateUserPage from "features/ActivateUserPage/ActivateUserPage";
import ActivateUserPasswordPage from "features/ActivateUserPasswordPage/ActivateUserPasswordPage";
import NotAValidPage from "features/NotAValidPage/NotAValidPage";
import TermsAndConditionAcceptDeclineDialogue from "features/TermsAndConditions/TermsAndConditionAcceptDeclineDialogue";
import ProfilePage from "features/ProfilePage/ProfilePage";
import OverviewStickerPage from "features/OverviewStickersPage/OverviewStickerPage";
import ReceiptsPage from "features/Receipts/ReceiptsPage";
import ReceiptPage from "features/Receipts/ReceiptPage";
import BuyStickerPage from "features/BuyStickers/BuyStickerPage";
import SummaryPage from "features/CheckoutPage/SummaryPage";
import BuyStickersBulkPage from "features/BuyStickersBulk/BuyStickersBulk";
import CheckNetsPaymentPage from "features/CompletePaymentPage/CompletePaymentPageContainer";
import InvoicePaymentPage from "features/CompleteInvoicePaymentPage/InvoicePaymentPageContainer";
import RefundConfirmedPage from "features/RefundConfirmedPage/RefundConfirmedPage";
import Faq from "features/Faq/FaqPage";
import * as urls from "appConstants/urls";
import { useReduxDispatch } from "utils/Storeutils";
import { getSystemkonfigurasjon } from "state/Konfigurasjoner/konfigurasjonerActions";
import MessagesToUser from "features/MessagesToUser/MessagesToUser";
import { getState as getConfigState } from "state/Konfigurasjoner/konfigurasjonerReducer";
import { useSelector } from "react-redux";
import { ApplicationState } from "state/reducers";
import { Configurations } from "interfaces";
import { useAuth } from "react-oidc-context";
import Login from "features/login/Login";
import { loginUser as loginUserAction, logoutUserAction } from "state/Auth/Auth";
import AuthErrorDialogue from "Auth/AuthErrorDialogue";
import ForcefullyLoggedOutModal, { setWasForcefullyLoggedOut } from "Auth/ForcefullyLoggedOutModal";

const retryAttempts = "retryAttempts";

function App() {
  const auth = useAuth();
  const history = useHistory();
  const dispatch = useReduxDispatch();

  useEffect(() => {
    function expired() {
      dispatch(logoutUserAction);
      setWasForcefullyLoggedOut(true);
      auth.signoutRedirect();
    }
    auth.events.addAccessTokenExpired(expired);
    return () => {
      auth.events.removeAccessTokenExpired(expired);
    };
  }, [auth, auth.events, dispatch]);

  useEffect(() => {
    const search = new URLSearchParams(history.location.search);
    if (
      !auth.isAuthenticated &&
      history.location.pathname === "/" &&
      search.get("code") &&
      auth?.error?.message === "No matching state found in storage" &&
      (sessionStorage.getItem(retryAttempts) ?? 0) == 0
    ) {
      sessionStorage.setItem(retryAttempts, "1");
      auth.signinRedirect();
    }
  }, [auth, auth.error?.name, auth.isAuthenticated, history]);

  useEffect(() => {
    if (!auth.isAuthenticated) return;
    if (auth.user?.access_token) dispatch(loginUserAction(auth.user.access_token));
    sessionStorage.setItem(retryAttempts, "0");
  }, [auth.isAuthenticated, auth.user, dispatch]);

  useEffect(() => {
    dispatch(getSystemkonfigurasjon());
  }, [dispatch]);

  const { salgAapent } = useSelector<ApplicationState, Configurations>(getConfigState);

  return (
    <>
      <div className="flex flex-col items-stretch min-h-screen">
        <Navbar />
        <main className="flex-grow  main-content-margin">
          <MessagesToUser />
          <Switch>
            <PublicRoute exact path={urls.hjem} component={LandingPage} />
            <PublicRoute exact path={urls.login} component={Login} />
            <PublicRoute exact path={urls.registrerBruker} component={RegisterPage} />
            <PublicRoute exact path={urls.registerCompany} component={RegisterCompany} />
            <PublicRoute exact path={urls.glemtPassord} component={ForgotPasswordPage} />
            <PublicRoute exact path={urls.createNewPassword} component={NewPasswordPage} />
            <PublicRoute exact path={urls.takkForRegistreringUrl} component={RegisteredUserSuccessPage} />
            <PublicRoute exact path={urls.aktiverBruker} component={ActivateUserPage} />
            <PublicRoute exact path={urls.activatePassword} component={ActivateUserPasswordPage} />
            <PublicRoute exact path={urls.faq} component={Faq} />
            {/* Private routes */}
            <PrivatRoute exact path={urls.oblatoversikt} component={OverviewStickerPage} />
            <PrivatRoute exact path={urls.profil} component={ProfilePage} />
            <PrivatRoute exact path={urls.kvittering} component={ReceiptsPage} />
            <PrivatRoute exact path={urls.kvitteringPath} component={ReceiptPage} />
            <PrivatRoute exact path={urls.kjopOblater} shouldRenderRoute={salgAapent} component={BuyStickerPage} />
            <PrivatRoute
              exact
              path={[urls.bulkKjop, urls.kontrollerBulkkjop]}
              restrictToKundeType="Bedriftkunde"
              shouldRenderRoute={salgAapent}
              component={BuyStickersBulkPage}
            />
            <PrivatRoute exact path={[urls.summaryAddSingle]} shouldRenderRoute={salgAapent} component={SummaryPage} />
            <PrivatRoute
              exact
              path={[urls.summaryAddBulk]}
              restrictToKundeType="Bedriftkunde"
              shouldRenderRoute={salgAapent}
              component={SummaryPage}
            />
            <PrivatRoute exact path={urls.betalingGjenomfort} component={CheckNetsPaymentPage} />
            <PrivatRoute exact path={urls.refusjonBekreftet} component={RefundConfirmedPage} />
            <PrivatRoute
              exact
              path={urls.betalingFaktura}
              restrictToKundeType="Bedriftkunde"
              component={InvoicePaymentPage}
            />

            <PublicRoute path="*" component={NotAValidPage} />
          </Switch>
        </main>
        <Footer className="flex-shrink-0" />
        <TermsAndConditionAcceptDeclineDialogue />
      </div>
      <AuthErrorDialogue />
      <ForcefullyLoggedOutModal />
    </>
  );
}

export default App;
