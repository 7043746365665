import {
  FormattedMessage as FormattedMessageIntl,
  useIntl as useReactIntl,
  IntlFormatters,
  MessageFormatElement,
  MessageDescriptor
} from "react-intl";
import { isProduction } from "../utils/environment";

const getDefaultMessage = (
  locale: string,
  id?: MessageDescriptor["id"],
  defaultMessage?: string | MessageFormatElement[]
) => defaultMessage ?? (isProduction() ? " " : `{Missing translation - ${locale}: ${id ?? "No id provided"}}`);

export function FormattedMessage(props: Parameters<typeof FormattedMessageIntl>[0]): JSX.Element {
  const intl = useReactIntl();
  const { id, defaultMessage, values } = props;
  return (
    <FormattedMessageIntl id={id} defaultMessage={getDefaultMessage(intl.locale, id, defaultMessage)} values={values} />
  );
}

type FormatMessageArgs = Parameters<IntlFormatters<React.ReactNode>["formatMessage"]>;

function useIntl() {
  const { formatMessage, locale, ...rest } = useReactIntl();
  const formatMessageWrapper: typeof formatMessage = (
    descriptor: FormatMessageArgs[0] & {
      id?: MessageDescriptor["id"];
    },
    values?: FormatMessageArgs[1],
    options?: FormatMessageArgs[2]
  ) =>
    formatMessage(
      { ...descriptor, defaultMessage: getDefaultMessage(locale, descriptor.id, descriptor?.defaultMessage) },
      values,
      options
    ) as string;

  return {
    ...rest,
    formatMessage: formatMessageWrapper,
    locale
  };
}

export * from "react-intl";

export { useIntl };
