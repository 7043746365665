import React, { ReactNode } from "react";

export interface FigureProps {
  children: {
    figCaption?: ReactNode;
    images: ReactNode;
    content?: ReactNode;
  };
}

export default function Figure({ children: { figCaption, images, content } }: FigureProps): JSX.Element {
  return (
    <figure className="osg-figure osg-v-default ">
      <picture>{images}</picture>
      {figCaption && <figcaption className="osg-figcaption osg-u-text-7">{figCaption}</figcaption>}
      {content}
    </figure>
  );
}
