import { StickerWInfo } from "interfaces";
import DateTimeFormat from "locale/DateTimeFormat";

import { FormattedMessage } from "locale/langUtils";
interface StickerInfoBlockProps {
  oblat: StickerWInfo;
  className?: string;
}

export default function StickerInfo({ oblat, className }: StickerInfoBlockProps): JSX.Element {
  return (
    <div className={`bg-grey-light w-full p-4 ${className ?? ""}`}>
      <div className="flex items-center ">
        <span className="font-medium">
          <FormattedMessage id="common.stickerinfo.stickertype" defaultMessage="Oblattype" />:
        </span>
        &nbsp;
        <FormattedMessage id={oblat.name} defaultMessage="" />
      </div>
      <div className="">
        <span className="font-medium">
          <FormattedMessage id="common.stickerinfo.validfrom" defaultMessage="Gyldig fra" />:{" "}
        </span>
        <DateTimeFormat value={oblat.oblat.gyldigFra} />
      </div>
      <div className="">
        <span className="font-medium">
          <FormattedMessage id="common.stickerinfo.validto" defaultMessage="Gyldig til" />:{" "}
        </span>
        <DateTimeFormat value={oblat.oblat.gyldigTil} />
      </div>
      <div className="">
        <span className="font-medium">
          <FormattedMessage id="common.stickerinfo.numberplate" defaultMessage="Registreringsnummer" />:{" "}
        </span>
        {oblat.oblat.kjoretoy?.registreringsnummer ?? " "}
      </div>
    </div>
  );
}
