import { piggavService } from "api";
import { isWithinInterval, addMonths } from "date-fns";
import { filter } from "lodash-es";
import { getOblaterForBruker } from "state/Oblat/oblatActions";

export const SET_LOADING = "SET_HANDLEKURV_LOADING";
export const CREATE_HANDLEKURV_REQUEST = "CREATE_HANDLEKURV_REQUEST";
export const CREATE_HANDLEKURV_SUCCESS = "CREATE_HANDLEKURV_SUCCESS";
export const CREATE_HANDLEKURV_FALIURE = "CREATE_HANDLEKURV_FALIURE";
export const GET_HANDLEKURV_REQUEST = "GET_HANDLEKURV_REQUEST";
export const GET_HANDLEKURV_SUCCESS = "GET_HANDLEKURV_SUCCESS";
export const GET_HANDLEKURV_FAILURE = "GET_HANDLEKURV_FAILURE";
export const ADD_VARE_TO_HANDLEKURV_REQUEST = "ADD_VARE_TO_HANDLEKURV_REQUEST";
export const ADD_VARE_TO_HANDLEKURV_SUCCESS = "ADD_VARE_TO_HANDLEKURV_SUCCESS";
export const ADD_VARER_IN_BULK_SUCCESS = "ADD_VARER_IN_BULK_SUCCESS";
export const ADD_VARE_TO_HANDLEKURV_FAILURE = "ADD_VARE_TO_HANDLEKURV_FAILURE";
export const LAGRE_BESTILLING_REQUEST = "LAGRE_BESTILLING_REQUEST";
export const LAGRE_BESTILLING_SUCCESS = "LAGRE_BESTILLING_SUCCESS";
export const LAGRE_BESTILLING_FAILURE = "LAGRE_BESTILLING_FAILURE";
export const LAGRE_BESTILLING_FAKTRA_REQUEST = "LAGRE_BESTILLING_FAKTRA_REQUEST";
export const LAGRE_BESTILLING_FAKTRA_SUCCESS = "LAGRE_BESTILLING_FAKTRA_SUCCESS";
export const LAGRE_BESTILLING_FAKTRA_FAILURE = "LAGRE_BESTILLING_FAKTRA_FAILURE";
export const REMOVE_VARE_FROM_HANDLEKURV_REQUEST = "REMOVE_VARE_FROM_HANDLEKURV_REQUEST";
export const REMOVE_VARE_FROM_HANDLEKURV_SUCCESS = "REMOVE_VARE_FROM_HANDLEKURV_SUCCESS";
export const REMOVE_VARE_FROM_HANDLEKURV_FAILURE = "REMOVE_VARE_FROM_HANDLEKURV_FAILURE";
export const SJEKK_BETALING_REQUEST = "SJEKK_BETALING_REQUEST";
export const SJEKK_BETALING_SUCCESS = "SJEKK_BETALING_SUCCESS";
export const SJEKK_BETALING_FAILURE = "SJEKK_BETALING_FAILURE";
export const GET_VAREKJOP_BY_ID_REQUEST = "GET_VAREKJOP_BY_ID_REQUEST";
export const GET_VAREKJOP_BY_ID_SUCCESS = "GET_VAREKJOP_BY_ID_SUCCESS";
export const GET_VAREKJOP_BY_ID_FALIURE = "GET_VAREKJOP_BY_ID_FALIURE";
export const RESET_HANDLEKURV = "piggav/handlekurv/RESET_HANDLEKURV";
export const IS_HANDLEKURV_OPEN = "piggav/handlekurv/IS_HANDLEKURV_OPEN";

export const setIsHandlekurvOpen = (payload) => ({
  type: IS_HANDLEKURV_OPEN,
  payload
});

export const resetHandlekurvState = () => ({
  type: RESET_HANDLEKURV,
  payload: undefined
});

const removeVareFromHandlekurvRequest = payload => ({
  type: REMOVE_VARE_FROM_HANDLEKURV_REQUEST,
  payload
});

const removeVareFromHandlekurvSuccess = varekjop => ({
  type: REMOVE_VARE_FROM_HANDLEKURV_SUCCESS,
  payload: varekjop
});

const getHandlekurvOblaterRequest = () => ({
  type: GET_HANDLEKURV_REQUEST,
  payload: ""
});

const getHandlekurvOblaterSuccess = varekjop => ({
  type: GET_HANDLEKURV_SUCCESS,
  payload: varekjop
});

const getHandlekurvOblaterFailure = error => ({
  type: GET_HANDLEKURV_FAILURE,
  payload: error,
  error: true
});

const addOblatToHandlekurvRequest = payload => ({
  type: ADD_VARE_TO_HANDLEKURV_REQUEST,
  payload
});

const addOblatToHandlekurvSuccess = oblat => ({
  type: ADD_VARE_TO_HANDLEKURV_SUCCESS,
  payload: oblat
});

const addOblatToHandlekurvFailure = () => ({
  type: ADD_VARE_TO_HANDLEKURV_FAILURE,
  payload: true
});

const lagreBestillingRequest = () => ({
  type: LAGRE_BESTILLING_REQUEST,
  payload: ""
});

const lagreBestillingSuccess = varekjop => ({
  type: LAGRE_BESTILLING_SUCCESS,
  payload: varekjop
});

const lagreBestillingFailure = error => ({
  type: LAGRE_BESTILLING_FAILURE,
  payload: error,
  error: true
});

const sjekkBetalingstatusRequest = () => ({
  type: SJEKK_BETALING_REQUEST,
  payload: ""
});

const sjekkBetalingstatusSuccess = varekjop => ({
  type: SJEKK_BETALING_SUCCESS,
  payload: varekjop
});

const sjekkBetalingstatusFailure = error => ({
  type: SJEKK_BETALING_FAILURE,
  payload: error,
  error: true
});

const lagreBestillingFakturaRequest = () => ({
  type: LAGRE_BESTILLING_FAKTRA_REQUEST,
  payload: ""
});

const createHandlekurvSuccess = varekjop => ({
  type: CREATE_HANDLEKURV_SUCCESS,
  payload: varekjop
});

const createHandlekurvFailure = error => ({
  type: CREATE_HANDLEKURV_FALIURE,
  payload: error
});

const getHandlekurvVarekjopByIdSuccess = varekjop => ({
  type: GET_VAREKJOP_BY_ID_SUCCESS,
  payload: varekjop
});

const getHandlekurvVarekjopByIdFailure = error => ({
  type: GET_VAREKJOP_BY_ID_FALIURE,
  payload: error,
  error: true
});

const setHandlekurvLoading = isLoading => ({
  type: SET_LOADING,
  payload: isLoading
});

const addOblatInBulkToHandlekurvSuccess = ordrelinjer => ({
  type: ADD_VARER_IN_BULK_SUCCESS,
  payload: ordrelinjer
});

export const addOblaterInBulkToVarekjop = (brukerId, kjoretoyListe, varekjopId) => dispatch => {
  dispatch(addOblatToHandlekurvRequest(true));
  return piggavService
    .createBulkkjop(brukerId, kjoretoyListe, varekjopId)
    .then(response => {
      const { result, errorkode } = response.data;
      if (!(errorkode === 409)) {
        // dispatch(addOblatToHandlekurvSuccess(result));
        dispatch(addOblatInBulkToHandlekurvSuccess(result));
      }
      return Promise.resolve(response);
    })
    .finally(() => dispatch(addOblatToHandlekurvRequest(false)));
};

export const getHandlekurvVarekjopById = (brukerId, varekjopId) => dispatch => {
  return piggavService
    .getVarekjopById(brukerId, varekjopId)
    .then(response => {
      const data = response.data.result;
      dispatch(getHandlekurvVarekjopByIdSuccess(data));
    })
    .catch(error => {
      dispatch(getHandlekurvVarekjopByIdFailure(error));
    });
};

export const getHandlekurvOblater = (brukerId, varekjopId) => async (dispatch, state) => {
  dispatch(getHandlekurvOblaterRequest(true));
  try {
    const response = await piggavService.getOblaterForVarekjop(brukerId, varekjopId);
    const oblaterOutOfSeasonToBeDeleted = [];
    // Identify all stickers out of season
    const intervalStart = addMonths(new Date(state().konfigurasjon.sesongStart.verdi), 0 - 1);
    const intervalEnd = addMonths(new Date(state().konfigurasjon.sesongSlutt.verdi), 1);
    const seasonInterval = { start: intervalStart, end: intervalEnd };
    const oblaterInSeason = filter(response.data.result, o => {
      if (
        isWithinInterval(new Date(o.gyldigFra), seasonInterval) &&
        isWithinInterval(new Date(o.gyldigTil), seasonInterval)
      )
        return true;
      oblaterOutOfSeasonToBeDeleted.push(() => piggavService.deleteOblat(brukerId, varekjopId, o.id));
      return false;
    });
    // Delete all stickers out of season
    try {
      await Promise.all(oblaterOutOfSeasonToBeDeleted.map(r => r()));
    } catch (error) {
      if (error.response.status === 401) console.log("Error, not found", error);
      else throw error;
    }
    return dispatch(getHandlekurvOblaterSuccess(oblaterInSeason));
  } catch (error) {
    return dispatch(getHandlekurvOblaterFailure(error));
  } finally {
    dispatch(getHandlekurvOblaterRequest(false));
  }
};

export const createHandlekurv = brukerId => async (dispatch, state) => {
  if (state().handlekurv.handlekurvLoading) return;
  try {
    dispatch(setHandlekurvLoading(true));
    const response = await piggavService.createVarekjop(brukerId);
    const data = response.data.result;
    dispatch(createHandlekurvSuccess(data.id));
    dispatch(getHandlekurvVarekjopById(brukerId, data.id));
    await dispatch(getHandlekurvOblater(brukerId, data.id));
  } catch (error) {
    dispatch(createHandlekurvFailure(error.response));
  } finally {
    dispatch(setHandlekurvLoading(false));
  }
};

export function addOblatToHandlekurv(brukerId, varekjopId, payload) {
  return dispatch => {
    dispatch(addOblatToHandlekurvRequest(true));
    return piggavService
      .postOblat(brukerId, varekjopId, payload)
      .then(response => {
        const { result, errorkode } = response.data;
        if (errorkode) dispatch(addOblatToHandlekurvFailure());
        else dispatch(addOblatToHandlekurvSuccess(result));
        // dispatch(getHandlekurvOblater(brukerId, varekjopId));
        return Promise.resolve(response);
      })
      .catch(response => {
        const { status, statusText, data } = response;
        const errorResponse = { status, statusText, data };
        dispatch(addOblatToHandlekurvFailure());
        return Promise.reject(errorResponse);
      })
      .finally(() => {
        addOblatToHandlekurvRequest(false);
      });
  };
}

export const removeVareFromHandlekurv = (brukerId, varekjopId, oblatId) => dispatch => {
  dispatch(removeVareFromHandlekurvRequest(true));
  return piggavService
    .deleteOblat(brukerId, varekjopId, oblatId)
    .then(response => {
      const data = response.data.result;
      dispatch(removeVareFromHandlekurvSuccess(data));
      dispatch(getHandlekurvOblater(brukerId, varekjopId));
    })
    .catch(() => {
      dispatch(getHandlekurvOblater(brukerId, varekjopId));
    })
    .finally(() => {
      dispatch(removeVareFromHandlekurvRequest(false));
    });
};

export const lagreBestilling = (brukerId, varekjopId) => dispatch => {
  dispatch(lagreBestillingRequest());
  return piggavService
    .lagreBestilling(brukerId, varekjopId)
    .then(response => {
      const data = response.data.result;
      // don't redirect to nets if we are using fake nets data
      if (data && data.isFake === true) {
        window.location.href = `/hjem/gjenomfort-betaling/transaksjon?v=1&transactionId=${data.fakeTransaksjonId}`;
      } else {
        window.location.href = data.url;
      }
      dispatch(lagreBestillingSuccess(data.id));
      Promise.resolve(response);
    })
    .catch(error => {
      dispatch(lagreBestillingFailure(error));
      return Promise.reject(error);
    });
};

export const lagreBestillingFaktura = (brukerId, varekjopId) => dispatch => {
  dispatch(lagreBestillingFakturaRequest());
  return piggavService.lagreBestillingFaktura(brukerId, varekjopId).then(res => {
    dispatch(getOblaterForBruker(brukerId, true));
    return Promise.resolve(res);
  });
};

export const lagreBestillingFakturaPerBil = (brukerId, varekjopId, sendEpostKvittering) => dispatch => {
  dispatch(lagreBestillingFakturaRequest());
  return piggavService.lagreBestillingFakturaPerBil(brukerId, varekjopId, sendEpostKvittering).then(res => {
    dispatch(getOblaterForBruker(brukerId, true));
    return Promise.resolve(res);
  });
};

export const lagreBestillingKontanter = (brukerId, varekjopId) => dispatch => {
  dispatch(lagreBestillingFakturaRequest());
  return piggavService.lagreBestillingKontanter(brukerId, varekjopId);
};

export const lagreBestillingTerminal = (brukerId, varekjopId) => dispatch => {
  dispatch(lagreBestillingFakturaRequest());
  return piggavService.lagreBestillingTerminal(brukerId, varekjopId);
};

export const sjekkBetalingstatus = transaksjonsId => dispatch => {
  dispatch(sjekkBetalingstatusRequest());
  return piggavService
    .sjekkBetalingsstatus(transaksjonsId)
    .then(response => {
      const data = response.data.result;
      dispatch(sjekkBetalingstatusSuccess(data));
    })
    .catch(error => {
      dispatch(sjekkBetalingstatusFailure(error.response));
    });
};
