/* eslint-disable @typescript-eslint/no-use-before-define */
import { useLayoutEffect } from "react";
import "./RemoveFocusRingOnClick.scss";

const handleFirstClick = () => {
  document.body.classList.remove("user-is-tabbing");
  document.removeEventListener("mousedown", handleFirstClick);
  document.addEventListener("keydown", handleFirstTab);
};

const handleFirstTab = (event: KeyboardEvent) => {
  if (event.key === "Tab") {
    document.body.classList.add("user-is-tabbing");
    document.removeEventListener("keydown", handleFirstTab);
    document.addEventListener("mousedown", handleFirstClick);
  }
};

const RemoveFocusRingOnClick = () => {
  useLayoutEffect(() => {
    document.addEventListener("keydown", handleFirstTab);
    return () => {
      document.removeEventListener("keydown", handleFirstTab);
      document.removeEventListener("mousedown", handleFirstClick);
    };
  });
  return null;
};

export default RemoveFocusRingOnClick;
