import React, { useCallback, useMemo } from "react";
import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { Button } from "features/common/OSG";
// import OblatListeTabell from "../Common/OblatListeTabell/OblatListeTabell";
import { FormattedMessage } from "locale/langUtils";
import { filter, isEmpty, every, some, find } from "lodash-es";
import { StickerPeriodId } from "appConstants/identifiers";
import OblatListeTabell from "features/common/OblatListeTabell/OblatListeTabell";
import { useSelector } from "react-redux";
import "./OverlappingStickerDialogue.css";
import MessageBoxWIcon from "features/common/ComposedComponents/MessageBoxWIcon/MessageBoxWIcon";
import { Kjoretoy, Oblattype } from "interfaces";

interface OverlappingStickerDialogueProps {
  show: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
  onHide?: () => void;
  varekjopId: string;
  overlappendeOblaterList: any[];
  chosenStickerId: string;
  kjoretoy?: Kjoretoy;
}

const allStickersInBasket = (stickerList: any[], varekjopId: string): boolean =>
  every(stickerList, sticker => sticker.varekjopId === varekjopId);

const noneOfStickersInBasket = (stickerList: any[], varekjopId: string): boolean =>
  every(stickerList, sticker => sticker?.varekjopId !== varekjopId);

const getSameStickerMessage = (
  inBasket: boolean,
  periodeId?: StickerPeriodId
): { headerText: string; bodyText: string } => {
  switch (periodeId) {
    case StickerPeriodId.Day:
      return {
        headerText: "buystickers.dialogue.overlappingsticker.heading",
        bodyText: inBasket
          ? "buystickers.dialogue.overlappingsticker.dayStickerAllreadyInBasketMessage"
          : "buystickers.dialogue.overlappingsticker.overlappingKjopDayStickerMessage"
      };
    case StickerPeriodId.Month:
      return {
        headerText: "buystickers.dialogue.overlappingsticker.heading",
        bodyText: inBasket
          ? "buystickers.dialogue.overlappingsticker.monthStickerAllreadyInBasketMessage"
          : "buystickers.dialogue.overlappingsticker.overlappingKjopMonthStickerMessage"
      };
    case StickerPeriodId.Season:
      return {
        headerText: "buystickers.dialogue.overlappingsticker.heading",
        bodyText: inBasket
          ? "buystickers.dialogue.overlappingsticker.seasonStickerAllreadyInBasketMessage"
          : "buystickers.dialogue.overlappingsticker.overlappingKjopSeasonStickerMessage"
      };
    default:
      return {
        headerText: "buystickers.dialogue.overlappingsticker.heading",
        bodyText: "buystickers.dialogue.overlappingsticker.overlappingStickerDefaultMessage"
      };
  }
};
function OverlappingStickerDialogue({
  show,
  onHide,
  onConfirm,
  onCancel,
  varekjopId,
  overlappendeOblaterList,
  chosenStickerId = "",
  kjoretoy
}: OverlappingStickerDialogueProps): JSX.Element {
  const oblattyper = useSelector<any, Oblattype[]>(state => state.oblattyper.oblattyper);

  const rightTexts = useMemo(() => {
    const valgtOblattype = find(oblattyper, o => o.id === chosenStickerId);

    const sameStickerList = filter(overlappendeOblaterList, o => {
      if (o?.isEkstern) return false;
      return o?.oblattype?.id === valgtOblattype?.id;
    });

    const sameStickerBasket = some(sameStickerList, o => o.varekjopId === varekjopId);

    if (!isEmpty(sameStickerList))
      // If the same sticker exists
      return {
        ...getSameStickerMessage(sameStickerBasket, valgtOblattype?.periodeId as StickerPeriodId),
        confirmBtnText: null,
        cancelBtnText: "buystickers.dialogue.overlappingsticker.confirmBtn",
        sameStickerList
      };

    if (allStickersInBasket(overlappendeOblaterList, varekjopId)) {
      // If all overlapping stickers in basket
      return {
        headerText: "buystickers.dialogue.overlappingsticker.heading",
        bodyText: "buystickers.dialogue.overlappingsticker.defaultOverlappingInBasketText",
        confirmBtnText: null,
        cancelBtnText: "buystickers.dialogue.overlappingsticker.confirmBtn"
      };
    }
    if (noneOfStickersInBasket(overlappendeOblaterList, varekjopId)) {
      // If none of the stickers are in basket
      return {
        headerText: "buystickers.dialogue.overlappingsticker.heading",
        bodyText: "buystickers.dialogue.overlappingsticker.allreadyHasOverlappingStickers",
        confirmBtnText: "buystickers.dialogue.overlappingsticker.addStickerBtn",
        cancelBtnText: "buystickers.dialogue.overlappingsticker.cancelBtn"
      };
    }
    // If mixed both in basket and allready bought
    return {
      headerText: "buystickers.dialogue.overlappingsticker.heading",
      bodyText: "buystickers.dialogue.overlappingsticker.overlappingStickersInBasketAndServer",
      confirmBtnText: null,
      cancelBtnText: "buystickers.dialogue.overlappingsticker.confirmBtn"
    };
  }, [oblattyper, overlappendeOblaterList, varekjopId, chosenStickerId]);

  const setButtonRow = useCallback(
    (): JSX.Element => (
      <>
        {onConfirm && rightTexts.confirmBtnText && (
          <Button className=" mobile-only:mb-2 tablet:mr-2" onClick={onConfirm}>
            <FormattedMessage id={rightTexts.confirmBtnText} />{" "}
            <span className="sr-only">
              <FormattedMessage id="common.modal.btn.close.aria" />
            </span>
          </Button>
        )}
        {onCancel && rightTexts.cancelBtnText && (
          <Button modifier="outline" colorOption="yellow" onClick={onCancel}>
            <FormattedMessage id={rightTexts.cancelBtnText} />{" "}
            <span className="sr-only">
              <FormattedMessage id="common.modal.btn.close.aria" />
            </span>
          </Button>
        )}
      </>
    ),
    [onCancel, onConfirm, rightTexts.cancelBtnText, rightTexts.confirmBtnText]
  );

  return (
    <Modal role="alertdialog" isOpen={show} onHide={onHide} modalSize="large">
      <ModalHeader>
        {{
          heading: <FormattedMessage id={rightTexts.headerText} />
        }}
      </ModalHeader>
      <ModalSection>
        <MessageBoxWIcon type="warning" icon="exclamation-mark-circle" className="mb-3">
          <FormattedMessage id={rightTexts.bodyText} />{" "}
        </MessageBoxWIcon>
        <OblatListeTabell oblaterList={overlappendeOblaterList} kjoretoy={kjoretoy} />
      </ModalSection>
      <ModalSection className="flex mobile-only:flex-col tablet:justify-end">{setButtonRow()}</ModalSection>
    </Modal>
  );
}

export default OverlappingStickerDialogue;
