/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";
import "./Radio.scss";
import { StatePropType } from "../Interface";

interface RadioProps extends React.HTMLProps<HTMLInputElement>, StatePropType {}

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  ({ id, type, label, className, children, state, required, ...rest }, ref) => {
    return (
      <label htmlFor={id} className="radio-label mb-3">
        <input id={id} type="radio" {...rest} ref={ref} />
        <span className="radio-checkmark" />
        <span className="radio-label-text osg-u-padding-left-1">
          {label ?? children}
          {`${required ? " *" : ""}`}
        </span>
      </label>
    );
  }
);

export default Radio;
