import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "@formatjs/intl-getcanonicallocales/polyfill";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { Provider as StoreProvider } from "react-redux";
import { applicationStore } from "state";
import { AuthProvider } from "react-oidc-context";
import Scrolltop from "features/Scrolltop/Scrolltop";
import RemoveFocusRingOnClick from "features/common/RemoveFocusRingOnClick/RemoveFocusRingOnClick";
import { WindowUtilsProvider } from "features/Hooks/WindowUtilsHook";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import LocaleProvider, { nbConfig } from "./locale/LocaleProvider";
import "styles/index.scss";
import { oidcConfig } from "Auth/oidcConfig";

ReactDOM.render(
  <AuthProvider {...oidcConfig}>
    <StoreProvider store={applicationStore}>
      <LocaleProvider defaultConfig={nbConfig}>
        <BrowserRouter>
          <WindowUtilsProvider>
            <RemoveFocusRingOnClick />
            <Scrolltop />
            <App />
          </WindowUtilsProvider>
        </BrowserRouter>
      </LocaleProvider>
    </StoreProvider>
  </AuthProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
