// types
const ERROR = "toast/ERROR";
const SUCCESS = "toast/SUCCESS";
const CLEAR = "toast/CLEAR";

export default function reducer(state = { error: undefined, success: undefined }, action = {}) {
  switch (action.type) {
    case ERROR:
      return {
        ...state,
        error: action.payload
      };

    case SUCCESS:
      return {
        ...state,
        success: action.payload
      };

    case CLEAR:
      return {
        ...state,
        error: null,
        success: null
      };
    default:
      return { ...state };
  }
}

export function toastError(message) {
  return { type: ERROR, payload: message };
}

export function toastSuccess(message) {
  return { type: SUCCESS, payload: message };
}

export function resetToast() {
  return { type: CLEAR };
}

export const errorSelector = state => state.toast.error;
export const successSelector = state => state.toast.success;
