/**
 * Function that returns the value of a given environment variable.
 * It first checks if the variable is defined on the global window object,
 * if not it falls back to process.env.
 */
export function getEnvironmentVariable(shortKey: string, defaultValue?: string) {
  const key = `REACT_APP_${shortKey}`;
  const env = (window as any)[key] || process.env[key];
  if (env != null) return env;
  if (defaultValue) return defaultValue;
  throw new Error(`Missing environment configuration with key '${key}'`);
}

export function getEnvironment() {
  return getEnvironmentVariable("REACT_ENV", "development");
}

export function isDevelopment() {
  const env = getEnvironment();
  return env === "development";
}

export function isTestEnvironment() {
  const env = getEnvironment();
  return env === "testing";
}

export function isProduction() {
  const env = getEnvironment();
  return env === "production";
}
