interface BYMAddressProps {
    className?: string;
  }
export function BYMAddress({className}:BYMAddressProps) {
  return (
    <div className={className}>
        <h2>Oslo kommune, Bymiljøetaten</h2>
        <ul>
        <li>Karvesvingen 3</li>
        <li>0579 Oslo</li>
        <li>Org.nr.: 996 922 766</li>
        </ul>
    </div>
  );
}
