/* eslint-disable react/button-has-type */
import { Icon, IconTypes } from "features/common/OSG";
import React from "react";
import "./IconButton.scss";

interface IconButtonProps extends React.HTMLProps<HTMLButtonElement> {
  icon: IconTypes;
  type?: "button" | "submit" | "reset";
}
export default function IconButton({ icon, type, className, ...rest }: IconButtonProps): JSX.Element {
  return (
    <button className={`icon-button ${className}`} type={type ?? "button"} {...rest}>
      <Icon icon={icon} />
    </button>
  );
}
