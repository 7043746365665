import { IconTypes } from "features/common/OSG";
import { OSGPattern } from "features/common/OSG/interfaces";
import React, { ReactNode } from "react";
import "./IconLink.scss";

interface IconLinkProps extends OSGPattern<undefined, string>, React.HTMLProps<HTMLAnchorElement> {
  icon: IconTypes;
  children: {
    title: ReactNode;
    prompt?: ReactNode;
  };
}

export default function IconLink({
  icon,
  modifier = "",
  variant = "default",
  children: { title, prompt },
  ...rest
}: IconLinkProps): JSX.Element {
  return (
    <div className={`osg-icon-link osg-v-${variant} ${modifier}`}>
      <a className="osg-icon-link__link" {...rest}>
        <div className="osg-icon-link__link-icon">
          <span className={`osg-icon osg-icon--${icon}`} aria-hidden />
        </div>
        <div className="osg-icon-link__link-text">
          <h2 className="osg-icon-link__heading osg-u-heading-4">{title}</h2>
          {prompt && <p className="osg-icon-link__description osg-u-text-5">{prompt}</p>}
        </div>
      </a>
    </div>
  );
}
