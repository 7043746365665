import React, { useState, useMemo, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { FormattedMessage } from "locale/langUtils";
import InputField from "features/common/ComposedComponents/Inputfield/Inputfield";
import { Button } from "features/common/ComposedComponents";
import { useIntl } from "react-intl";
import { yupResolver } from "@hookform/resolvers";
import { passwordRegex } from "utils/validationUtils";
import { getBrukerApplikasjoner, putEndrePassord } from "api/piggavService";
import ProfilSection from "./ProfileSection";
import { Applikasjon } from "interfaces/Applikasjon";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import Form from "features/common/BaseComponents/Form/Form";

type ChangePasswordSectionProps = React.HTMLProps<HTMLDivElement>;
export default function ChangePasswordSection({ ...rest }: ChangePasswordSectionProps){
  const profileSectionId = "passwordsettings";
  const [isOpen, setIsOpen] = useState(false);
  const [isSendingUpdatePWDRequest, setIsSendingUpdatePWDRequest] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const [applikasjoner, setApplikasjoner] = useState<Applikasjon[]>();

  const { formatMessage } = useIntl();

  useEffect(() => {
    getBrukerApplikasjoner().then(resp => {
      setApplikasjoner(resp.data.result);
    });
  }, []);

  const forgotPasswordSchema = useMemo(
    () =>
      yup.object().shape({
        newPassword: yup
          .string()
          .required(
            formatMessage({
              id: "common.error.requiredfield",
              defaultMessage: "Feltet må fylles ut"
            })
          )
          .matches(
            passwordRegex,
            formatMessage({
              id: "common.error.invalidpassword",
              defaultMessage: "Passord må være 8 tegn eller lengre, og inneholde minst 1 stor bokstav og 1 tall"
            })
          ),
        repeatPassword: yup
          .string()
          .required(
            formatMessage({
              id: "common.error.requiredfield",
              defaultMessage: "Feltet må fylles ut"
            })
          )
          .oneOf(
            [yup.ref("newPassword")],
            formatMessage({
              id: "common.error.equalpassword",
              defaultMessage: "Passordene må være like."
            })
          ),
        oldPassword: yup.string().required(
          formatMessage({
            id: "common.error.requiredfield",
            defaultMessage: "Feltet må fylles ut"
          })
        )
      }),
    [formatMessage]
  );
  const { handleSubmit, register, errors, setError } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(forgotPasswordSchema)
  });

  const handleUpdatePasswordSubmit = handleSubmit(async ({ newPassword, oldPassword }) => {
    try {
      setIsSendingUpdatePWDRequest(true);
      await putEndrePassord(newPassword, oldPassword);
      setIsPasswordChanged(true);
    } catch (e: any) {
      if (e.response?.data?.ErrorMessage) {
        setError("repeatPassword", {
          type: "errorWhenUpdating",
          message: e.response?.data?.ErrorMessage
        });
      } else {
        setError("repeatPassword", {
          type: "errorWhenUpdating",
          message: formatMessage({
            id: "newpasswordpage.error.whenupdating",
            defaultMessage:
              "Kunne ikke oppdatere passord, prøv igjen eller kontakt oss, se nederst på siden for kontaktinfo"
          })
        });
      }
    } finally {
      setIsSendingUpdatePWDRequest(false);
    }
  });

  const toggle = () => {
    if (isOpen) {
      const accordionTogglerBtn = document.getElementById(`${profileSectionId}--toggler`);
      accordionTogglerBtn?.focus();
    }
    setIsOpen(prev => !prev);
  };

  return (
    <ProfilSection id={profileSectionId} isOpen={isOpen} toggle={toggle} {...rest}>
      {{
        heading: formatMessage({
          id: "profile.changepassword.heading",
          defaultMessage: "Passord"
        }),
        openContent: (
          <div>
            {applikasjoner && applikasjoner.length > 1 && (
              <div>
                <div className="">
                  <FormattedMessage id="profile.changepassword.header" />
                </div>
                <div className="mt-2">
                  {applikasjoner.map(a => (
                    <div key={a.id} className="flex items-center mt-1">
                      {a.iconUrl && !a.iconUrl.includes("missing_connector_icon") && (
                        <img className="w-4 h-4" src={a.iconUrl} alt="icon" />
                      )}
                      {a.iconUrl && a.iconUrl.includes("missing_connector_icon") && (
                        <div className="w-4 h-4 flex items-center justify-center font-bold bg-blue-dark text-white">
                          {a.name.substring(0, 1)}
                        </div>
                      )}
                      <div className="ml-1 flex">{a.name}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <Form id="new-password-form" className="mt-3" onSubmit={handleUpdatePasswordSubmit}>
              <InputField
                name="oldPassword"
                autoComplete="currentPassword"
                label={formatMessage({
                  id: "profile.verify.existing.password",
                  defaultMessage: "Verifiser eksiterende passord"
                })}
                type="password"
                error={errors.oldPassword?.message}
                required
                ref={register}
                onChange={() => {
                  setIsPasswordChanged(false);
                }}
              />
              <InputField
                name="newPassword"
                label={formatMessage({
                  id: "newpasswordpage.label.newpassword",
                  defaultMessage: "Nytt passord"
                })}
                onChange={() => {
                  setIsPasswordChanged(false);
                }}
                error={errors.newPassword?.message}
                required
                ref={register}
                type="password"
              />
              <InputField
                name="repeatPassword"
                autoComplete="new-password"
                label={formatMessage({
                  id: "profile.changepassword.repeat.password",
                  defaultMessage: "Gjenta nytt passord"
                })}
                required
                onChange={() => {
                  setIsPasswordChanged(false);
                }}
                error={errors.repeatPassword?.message}
                ref={register}
                type="password"
              />

              {isPasswordChanged && (
                <MessageBox role="status" type="success">
                  <FormattedMessage id="profile.changepassword.success" />
                </MessageBox>
              )}
              <div className="flex justify-between flex-wrap mt-4">
                <Button
                  aria-expanded={isOpen}
                  aria-label={formatMessage({ id: "profile.cancel.button" })}
                  className="w-full tablet:w-1/2 mobile-only:mb-2"
                  modifier="outline"
                  disabled={isSendingUpdatePWDRequest}
                  onClick={toggle}
                >
                  {isPasswordChanged ? (
                    <FormattedMessage id="profile.button.close" defaultMessage="Lukk" />
                  ) : (
                    <FormattedMessage id="common.cancel" defaultMessage="Avbryt" />
                  )}
                </Button>
                <Button
                  className="w-full tablet:w-1/2 "
                  form="new-password-form"
                  type="submit"
                  disabled={isSendingUpdatePWDRequest}
                  isLoading={isSendingUpdatePWDRequest}
                >
                  <FormattedMessage id="common.save" defaultMessage="Lagre passord" />
                </Button>
              </div>
            </Form>
          </div>
        )
      }}
    </ProfilSection>
  );
}
