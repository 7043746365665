import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Page from "features/common/layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Section from "features/common/layout/Section";
import { FormattedMessage } from "locale/langUtils";
import InputField from "features/common/ComposedComponents/Inputfield/Inputfield";
import { LinkButton } from "features/common/OSG";
import { Button } from "features/common/ComposedComponents";
import { useIntl } from "react-intl";
import * as urls from "appConstants/urls";
import { postGlemtPassord } from "api/piggavService";

const forgotPasswordSchema = yup.object().shape({
  email: yup.string().email("common.error.notavalidemail").required("common.error.requiredfield")
});

export default function ForgotPasswordPage(): JSX.Element {
  const [isSendingResetPWDRequest, setIsSendingResetPWDRequest] = useState(false);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { handleSubmit, register, errors, setError } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(forgotPasswordSchema)
  });

  const handleGlemtPassord = handleSubmit(async ({ email }) => {
    setIsSendingResetPWDRequest(true);
    try {
      await postGlemtPassord({ epost: email });
      setIsEmailSent(true);
    } catch {
      setError("email", {
        type: "notFoundError",
        message: "forgotpasswordpage.error.emailnotfound"
      });
    } finally {
      setIsSendingResetPWDRequest(false);
    }
  });
  return (
    <Page
      metaInfo={{
        titlePageName: formatMessage({ id: "forgotpasswordpage.meta.title" }),
        titleDescription: formatMessage({ id: "forgotpasswordpage.meta.title.description" }),
        description: formatMessage({ id: "forgotpasswordpage.meta.description" })
      }}
    >
      {{
        pageContent: (
          <Section>
            <div className={`${isEmailSent ? "hidden" : ""}`}>
              <h1 className="heading-1 mb-4">
                <FormattedMessage id="forgotpasswordpage.heading" defaultMessage="Glemt passord?" />
              </h1>
              <p className="text-3 mb-4">
                <FormattedMessage
                  id="forgotpasswordpage.subheading"
                  defaultMessage="Fortvil ikke, legg inn din epost, så sender vi deg instruksjoner på hvordan du kan tilbakestille ditt passord."
                />
              </p>
              <form className="w-full tablet:w-1/2" id="glemt-passord" onSubmit={handleGlemtPassord}>
                <InputField
                  name="email"
                  label={formatMessage({
                    id: "forgotpasswordpage.label.email",
                    defaultMessage: "E-post"
                  })}
                  error={
                    errors.email?.message &&
                    formatMessage({
                      id: errors.email.message
                    })
                  }
                  ref={register}
                  type="email"
                />
                <div className="flex flex-col tablet:flex-row tablet:justify-end">
                  <LinkButton
                    to="/"
                    className="tablet:w-auto hover:cursor-pointer mobile-only:mb-2 tablet:mr-2"
                    modifier="outline"
                    colorOption="yellow"
                    onClick={e => {
                      e.preventDefault();
                      history.goBack();
                    }}
                  >
                    <FormattedMessage id="forgotpasswordpage.link.goback" defaultMessage="Gå tilbake" />
                  </LinkButton>
                  <Button
                    className="w-100"
                    form="glemt-passord"
                    type="submit"
                    disabled={isSendingResetPWDRequest}
                    isLoading={isSendingResetPWDRequest}
                  >
                    <FormattedMessage id="forgotpasswordpage.button.submit" defaultMessage="Reset passord" />
                  </Button>
                </div>
              </form>
            </div>
            <div className={`${isEmailSent ? "" : "hidden"}`}>
              <h1 className="heading-1 mb-4">
                <FormattedMessage id="forgotpasswordpage.heading.passwordsent" defaultMessage="Sjekk e-posten din!" />
              </h1>
              <p className="text-3 mb-4">
                <FormattedMessage
                  id="forgotpasswordpage.subheading.passwordsent"
                  defaultMessage="Lenke for å gjenopprette passordet ditt er sendt til din e-post."
                />
              </p>
              <LinkButton
                to={urls.hjem}
                className="w-full tablet:w-auto hover:cursor-pointer"
                modifier="outline"
                colorOption="yellow"
              >
                <FormattedMessage id="forgotpasswordpage.link.tologin" defaultMessage="Tilbake til innlogging" />
              </LinkButton>
            </div>
          </Section>
        )
      }}
    </Page>
  );
}
