import React, { useState, useEffect } from "react";
import Modal, { ModalSection, ModalHeader } from "features/common/BaseComponents/Modal/Modal";
import { Button, Icon } from "features/common/OSG";
import InputField from "features/common/ComposedComponents/Inputfield/Inputfield";
import Select from "features/common/BaseComponents/Select/CustomReactSelect";
import { useIntl } from "react-intl";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { VehicleWeightId } from "appConstants";
import { map } from "lodash-es";
import { useSelector } from "react-redux";
import { getState as getKjoretoyState } from "state/Kjoretoy/kjoretoyReducer";
import { useReduxDispatch } from "utils/Storeutils";
import { getKjoretoytyper } from "state/Kjoretoytyper/kjoretoytyperActions";
import { getState as getKjoretoytyperState } from "state/Kjoretoytyper/kjoretoytyperReducer";
import { getState as getAuthState } from "state/Auth/Auth";
import successIkon from "images/success-ikon.svg";
import { FormattedMessage } from "locale/langUtils";
import { createKjoretoy, resetKjoretoyError } from "state/Kjoretoy/kjoretoyActions";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import { Button as ButtonWSpinner } from "features/common/ComposedComponents/";

interface AddVehicleProps {
  className?: string;
  leggTilKjoreotySuccessCallback?: (...args: any[]) => any;
}

const kjoretoyAlleredeLagtTilError = 409;

const tilgangskontrollAutosysError = 400;

const kunneIkkeFinneKjoretoyError = 404;

const kjoretoyInternalServerError = 500;

const addVehicleFormSchema = yup.object().shape({
  registreringsnummer: yup.string().required("common.error.requiredfield"),
  beskrivelse: yup.string(),
  kjoretoytypeId: yup.object().shape({
    value: yup.string().notOneOf(["0"]),
    label: yup.string()
  })
});

export default function Addvehicle({ leggTilKjoreotySuccessCallback, className }: AddVehicleProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [kjoretoyRegistrert, setKjoretoyRegistrert] = useState(false);
  const [waitingForCreateKjoretoy, setWaitingForCreateKjoretoy] = useState<boolean>(false);
  const [registrertKjoretoy, setRegistrertKjoretoy] = useState<any>(null);

  const kjoretoyState = useSelector<any, any>(getKjoretoyState);
  const kjoretoyTyperState = useSelector<any, any>(getKjoretoytyperState);
  const dispatch = useReduxDispatch();
  useEffect(() => {
    dispatch(getKjoretoytyper());
  }, [dispatch]);
  const { userInfo } = useSelector(getAuthState);

  const { errors, register, reset, handleSubmit, control, setValue } = useForm({
    mode: "onSubmit",
    defaultValues: {
      beskrivelse: "",
      kjoretoytypeId: {} as any,
      registreringsnummer: ""
    },
    resolver: yupResolver(addVehicleFormSchema)
  });

  const { formatMessage } = useIntl();

  const oversettKjøretøytyper = (kjoretoytyper: any[]) => {
    const oversattKjoretoytyper = map(kjoretoytyper, type => {
      const oversattTypenavn =
        type.id === VehicleWeightId.Light
          ? `${formatMessage({ id: "common.vehicles.lightvehicle" })} (< ${type.vektTil} kg)`
          : `${formatMessage({ id: "common.vehicles.heavyvehicle" })} (${type.vektFra} - ${type.vektTil} kg)`;
      const overasttType = { ...type };
      overasttType.navn = oversattTypenavn;

      return {
        id: String(type.id),
        navn: oversattTypenavn
      };
    });
    return oversattKjoretoytyper;
  };

  const toggleOpen = (): void => {
    setIsOpen(true);
  };

  const abort = (): void => {
    reset();
    dispatch(resetKjoretoyError());
    setIsOpen(false);
    setKjoretoyRegistrert(false);
    setWaitingForCreateKjoretoy(false);
    setRegistrertKjoretoy(null);
  };

  const successCallback = (data: any): void => {
    setKjoretoyRegistrert(true);
    setWaitingForCreateKjoretoy(false);
    setRegistrertKjoretoy(data);
    leggTilKjoreotySuccessCallback?.(data);
  };

  const errorCallback = (e: any): void => {
    setKjoretoyRegistrert(false);
    setWaitingForCreateKjoretoy(false);
    setRegistrertKjoretoy(null);
    if (e?.status === kunneIkkeFinneKjoretoyError || e?.status === tilgangskontrollAutosysError)
      setValue("kjoretoytypeId", {
        value: "0",
        label: formatMessage({
          id: "addvehicle.select.placeholder.wightclass"
        })
      });
  };

  const handleChangeRegnumber = (): void => {
    dispatch(resetKjoretoyError());
    setKjoretoyRegistrert(false);
  };

  const handleAddVehicle = handleSubmit(
    // eslint-disable-next-line no-shadow
    ({ registreringsnummer, beskrivelse, kjoretoytypeId }) => {
      if (!userInfo?.brukerId) return;
      setRegistrertKjoretoy(null);

      if (!waitingForCreateKjoretoy) {
        setWaitingForCreateKjoretoy(true);
        dispatch(
          createKjoretoy(
            {
              registreringsnummer,
              beskrivelse,
              kjoretoytypeId: kjoretoytypeId?.value ?? ""
            },
            userInfo?.brukerId,
            successCallback,
            errorCallback
          )
        );
      }
      dispatch(resetKjoretoyError());
    }
  );

  const kjoretoyError =
    kjoretoyState && kjoretoyState.error && kjoretoyState.error.status ? kjoretoyState.error.status : "";

  const oversatteKjoretoytyper = oversettKjøretøytyper(kjoretoyTyperState);

  const showDetailedForm =
    (kjoretoyRegistrert !== true && kjoretoyError === kunneIkkeFinneKjoretoyError) ||
    kjoretoyError === tilgangskontrollAutosysError;

  return (
    <div className={className}>
      <button
        aria-label={formatMessage({ id: "addvehicle.button.aria" })}
        type="button"
        className="text-button"
        onClick={toggleOpen}
        aria-haspopup="dialog"
        aria-controls="legg-til-kjøretøy-dialog"
        aria-expanded={isOpen}
      >
        <FormattedMessage id="addvehicle.button.opendialog" />
        <Icon icon="cross-circle" className="transform rotate-45" />
      </button>
      <Modal id="legg-til-kjøretøy-dialog" staticModal isOpen={isOpen} toggle={abort} onHide={abort}>
        <ModalHeader>{{ heading: <FormattedMessage id="addvehicle.heading" /> }}</ModalHeader>
        <ModalSection>
          {kjoretoyError === kjoretoyAlleredeLagtTilError && (
            <MessageBox role="alert" type="success">
              <FormattedMessage id="addvehicle.error.kjoretoyAlleredeLagtTilErrorMessage" />
            </MessageBox>
          )}

          {kjoretoyError === kunneIkkeFinneKjoretoyError && (
            <MessageBox role="alert" type="danger">
              <FormattedMessage id="addvehicle.error.kunneIkkeFinneKjoretoyErrorMessage" />
            </MessageBox>
          )}

          {(kjoretoyError === kjoretoyInternalServerError || kjoretoyError === tilgangskontrollAutosysError) && (
            <MessageBox role="alert" type="danger">
              <FormattedMessage id="addvehicle.error.leggTilKjoretoyGenerellErrorMessage" />
            </MessageBox>
          )}

          {kjoretoyRegistrert && (
            <MessageBox role="alert" type="success" className="flex items-start">
              <img alt="Success icon" className="mr-2" width="23px" src={successIkon} />
              <div className="flex flex-col w-full">
                <FormattedMessage id="addvehicle.success.leggTilKjoretoySuccessMessage" />
                <div className="mt-2">
                  {registrertKjoretoy && registrertKjoretoy.registreringsnummer
                    ? `${formatMessage({ id: "common.label.numberplate" })}: ${registrertKjoretoy.registreringsnummer} `
                    : ""}
                  <br />
                  {registrertKjoretoy && registrertKjoretoy.kjoretoytypeId && oversatteKjoretoytyper
                    ? `${formatMessage({
                        id: "addvehicle.label.formLabelKjøretøytype"
                      })}: ${
                        registrertKjoretoy.kjoretoytypeId === VehicleWeightId.Light
                          ? oversatteKjoretoytyper[0].navn
                          : oversatteKjoretoytyper[1].navn
                      } `
                    : ""}
                  <br />
                  {registrertKjoretoy && registrertKjoretoy.beskrivelse
                    ? `${formatMessage({
                        id: "addvehicle.label.description"
                      })}: ${registrertKjoretoy.beskrivelse} `
                    : ""}
                </div>
              </div>
            </MessageBox>
          )}
        </ModalSection>
        <ModalSection>
          <div id="register-vehicle-form" className="flex flex-col w-full" onSubmit={handleAddVehicle}>
            <div className="flex mobile-only:flex-col">
              <InputField
                required
                aria-label={formatMessage({
                  id: "common.vehcile.numberplate"
                })}
                rootClassName="w-full tablet:w-1/2"
                name="registreringsnummer"
                maxLength={8}
                label={formatMessage({
                  id: "common.label.numberplate"
                })}
                placeholder="AB12345"
                disabled={kjoretoyRegistrert}
                onChange={handleChangeRegnumber}
                error={
                  errors?.registreringsnummer?.message ? formatMessage({ id: errors.registreringsnummer.message }) : ""
                }
                ref={register}
              />
              <div className={`flex flex-col ${showDetailedForm ? "hidden" : ""}`}>
                <ButtonWSpinner
                  className="mt-2 tablet:mt-margin-label-height tablet:ml-1"
                  name="othername"
                  type="submit"
                  form="register-vehicle-form"
                  onClick={handleAddVehicle}
                  disabled={
                    waitingForCreateKjoretoy ||
                    kjoretoyRegistrert ||
                    kjoretoyError === kunneIkkeFinneKjoretoyError ||
                    kjoretoyError === tilgangskontrollAutosysError
                  }
                  isLoading={waitingForCreateKjoretoy}
                >
                  <FormattedMessage
                    id={waitingForCreateKjoretoy ? "addvehicle.button.adding" : "addvehicle.button.add"}
                  />
                </ButtonWSpinner>
              </div>
            </div>
            {showDetailedForm && (
              <>
                <div className="flex flex-col mb-3 w-full tablet:w-1/2">
                  <span className="text-4">
                    <FormattedMessage id="addvehicle.label.formLabelKjøretøytype" />
                  </span>
                  <Controller
                    name="kjoretoytypeId"
                    control={control}
                    as={Select}
                    isDisabled={kjoretoyRegistrert}
                    options={map(oversatteKjoretoytyper, kt => ({
                      label: kt.navn,
                      value: kt.id
                    }))}
                    defaultValue={{
                      value: "0",
                      label: formatMessage({
                        id: "addvehicle.select.placeholder.wightclass"
                      })
                    }}
                  />
                  <MessageBox
                    role="alert"
                    type="danger"
                    className="px-2 py-2 mt-2"
                    visible={errors?.kjoretoytypeId !== undefined}
                  >
                    <FormattedMessage id="common.error.requiredfield" />
                  </MessageBox>
                </div>
                <InputField
                  rootClassName="w-full tablet:w-1/2"
                  key="beskrivelse"
                  name="beskrivelse"
                  maxLength={50}
                  label={formatMessage({
                    id: "addvehicle.label.description"
                  })}
                  ref={register}
                  disabled={kjoretoyRegistrert}
                />
                <Button
                  className="self-end mt-1 w-full tablet:w-1/2"
                  type="submit"
                  form="register-vehicle-form"
                  disabled={waitingForCreateKjoretoy || kjoretoyRegistrert}
                  onClick={handleAddVehicle}
                >
                  <FormattedMessage
                    id={waitingForCreateKjoretoy ? "addvehicle.button.saving" : "addvehicle.button.save"}
                  />
                </Button>
              </>
            )}
          </div>
          <div className="flex justify-end mt-3">
            <Button modifier="outline" onClick={abort} className="mobile-only:w-full">
              <FormattedMessage id="common.button.close" />
            </Button>
          </div>
        </ModalSection>
      </Modal>
    </div>
  );
}
