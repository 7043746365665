import React, { useEffect, useState, useMemo, useLayoutEffect } from "react";
import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import { getMonth, getYear } from "date-fns";
import { FormattedMessage, useIntl } from "locale/langUtils";
import { useReduxDispatch } from "utils/Storeutils";
import UserHook from "features/Hooks/UserHook";
import { Varekjop } from "interfaces";
import { useTable, Column, usePagination, useSortBy, useFilters, TableState, Row } from "react-table";
import DateTimeFormat from "locale/DateTimeFormat";

import * as urls from "appConstants/urls";
import CurrencyFormat from "locale/CurrencyFormat";
import useWindowUtilsHook from "features/Hooks/WindowUtilsHook";
import { useHistory } from "react-router";
import Loader from "features/common/ComposedComponents/Loader/Loader";
import CustomReactSelect from "features/common/BaseComponents/Select/CustomReactSelect";
import { uniqBy, isEmpty } from "lodash-es";

import { convertPaymentmethodToLangId } from "utils/oblatHelpers";
import Paging from "features/common/TableComponents/Paging";
import FilterArrow from "features/common/ComposedComponents/FilterArrow/FilterArrow";
import "./Receipts.scss";
import LinkAsButton from "features/common/BaseComponents/LinkAsButton/LinkAsButton";
import { getVarekjop } from "api/piggavService";

interface VarekjopWSeason extends Varekjop {
  season: string;
}

function seasonFilter(rows: Row<VarekjopWSeason>[], [columnId]: string[], filterValue: any) {
  return rows.filter(r => r.values[columnId] === filterValue || filterValue === undefined);
}

export default function ReceiptsPage(): JSX.Element {
  const [varekjop, setVarekjop] = useState<VarekjopWSeason[]>([] as VarekjopWSeason[]);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setError] = useState<any | undefined>();
  const { userInfo } = UserHook();
  const history = useHistory();
  const dispatch = useReduxDispatch();
  const { currentBreakpoint } = useWindowUtilsHook();
  const intl = useIntl();
  useEffect(() => {
    setIsLoading(true);
    // Gets all receipts
    getVarekjop(userInfo?.brukerId ?? "", "?status=110&&status=102")
      .then((result: any) => {
        const purchases = result.data.result as Varekjop[];
        const resultWseason = purchases.map<VarekjopWSeason>(p => {
          let season = "";
          const purchaseDate = new Date(p.betalt);
          if (getMonth(purchaseDate) > 6) season = `${getYear(purchaseDate)}/${getYear(purchaseDate) + 1}`;
          else season = `${getYear(purchaseDate) - 1}/${getYear(purchaseDate)}`;
          return { ...p, season };
        });
        setVarekjop(resultWseason);
      })
      .catch((error: any) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userInfo, dispatch]);

  const cols = useMemo<Column<VarekjopWSeason>[]>(() => {
    return [
      {
        Header: (
          <span aria-label={intl.formatMessage({ id: "receipstpage.receiptThReceiptid.aria" })}>
            <FormattedMessage id="receipstpage.receiptThReceiptid" defaultMessage="Kvitteringsnummer" />
          </span>
        ),

        accessor: "kjopsnummer",
        Cell: v => {
          const { formatMessage } = useIntl();
          return (
            <LinkAsButton
              className="osg-u-text-is-medium"
              aria-label={formatMessage(
                {
                  id: "receipstpage.link.arialabel.receipt"
                },
                {
                  receiptNumber: v.value,
                  purchaseDate: v.row.original.betalt
                  // purchaseTime: v.row.original.betalt,
                }
              )}
              onClick={e => {
                e.preventDefault();
                history.push(urls.kvitteringPath.replace(":key", v.row.original.id));
              }}
            >
              {v.value}
            </LinkAsButton>
          );
        }
      },
      {
        Header: (
          <span aria-label={intl.formatMessage({ id: "receipstpage.receiptThDate.aria" })}>
            <FormattedMessage id="receipstpage.receiptThDate" defaultMessage="Kjøpsdato" />
          </span>
        ),
        accessor: "betalt",
        Cell: c => <DateTimeFormat value={c.value} />
      },
      {
        Header: (
          <span aria-label={intl.formatMessage({ id: "receipstpage.receiptThPaymentOption.aria" })}>
            <FormattedMessage id="receipstpage.receiptThPaymentOption" defaultMessage="Betalingsform" />
          </span>
        ),
        accessor: "betalingsform",
        Cell: c => {
          const languageId = convertPaymentmethodToLangId(c.value);
          return <FormattedMessage id={languageId} defaultMessage={c.value} />;
        }
      },
      {
        Header: (
          <span aria-label={intl.formatMessage({ id: "receipstpage.receiptThSum.aria" })}>
            <FormattedMessage id="receipstpage.receiptThSum" defaultMessage="Totalpris" />
          </span>
        ),
        accessor: "totalsum",
        Cell: c => <CurrencyFormat value={c.value} />
      },
      {
        Header: (
          <span aria-label={intl.formatMessage({ id: "receipstpage.receiptThSeason.aria" })}>
            <FormattedMessage id="receipstpage.receiptThSeason" defaultMessage="Sesong" />
          </span>
        ),
        accessor: "season",
        disableSortBy: true,
        defaultCanFilter: true,
        width: "250px",
        Filter: ({
          // eslint-disable-next-line react/prop-types
          column: { filterValue, setFilter, preFilteredRows, id }
        }) => {
          const { formatMessage } = useIntl();
          // Calculate the options for filtering
          // using the preFilteredRows
          const options = React.useMemo(() => {
            // eslint-disable-next-line react/prop-types
            const opts = uniqBy(preFilteredRows, `values.${id}`).map(r => ({
              label: r.values[id],
              value: r.values[id]
            }));
            opts.unshift({
              label: formatMessage({
                id: "receipstpage.select.showAll",
                defaultMessage: "Alle sesonger"
              }),
              value: undefined
            });
            return opts;
          }, [formatMessage, id, preFilteredRows]);
          // Render a multi-select box
          return (
            <div style={{ width: "160px" }}>
              <CustomReactSelect
                defaultValue={{
                  label: formatMessage({
                    id: "receipstpage.select.label.season",
                    defaultMessage: "Alle sesonger"
                  }),
                  value: ""
                }}
                options={options}
                onChange={(e: any) => {
                  setFilter(e?.value || undefined);
                }}
                noOptionsMessage={({ inputValue }) => {
                  return "Finnes ikke";
                }}
              />
            </div>
          );
        },
        filter: "season"
      }
    ];

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);

  const filterTypes = useMemo(() => ({ season: seasonFilter }), []);

  const initialState = useMemo<Partial<TableState<VarekjopWSeason>>>(
    () => ({
      pageIndex: 0,
      pageSize: currentBreakpoint === "mobile" ? 5 : 10,
      filters: [
        {
          id: "season",
          value: undefined
        }
      ],
      sortBy: [{ id: "betalt", desc: true }]
    }),
    [currentBreakpoint]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    nextPage,
    previousPage,
    setHiddenColumns,
    setPageSize,
    state: { pageIndex, pageSize }
  } = useTable<VarekjopWSeason>(
    {
      columns: cols,
      data: varekjop,
      initialState,
      defaultCanSort: true,
      defaultCanFilter: false,
      filterTypes
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useLayoutEffect(() => {
    if (currentBreakpoint === "mobile") {
      setHiddenColumns(["season", "kjopsnummer"]);
      setPageSize(5);
    } else {
      setHiddenColumns([]);
      setPageSize(10);
    }
  }, [currentBreakpoint, setHiddenColumns, setPageSize]);

  return (
    <Page
      metaInfo={{
        titlePageName: intl.formatMessage({ id: "receiptpage.meta.title" }),
        titleDescription: intl.formatMessage({ id: "receipstpage.meta.title.description" })
      }}
    >
      {{
        heading: {
          pageTitle: <FormattedMessage id="receipstpage.title" defaultMessage="Kvitteringer" />,
          subtitle: <FormattedMessage id="receipstpage.subtitle" defaultMessage="" />
        },
        pageContent: (
          <Section className="">
            <div
              className={`${pageCount > 1 ? "receipt-table-container-min-height" : 1} flex flex-col justify-between`}
            >
              <div className="osg-simple-table w-full overflow-x-auto">
                <table {...getTableProps({ className: "" })}>
                  <caption className="sr-only">
                    <FormattedMessage id="receipstpage.table.caption" />
                  </caption>
                  <thead>
                    {headerGroups.map(headergroup => (
                      <tr {...headergroup.getHeaderGroupProps()}>
                        {headergroup.headers.map(header => (
                          <th
                            {...header.getHeaderProps({
                              className: "osg-content w-1/3 tablet: w-1/5"
                            })}
                          >
                            {header.canSort && (
                              <div
                                {...header.getSortByToggleProps({
                                  className: "flex items-center hover:text-blue-hover focus:text-blue-hover ",
                                  tabIndex: 0,
                                  role: "button",
                                  title: "Sorter denne kolonnen",
                                  onKeyDown: (e: React.KeyboardEvent) => {
                                    if (e.key === "Enter") header.toggleSortBy();
                                  }
                                } as any)}
                              >
                                {header.render("Header")}
                                <span>
                                  <FilterArrow higlight={header.isSorted} highlightUp={!header.isSortedDesc} />
                                </span>
                              </div>
                            )}
                            {header.defaultCanFilter ? <div>{header.render("Filter")}</div> : null}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody {...getTableBodyProps()}>
                    <Loader isLoading={isLoading}>
                      {{
                        loadingText: <FormattedMessage id="common.loading" />,
                        renderLoader: Spinner => (
                          <tr key="loader-row">
                            <td colSpan={100} className="hover:bg-white">
                              <div className="flex justify-center text-5 mt-2 p-3 osg-u-text-is-medium">{Spinner}</div>
                            </td>
                          </tr>
                        ),
                        loadingContent: isEmpty(page) ? (
                          <tr key="information-row">
                            <td colSpan={100} className="w-12/12">
                              <div className="flex justify-center text-5 mt-2 p-3 osg-u-color-primary osg-u-text-is-medium">
                                {!errors ? (
                                  <FormattedMessage id="receipstpage.receiptNoReceipts" />
                                ) : (
                                  <FormattedMessage id="receipstpage.tablenoDataText" />
                                )}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          page.map((r) => {
                            prepareRow(r);
                            return (
                              <tr
                                {...r.getRowProps({
                                  className: "cursor-pointer"
                                })}
                                onClick={() => {
                                  history.push(urls.kvitteringPath.replace(":key", r.original.id));
                                }}
                              >
                                {r.cells.map(c => (
                                  <td
                                    {...c.getCellProps({
                                      className: "osg-content w-1/3 tablet: w-1/5"
                                    })}
                                  >
                                    {c.render("Cell")}
                                  </td>
                                ))}
                              </tr>
                            );
                          })
                        )
                      }}
                    </Loader>
                  </tbody>
                </table>
              </div>

              <Paging
                showPaginationControlls={pageCount > 1}
                beginFrom={1}
                pageSize={pageSize}
                pageIndex={pageIndex}
                canPreviousPage={canPreviousPage}
                canNextPage={canNextPage}
                nextPage={nextPage}
                previousPage={previousPage}
                totalNumberOfRows={rows.length}
              />
            </div>
          </Section>
        )
      }}
    </Page>
  );
}
