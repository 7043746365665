import React from "react";
import "./Link.scss";

export default function Link({ children, className, ...rest }: React.HTMLProps<HTMLAnchorElement>): JSX.Element {
  return (
    <a className={`link ${className}`} {...rest}>
      {children}
    </a>
  );
}
