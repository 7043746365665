import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useReduxDispatch } from "utils/Storeutils";
import {
  createHandlekurv,
  addOblatToHandlekurv,
  addOblaterInBulkToVarekjop,
  removeVareFromHandlekurv,
  lagreBestilling,
  lagreBestillingKontanter,
  lagreBestillingTerminal,
  lagreBestillingFaktura,
  setIsHandlekurvOpen
} from "state/Handlekurv/handlekurvActions";
import { getState as getHandlekurvState } from "state/Handlekurv/handlekurvReducer";
import { getState as getAuthState } from "state/Auth/Auth";
import { BymEndpointResult, Oblat, OrdreLinje, VehicleDictionary } from "interfaces";
import { sumBy, remove, groupBy, map, isEmpty } from "lodash-es";
import { AxiosResponse } from "axios";
import { getName } from "utils/oblatHelpers";
import { getOblaterForBruker } from "state/Oblat/oblatActions";
import { useLocation, matchPath } from "react-router";
import * as urls from "appConstants/urls";

type OblatListenerFN = (shouldShowPopup?: boolean) => void;

interface HandlekurvHookApi {
  varekjopId: string;
  addStickerToBasket: (
    payload: {
      oblattypeId: string;
      kjoretoyId: string;
      gyldigFra: string;
      tillatOverlapp?: boolean;
    },
    shouldShowPopup?: boolean
  ) => Promise<AxiosResponse<any>>;
  addStickerInBulkToBasket: (
    kjoretoyMedKjoretoytype: VehicleDictionary,
    shouldShowPopup?: boolean
  ) => Promise<AxiosResponse<BymEndpointResult<Oblat[] | { [index: string]: Oblat[] }>>>;
  removeOrdrelinjeFromBasket: (oblatId: string) => Promise<any>;
  handlePayWithNets: () => Promise<any>;
  handlePayWithInvoice: () => any;
  handlePayByCash: () => Promise<any>;
  handlePayByCardTerminal: () => Promise<any>;
  shoppingCartItems: any[];
  subscribeToAddSticker: (c: OblatListenerFN) => number;
  unsubscribeToAddSticker: (key: number) => void;
  stickerSummary: StickerSummaryItem[];
  totalPrice: number;
  isShoppingCartEmpty: boolean;
  isAddingStickerToBasker: boolean;
  isHandlekurvOpen: boolean;
}
let key = 0;
const addOblatListeners: {
  key: number;
  fun: OblatListenerFN;
}[] = [];

export default function HandleKurvHook(): HandlekurvHookApi {
  // const [isAddingStickerToBasker, setIsAddingStickerToBasker] = useState(false);
  const { userInfo } = useSelector(getAuthState);
  const { ordrelinjer, id, leggerTilOrdrelinje, isHandlekurvOpen } = useSelector<any, HandelkurvStateType>(
    getHandlekurvState
  );

  const dispatch = useReduxDispatch();

  const { pathname } = useLocation();

  useEffect(() => {
    const m = matchPath(pathname, {
      path: urls.betalingGjenomfort,
      exact: true,
      strict: true
    });
    if (!userInfo?.brukerId || !!m) return;
    /* This action creates a handlekurv_Id, or gets an existing one, then it gets all stickers connected to that varekjop id.
     * So the action consists of several API calls. This is from the old project.
     */
    dispatch(createHandlekurv(userInfo.brukerId));
  }, [dispatch, pathname, userInfo]);

  const totalPrice = useMemo(() => {
    return sumBy(ordrelinjer, "oblattype.pris");
  }, [ordrelinjer]);

  const isShoppingCartEmpty = useMemo(() => isEmpty(ordrelinjer), [ordrelinjer]);

  const addStickerToBasket = (
    payload: {
      oblattypeId: string;
      kjoretoyId: string;
      gyldigFra: string;
      tillatOverlapp?: boolean;
    } = {
      oblattypeId: "",
      kjoretoyId: "",
      gyldigFra: "",
      tillatOverlapp: false
    },
    shouldShowPopup = true
  ) => {
    // setIsAddingStickerToBasker(true);
    return dispatch(addOblatToHandlekurv(userInfo?.brukerId, id, payload))
      .then(res => {
        if (!res.data.errorkode) {
          addOblatListeners.forEach(callBack => {
            callBack.fun(shouldShowPopup);
            setIsHandlekurvOpen(shouldShowPopup);
          });
        }
        return Promise.resolve(res);
      })
      .finally(() => {
        // setIsAddingStickerToBasker(false);
      });
  };

  const addStickerInBulkToBasket = (kjoretoyMedKjoretoytype: VehicleDictionary, shouldShowPopup = false) => {
    // setIsAddingStickerToBasker(true);
    return dispatch(addOblaterInBulkToVarekjop(userInfo?.brukerId, kjoretoyMedKjoretoytype, id))
      .then(res => {
        if (!res.data.errorkode) {
          addOblatListeners.forEach(callBack => {
            callBack.fun(shouldShowPopup);
          });
        }
        return Promise.resolve(res);
      })
      .finally(() => {
        // setIsAddingStickerToBasker(false);
      });
  };

  const removeOrdrelinjeFromBasket = (oblatId: string) => {
    return dispatch(removeVareFromHandlekurv(userInfo?.brukerId, id, oblatId));
  };

  const handlePayWithNets = () => {
    return dispatch(lagreBestilling(userInfo?.brukerId, id));
  };

  const handlePayWithInvoice = async () => {
    try {
      const res = await dispatch(lagreBestillingFaktura(userInfo?.brukerId, id));
      try {
        // If success, fetch all stickers so it gets updated
        await dispatch(getOblaterForBruker(userInfo?.brukerId, true));
      } catch {
        // Do nothing
      }
      return res; // Promise.resolve(res);
    } finally {
      // Do noting
    }
  };

  const handlePayByCash = async () => {
    try {
      const res = await dispatch(lagreBestillingKontanter(userInfo?.brukerId, id));
      try {
        // If success, fetch all stickers so it gets updated
        await dispatch(getOblaterForBruker(userInfo?.brukerId, true));
      } catch {
        // Do nothing
      }
      return res;
      // Promise.resolve(res);
    } finally {
      // Do nothing
    }
  };

  const handlePayByCardTerminal = async () => {
    try {
      const res = await dispatch(lagreBestillingTerminal(userInfo?.brukerId, id));
      try {
        // If success, fetch all stickers so it gets updated
        await dispatch(getOblaterForBruker(userInfo?.brukerId, true));
      } catch {
        // Do nothing
      }
      return res; // Promise.resolve(res);
    } finally {
      // Do nothing
    }
  };

  const subscribeToAddSticker = (c: () => any) => {
    key += 1;
    addOblatListeners.push({ key, fun: c });
    return key;
  };

  const unsubscribeToAddSticker = (deleteKey: number) => {
    remove(addOblatListeners, e => e.key === deleteKey);
  };

  const stickerSummary: StickerSummaryItem[] = useMemo(() => {
    const groupedshoppingCartItems = groupBy(ordrelinjer, e => {
      return getName(e.oblattype.periodeId, e.kjoretoy.kjoretoytypeId);
    });
    return map<typeof groupedshoppingCartItems, StickerSummaryItem>(
      groupedshoppingCartItems,
      (stickersOfType, name) => {
        const groupePrice = sumBy(stickersOfType, s => s.oblattype.pris);
        const pricePer = stickersOfType[0].oblattype.pris;
        return {
          name,
          totalPrice: groupePrice,
          pricePer,
          numberOfType: stickersOfType.length
        };
      }
    );
  }, [ordrelinjer]);

  return {
    varekjopId: id,
    shoppingCartItems: ordrelinjer,
    isShoppingCartEmpty,
    isHandlekurvOpen,
    isAddingStickerToBasker: leggerTilOrdrelinje,
    addStickerToBasket,
    addStickerInBulkToBasket,
    removeOrdrelinjeFromBasket,
    subscribeToAddSticker,
    unsubscribeToAddSticker,
    handlePayWithNets,
    handlePayByCash,
    handlePayByCardTerminal,
    handlePayWithInvoice,
    stickerSummary,
    totalPrice
  };
}
export interface StickerSummaryItem {
  name: string;
  numberOfType: number;
  pricePer: number;
  totalPrice: number;
}

interface HandelkurvStateType {
  id: string; // This is the id of the varekjop with state NY, a customer can only have one varekjop with state new.
  ordrelinjer: OrdreLinje[];
  handlekurvLoading: boolean;
  bestilling: any;
  varekjop: any;
  sjekkerBetaling: boolean;
  leggerTilOrdrelinje: boolean;
  isHandlekurvOpen: boolean;
}
