import { Configurations, Oblattype } from "interfaces";
import { isValid, parseISO, isBefore, addMinutes } from "date-fns";
import { StickerPeriodId } from "appConstants";

export const passwordRegex = /^(?=.*[a-zæøåA-ZÆØÅ])(?=.*\d)(?=.{8,})/;
export const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateDatetimeInput = (date?: string) => {
  return !isValid(parseISO(date ?? ""));
};

export const validateNullOrEmpty = (input?: string) => !input || input === "" || input === null || input === undefined;

export const validateRegistrationNumber = (input?: string) => {
  const regnummerRegex = /^[A-Z]{2}[0-9]{5}/g;
  return regnummerRegex.test(input ?? "");
};
export const validerOrdrelinje = (
  ordrelinje: {
    oblattypeId?: string;
    kjoretoyId?: string;
    gyldigFra?: string;
  },
  konfigurasjon: Configurations,
  oblattype?: Oblattype
) => {
  const errors: any = {};
  // Sjekk om kjøretøy er valgt
  if (validateNullOrEmpty(ordrelinje.kjoretoyId)) {
    errors.kjoretoyIdError = "buystickers.validation.registreringsnummerIsRequired";
  } else {
    errors.kjoretoyIdError = null;
  }
  // Sjekk om oblattype er valgt
  if (validateNullOrEmpty(ordrelinje.oblattypeId)) {
    errors.oblattypeIdError = "buystickers.validation.oblattypeIsRequired";
  } else {
    errors.oblattypeIdError = null;
  }
  // GyldigFra må være iso-string
  if (validateDatetimeInput(ordrelinje.gyldigFra)) {
    errors.gyldigFraError = "buystickers.validation.starttidIsRequired";
  } else {
    errors.gyldigFraError = null;
  }
  // Sjekk dagsoblat er valgt og om det er før dagens dato
  if (oblattype?.periodeId === StickerPeriodId.Day) {
    if (isBefore(addMinutes(new Date(ordrelinje.gyldigFra ?? ""), 2), new Date())) {
      errors.datoTidIFortidError = "buystickers.validation.datoIFortidError";
    } else {
      errors.datoTidIFortidError = null;
    }
    // Sjekk om oblat som er valgt er før sesong start
    if (isBefore(new Date(ordrelinje.gyldigFra ?? ""), new Date(konfigurasjon.sesongStart.verdi))) {
      errors.datoFørSesongstartError = "buystickers.validation.datoFørSesongstartError";
    } else {
      errors.datoFørSesongstartError = null;
    }
  }
  return errors;
};
