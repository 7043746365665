import { PropsWithChildren, ReactNode, useState } from "react";
import "./Tooltip.scss";
interface TooltipProps extends Pick<React.HTMLAttributes<HTMLSpanElement>, "aria-label"> {
  tooltip: ReactNode;
  className?: string;
}

export default function Tooltip({ tooltip, children, className = "", ...rest }: PropsWithChildren<TooltipProps>) {
  const [showTooltip, setShowTooltip] = useState(false);

  // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
  return (
    <span
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      className={`md:mt-0 relative inline-block cursor-pointer text-14-regular whitespace-no-wrap ${className}`}
      onMouseOver={() => setShowTooltip(true)}
      onFocus={() => setShowTooltip(true)}
      onMouseOut={() => setShowTooltip(false)}
      onBlur={() => setShowTooltip(false)}
      {...rest}
    >
      {children}
      {showTooltip && (
        <div role="tooltip" className="tooltip absolute left-[50%] top-[100%] z-tooltip rounded shadow-md bg-white">
          <span className="pointer" />
          <span>{tooltip}</span>
        </div>
      )}
    </span>
  );
}
