import { useMemo } from "react";
import Select from "features/common/BaseComponents/Select/CustomReactSelect";
import { useIntl } from "react-intl";
import { StickerStatus } from "appConstants";
import { uniqBy } from "lodash-es";

import { Filters, Row } from "react-table";
import { FormattedMessage } from "locale/langUtils";
import { SearchField } from "features/common/OSG";
import { StickerWInfoAndSeason } from "features/Hooks/OblaterHook";

interface OverviewStickerFiltersProps {
  preFilteredRows: Row<StickerWInfoAndSeason>[];
  setFilter: (columnId: string, filterValue: any) => void;
  filters: Filters<StickerWInfoAndSeason>;
}
export default function OverviewStickerFilters({
  preFilteredRows,
  setFilter,
  filters
}: OverviewStickerFiltersProps): JSX.Element {
  const { formatMessage } = useIntl();
  const statusOptions = useMemo(() => {
    return [
      {
        label: formatMessage({
          id: "overviewstickers.select.status.option.showall"
        }),
        value: StickerStatus.All.toString()
      },
      {
        label: formatMessage({ id: "common.stickerstatus.active" }),
        value: StickerStatus.Active.toString()
      },
      {
        label: formatMessage({ id: "common.stickerstatus.notstarted" }),
        value: StickerStatus.NotStartedFrontendOnly.toString()
      },
      {
        label: formatMessage({ id: "common.stickerstatus.awaitingpayment" }),
        value: StickerStatus.AwaitingPayment.toString()
      },
      {
        label: formatMessage({ id: "common.stickerstatus.cancelled" }),
        value: StickerStatus.CanceledFrontendOnly.toString()
      },
      {
        label: formatMessage({ id: "common.stickerstatus.expired" }),
        value: StickerStatus.Expired.toString()
      },
      {
        label: formatMessage({ id: "common.stickerstatus.transferred" }),
        value: StickerStatus.Transferred.toString()
      }
    ];
  }, [formatMessage]);

  const seasonOptions = useMemo(() => {
    const opts = uniqBy(preFilteredRows, "original.season")
      .map(o => {
        return { label: o.original.season, value: o.original.season };
      })
      .sort((a, b) => (a.value < b.value ? 1 : -1));
    opts.unshift({
      label: formatMessage({
        id: "overviewstickers.select.season.option.showall"
      }),
      value: "0"
    });
    return opts;
  }, [formatMessage, preFilteredRows]);

  const { seasonFilter } = useMemo(() => {
    const statusFltr = filters.find(f => f.id === "status");
    const seasonFltr = filters.find(f => f.id === "season");
    return {
      statusFilter: {
        label:
          statusFltr?.value === StickerStatus.All.toString()
            ? formatMessage({
                id: "overviewstickers.select.status.option.showall"
              })
            : statusFltr?.value,
        value: statusFltr?.value
      },
      seasonFilter: {
        label:
          seasonFltr?.value === "0"
            ? formatMessage({
                id: "overviewstickers.select.season.option.showall"
              })
            : seasonFltr?.value,
        value: seasonFltr?.value
      }
    };
  }, [filters, formatMessage]);

  return (
    <div className="flex mobile-only:flex-col justify-evenly items-end">
      <div className="w-full tablet:w-3/12 mb-2">
        <Select
          label={<FormattedMessage id="overviewstickers.select.status.label" />}
          onChange={(v: any) => {
            setFilter("status", v?.value ?? undefined);
          }}
          isSearchable={false}
          options={statusOptions}
          placeholder={<FormattedMessage id="overviewstickers.select.status.placeholder" />}
        />
      </div>
      <div className="w-full tablet:w-3/12 mb-2">
        <Select
          label={<FormattedMessage id="overviewstickers.select.season.label" />}
          onChange={(v: any) => {
            setFilter("season", v?.value ?? undefined);
          }}
          isSearchable={false}
          options={seasonOptions}
          value={seasonFilter}
          placeholder={<FormattedMessage id="overviewstickers.select.season.placeholder" />}
        />
      </div>
      <div className="w-full tablet:w-3/12 pb-2 mobile-only:order-first">
        <SearchField
          label={<FormattedMessage id="overviewstickers.input.vehicle.label" />}
          className="m-0"
          placeholder={formatMessage({
            id: "overviewstickers.select.vehicle.placeholder"
          })}
          onChange={e => {
            setFilter("registreringsnummer", e.currentTarget.value);
          }}
        />
      </div>
    </div>
  );
}
