import { ReactNode } from "react";
import { FormattedMessage } from "locale/langUtils";

export interface PaymentDetail {
  label: ReactNode;
  value: ReactNode | string | null;
}
interface PaymentDetailsProps {
  details: PaymentDetail[];
}
export function PaymentDetails({ details }: PaymentDetailsProps): JSX.Element {
  return (
    <div className="flex flex-col text-5">
      <strong>
        <FormattedMessage id="receiptpage.details" />
      </strong>
      {details.map((d, i) => (
        <span key={i} className="kvittering-betalingsdetalj mb-1">
          {d.label}{" "}
          {d.value ? (
            <>
              {`: `}
              {d.value}
            </>
          ) : (
            ""
          )}
        </span>
      ))}
    </div>
  );
}
