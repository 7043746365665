import React, { useState, useMemo, useEffect } from "react";
import queryString from "query-string";
import useHandlekurv from "features/Hooks/HandlekurvHook";
import Radio from "features/common/BaseComponents/Radio/Radio";
import { FormattedMessage } from "locale/langUtils";
import { useHistory } from "react-router";
import * as urls from "appConstants/urls";
import { useIntl } from "react-intl";
import { some, uniqBy, isEmpty } from "lodash-es";
import { getState as getConfigState } from "state/Konfigurasjoner/konfigurasjonerReducer";

import UserHook from "features/Hooks/UserHook";
import { useSelector } from "react-redux";
import { StickerPeriodId } from "appConstants";
import BrregHook from "features/Hooks/Brreg/BrregHook";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import { Checkbox } from "features/common/OSG";
import CheckoutSection from "./CheckoutSection";
import { Button } from "features/common/ComposedComponents";

type ComapanyUserCheckout = React.HTMLProps<HTMLDivElement>;
type PaymentMethods = "card" | "invoice";
export default function ComanyUserCheckout({ ...divProps }: ComapanyUserCheckout): JSX.Element {
  const [betalingsform, setBetalingsformState] = useState<PaymentMethods>("card");
  const [loading, setLoadingState] = useState(false);
  const [betalingErrorMessage, setBetalingErrorMessageState] = useState("");
  const [confirmKvitteringEpostDialogOpen, setConfirmKvitteringEpostDialogOpenState] = useState(false);
  const [fakturaPrBil, setFakturaPrBil] = useState(false);
  const [epostPrBil, setEpostPrBil] = useState(false);

  const { shoppingCartItems, varekjopId, totalPrice, isShoppingCartEmpty, handlePayWithNets, stickerSummary } =
    useHandlekurv();

  const { customerState } = UserHook();

  const configurations = useSelector<any, any>(getConfigState);

  const { erUnderenhet, erGyldig } = BrregHook(customerState?.organisasjonsnummer);

  const history = useHistory();

  const { formatNumber } = useIntl();

  const onCheckoutClicked = () => {
    if (betalingsform === "invoice") {
      setLoadingState(true);
      setBetalingErrorMessageState("");
      history.push({
        pathname: urls.betalingFaktura.replace(":varekjopId", varekjopId), // `${history.location.pathname}${urls.betalingFaktura.replace(":varekjopId",varekjopId)}`,
        search: queryString.stringify({ fakturaPrBil, epostPrBil }),
        state: {
          varekjopId,
          fakturaPrBil,
          epostPrBil
        }
      });
    } else {
      // Pay with nets
      setLoadingState(true);
      setBetalingErrorMessageState("");
      handlePayWithNets()
        .catch(() => {
          setBetalingErrorMessageState("checkoutpage.paymentError");
        })
        .finally(() => {
          setLoadingState(false);
        });
    }
  };

  const onPaymentMethodClicked = (method: PaymentMethods) => {
    setBetalingsformState(method);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleConfirmKvitteringerPaaEpost = (allReceiptsOnNorwegian: boolean) => {
    setConfirmKvitteringEpostDialogOpenState(false);
    setEpostPrBil(allReceiptsOnNorwegian);
  };

  const {
    invoicePrCarDisabled,
    belopUnderMinstebelop,
    hasDagsoblatInHandlekurv,
    isOneVehicleInBasketOnly,
    invoiceOptionDisabled
  } = useMemo(() => {
    const belopUnderMinstebelopS =
      !customerState?.fritattMinstebelopFaktura &&
      configurations &&
      configurations.minstebelopFaktura &&
      totalPrice < configurations.minstebelopFaktura.verdi &&
      !isEmpty(shoppingCartItems);
    const hasDagsoblatInHandlekurvS = some(shoppingCartItems, ol => ol.oblattype.periodeId === StickerPeriodId.Day);
    const invoiceOptionDisabledS = belopUnderMinstebelopS || hasDagsoblatInHandlekurvS || erUnderenhet || !erGyldig;
    const isOneVehicleInBasketOnlyS = uniqBy(shoppingCartItems, "kjoretoyId").length === 1;
    const invoicePrCarDisabledS = shoppingCartItems.length < 2 && isOneVehicleInBasketOnlyS;

    return {
      invoicePrCarDisabled: invoicePrCarDisabledS,
      belopUnderMinstebelop: belopUnderMinstebelopS,
      hasDagsoblatInHandlekurv: hasDagsoblatInHandlekurvS,
      isOneVehicleInBasketOnly: isOneVehicleInBasketOnlyS,
      invoiceOptionDisabled: invoiceOptionDisabledS
    };
  }, [customerState, shoppingCartItems, erUnderenhet, erGyldig, totalPrice, configurations]);

  useEffect(() => {
    if (invoicePrCarDisabled && fakturaPrBil) setFakturaPrBil(false);
  }, [fakturaPrBil, invoicePrCarDisabled]);

  return (
      <CheckoutSection
        isBasketEmpty={isShoppingCartEmpty}
        onCheckoutClicked={onCheckoutClicked}
        stickerSummary={stickerSummary}
        isAddingToBasket={loading}
        {...divProps}
      >
        {{
          heading: <FormattedMessage id="checkoutpage.heading" />,
          totalPrice,
          paymentSection: (
            <div className="flex flex-col items-center">
              <span className="text-2 mb-3">
                <FormattedMessage id="checkoutpage.paymentmethod" />
              </span>
              <form className="flex flex-col bg-white px-4 py-4 text-3 w-full">
                <Radio
                  onClick={() => onPaymentMethodClicked("card")}
                  checked={betalingsform === "card" && !isShoppingCartEmpty}
                  disabled={isShoppingCartEmpty}
                >
                  <FormattedMessage id="checkoutpage.paymentmethod.card" />
                </Radio>

                <Radio
                  onClick={() => onPaymentMethodClicked("invoice")}
                  checked={betalingsform === "invoice"}
                  disabled={invoiceOptionDisabled || isShoppingCartEmpty}
                >
                  <FormattedMessage id="checkoutpage.paymentmethod.invoice" />
                </Radio>
                {!invoiceOptionDisabled && betalingsform === "invoice" && (
                  <div className="">
                    <Checkbox
                      disabled={invoicePrCarDisabled}
                      onClick={() => setFakturaPrBil(prev => !prev)}
                      checked={fakturaPrBil}
                    >
                      <FormattedMessage id="checkoutpage.fakturaPrKjoretoyCheck" />
                    </Checkbox>

                    {/** <Checkbox
                      onClick={() => setEpostPrBil((prev) => !prev)}
                      checked={epostPrBil}
                    >
                      <FormattedMessage
                        id="checkoutpage.epostPrBilCheck"
                        values={{ numOfMails: shoppingCartItems.length }}
                      />
                    </Checkbox> */}
                  </div>
                )}
              </form>
              <MessageBox visible={erUnderenhet} type="warning" className="w-full mt-1">
                <FormattedMessage id="checkoutpage.erUnderenhet" />
              </MessageBox>
              <MessageBox visible={!erGyldig} type="warning" className="w-full mt-1">
                <FormattedMessage id="checkoutpage.erUgyldigOrganisasjon" />
              </MessageBox>
              <MessageBox visible={betalingsform === "invoice"} type="warning" className="w-full mt-1">
                <p>
                  <FormattedMessage id="checkoutpage.payByInvoiceNotification" />
                </p>
                <p>
                  <FormattedMessage id="checkoutpage.payByInvoiceNotification_2" />
                </p>
              </MessageBox>
              <MessageBox visible={hasDagsoblatInHandlekurv} type="warning" className="w-full mt-1">
                <p>
                  <FormattedMessage id="checkoutpage.cantBuyDayStickerWithInvoice" />
                </p>
              </MessageBox>
              <MessageBox visible={belopUnderMinstebelop} type="warning" className="w-full mt-1">
                <p>
                  <FormattedMessage
                    id="checkoutpage.payByInvoiceExceed"
                    values={{
                      sum: formatNumber((configurations?.minstebelopFaktura?.verdi ?? 1) - 1, {
                        currency: "NOK",
                        style: "currency",
                        compactDisplay: "short"
                      })
                    }}
                  />
                </p>
              </MessageBox>
            </div>
          ),
          checkoutButton:
            betalingsform === "card" ? undefined : (
              <Button onClick={onCheckoutClicked}>
                <FormattedMessage id="checkoutpage.payment.invoice.button" />
                <span className="sr-only">
                  <FormattedMessage id="checkoutpage.payment.invoice.button.wcag" />
                </span>
              </Button>
            )
        }}
      </CheckoutSection>
  );
}
