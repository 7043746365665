import React from "react";
import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { useIntl } from "react-intl";
import { FormattedMessage } from "locale/langUtils";
import useSamtykkeHook from "features/Hooks/SamtykkeHook";
import { Button } from "features/common/OSG";

interface TrekkSamtykke {
  onDeclein?: () => any;
  isOpen: boolean;
  cancel?: () => any;
}

export default function WithdrawConsentDialogue({ onDeclein, isOpen, cancel }: TrekkSamtykke): JSX.Element {
  const { formatMessage } = useIntl();
  const {
    state: { acceptedSamtykke },
    trekkSamtykke
  } = useSamtykkeHook();

  return (
    <Modal isOpen={isOpen} toggle={cancel}>
      <ModalHeader>
        {{
          heading: formatMessage({
            id: "termsandconditions.declinetermsdialogue.heading"
          })
        }}
      </ModalHeader>
      <ModalSection>
        {acceptedSamtykke ? (
          <FormattedMessage id="termsandconditions.declinetermsdialogue.body.haspreviouslyaccepted.info" />
        ) : (
          <FormattedMessage id="termsandconditions.declinetermsdialogue.body.hasnotaccepted.info" />
        )}
      </ModalSection>
      <ModalSection className="flex flex-col tablet:flex-row">
        <Button
          aria-label={formatMessage({ id:"termsandconditions.button.cancel.aria" })}
          colorOption="green-light"
          onClick={cancel}
          className="mx-0 my-1 tablet:my-0 tablet:mr-1"
        >
          <FormattedMessage id="termsandconditions.button.cancel" />
        </Button>
        <Button
          modifier="outline"
          onClick={() => {
            trekkSamtykke();
            // eslint-disable-next-line no-unused-expressions
            onDeclein?.();
          }}
          aria-label={
            acceptedSamtykke
              ? formatMessage({ id: "termsandconditions.button.decline.haspreviouslyaccepted.aria" })
              : undefined
          }
          className="mx-0 my-1 tablet:my-0 tablet:mr-1"
        >
          {acceptedSamtykke ? (
            <FormattedMessage id="termsandconditions.button.decline.haspreviouslyaccepted" />
          ) : (
            <FormattedMessage id="termsandconditions.button.decline" />
          )}
        </Button>
      </ModalSection>
    </Modal>
  );
}
