import { useEffect, useMemo, useContext, useCallback } from "react";
import { useSelector } from "react-redux";
import {
  getState,
  trekkAcceptsSamtykke,
  setAcceptsVilkaar,
  getSamtykke,
  getVilkaarData,
  setInitialized,
  setHasAcceptedVilkaar
} from "state/TermsAndCondition/SamtykkeAndVilkaar";
import { useReduxDispatch } from "utils/Storeutils";
import { LocaleContext } from "locale/LocaleProvider";
import { useAuth } from "react-oidc-context";

export default function SamtykkeHook() {
  const auth = useAuth();

  const useLocale = useContext(LocaleContext);

  const {
    currentLocale: { locale },
    initalized: localeInitialized
  } = useLocale;

  const state = useSelector(getState);

  const { initialized, vilkaar, samtykke, acceptedSamtykke } = state;

  const dispatch = useReduxDispatch();

  const isLoggedInAndNOTAcceptedSamtykke = useMemo(
    () => auth.isAuthenticated && initialized && !acceptedSamtykke,
    [acceptedSamtykke, auth.isAuthenticated, initialized]
  );

  /**
   * Fetches vilkaar
   */
  useEffect(() => {
    if (!localeInitialized) return;
    dispatch(getVilkaarData(locale));
  }, [dispatch, locale, localeInitialized]);

  /**
   * Effect for getting samtykke for a user
   */
  useEffect(() => {
    if (!auth.isAuthenticated || !auth.user?.profile?.sub || !vilkaar) return;
    dispatch(getSamtykke(auth.user?.profile?.sub));
  }, [auth.isAuthenticated, auth.user?.profile?.sub, dispatch, vilkaar]);

  /**
   * If both samtykke and vilkaar is present, check if user has accepted the newest vilkkaar
   */
  useEffect(() => {
    if (!samtykke || !vilkaar) return;
    if (vilkaar.id === samtykke.vilkaarId) dispatch(setHasAcceptedVilkaar(true));
    else dispatch(setHasAcceptedVilkaar(false));
    dispatch(setInitialized(true));
  }, [dispatch, samtykke, vilkaar]);

  const acceptSamtykke = useCallback(() => {
    if (!auth.isAuthenticated || !vilkaar || !auth.user?.profile.sub) return;
    dispatch(setAcceptsVilkaar(vilkaar.id, auth.user?.profile.sub));
  }, [auth.isAuthenticated, auth.user?.profile.sub, dispatch, vilkaar]);

  const trekkSamtykke = useCallback(() => {
    if (!auth.isAuthenticated && !samtykke) return;
    if (!acceptedSamtykke){
       auth.signoutRedirect();
        return;
      }
    dispatch(trekkAcceptsSamtykke((samtykke && samtykke.id) || "")).then(() => {
      auth.signoutRedirect();
    });
  }, [acceptedSamtykke, auth, dispatch, samtykke]);

  return {
    state,
    acceptSamtykke,
    trekkSamtykke,
    isLoggedInAndNOTAcceptedSamtykke
  };
}
