import { ReactNode, forwardRef } from "react";
import { useIntl } from "react-intl";
import Select, { Props as ReactSelectProps } from "react-select";
import "../CommonStyles/InputElement.scss";
import { reactSelectCustomStyles, reactSelectCustomTheme } from "./ReactSelectStyles";

export interface CustomReactSelectProps extends Exclude<ReactSelectProps, "styles" | "theme"> {
  label?: ReactNode;
}

const CustomReactSelect = forwardRef<any, CustomReactSelectProps & { rootClassName?: string }>(
  ({ name, label, rootClassName, ...rest }, ref) => {
    const intl = useIntl();

    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label className={rootClassName}>
        <span className="label">{label}</span>
        <Select
          styles={reactSelectCustomStyles}
          theme={reactSelectCustomTheme}
          noOptionsMessage={() => intl.formatMessage({ id: "select.option.nooptions" })}
          aria-live="polite"
          ariaLiveMessages={{
            onFocus({ label: fLabel }) {
              return intl.formatMessage({ id: "select.option.focus" }, { label: fLabel });
            },
            guidance: () => "",
            onChange: (props: any) =>
              intl.formatMessage({ id: "select.option.onChange" }, { label: props.label ?? "" }),
            onFilter: () => ""
          }}
          ref={ref}
          {...rest}
        />
      </label>
    );
  }
);

export default CustomReactSelect;
