import { Icon, IconTypes } from "features/common/OSG";
import { FormattedMessage } from "locale/langUtils";
import { ComponentProps } from "react";

interface IconWLinkProps extends ComponentProps<"a"> {
  icon: IconTypes;
  outgoing?: boolean;
}

export default function IconWLink({ icon, children, outgoing = false, ...anchorProps }: IconWLinkProps): JSX.Element {
  return (
    <a {...anchorProps} {...(outgoing ? { target: "_blank", rel: "noopener noreferrer" } : {})}>
      {children}{" "}
      {outgoing ? (
        <span className="sr-only">
          <FormattedMessage id="common.link.outgoing" />
        </span>
      ) : null}
      <Icon style={{ fontSize: "inherit" }} className="" icon={icon} />
    </a>
  );
}
