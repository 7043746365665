import { Icon } from "features/common/OSG";
import React from "react";

interface FilterArrowProps extends React.HTMLProps<HTMLSpanElement> {
  highlightUp: boolean;
  higlight: boolean;
}

export default function FilterArrow({ higlight, highlightUp, className, ...rest }: FilterArrowProps): JSX.Element {
  return (
    <span className={`inline-flex flex-col ml-1 ${className}`} {...rest}>
      <Icon
        className={`mt-2 text-3 ${higlight && highlightUp ? "text-black" : "text-grey-dark"}`}
        icon="chevron-thin-up"
      />
      <Icon
        className={`mb-2 text-3 ${higlight && !highlightUp ? "text-black" : "text-grey-dark"}`}
        icon="chevron-thin-down"
      />
    </span>
  );
}
