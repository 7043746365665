import { remove } from "lodash-es";
import * as KjoretoyActions from "./kjoretoyActions";

export default function kjoretoyReducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state = { kjoretoy: [], loading: false, error: null },
  action
) {
  switch (action.type) {
    case KjoretoyActions.RESET_KJORETOY:
      return { kjoretoy: [], loading: false, error: null };
    case KjoretoyActions.GET_KJORETOY_REQUEST:
      return { ...state, error: null, loading: true, success: false };
    case KjoretoyActions.GET_KJORETOY_SUCCESS:
      return {
        ...state,
        kjoretoy: action.payload,
        loading: false,
        success: true
      };
    case KjoretoyActions.DELETE_KJORETOY_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const indexDeleteVehicle = state.kjoretoy.findIndex(e => {
        return e.id === action.payload;
      });
      if (indexDeleteVehicle > -1) {
        const copyDeleteVehicle = [...state.kjoretoy];
        copyDeleteVehicle.splice(indexDeleteVehicle, 1);
        return {
          ...state,
          loading: false,
          success: true,
          kjoretoy: copyDeleteVehicle
        };
      }
      return {
        ...state
      };
    case KjoretoyActions.CREATE_KJORETOY_FALIURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
        success: false
      };
    case KjoretoyActions.CREATE_KJORETOY_SUCCESS:
      return {
        ...state,
        error: null,
        success: true
        // kjoretoy: [...state.kjoretoy, action.payload],
      };
    case KjoretoyActions.UPDATE_KJORETOY_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const indexUpdateVehicle = state.kjoretoy.findIndex(e => {
        return e.id === action.payload.id;
      });
      if (indexUpdateVehicle > -1) {
        const copyUpdateVehicle = [...state.kjoretoy];
        copyUpdateVehicle[indexUpdateVehicle] = action.payload;
        return {
          ...state,
          loading: false,
          success: true,
          kjoretoy: copyUpdateVehicle
        };
      }
      return state;
    case KjoretoyActions.RESET_KJORETOY_ERROR:
      return { ...state, error: null, success: false };
    default:
      return state;
  }
}

export const getState = state => state.kjoretoy;

export const getKjoretoy = state => state.kjoretoy.kjoretoy;
