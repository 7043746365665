import { piggavService } from "api";

export const KJORETOYTYPER_REQUEST = "KJORETOYTYPER_REQUEST";
export const KJORETOYTYPER_SUCCESS = "KJORETOYTYPER_SUCCESS";
export const KJORETOYTYPER_FAILURE = "KJORETOYTYPER_FAILURE";

const kjoretoytyperSuccess = kjoretoytyper => ({
  type: KJORETOYTYPER_SUCCESS,
  payload: kjoretoytyper
});

const kjoretoytyperFailure = error => ({
  type: KJORETOYTYPER_FAILURE,
  payload: error,
  error: true
});

export const getKjoretoytyper = () => dispatch =>
  piggavService
    .getKjoretoytyper()
    .then(response => {
      const data = response.data.result;
      dispatch(kjoretoytyperSuccess(data));
    })
    .catch(error => {
      dispatch(kjoretoytyperFailure(error));
    });
