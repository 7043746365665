import {
  GET_KUNDE_SUCCESS,
  GET_KUNDE_ERROR,
  UPDATE_KUNDE_SUCCESS,
  GET_KUNDE_REQUEST,
  TREKK_SAMTYKKE_SUCCESS,
  KUNDE_RESET,
  UPDATE_STANDARDKJORETOY_ID_SUCCESS
} from "./kundeActions";

export const getState = state => state.kunde;

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function kjoretoyReducer(state = { loading: false }, action) {
  switch (action.type) {
    case KUNDE_RESET:
      return {};
    case GET_KUNDE_REQUEST:
      return { ...state, loading: true };
    case GET_KUNDE_SUCCESS:
      return { ...state, ...action.payload, loading: false };
    case GET_KUNDE_ERROR:
      return { ...state, loading: false };
    case UPDATE_KUNDE_SUCCESS:
      return { ...state, ...action.payload };
    case TREKK_SAMTYKKE_SUCCESS:
      return action.payload;
    case UPDATE_STANDARDKJORETOY_ID_SUCCESS:
      return {
        ...state,
        standardKjoretoyId: action.payload
      };
    default:
      return state;
  }
}
