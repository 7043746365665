/* eslint-disable import/no-duplicates */
import React, { useMemo, useLayoutEffect } from "react";
import Page from "features/common/layout/Page";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import Section from "features/common/layout/Section";

import { getState as getAuthState } from "state/Auth/Auth";
import { FormattedMessage, useIntl } from "locale/langUtils";
import useOblaterHook, { StickerWInfoAndSeason } from "features/Hooks/OblaterHook";
import { Icon } from "features/common/OSG";
import { useTable, useFilters, usePagination, Column, useExpanded, useSortBy, TableState } from "react-table";
import { StickerStatus } from "appConstants/identifiers";
import "./OversiktTabell.scss";
import { filterNumberPlate, filterSeason, filterStatus, sortOblaterByKey } from "utils/oblatHelpers";
import Loader from "features/common/ComposedComponents/Loader/Loader";
import LinkAsButton from "features/common/BaseComponents/LinkAsButton/LinkAsButton";
import { StickerWInfo } from "interfaces";
import FilterArrow from "features/common/ComposedComponents/FilterArrow/FilterArrow";
import useWindowUtilsHook from "features/Hooks/WindowUtilsHook";
import Paging from "features/common/TableComponents/Paging";
import OblatDetaljer from "./OblatDetaljer";
import OverviewStickerFilters from "./OverviewStickerFilters";

export default function OverviewStickerPage(): JSX.Element {
  const { isLoading: isLoadingStickers, oblater } = useOblaterHook();

  const { userInfo } = useSelector(getAuthState);

  const intl = useIntl();

  const stikerWSeasonInfo = useMemo<StickerWInfoAndSeason[]>(() => {
    const sortedStickers = sortOblaterByKey(oblater, [
      StickerStatus.All,
      StickerStatus.NotStartedFrontendOnly,
      StickerStatus.Expired,
      StickerStatus.AwaitingPayment,
      StickerStatus.CanceledFrontendOnly,
      StickerStatus.Transferred,
      StickerStatus.Inactive
    ]);
    return sortedStickers;
  }, [oblater]);

  const { currentBreakpoint } = useWindowUtilsHook();

  const isRowDisabled = (sticker: StickerWInfo) => {
    if (!sticker.oblat?.kjopsinfo) return true;
    return false;
  };

  const columns = useMemo<Column<StickerWInfoAndSeason>[]>(
    () => [
      {
        Header: (
          <span className="sr-only">
            <FormattedMessage id="overviewstickers.select.status.label.aria" />
          </span>
        ),
        accessor: "statusClass",
        id: "status",
        disableSortBy: true,
        Cell: ({ row, value }: any) => {
          const { formatMessage: formatMessageInnner, locale } = useIntl();
          const statusName = formatMessageInnner({
            id: row.original.statusText
          });

          return (
            <span
              role="img"
              className={`status-indicator ${value}`}
              aria-label={`${
                locale === "nb" ? "Oblatet har følgende status" : "The sticker has the following status"
              }: ${statusName}`}
            />
          );
        },
        filter: "status"
      },
      {
        Header: <span>Status</span>,
        accessor: r => r.statusText,
        id: "type",
        Cell: (v: any) => <FormattedMessage id={v.value} />
      },
      {
        Header: <FormattedMessage id="overviewstickers.type" defaultMessage="" />,
        accessor: "name",
        Cell: v => <FormattedMessage id={v.value} />
      },
      {
        Header: <FormattedMessage id="overviewstickers.validfrom" defaultMessage="" />,
        accessor: v => v.oblat.gyldigFra,
        id: "validFrom",
        Cell: (v: any) => format(new Date(v.value), "dd.MM.yyyy HH:mm")
      },
      {
        Header: <FormattedMessage id="overviewstickers.validto" defaultMessage="" />,
        accessor: v => v.oblat.gyldigTil,
        id: "validTo",
        Cell: (v: any) => format(new Date(v.value), "dd.MM.yyyy HH:mm")
      },
      {
        Header: <FormattedMessage id="overviewstickers.season" defaultMessage="" />,
        accessor: "season",
        Cell: v => v.value,
        disableSortBy: true,
        filter: "season"
      },
      {
        Header: <FormattedMessage id="overviewstickers.vehicles" defaultMessage="" />,
        accessor: v => v.oblat.kjoretoy?.registreringsnummer,
        id: "registreringsnummer",
        filter: "numberPlate"
      },
      {
        Header: (
          <span className="sr-only">
            <FormattedMessage id="overviewstickers.select.show.label" />
          </span>
        ),
        id: "expander",
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        accessor: () => null,
        disableSortBy: true,
        // eslint-disable-next-line react/prop-types
        Cell: ({ row }: any) => {
          const { formatMessage: formatMessageInnner } = useIntl();
          const title = isRowDisabled(row.original)
            ? formatMessageInnner({
                id: "overviewstickers.lunkbutton.expander.title.notAllowed"
              })
            : formatMessageInnner({
                id: "overviewstickers.lunkbutton.expander.title"
              });
          return (
            <LinkAsButton
              {...row.getToggleRowExpandedProps()}
              className="inline-flex nowrap items-center pb-0 text-6"
              role="button"
              type="button"
              tabIndex={0}
              disabled={isRowDisabled(row.original)}
              title={title}
            >
              {row.isExpanded ? (
                <FormattedMessage id="overviewstickers.button.lessdetails" />
              ) : (
                <FormattedMessage id="overviewstickers.button.moredetails" />
              )}
              <Icon
                icon="chevron-right"
                className={`overview-button-caret ${
                  row.isExpanded ? "overview-button-caret-aapen" : "overview-button-caret-lukket"
                }`}
              />
            </LinkAsButton>
          );
        }
      }
    ],
    []
  );

  const initialState = useMemo<Partial<TableState<StickerWInfoAndSeason>>>(() => {
    const hiddenColumns = ["season"];
    if (currentBreakpoint === "mobile") hiddenColumns.push("season", "validFrom", "validTo", "expander");
    return {
      pageIndex: 0,
      pageSize: 10,
      hiddenColumns,
      filters: [
        { id: "season", value: "0" },
        { id: "status", value: "0" }
      ]
    };
  }, [currentBreakpoint]);

  const filterTypes = useMemo(
    () => ({
      season: filterSeason,
      status: filterStatus,
      numberPlate: filterNumberPlate
    }),
    []
  );

  const {
    rows,
    preFilteredRows,
    setFilter,
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    setHiddenColumns,
    toggleAllRowsExpanded,
    state: { pageIndex, pageSize, filters }
  } = useTable<StickerWInfoAndSeason>(
    {
      columns,
      data: stikerWSeasonInfo,
      initialState,
      expandSubRows: false,
      defaultCanSort: true,
      filterTypes
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  useLayoutEffect(() => {
    if (currentBreakpoint === "mobile") setHiddenColumns(["season", "validFrom", "validTo", "expander"]);
    else setHiddenColumns(["season"]);
  }, [currentBreakpoint, setHiddenColumns]);

  return (
    <Page
      metaInfo={{
        titlePageName: intl.formatMessage({ id: "overviewstickers.meta.title" }),
        titleDescription: intl.formatMessage({ id: "overviewstickers.meta.title.description" })
      }}
    >
      {{
        heading: { pageTitle: <FormattedMessage id="overviewstickers.title" /> },
        pageContent: (
          <Section>
            <OverviewStickerFilters preFilteredRows={preFilteredRows} setFilter={setFilter} filters={filters} />
            <div className={`${pageCount > 1 ? "overview-sticker-container-min-height" : ""}  w-full overflow-x-auto`}>
              <table {...getTableProps()} className="overview-tags-container ">
                <caption className="text-left text-3 invisible">
                  <FormattedMessage id="overviewstickers.title" />
                </caption>
                <thead>
                  {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <th
                          {...column.getHeaderProps()}
                          {...column.getSortByToggleProps({
                            title: "Sorter denne kolonnen"
                          })}
                        >
                          {column.canSort ? (
                            <span
                              className="flex items-center"
                              role="button"
                              tabIndex={0}
                              onKeyDown={e => {
                                if (e.key === "Enter") column.toggleSortBy();
                              }}
                            >
                              {column.render("Header")}

                              <FilterArrow higlight={column.isSorted} highlightUp={!column.isSortedDesc} />
                            </span>
                          ) : (
                            column.render("Header")
                          )}
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                  <Loader isLoading={isLoadingStickers}>
                    {{
                      loadingText: <FormattedMessage id="common.loading" />,
                      renderLoader: Spinner => (
                        <tr key="loader-row">
                          <td colSpan={7}>
                            <div className="flex justify-center text-5 mt-2 p-3 osg-u-text-is-medium">{Spinner}</div>
                          </td>
                        </tr>
                      ),
                      loadingContent:
                        page.length === 0 ? (
                          <tr key="information-row">
                            <td colSpan={7}>
                              <div
                                aria-live="polite"
                                className="flex justify-center text-5 mt-2 p-3 osg-u-color-primary osg-u-text-is-medium"
                              >
                                {oblater.length === 0 ? (
                                  <FormattedMessage id="overviewstickers.table.nosticker.purchased" />
                                ) : (
                                  <FormattedMessage id="overviewstickers.table.nostickers" />
                                )}
                              </div>
                            </td>
                          </tr>
                        ) : (
                          page.map(row => {
                            prepareRow(row);
                            return (
                              <React.Fragment key={row.getRowProps().key + row.original.uniqueIdOnlyInFrontend}>
                                <tr
                                  {...row.getRowProps()}
                                  className={`overview-tags-trow ${
                                    row.isExpanded ? "overview-tags-trow-aapen" : "overview-tags-trow-lukket"
                                  }
                            ${isRowDisabled(row.original) ? "mobile-only:text-grey-dark" : ""}
                            `}
                                  onClick={() => {
                                    if (isRowDisabled(row.original)) return;
                                    toggleAllRowsExpanded(false);
                                    if (!row.isExpanded) row.toggleRowExpanded(true);
                                  }}
                                >
                                  {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render("Cell")}</td>;
                                  })}
                                </tr>
                                {row.isExpanded && (
                                  <tr
                                    key={`overview-tags-trow-2-'${
                                      row.original.uniqueIdOnlyInFrontend + row.getRowProps().key
                                    }`}
                                    className="overview-tags-datails-aapen"
                                  >
                                    <td className="mobile-only:hidden" />
                                    <td className="mobile-only:hidden" />
                                    <td colSpan={4} className="overview-tags-datails-col">
                                      <OblatDetaljer oblat={row.original} brukerId={userInfo?.brukerId ?? ""} />
                                    </td>
                                    <td className="mobile-only:hidden" />
                                  </tr>
                                )}
                              </React.Fragment>
                            );
                          })
                        )
                    }}
                  </Loader>
                </tbody>
              </table>
            </div>
            <Paging
              showPaginationControlls={pageCount > 1}
              beginFrom={1}
              pageSize={pageSize}
              pageIndex={pageIndex}
              canPreviousPage={canPreviousPage}
              canNextPage={canNextPage}
              nextPage={nextPage}
              previousPage={previousPage}
              totalNumberOfRows={rows.length}
            />
          </Section>
        )
      }}
    </Page>
  );
}
