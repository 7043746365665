import React, { FC, ReactNode } from "react";
import Figure, { FigureProps } from "../../Atoms/Decorators/Figure";
import Shape from "../../Atoms/Decorators/Shape";
import { OSGPattern } from "../../interfaces";

type Modifiers =
  | "circle"
  | "left"
  | "right"
  | "top"
  | "left-tablet-and-up"
  | "top-tablet-and-up"
  | "right-tablet-and-up"
  | "center-mobile-only";

type Variants = "";

interface HeroProps extends OSGPattern<Variants, Array<Modifiers>> {
  linkProps?: Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, "className">;
  children: {
    figure: FigureProps["children"];
    heading: ReactNode;
    subHeading: ReactNode;
  };
}
/*
    General:
    This pattern is primarily to be used for previewing part of content basically an image, text and a link.

    Note on the link:
    If a the link variable is passed to this patterns twig the whole component will be a link. You can also choose to have a link inside the pattern instead by putting the markup for it inside the "content" block.

    Blocks:
    This pattern has one block named "content". Content passed to the block will render below the image and image caption.

    Modifiers are passed as an array of the following string values:
      circle	Makes the image circular
      left{-tablet-and-up} Text on the left of the image
      right{-tablet-and-up}	Text on the right of the image
      top{-tablet-and-up}	Text on the top of the image
      center-mobile-only	Image and caption centered"
*/
export default function Hero({ linkProps, modifier = ["left"], ...innerProps }: HeroProps): JSX.Element {
  const commonClassName = `osg-hero osg-hero-default ${(modifier as string[]).map(m => ` osg-hero--${m} `).join(" ")}`;
  if (linkProps)
    return (
      <a {...linkProps} className={commonClassName}>
        <HeroInner {...innerProps} />
      </a>
    );

  return (
    <div className={commonClassName}>
      <HeroInner {...innerProps} />
    </div>
  );
}

const HeroInner: FC<Pick<HeroProps, "children">> = ({ children: { figure, heading, subHeading } }: HeroProps) => {
  return (
    <>
      <div className="osg-hero__image">
        <Shape variant="image">
          <Figure>{figure}</Figure>
        </Shape>
      </div>
      <div className="osg-hero__content">
        <h1 className="osg-u-heading-1 osg-u-padding-bottom-2 osg-u-padding-bottom-3-desktop-and-up">{heading}</h1>
        <p className="osg-u-text-1">{subHeading}</p>
      </div>
    </>
  );
};
