import { Icon } from "features/common/OSG";
import { useLayoutEffect, useMemo } from "react";
import { useLocation } from "react-router";
import * as urls from "appConstants/urls";
import { filter, map } from "lodash-es";
import { useIntl } from "react-intl";
import "./Breadcrumb.scss";
import { NavLink } from "react-router-dom";

const createBreadcrumb = (name: string, url: string, active: boolean) => ({
  url,
  name,
  isActive: active
});

export default function Breadcrumb(): JSX.Element {
  const { formatMessage } = useIntl();

  const { pathname } = useLocation();

  const translatedPath = useMemo(() => {
    let paths = pathname?.split("/");
    // remove the last element if there was a / at the end of the pathname
    paths = paths[paths.length - 1] === "" ? paths.slice(0, paths.length - 1) : paths;
    // remove the first element if the second one is an empty string which means that we are in the root of the website
    paths = paths[1] === "" ? paths.slice(1) : paths;

    const breadcrumbs = map(paths, (item, index) => {
      let name = "";
      let url = "";

      const activeBreadcrumb = index + 1 === paths.length;
      switch (true) {
        case urls.hjem.endsWith(`/${item}`):
          name = "breadcrumb.path.startPage";
          url = urls.hjem;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case urls.profil.endsWith(`/${item}`):
          name = "breadcrumb.path.innstillinger";
          url += urls.profil;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case urls.login.endsWith(`/${item}`):
          name = "breadcrumb.path.login";
          url += urls.login;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case urls.registrerBruker.endsWith(`/${item}`):
          name = "breadcrumb.path.register";
          url += urls.registrerBruker;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case urls.registerCompany.endsWith(`/${item}`):
          name = "breadcrumb.path.registerOrganization";
          url += urls.registerCompany;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case urls.takkForRegistrering.endsWith(`/${item}`):
          name = "breadcrumb.path.takkForDinRegistrering";
          url += urls.glemtPassord;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case urls.kjopOblater.endsWith(`/${item}`):
          name = "breadcrumb.path.kjopOblater";
          url = urls.kjopOblater;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case urls.summaryAddSingle.endsWith(`/${item}`):
          name = "breadcrumb.path.confirm";
          url += urls.summaryAddSingle;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case urls.kvitteringer.endsWith(`/${item}`):
          name = "breadcrumb.path.receipts";
          url = urls.kvitteringer;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case urls.oblatoversikt.endsWith(`/${item}`):
          name = "breadcrumb.path.OversiktOblater";
          url += urls.oblatoversikt;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case urls.vilkaar.endsWith(`/${item}`):
          name = "breadcrumb.path.vilkaar";
          url += urls.vilkaar;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case urls.bulkKjop.endsWith(`/${item}`):
          name = "breadcrumb.path.bulkKjop";
          url += urls.bulkKjop;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case urls.kontrollerBulkkjop.endsWith(`/${item}`):
          name = "breadcrumb.path.kontrollerBulkkjop";
          url = urls.kontrollerBulkkjop;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case "/bekreft-bulkkjop".endsWith(`/${item}`):
          name = "breadcrumb.path.bekreftBulkKjop";
          url += urls.summaryAddBulk;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case "/betaling-faktura".endsWith(`/${item}`):
          name = "breadcrumb.path.invoicePayment";
          url += urls.betalingFaktura;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case "/hjem/gjenomfort-betaling".endsWith(`/${item}`):
          name = "breadcrumb.path.netPayment";
          url += urls.betalingFaktura;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case "/glemt-passord".endsWith(`/${item}`):
          name = "breadcrumb.path.glemtPassord";
          url += urls.glemtPassord;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case "/konto".endsWith(`/${item}`):
          name = "breadcrumb.path.login";
          url += urls.login;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case "/passord".endsWith(`/${item}`):
          name = "breadcrumb.path.createNewPassword";
          url += urls.createNewPassword;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case urls.sentNewPassword.endsWith(`/${item}`):
          name = "breadcrumb.path.glemtPassord";
          url += urls.sentNewPassword;
          return createBreadcrumb(name, url, activeBreadcrumb);
        case "/aktivering".endsWith(`/${item}`):
          name = "breadcrumb.path.aktivering";
          url += urls.aktiverBruker;
          return createBreadcrumb(name, url, true);
        case urls.faq.endsWith(`/${item}`):
          name = "breadcrumb.path.faq";
          url += urls.faq;
          return createBreadcrumb(name, url, activeBreadcrumb);
        default:
          // if none of the above check the following
          name = item;
          url += item;
          if (index > 1 && `/${paths[index - 1]}` === urls.kvitteringer) {
            return createBreadcrumb("", url, activeBreadcrumb);
          }
          return null;
      }
    });
    return filter(breadcrumbs, bc => bc !== null);
  }, [pathname]);
  const path = translatedPath?.[translatedPath.length - 2];
  let pathName = "";
  if (path) {
    const pathNameTmp = formatMessage({ id: path?.name, defaultMessage: "" });
    pathName = pathNameTmp.charAt(0) + pathNameTmp.slice(1).toLowerCase();
  }

  useLayoutEffect(() => {
    if (!path) document.body.classList.add("no-breadcrumbs");
    else document.body.classList.remove("no-breadcrumbs");
  }, [path]);

  if (!path) return <></>;

  return (
    <nav className="osg-u-margin-bottom-3 osg-u-margin-bottom-4-tablet-and-up osg-u-margin-horizontal-2 osg-u-margin-horizontal-5-tablet-and-up">
      <span>
        <NavLink
          to={path.url}
          className="osg-u-text-7 align-middle cursor-pointer visited:text-black ok-breadcrumb"
          aria-label={formatMessage({ id: "breadcrumb.alt.breadCrumbMobile.aria" }, { page: pathName })}
        >
          <Icon icon="chevron-left" aria-hidden className="osg-u-text-7 align-middle" />{" "}
          <span className="align-middle leading-3">{pathName}</span>
        </NavLink>
      </span>
    </nav>
  );
}
