import React, { createContext, useReducer, Reducer, Dispatch } from "react";
import PrivateRoute from "features/Routes/PrivateRoute";
import * as urls from "appConstants/urls";
import { VehicleWeightId } from "appConstants";
import { BymEndpointResult, Oblat, VehicleDictionary } from "interfaces";
import { useHistory } from "react-router";
import { isEmpty } from "lodash-es";
import useHandleKurvHook from "features/Hooks/HandlekurvHook";
import BuyStickersBulkUploadPage from "./BuyStickersBulkUploadPage";
import BuyStickersBulkConfirmVehiclesPage from "./BuyStickersBulkConfirmVehiclesPage";
import OverlappingStickerDialogueBulkBuy from "./OverlappingStickerDialogBulkBuy";

export type BymEndpointResultVehicleList = BymEndpointResult<{
  bekreftedeKjoretoy: VehicleDictionary;
  ubekreftedeKjoretoy: VehicleDictionary;
}>;

type OverlappingStickerDict = { [id: string]: Oblat[] };

interface BulkBuyState {
  overlappingStickerDict: OverlappingStickerDict;
  unconfirmedVehicles: VehicleDictionary;
  confirmedVehicles: VehicleDictionary;
}

type Actions =
  | {
      type: "BUY_BULK_SET_BEKREFTEDE_AND_UBEKREFTEDE_VEHICLES";
      bekreftedeKjoretoy: VehicleDictionary;
      ubekreftedeKjoretoy: VehicleDictionary;
    }
  | { type: "BUY_BULK_DELETE_VEHICLE_FROM_UNCONFIRMED"; reg: string }
  | {
      type: "BUY_BULK_PUT_VEHICLE_WEIGHT_UNCONFIRMED";
      vehicle: { reg: string; weightID: VehicleWeightId | null };
    }
  | {
      type: "BUY_BULK_SET_OVERLAPPING_STICKER_DIALOGUE";
      overlappingList: OverlappingStickerDict;
    }
  | {
      type: "BUY_BULK_RESET_ALL";
    };

const initialSate: BulkBuyState = {
  overlappingStickerDict: {},
  unconfirmedVehicles: {},
  confirmedVehicles: {}
};

export const BulkBuyContext = createContext<{
  state: BulkBuyState;
  dispatch: Dispatch<Actions>;
  addToBasket: Function;
  checkAndValidateListAndAddToBasket: Function;
}>({
  state: initialSate,
  dispatch: () => null,
  addToBasket: () => null,
  checkAndValidateListAndAddToBasket: () => null
});

const reducer: Reducer<BulkBuyState, Actions> = (prevState, action) => {
  const { unconfirmedVehicles } = prevState;
  switch (action.type) {
    case "BUY_BULK_SET_BEKREFTEDE_AND_UBEKREFTEDE_VEHICLES":
      return {
        ...prevState,
        confirmedVehicles: action.bekreftedeKjoretoy,
        unconfirmedVehicles: action.ubekreftedeKjoretoy
      };
    case "BUY_BULK_DELETE_VEHICLE_FROM_UNCONFIRMED":
      delete unconfirmedVehicles[action.reg];
      return { ...prevState, unconfirmedVehicles: { ...unconfirmedVehicles } };
    case "BUY_BULK_PUT_VEHICLE_WEIGHT_UNCONFIRMED":
      unconfirmedVehicles[action.vehicle.reg] = action.vehicle.weightID;
      return { ...prevState, unconfirmedVehicles: { ...unconfirmedVehicles } };
    case "BUY_BULK_SET_OVERLAPPING_STICKER_DIALOGUE":
      return { ...prevState, overlappingStickerDict: action.overlappingList };
    case "BUY_BULK_RESET_ALL":
      return {
        confirmedVehicles: {},
        unconfirmedVehicles: {},
        overlappingStickerDict: {}
      };
    default:
      return prevState;
  }
};

export default function BuyStickersBulkPage(): JSX.Element {
  const [state, dispatch] = useReducer(reducer, initialSate);
  const history = useHistory();
  const { addStickerInBulkToBasket } = useHandleKurvHook();

  const addToBasket = async (bekreftedeKjoretoy: VehicleDictionary) => {
    try {
      const res = await addStickerInBulkToBasket(bekreftedeKjoretoy, false);
      const {
        data: { errorkode, result }
      } = res;
      // If overlapping season sticker exists
      if (errorkode === 409) {
        const oDictList: unknown = result;
        dispatch({
          type: "BUY_BULK_SET_OVERLAPPING_STICKER_DIALOGUE",
          overlappingList: oDictList as OverlappingStickerDict
        });
      } else history.push(urls.summaryAddBulk);
      return res;
    } finally {
      //
    }
  };

  const checkAndValidateListAndAddToBasket = async (
    ubekreftedeKjoretoy: VehicleDictionary,
    bekreftedeKjoretoy: VehicleDictionary
  ) => {
    try {
      if (!isEmpty(ubekreftedeKjoretoy)) {
        // Gå videre
        history.push(urls.kontrollerBulkkjop);
        return false;
      }
      await addToBasket(bekreftedeKjoretoy);
      return true;
    } finally {
      //
    }
  };

  return (
    <BulkBuyContext.Provider
      value={{
        state,
        dispatch,
        addToBasket,
        checkAndValidateListAndAddToBasket
      }}
    >
      <PrivateRoute exact path={urls.bulkKjop} component={BuyStickersBulkUploadPage} />
      <PrivateRoute exact path={urls.kontrollerBulkkjop} component={BuyStickersBulkConfirmVehiclesPage} />
      <OverlappingStickerDialogueBulkBuy />
    </BulkBuyContext.Provider>
  );
}
