import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import SjekkBetalingsstatusside from "./CompletePaymentPage";
import { sjekkBetalingstatus } from "../../state/Handlekurv/handlekurvActions";
import { getOblaterForBruker } from "../../state/Oblat/oblatActions";

const mapStateToProps = state => ({
  brukerId: state.auth.userInfo?.brukerId,
  handlekurv: state.handlekurv
});

const mapDispatchToProps = dispatch => ({
  sjekkBetalingstatus: (transaksjonsId) => dispatch(sjekkBetalingstatus(transaksjonsId)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SjekkBetalingsstatusside));
