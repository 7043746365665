import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";
import InputField from "features/common/ComposedComponents/Inputfield/Inputfield";
import { TextArea } from "features/common/ComposedComponents/";

const SendKansellerSkjema = ({
  handleChange,
  saksbehandlerNavn,
  kontonummer,
  kontaktinformasjon,
  melding,
}) => {
  const { formatMessage } = useIntl();
  return (
    <form>
      <InputField
        name="resepsjonssaksbehandlernavn"
        type="text"
        label={formatMessage({
          id: "canceldialogue.kansellerResepsjonsoblatsakSbehandlerNavn",
          defaultMessage: "Saksbehandler",
        })}
        onChange={(e) => handleChange("saksbehandlerNavn", e.target.value)}
      />
      <InputField
        name="accountnumber"
        type="text"
        label={formatMessage({
          id: "canceldialogue.kansellerResepsjonsoblatInputKontonr",
          defaultMessage: "Kontonummer",
        })}
        value={kontonummer}
        onChange={(e) => handleChange("kontonummer", e.target.value)}
      />
      <InputField
        name="contact"
        type="text"
        label={formatMessage({
          id: "canceldialogue.kansellerResepsjonsoblatInputContact",
          defaultMessage: "Kontaktinformasjon",
        })}
        value={kontaktinformasjon}
        onChange={(e) => handleChange("kontaktinformasjon", e.target.value)}
      />
      <TextArea
        name="contact"
        label={formatMessage({
          id: "canceldialogue.kansellerResepsjonsoblatInputMelding",
          defaultMessage: "Melding",
        })}
        rows={10}
        value={melding}
        onChange={(e) => handleChange("melding", e.target.value)}
      />
    </form>
  );
};

SendKansellerSkjema.propTypes = {
  handleChange: PropTypes.func.isRequired,
  kontonummer: PropTypes.string.isRequired,
  saksbehandlerNavn: PropTypes.string.isRequired,
  kontaktinformasjon: PropTypes.string.isRequired,
  melding: PropTypes.string.isRequired,
};

export default SendKansellerSkjema;
