import React from "react";
import { useHistory } from "react-router-dom";
import Section from "features/common/layout/Section";
import { FormattedMessage } from "locale/langUtils";
import { IconLink } from "features/common/OSG";
import { LinkButtonReactRouter as LinkButton } from "features/common/ComposedComponents/";
import MiniOverview from "features/OverviewStickersPage/MiniOverview";
import { getState } from "state/Konfigurasjoner/konfigurasjonerReducer";
import * as urls from "appConstants/urls";
import Page from "features/common/layout/Page";
import Hero from "features/common/OSG/Organisms/Hero/Hero";
import carTabletAndUp from "images/bil-med-sirkel-tablet-and-up.png";
import { useIntl } from "react-intl";
import "./LandingPage.scss";
import useUserHook from "features/Hooks/UserHook";
import { useSelector } from "react-redux";
import { Configurations } from "interfaces";

export default function LoggedinLandingPage(): JSX.Element {
  const history = useHistory();
  const { formatMessage } = useIntl();
  const { customerType } = useUserHook();
  const onLinkClick = (to: string, e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault(); // preven
    history.push(to);
  };
  const { salgAapent } = useSelector<any, Configurations>(getState);
  return (
    <Page
      metaInfo={{
        titlePageName: formatMessage({ id: "landingpageloggedin.meta.title" }),
        titleDescription: formatMessage({ id: "landingpageloggedin.meta.title.description" }),
        description: formatMessage({ id: "landingpageloggedin.meta.description" })
      }}
    >
      {{
        pageContent: (
          <>
            <Section modifier="osg-u-padding-top-5">
              <Hero variant="default" modifier={["left-tablet-and-up", "center-mobile-only"]}>
                {{
                  figure: {
                    images: (
                      <>
                        <img
                          className="hero-picture"
                          src={carTabletAndUp}
                          alt={formatMessage({
                            id: "landingpageloggedin.illustration.alt"
                          })}
                        />
                      </>
                    )
                  },
                  heading: (
                    <FormattedMessage id="landingpageloggedin.heading" defaultMessage="Velkommen til piggav.no" />
                  ),
                  subHeading:
                    customerType === "Bedriftkunde" ? (
                      <FormattedMessage id="landingpageloggedin.subheading.companyuser" />
                    ) : (
                      <FormattedMessage id="landingpageloggedin.subheading" />
                    )
                }}
              </Hero>
              <div className="flex flex-col tablet:flex-row osg-u-margin-top-4 osg-u-margin-top-5-tablet-and-up">
                <div
                  className="flex flex-col p-0 tablet:p-4 tablet:w-1/2 osg-u-margin-top-4
                    osg-u-margin-top-none-tablet-and-up
                    osg-u-padding-none
                    osg-u-padding-2-tablet-and-up
                    osg-u-padding-right-7-tablet-and-up"
                >
                  <div className="osg-u-margin-bottom-5 osg-u-margin-bottom-6-tablet-and-up osg-content">
                    <h2>
                      <span className="osg-u-heading-4">
                        <FormattedMessage id="landingpageloggedin.stickersection.buysticker.title" />
                      </span>
                    </h2>

                    <p className="osg-u-text-5">
                      <FormattedMessage id="landingpageloggedin.stickersection.buysticker.subtitle" defaultMessage="" />
                    </p>
                    <ul>
                      <li>
                        <FormattedMessage id="landingpageloggedin.stickersection.buysticker.list.daysticker" />
                      </li>
                      <li>
                        <FormattedMessage id="landingpageloggedin.stickersection.buysticker.list.monthsticker" />
                      </li>
                      <li>
                        <FormattedMessage id="landingpageloggedin.stickersection.buysticker.list.seasonsticker" />
                      </li>
                    </ul>
                    <LinkButton
                      disabled={!salgAapent}
                      to={urls.kjopOblater}
                      colorOption="green-dark"
                      className="mobile-only:w-full"
                    >
                      <FormattedMessage id="landingpageloggedin.stickersection.linkbutton.buysingle" />
                    </LinkButton>
                  </div>
                  {customerType === "Bedriftkunde" && (
                    <div className="osg-content">
                      <h2>
                        <span className="osg-u-heading-4">
                          <FormattedMessage id="landingpageloggedin.stickersection.buybulk.title" defaultMessage="" />
                        </span>
                      </h2>

                      <p className="osg-u-text-5">
                        <FormattedMessage id="landingpageloggedin.stickersection.buybulk.subtitle" />
                      </p>
                      <LinkButton
                        disabled={!salgAapent}
                        to={urls.bulkKjop}
                        colorOption="blue-dark"
                        modifier="outline"
                        className="mobile-only:w-full"
                      >
                        <FormattedMessage id="landingpageloggedin.stickersection.linkbutton.buybulk" />
                      </LinkButton>
                    </div>
                  )}
                </div>
                <div
                  className="
                    flex flex-col
                    osg-u-margin-top-5
                    osg-u-margin-top-none-tablet-and-up
                    tablet:w-1/2
                    osg-u-padding-none
                    osg-u-padding-2-tablet-and-up
                    bg-white
                    tablet:bg-grey-light
                    "
                >
                  <h2 className="osg-content">
                    <span className="osg-u-heading-4">
                      <FormattedMessage id="landingpageloggedin.overview.heading" />
                    </span>
                  </h2>
                  <MiniOverview />
                </div>
              </div>
            </Section>
            <Section colorClass="osg-u-color-bg-grey-light" className="flex flex-col tablet:flex-row">
              <IconLink
                role="link"
                tabIndex={0}
                icon="chevron-right"
                modifier="w-full tablet:w-1/3 tablet:mb-none mb-4 cursor-pointer osg-u-padding-horizontal-3-tablet-and-up"
                onClick={e => onLinkClick(urls.oblatoversikt, e)}
                href={urls.kvittering}
              >
                {{
                  title: <FormattedMessage id="landingpageloggedin.menupanel.link.overview.title" defaultMessage="" />,
                  prompt: <FormattedMessage id="landingpageloggedin.menupanel.link.overview.prompt" />
                }}
              </IconLink>
              <IconLink
                role="link"
                tabIndex={0}
                icon="chevron-right"
                modifier="w-full tablet:w-1/3 mb-4 cursor-pointer osg-u-padding-horizontal-3-tablet-and-up"
                onClick={e => onLinkClick(urls.kvittering, e)}
                href={urls.kvittering}
              >
                {{
                  title: <FormattedMessage id="landingpageloggedin.menupanel.link.receipts.title" defaultMessage="" />,
                  prompt: <FormattedMessage id="landingpageloggedin.menupanel.link.receipts.prompt" />
                }}
              </IconLink>
              <IconLink
                role="link"
                tabIndex={0}
                icon="chevron-right"
                modifier="w-full tablet:w-1/3 cursor-pointer osg-u-padding-horizontal-3-tablet-and-up"
                onClick={e => onLinkClick(urls.profil, e)}
                href={urls.profil}
              >
                {{
                  title: <FormattedMessage id="landingpageloggedin.menupanel.link.profile.title" defaultMessage="" />,
                  prompt: <FormattedMessage id="landingpageloggedin.menupanel.link.profile.prompt" />
                }}
              </IconLink>
            </Section>
          </>
        )
      }}
    </Page>
  );
}
