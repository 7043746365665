import React from "react";
import { SizeableOSGPattern } from "../../interfaces";

export type IconTypes =
  | "24h"
  | "attachment"
  | "backpack"
  | "bike"
  | "bus"
  | "cafe"
  | "calendar"
  | "camera"
  | "check"
  | "check-circle"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "chevron-thin-down"
  | "chevron-thin-up"
  | "chevron-up"
  | "clock"
  | "close"
  | "coin-stacks"
  | "crane"
  | "cross"
  | "cross-circle"
  | "download"
  | "edit"
  | "email"
  | "exclamation-mark-circle"
  | "exclamation-mark-square"
  | "expand"
  | "facebook"
  | "filter"
  | "fire-emblem"
  | "graph"
  | "grid"
  | "handicap"
  | "heart"
  | "heart-plus"
  | "home"
  | "house-heart"
  | "information"
  | "instagram"
  | "language"
  | "linked-in"
  | "list"
  | "location-pin"
  | "magnifying-glass-big"
  | "magnifying-glass-small"
  | "map"
  | "menu"
  | "message"
  | "minimize"
  | "minus-sign"
  | "museum"
  | "neutral"
  | "new-window"
  | "park"
  | "picture"
  | "plus-sign"
  | "print"
  | "question"
  | "recycling"
  | "sad"
  | "save"
  | "share"
  | "smile"
  | "sound-off"
  | "sound-on"
  | "swingset"
  | "table"
  | "target"
  | "thumbs-down"
  | "thumbs-up"
  | "tickets"
  | "trail"
  | "tram"
  | "twitter"
  | "user"
  | "water-faucet"
  | "wifi";

type IProps = JSX.IntrinsicElements["i"];

interface IconProps extends Omit<IProps, "aria-hidden">, SizeableOSGPattern {
  icon: IconTypes;
  inherit?: boolean;
}

/**
 * Represent OSG's icon palette. You can also use icons directly by osg-u-icon-[followed by the icons name].
 * Example osg-u-icon-exclamation-mark-circle.
 * You can use the color-text classes from utils to color the icons, example: osg-u-color-text-red makes icon red.
 */
export default function Icon({ icon, className = "", ...rest }: IconProps): JSX.Element {
  return <i className={`osg-icon osg-icon--${icon} ${className}`} {...rest} aria-hidden />;
}
