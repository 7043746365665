/* eslint-disable @typescript-eslint/no-unused-vars */
const GAP = 32;
const TABLET_BREAK_POINT = 769 + GAP;
const DESKTOP_BREAKPOINT = 960 + 2 * GAP;
const MOBILE_BREAK_POINT = TABLET_BREAK_POINT - 1;
const WIDESCREEN_BREAK_POINT = 1152 + 2 * GAP;
const FULL_HD_BREAKPOINT = 1344 + 2 * GAP;

exports.GAP = 32;
exports.TABLET_BREAK_POINT = 769 + GAP;
exports.DESKTOP_BREAKPOINT = 960 + 2 * GAP;
exports.MOBILE_BREAK_POINT = TABLET_BREAK_POINT - 1;
exports.WIDESCREEN_BREAK_POINT = 1152 + 2 * GAP;
exports.FULL_HD_BREAKPOINT = 1344 + 2 * GAP;
