import { map } from "lodash-es";
import { applyStickerWInfo } from "utils/oblatHelpers";

import { FormattedMessage } from "locale/langUtils";
import DateTimeFormat from "locale/DateTimeFormat";


import "./OblatListeTabell.scss";
import { Kjoretoy } from "interfaces";

interface OblatListeTabellProps {
  oblaterList: any[];
  kjoretoy?: Kjoretoy;
}

function OblatListeTabell({ oblaterList = [], kjoretoy }: OblatListeTabellProps) {
  return (
    <table className="overlapping-table">
      <thead>
        <tr>
          <th>
            <FormattedMessage id="common.label.numberplate.short" />
          </th>
          <th className="mobile-only:hidden">Status</th>
          <th>
            <FormattedMessage id="common.stickerinfo.stickertype" />
          </th>
          <th>
            <FormattedMessage id="common.stickerinfo.validfrom" />
          </th>
          <th className="mobile-only:hidden">
            <FormattedMessage id="common.stickerinfo.validto" />
          </th>
        </tr>
      </thead>
      <tbody>
        {map(oblaterList, sticker => {
          const stickerWInfo = applyStickerWInfo(sticker);

          // If the sticker comes from an external service
          if (sticker.isEkstern)
            return (
              <tr className="odd:bg-grey-light w-100">
                <td>{kjoretoy?.registreringsnummer ?? ""}</td>
                <td className="mobile-only:hidden">{sticker.statusTekst}</td>
                <td>Bil i Oslo</td>
                <td>
                  <DateTimeFormat value={sticker?.gyldigFra} />
                </td>
                <td className="mobile-only:hidden">
                  <DateTimeFormat value={sticker?.gyldigTil} />
                </td>
              </tr>
            );
          return (
            <tr key={stickerWInfo.uniqueIdOnlyInFrontend} className="odd:bg-grey-light w-100">
              <td>{stickerWInfo.oblat?.kjoretoy?.registreringsnummer ?? ""}</td>
              <td className="mobile-only:hidden">
                <FormattedMessage id={stickerWInfo.statusText} />
              </td>
              <td>
                <FormattedMessage id={stickerWInfo.name} />
              </td>
              <td>
                <DateTimeFormat value={stickerWInfo.oblat?.gyldigFra} />
              </td>
              <td className="mobile-only:hidden">
                <DateTimeFormat value={stickerWInfo.oblat?.gyldigTil} />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default OblatListeTabell;
