import React, { ReactNode } from "react";
import { FormattedMessage } from "locale/langUtils";
import { StickerSummaryItem } from "features/Hooks/HandlekurvHook";
import { BrukerType, KundeType } from "interfaces";
import CurrencyFormat from "locale/CurrencyFormat";
import Button from "features/common/ComposedComponents/ButtonWIconSpinner/ButtonWIconSpinner";

interface CommonCheckoutSectionProps {
  userType?: BrukerType;
  customerType?: KundeType;
  stickerSummary?: StickerSummaryItem[];
  isBasketEmpty?: boolean;
}
interface CheckoutSectionProps extends React.HTMLProps<HTMLDivElement>, CommonCheckoutSectionProps {
  stickerSummary?: StickerSummaryItem[];
  onCheckoutClicked?: () => any;
  isAddingToBasket?: boolean;
  children: {
    heading?: ReactNode;
    totalPrice: ReactNode | number;
    paymentSection: ReactNode;
    checkoutButton?: ReactNode;
  };
}

export default function CheckoutSection({
  isBasketEmpty,
  onCheckoutClicked,
  stickerSummary,
  isAddingToBasket,
  children: { heading, totalPrice, paymentSection, checkoutButton },
  className,
  ...divProps
}: CheckoutSectionProps): JSX.Element {
  return (
    <div className={`flex flex-col px-4 ${className}`} {...divProps}>
      <h4 className="flex justify-center text-1 border-0 border-b-2 border-solid border-grey-dark py-4">
        {!heading ? <FormattedMessage id="checkoutpage.heading" /> : heading}
      </h4>
      <div className="border-0 border-b-2 border-solid border-grey-dark  py-4">
        {stickerSummary && (
          <div className="flex flex-col text-5">
            {stickerSummary.map(e => (
              <div className="flex justify-between mt-2">
                <span>
                  <span>{`${e.numberOfType} X `}</span>
                  <span>
                    <FormattedMessage id={e.name} />
                  </span>
                </span>
                <span className="">
                  <CurrencyFormat value={e.totalPrice} />
                </span>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="text-2 border-0 border-b-2 border-solid border-grey-dark py-4 ">
        {typeof totalPrice === "number" ? (
          <div className="flex justify-between ">
            <span>
              <FormattedMessage id="checkoutpage.total" />:
            </span>
            <span>
              <CurrencyFormat value={totalPrice} />
            </span>
          </div>
        ) : (
          totalPrice
        )}
      </div>
      <div className="py-4">{paymentSection}</div>
      <div className="flex flex-col pb-4">
        {checkoutButton || (
          <Button
            disabled={isBasketEmpty || isAddingToBasket}
            isLoading={isAddingToBasket ?? false}
            onClick={onCheckoutClicked}
          >
            <FormattedMessage id="checkoutpage.button.pay" />
            <span className="sr-only"><FormattedMessage id="checkoutpage.button.pay.sr" /></span>
          </Button>
        )}
      </div>
    </div>
  );
}
