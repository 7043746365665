import React, { useEffect, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { getState as getAuthState } from "state/Auth/Auth";

window.onbeforeunload = () => {
  window.scrollTo(0, 0);
};

export default function Scrolltop(): JSX.Element {
  const history = useHistory();
  const { isLoggedIn } = useSelector(getAuthState);

  useLayoutEffect(() => {
    /* HACK :( : Scroll top when logging in/out, 
       this is needed because the loggedin landing page has the same url as logged out landingpage
    */
    window.scroll(0, 0);
  }, [isLoggedIn]);

  useEffect(() => {
    const unRegisterListener = history.listen(() => {
      // Scroll to top when location changes
      window.scroll(0, 0);
    });
    return () => {
      unRegisterListener();
    };
  }, [history]);
  return <></>;
}
