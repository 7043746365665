import React from "react";
import { map, isEmpty } from "lodash-es";
import { FormattedMessage } from "locale/langUtils";
import { OrdreLinje } from "interfaces";
import CurrencyFormat from "locale/CurrencyFormat";
import ShoppingCartLineItem from "./ShoppingCartLineItem";
import "./ShoppingCartLineItems.scss";

interface ShoppingCartLineItemsProps extends React.HTMLProps<HTMLDivElement> {
  shoppingCartItems: OrdreLinje[];
  totalPrice?: number;
  removeItem: (oblatId: string) => Promise<any>;
}
export default function ShoppingCartLineItems({
  shoppingCartItems,
  totalPrice,
  removeItem,
  className,
  ...rest
}: ShoppingCartLineItemsProps): JSX.Element {
  // Group items
  return (
    <div className={`line-items-container mobile-only:mb-3  ${className}`} {...rest}>
      {!isEmpty(shoppingCartItems) && (
        <div className="line-items-heading flex text-4 px-2 mb-2">
          <span className="tablet:hidden">
            <FormattedMessage id="shoppingcart.navbar.heading" />
          </span>
          <span className="w-1/4 px-1 mobile-only:hidden">
            <FormattedMessage id="shoppingcart.lineitems.theading.information" />
          </span>
          <span className="w-2/4 px-1 mobile-only:hidden">
            <FormattedMessage id="shoppingcart.lineitems.theading.period" />
          </span>
          <span className="w-1/4 px-1 mobile-only:hidden">
            <FormattedMessage id="shoppingcart.lineitems.theading.price" />
          </span>
        </div>
      )}
      {isEmpty(shoppingCartItems) ? (
        <div aria-live="polite" className="flex justify-center text-5 p-3 osg-u-color-primary osg-u-text-is-medium">
          <FormattedMessage id="shoppingcart.lineitems.emptycart" />
        </div>
      ) : (
        <>
          <div className="line-items flex flex-col">
            {map(shoppingCartItems, shoppingCartItem => (
              <ShoppingCartLineItem
                key={shoppingCartItem.id}
                className="mb-2"
                shoppingcartItem={shoppingCartItem}
                removeItem={removeItem}
              />
            ))}
          </div>
          {totalPrice && totalPrice !== 0 && (
            <div className="total-price flex justify-end text-4 mt-4">
              <FormattedMessage id="shoppingcart.label.total" />: <CurrencyFormat value={totalPrice} />
            </div>
          )}
        </>
      )}
    </div>
  );
}
