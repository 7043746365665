import React, { useState } from "react";
import Radio from "features/common/BaseComponents/Radio/Radio";
import { FormattedMessage } from "locale/langUtils";
import useHandlekurv from "features/Hooks/HandlekurvHook";
import { Button } from "features/common/OSG";
import CheckoutSection from "./CheckoutSection";

type ComanyUserCheckout = React.HTMLProps<HTMLDivElement>;
type PaymentMethods = "card";
export default function PrivateUserCheckout({ ...divProps }: ComanyUserCheckout): JSX.Element {
  const [betalingsform, setBetalingsformState] = useState<PaymentMethods>("card");
  const [loading, setLoadingState] = useState(false);
  const [betalingErrorMessage, setBetalingErrorMessageState] = useState("");

  const { isShoppingCartEmpty, totalPrice, handlePayWithNets, stickerSummary } = useHandlekurv();

  const onCheckoutClicked = () => {
    setLoadingState(true);
    setBetalingErrorMessageState("");
    handlePayWithNets()
      .catch(() => {
        setBetalingErrorMessageState("texts.paymentError");
      })
      .finally(() => {
        setLoadingState(false);
      });
  };
  return (
    <CheckoutSection
      // onCheckoutClicked={onCheckoutClicked}
      stickerSummary={stickerSummary}
      {...divProps}
    >
      {{
        heading: <FormattedMessage id="checkoutpage.heading" />,
        totalPrice,
        paymentSection: (
          <div className="flex flex-col items-center">
            <span className="text-2 mb-3">
              <FormattedMessage id="checkoutpage.paymentmethod" />
            </span>
            <form className="flex flex-col bg-white px-5 py-4 text-3">
              <Radio disabled={isShoppingCartEmpty} checked={betalingsform === "card"}>
                <FormattedMessage id="checkoutpage.paymentmethod.card" />
              </Radio>
            </form>
          </div>
        ),
        checkoutButton: (
          <Button disabled={isShoppingCartEmpty || loading} className="" onClick={onCheckoutClicked}>
            <FormattedMessage id="checkoutpage.button.pay" />
          </Button>
        )
      }}
    </CheckoutSection>
  );
}
