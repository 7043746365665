import React, { useEffect, SyntheticEvent, useRef } from "react";
import useHandlekurv from "features/Hooks/HandlekurvHook";
import { useHistory } from "react-router-dom";
import { Button } from "features/common/OSG";
import { FormattedMessage, useIntl } from "locale/langUtils";
import "./ShoppingCartNavbar.scss";
import * as urls from "appConstants/urls";
import XButton from "features/common/BaseComponents/XButton/XButton";
import ModalHook from "features/Hooks/ModalHook";
import ShoppingCartLineItems from "./ShoppingCartLineItems";
import ShoppingCartSummary from "./ShoppingCartSummary";
import { ReactComponent as BasketIcon } from "../../images/handlekurv.svg";
import { useDispatch } from "react-redux";
import { setIsHandlekurvOpen } from "state/Handlekurv/handlekurvActions";

export default function HandlekurvNavbar({
  className = "",
  ...htmlDivProps
}: React.HTMLProps<HTMLDivElement>): JSX.Element {
  const {
    shoppingCartItems,
    isShoppingCartEmpty,
    totalPrice,
    removeOrdrelinjeFromBasket,
    subscribeToAddSticker,
    unsubscribeToAddSticker,
    stickerSummary
  } = useHandlekurv();

  const history = useHistory();

  const intl = useIntl();

  const dispatch = useDispatch();

  const {
    modalRef,
    setIsModalOpen,
    modalState: { isModalOpen }
  } = ModalHook<HTMLDivElement>({
    initialState: { isOpen: false },
    closeOnClickOutside: true
  });

  useEffect(() => {
    const key = subscribeToAddSticker(shouldShowPopup => {
      if (shouldShowPopup) {
        setIsModalOpen(true);
      }
    });
    return () => {
      unsubscribeToAddSticker(key);
    };
  }, [setIsModalOpen, subscribeToAddSticker, unsubscribeToAddSticker]);

  const closeLineItems = (): void => {
    setIsModalOpen(false);
    dispatch(setIsHandlekurvOpen(false));
  };

  const continueShopping = () => {
    closeLineItems();
    const element = document.getElementById("kjoretoy_input");
    const focusable = element?.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    (focusable?.[0] as HTMLElement | undefined)?.focus();
  };

  const goToCheckout = (e: SyntheticEvent): void => {
    e.preventDefault();
    e.stopPropagation();
    closeLineItems();
    history.push(urls.summaryAddSingle);
  };

  return (
    <div className={`shoppingcart ${isModalOpen ? "open" : "not-open"} ${className}`} {...htmlDivProps}>
      <div
        aria-label={intl.formatMessage({ id: "shoppingcart.link.aria-label" })}
        role="link"
        title={intl.formatMessage({ id: "shoppingcart.link.aria-title" })}
        tabIndex={0}
        onClick={goToCheckout}
        onKeyDown={k => {
          if (k.key === "Enter") {
            goToCheckout(k);
          }
        }}
        className="shoppingcart-icon"
      >
        {!isShoppingCartEmpty && shoppingCartItems.length < 100 && (
          <span className="num-of-items  osg-u-text-is-medium">{shoppingCartItems.length}</span>
        )}
        <BasketIcon
          aria-label="Handlekurvikon"
          title=""
          style={{ width: "80px" }}
          className="text-beige-dark mobile-only:h-full"
        />
        <span ref={modalRef} className="mobile-only:hidden text-center osg-u-text-7 osg-u-text-is-medium">
          <FormattedMessage id="shoppingcart.navbar.heading" />
          {!isShoppingCartEmpty && shoppingCartItems.length >= 100 && <span>({shoppingCartItems.length})</span>}
        </span>
      </div>

      <div
        ref={modalRef}
        role="alert"
        aria-describedby="handlekurvnavbar--sr"
        className="shoppingcart-line-items-container z-popup py-5 px-2"
      >
        <XButton
          onClick={continueShopping}
          className="absolute right-0 top-0 mr-3 mt-3"
          aria-label={intl.formatMessage({ id: "shoppingcart.btn.close.aria-label" })}
        />
        <span id="handlekurvnavbar--sr" className="sr-only">
          {intl.formatMessage({ id: "shoppingcart.link.aria-label" })}
        </span>
        {shoppingCartItems.length < 7 ? (
          <ShoppingCartLineItems
            className="shoppingcart-line-items"
            shoppingCartItems={shoppingCartItems}
            totalPrice={totalPrice}
            removeItem={removeOrdrelinjeFromBasket}
          />
        ) : (
          <ShoppingCartSummary stickerSummary={stickerSummary} totalPrice={totalPrice} />
        )}

        <div className="shopping-cart-button-group flex mobile-only:flex-col tablet:justify-end mt-2 p-2">
          <Button
            modifier="outline"
            onClick={continueShopping}
            aria-label={intl.formatMessage({ id: "shoppingcart.btn.close.aria-label" })}
          >
            <FormattedMessage id="shoppingcart.button.continue" />
          </Button>
          <Button
            tabIndex={0}
            role="link"
            onClick={goToCheckout}
            disabled={isShoppingCartEmpty}
            className="tablet:ml-2 mobile-only:mt-2"
          >
            <FormattedMessage id="shoppingcart.button.gotocheckout" />
          </Button>
        </div>
      </div>
    </div>
  );
}
