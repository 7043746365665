import { User, Log } from "oidc-client-ts";
import { AuthProviderProps } from "react-oidc-context";
import { getEnvironmentVariable } from "utils/environment";

export const oidcConfig: AuthProviderProps = {
  authority: getEnvironmentVariable("ONELOGIN_AUTHORITY"),
  client_id: getEnvironmentVariable("ONELOGIN_CLIENT_ID"),
  redirect_uri: getEnvironmentVariable("ONELOGIN_REDIRECT_URI"),
  silent_redirect_uri: getEnvironmentVariable("ONELOGIN_SILENT_REDIRECT_URI"),
  post_logout_redirect_uri: getEnvironmentVariable("ONELOGIN_POST_LOGOUT_REDIRECT_URI"),
  automaticSilentRenew: true,
  response_type: "code",
  scope: "openid profile samtykke",
  resource: "https://piggav.no",
  onSigninCallback: () => {
    /**
     * You must provide an implementation of onSigninCallback to oidcConfig to remove the payload from the URL upon successful login.
     * Otherwise if you refresh the page and the payload is still there, signinSilent - which handles renewing your token - won't work.
     */
    window.history.replaceState({}, document.title, "/");
  }
};

export const getUser = () => {
  const oidcStorage = sessionStorage.getItem(`oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`);
  return !oidcStorage ? null : User.fromStorageString(oidcStorage);
};

Log.setLogger(console);
