import React, { useState, useMemo } from "react";
import { useReduxDispatch } from "utils/Storeutils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useIntl } from "react-intl";
import { updateKunde as updateKundeAction } from "state/Kunde/kundeActions";
import { Button } from "features/common/ComposedComponents";
import { FormattedMessage } from "locale/langUtils";
import InputField from "features/common/ComposedComponents/Inputfield/Inputfield";
import * as yup from "yup";
import { Kunde } from "interfaces";
import ProfileDetails from "./ProfileDetails";
import ProfilSection from "./ProfileSection";
import Form from "features/common/BaseComponents/Form/Form";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";

const registerPrivateUserSchema = yup.object().shape({
  contactperson: yup.string().required("common.error.requiredfield"),
  phonenumber: yup.string().required("common.error.requiredfield").min(8, "common.error.phonenumberinvalid")
});

interface ChangeCompanySettings extends React.HTMLProps<HTMLDivElement> {
  customerInfo: Kunde;
}
export default function ChangeContactInfoSection({ customerInfo, ...rest }: ChangeCompanySettings): JSX.Element {
  const profileSectionId = "contactsettings";
  const [isOpen, setIsOpen] = useState(false);
  const [formUpdateError, setformUpdateError] = useState("");
  const [formUpdateSuccess, setformUpdateSuccess] = useState(false);
  const [isUpdatingUserRequest, setIsUpdatingUserRequest] = useState(false);

  const { handleSubmit, register, errors } = useForm({
    criteriaMode: "firstError",
    mode: "onSubmit",
    resolver: yupResolver(registerPrivateUserSchema),
    defaultValues: {
      contactperson: customerInfo.kontaktperson,
      phonenumber: customerInfo.telefonnummer
    }
  });

  const { formatMessage } = useIntl();

  const dispatch = useReduxDispatch();

  const handleUpdateUserSubmit = handleSubmit(async ({ contactperson, phonenumber }) => {
    setIsUpdatingUserRequest(true);
    try {
      await dispatch(
        updateKundeAction(customerInfo.brukerId, {
          ...customerInfo,
          telefonnummer: phonenumber,
          kontaktperson: contactperson
        })
      );
      setformUpdateSuccess(true);
    } catch (e) {
      setformUpdateError("profile.error.general");
    } finally {
      setIsUpdatingUserRequest(false);
    }
  });

  const toggle = () => {
    if (isOpen) {
      const accordionTogglerBtn = document.getElementById(`${profileSectionId}--toggler`);
      accordionTogglerBtn?.focus();
    }

    setIsOpen(prev => !prev);
  };

  const contactDetails = useMemo(
    () => [
      {
        name: formatMessage({ id: "common.label.contactperson" }),
        value: customerInfo.kontaktperson
      },
      {
        name: formatMessage({ id: "common.label.phonenumber" }),
        value: customerInfo.telefonnummer
      }
    ],
    [customerInfo.kontaktperson, customerInfo.telefonnummer, formatMessage]
  );

  return (
    <ProfilSection
      id={profileSectionId}
      onChange={() => {
        if (formUpdateSuccess) setformUpdateSuccess(false);
        if (formUpdateError) setformUpdateError("");
      }}
      isOpen={isOpen}
      toggle={toggle}
      {...rest}
    >
      {{
        heading: formatMessage({
          id: "profile.changecontactpersondetails.heading",
          defaultMessage: "Kontaktinformasjon"
        }),
        closedContent: <ProfileDetails details={contactDetails} />,
        openContent: (
          <Form id="update-contactinfo-user-form" onSubmit={handleUpdateUserSubmit}>
            <InputField
              name="contactperson"
              required
              autoComplete="name"
              type="text"
              label={formatMessage({
                id: "common.label.contactperson",
                defaultMessage: "Kontaktperson"
              })}
              error={
                errors?.contactperson &&
                formatMessage({
                  id: errors.contactperson.message,
                  defaultMessage: ""
                })
              }
              ref={register}
            />
            <InputField
              name="phonenumber"
              autoComplete="tel"
              type="text"
              label={formatMessage({
                id: "common.label.phonenumber",
                defaultMessage: "Telefonnummer"
              })}
              error={
                errors?.phonenumber &&
                formatMessage({
                  id: errors.phonenumber.message,
                  defaultMessage: ""
                })
              }
              ref={register}
              required
            />

            {formUpdateError && (
              <MessageBox role="alert" type="danger">
                <FormattedMessage id={formUpdateError} values={{ orgnummer: customerInfo.organisasjonsnummer }} />
              </MessageBox>
            )}

            {formUpdateSuccess && (
              <MessageBox role="status" type="success">
                <FormattedMessage id="profile.changecompany.successupdating" />
              </MessageBox>
            )}

            <div className="flex justify-between flex-wrap mt-4">
              <Button
                aria-expanded={isOpen}
                aria-label={formatMessage({ id: "profile.cancel.button" })}
                className="w-full tablet:w-1/2 mobile-only:mb-2"
                modifier="outline"
                onClick={() => {
                  setformUpdateError("");
                  setformUpdateSuccess(false);
                  toggle();
                }}
                disabled={isUpdatingUserRequest}
              >
                {formUpdateSuccess ? (
                  <FormattedMessage id="profile.button.close" defaultMessage="Lukk" />
                ) : (
                  <FormattedMessage id="common.cancel" defaultMessage="Avbryt" />
                )}
              </Button>
              <Button
                className="w-full tablet:w-1/2 "
                form="update-contactinfo-user-form"
                type="submit"
                disabled={isUpdatingUserRequest}
                isLoading={isUpdatingUserRequest}
              >
                <FormattedMessage id="common.save" defaultMessage="Lagre passord" />
              </Button>
            </div>
          </Form>
        )
      }}
    </ProfilSection>
  );
}
