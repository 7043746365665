/* eslint-disable react/prop-types */
import React, { useState, useMemo, useEffect, ReactNode, useLayoutEffect, useCallback } from "react";
import { isEmpty } from "lodash-es";
import useWindowUtils from "features/Hooks/WindowUtilsHook";
import { useTable, usePagination, Column, useFlexLayout, TableState } from "react-table";
import { useIntl } from "react-intl";
import { Checkbox } from "features/common/OSG";
import { Button, MessageBoxWIcon } from "features/common/ComposedComponents";
import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";

import { FormattedMessage } from "locale/langUtils";
import "./VehiclesSetting.scss";
import { Kjoretoy, Kunde } from "interfaces";

import XButton from "features/common/BaseComponents/XButton/XButton";
import Addvehicle from "features/AddVehiclesDialogue/AddVehicle";
import ChangeVehicleDialogue from "features/ChangeVehicleDialogue/ChangeVehicleDialogue";
import { useSelector } from "react-redux";
import { getState as getAuthState } from "state/Auth/Auth";
import { getState as getKundeState } from "state/Kunde/kundeReducer";
import { getState as getKjoretoyState } from "state/Kjoretoy/kjoretoyReducer";
import { ApplicationState } from "state/reducers";
import { deleteKjoretoy, getKjoretoyForBruker } from "state/Kjoretoy/kjoretoyActions";
import Select from "features/common/BaseComponents/Select/CustomReactSelect";
import { updateStandardKjoretoy } from "state/Kunde/kundeActions";
import { useReduxDispatch } from "utils/Storeutils";
import Paging from "features/common/TableComponents/Paging";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import { VehicleWeightId } from "../../appConstants/identifiers";

interface TableData extends Kjoretoy {
  s: ReactNode;
  d: ReactNode;
  vektKlasse: ReactNode;
  endreKjoretoy: ReactNode;
}

export default function VehiclesSetting({ ...divProps }: React.HTMLProps<HTMLDivElement>): JSX.Element {
  const [showDeleteDialogue, setShowDeleteDialogue] = useState(false);
  const [isDeletingVehicle, setisDeletingVehicle] = useState(false);
  const [selectedVehicleId, setSelectedVehicleId] = useState("");
  const [removeVehicleError, setRemoveVehicleError] = useState(false);
  const [removeVehicleSuccess, setRemoveVehicleSuccess] = useState(false);
  const [isUpdatingStandardVehicle, setIsUpdatingStandardVehicle] = useState(false);
  const { standardKjoretoyId } = useSelector<any, Kunde>(getKundeState);
  const [standardVehicle, setStandardVehicle] = useState(standardKjoretoyId);

  const { formatMessage } = useIntl();

  const { userInfo } = useSelector(getAuthState);

  const { kjoretoy, loading: vehicleIsLoading } = useSelector<
    ApplicationState,
    { kjoretoy: Kjoretoy[]; loading: boolean }
  >(getKjoretoyState);

  const dispatch = useReduxDispatch();

  const { currentBreakpoint } = useWindowUtils();

  useEffect(() => {
    if (!userInfo?.brukerId) return;
    dispatch(getKjoretoyForBruker(userInfo?.brukerId));
  }, [dispatch, userInfo]);

  useEffect(() => {
    setStandardVehicle(standardKjoretoyId);
  }, [standardKjoretoyId]);

  const setStandardKjoretoy = useCallback(
    async (kjoretoyId: string) => {
      // If it's the same
      if (kjoretoyId === standardVehicle || !userInfo?.brukerId) return;
      // Call Update
      try {
        setIsUpdatingStandardVehicle(true);
        await dispatch(
          updateStandardKjoretoy(
            userInfo?.brukerId,
            kjoretoyId,
            formatMessage({ id: "vehiclelist.standardKjoretoyUpdateErrorMsg" }),
            formatMessage({
              id: "vehiclelist.standardKjoretoyUpdateSuccessMsg"
            })
          )
        );
      } catch (e) {
        dispatch(updateStandardKjoretoy(standardKjoretoyId));
      } finally {
        setIsUpdatingStandardVehicle(false);
      }
    },
    [dispatch, formatMessage, standardKjoretoyId, standardVehicle, userInfo]
  );

  const handleRemoveVehicle = (vehicleId: string): void => {
    setShowDeleteDialogue(true);
    setSelectedVehicleId(vehicleId);
  };

  const resetDeleteDialogue = (): void => {
    setShowDeleteDialogue(false);
    setSelectedVehicleId("");
    setRemoveVehicleError(false);
    setRemoveVehicleSuccess(false);
  };

  const removeVehicle = async () => {
    try {
      if (!selectedVehicleId || !userInfo?.brukerId) return;
      setisDeletingVehicle(true);
      await dispatch(deleteKjoretoy(selectedVehicleId, userInfo?.brukerId));
      setRemoveVehicleError(false);
      setRemoveVehicleSuccess(true);
      // resetDeleteDialogue();
    } catch {
      setRemoveVehicleError(true);
    } finally {
      setisDeletingVehicle(false);
    }
  };

  const columns = useMemo<Column<Kjoretoy>[]>(
    () => [
      {
        accessor: "id",
        id: "standardVehicle",
        sortable: false,
        width: "undefined",
        Header: ({ column }) => (
          <span
            {...column.getHeaderProps({
              className: "w-1/6 osg-u-padding-2"
            })}
          >
            <FormattedMessage id="vehiclelist.formLabelStandard" />
          </span>
        ),
        Cell: ({ row, value, cell }) => {
          const { formatMessage: formatMessageInner } = useIntl();
          return (
            <span
              {...cell.getCellProps({
                className: "w-1/6 osg-u-padding-2 items-center justify-items-center"
              })}
            >
              <Checkbox
                title={formatMessageInner({
                  id: "vehiclelist.formLabelStandard"
                })}
                id={`standard-kjoretoy-checkbox-${row.original.registreringsnummer}`}
                checked={standardVehicle === value}
                aria-checked={standardVehicle === value}
                aria-label={formatMessage(
                  { id: "vehiclelist.delete.standard.check" },
                  { regnr: row.original.registreringsnummer }
                )}
                onChange={() => {
                  setStandardKjoretoy(value);
                }}
              />
            </span>
          );
        }
      },
      {
        accessor: "registreringsnummer",
        sortable: true,
        width: "undefined",
        Header: ({ column }) => (
          <span
            {...column.getHeaderProps({
              className: "w-6/12 tablet:w-1/6 break-words osg-u-padding-2"
            })}
          >
            <FormattedMessage id="vehiclelist.formLabelRegNumber" />
          </span>
        ),
        Cell: ({ value, cell }) => (
          <span
            {...cell.getCellProps({
              className: "w-6/12 tablet:w-1/6 break-words osg-u-padding-2"
            })}
          >
            {value}
          </span>
        )
      },

      {
        accessor: "beskrivelse",
        sortable: false,
        width: "undefined",

        Header: ({ column }) => (
          <span
            {...column.getHeaderProps({
              className: "w-1/3 tablet:w-1/6 break-words osg-u-padding-2"
            })}
          >
            <FormattedMessage id="vehiclelist.formLabelKBeskrivelse" />
          </span>
        ),
        Cell: ({ value, cell }) => (
          <span
            {...cell.getCellProps({
              className: "w-1/3 tablet:w-1/6 break-words osg-u-padding-2"
            })}
          >
            {value}
          </span>
        )
      },
      {
        accessor: "kjoretoytypeId",
        sortable: true,
        width: "undefined",
        Header: ({ column }) => (
          <span
            {...column.getHeaderProps({
              className: "w-1/3 tablet:w-1/6 break-words osg-u-padding-2"
            })}
          >
            <FormattedMessage id="vehiclelist.formLabelKjøretøytype" />
          </span>
        ),
        Cell: ({ value, cell }) => {
          return (
            <span
              {...cell.getCellProps({
                className: "w-1/3 tablet:w-1/6 break-words osg-u-padding-2"
              })}
            >
              <FormattedMessage
                id={
                  value === VehicleWeightId.Light
                    ? "vehiclelist.labelCartypeSelectLight"
                    : "vehiclelist.labelCartypeSelectHeavy"
                }
              />
            </span>
          );
        }
      },
      {
        accessor: r => null,
        id: "endreKjoretoy",
        sortable: false,
        width: "undefined",
        Header: ({ column }) => (
          <span
            {...column.getHeaderProps({
              className: "w-3/12 tablet:w-1/6 break-words osg-u-padding-2"
            })}
          >
            <FormattedMessage id="vehiclelist.editCarButtonText" />
          </span>
        ),
        Cell: ({ row, cell }: any) => (
          <span
            {...cell.getCellProps({
              className: "w-3/12 tablet:w-1/6 break-words osg-u-padding-2"
            })}
          >
            <ChangeVehicleDialogue vehicle={row.original} />
          </span>
        )
      },
      {
        accessor: "id",
        id: "deleteCar",
        sortable: false,
        width: "undefined",
        Header: ({ column }) => (
          <span
            {...column.getHeaderProps({
              className: "w-3/12 tablet:w-1/6 break-words osg-u-padding-2"
            })}
          >
            <FormattedMessage id="vehiclelist.deleteCarButtonText" />
          </span>
        ),
        Cell: ({ row, value, cell }: any) => (
          <span
            {...cell.getCellProps({
              className: "w-3/12 tablet:w-1/6 break-words osg-u-padding-2"
            })}
          >
            <XButton
              aria-label={formatMessage(
                { id: "vehiclelist.delete.vehicle" },
                { vehicle: row.original.registreringsnummer }
              )}
              onClick={() => handleRemoveVehicle(value)}
            />
          </span>
        )
      }
    ],
    [formatMessage, setStandardKjoretoy, standardVehicle]
  );

  const initialState = useMemo<Partial<TableState<Kjoretoy>>>(
    () => ({
      pageIndex: 0,
      pageSize: currentBreakpoint === "mobile" ? 5 : 10,
      hiddenColumns: currentBreakpoint === "mobile" ? ["standardVehicle", "kjoretoytypeId", "beskrivelse"] : []
    }),
    [currentBreakpoint]
  );

  const {
    getTableBodyProps,
    getTableProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    setHiddenColumns,
    canNextPage,
    canPreviousPage,
    previousPage,
    nextPage,
    setPageSize,
    pageCount,
    state: { pageIndex, pageSize }
  } = useTable<Kjoretoy>(
    {
      columns,
      data: kjoretoy,
      initialState,
      autoResetPage: true
    },
    usePagination,
    useFlexLayout
  );

  useLayoutEffect(() => {
    if (currentBreakpoint === "mobile") {
      setPageSize(5);
      setHiddenColumns(["standardVehicle", "kjoretoytypeId", "beskrivelse"]);
    } else {
      setHiddenColumns([]);
      setPageSize(10);
    }
  }, [currentBreakpoint, setHiddenColumns, setPageSize]);

  const { selectedValue, options } = useMemo(() => {
    const stdVhcl = kjoretoy.find(v => standardKjoretoyId === v.id);
    return {
      selectedValue: {
        label: stdVhcl?.registreringsnummer ?? "",
        value: stdVhcl?.id ?? ""
      },
      options: kjoretoy.map(v => ({
        label: v.registreringsnummer,
        value: v.id
      }))
    };
  }, [kjoretoy, standardKjoretoyId]);

  return (
    <div {...divProps}>
      <h2 className="heading-3 mb-3">
        <FormattedMessage id="vehiclelist.mineKjoretoyTitle" />
      </h2>
      <Select
        rootClassName="tablet:hidden"
        label={<FormattedMessage id="vehiclelist.select.label.standardvehicle" />}
        placeholder={formatMessage({
          id: "vehiclelist.select.placeholder.standardvehicle"
        })}
        options={options}
        value={selectedValue}
        isLoading={isUpdatingStandardVehicle}
        onChange={(option: any) => {
          setStandardKjoretoy(option?.value);
        }}
        noOptionsMessage={({ inputValue }) =>
          formatMessage(
            {
              id: "vehiclelist.select.noOptions"
            },
            {
              searchString: inputValue
            }
          )
        }
      />
      <div className="flex flex-col items-center w-full">
        <div
          className="vehicle-table "
          {...getTableProps()}
          aria-label={formatMessage({ id: "vehiclelist.tablecaption" })}
        >
          {headerGroups.map(headerGroup => (
            <div
              {...headerGroup.getHeaderGroupProps({
                className: "vehicle-table-header border-blue-dark py-3 osg-u-text-is-medium"
              })}
            >
              {headerGroup.headers.map(headerCol => headerCol.render("Header", { className: "self-center" }))}
            </div>
          ))}

          <div
            {...getTableBodyProps({
              className: pageCount > 1 ? "vehicle-setting-table-body" : ""
            })}
          >
            {isEmpty(page) && !vehicleIsLoading ? (
              <div className="flex justify-center text-5 mt-2 p-3 osg-u-color-primary osg-u-text-is-medium">
                <FormattedMessage id="vehiclelist.tablenoDataText" />
              </div>
            ) : (
              page.map(row => {
                prepareRow(row);
                return (
                  <>
                    <div
                      {...row.getRowProps({
                        className: "vehicle-table-row border-grey-dark items-center "
                      })}
                    >
                      {row.cells.map(cell => cell.render("Cell"))}
                    </div>
                    <div role="separator" className="border-solid border-grey-dark border border-0 border-b-1 w-full" />
                  </>
                );
              })
            )}
          </div>
          <Paging
            showPaginationControlls={pageCount > 1}
            beginFrom={1}
            pageSize={pageSize}
            pageIndex={pageIndex}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            nextPage={nextPage}
            previousPage={previousPage}
            totalNumberOfRows={rows.length}
          />
          <div className="flex">
            <Addvehicle className="mt-3 ml-auto" />
          </div>
        </div>
      </div>
      <Modal isOpen={showDeleteDialogue} toggle={() => resetDeleteDialogue()}>
        <ModalHeader>
          {{
            heading: formatMessage({
              id: "vehiclelist.removeVehicleDialogueTitle"
            })
          }}
        </ModalHeader>
        <ModalSection>
          {(removeVehicleError && (
            <MessageBoxWIcon role="alert" visible type="danger" icon="exclamation-mark-circle">
              <FormattedMessage id="vehiclelist.removeVehicleDialogue.errormessage" />
            </MessageBoxWIcon>
          )) ||
            (removeVehicleSuccess && (
              <MessageBox role="alert" visible type="success">
                <FormattedMessage id="vehiclelist.removeVehicleDialogue.successmessage" />
              </MessageBox>
            )) || (
              <MessageBoxWIcon role="alert" visible type="primary" icon="information">
                <FormattedMessage id="vehiclelist.removeVehicleDialogue.message" />
              </MessageBoxWIcon>
            )}
        </ModalSection>

        <ModalSection className="flex flex-col tablet:flex-row tablet:justify-end">
          <Button
            disabled={isDeletingVehicle || removeVehicleSuccess}
            isLoading={isDeletingVehicle}
            className="mobile-only:mb-2 tablet:mr-2"
            onClick={removeVehicle}
          >
            <FormattedMessage id="vehiclelist.confirmRemoveVehicle" />
          </Button>
          <Button disabled={isDeletingVehicle} modifier="outline" onClick={() => resetDeleteDialogue()}>
            {removeVehicleSuccess ? (
              <FormattedMessage id="vehiclelist.closeRemoveVehicle" />
            ) : (
              <FormattedMessage id="vehiclelist.cancelRemoveVehicle" />
            )}
          </Button>
        </ModalSection>
      </Modal>
    </div>
  );
}
