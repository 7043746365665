import PublicRoute from "features/Routes/PublicRoute";
import { Configurations } from "interfaces";
import React, { ReactNode } from "react";
import { useSelector } from "react-redux";
import { getState } from "state/Konfigurasjoner/konfigurasjonerReducer";
import { ApplicationState } from "state/reducers";
import * as urls from "appConstants/urls";
import { Icon } from "features/common/OSG";
import "./MessageToUser.scss";
import { isEmpty } from "lodash-es";
import DateTimeFormat from "locale/DateTimeFormat";

export default function MessagesToUser() {
  const { forsideBeskjed } = useSelector<ApplicationState, Configurations>(getState);

  return !isEmpty(forsideBeskjed?.verdi) ? (
    <PublicRoute exact path={urls.hjem}>
      <div className="flex justify-center osg-u-color-bg-beige osg-u-padding-vertical-3 w-full">
        <div className="flex w-full widescreen:w-widescreen-content-max-width">
          {!isEmpty(forsideBeskjed?.verdi) ? (
            // eslint-disable-next-line @typescript-eslint/no-use-before-define
            <Message className="mobile-only:w-full tablet:w-1/2">
              {{ heading: "Beskjed til bruker", message: forsideBeskjed.verdi, date: forsideBeskjed.sistEndret }}
            </Message>
          ) : null}
        </div>
      </div>
    </PublicRoute>
  ) : (
    null
  );
}

interface MessageProps extends React.HTMLProps<HTMLDivElement> {
  children: {
    heading: ReactNode;
    message: ReactNode;
    date: string;
  };
}
export function Message({ children: { heading, message, date }, className, ...divProps }: MessageProps): JSX.Element {
  return (
    <div className={`message-to-user-box flex osg-u-padding-3 ${className}`} {...divProps}>
      <div className="flex bg-white">
        <Icon
          className="message-to-user-box--icon osg-u-padding-horizontal-3 osg-u-padding-left-medium-tablet-and-up osg-u-padding-right-3-tablet-and-up"
          icon="information"
        />
        <div
          className="osg-u-padding-vertical-3 osg-u-padding-right-3
         osg-u-padding-vertical-medium-tablet-and-up
          osg-u-padding-right-medium-tablet-and-up "
          role="status"
          aria-live="off"
        >
          <h2 className="osg-u-heading-6">{heading}</h2>
          {/* eslint-disable-next-line react/no-danger */}
          <div className="osg-content osg-u-text-7 osg-u-padding-top-1 ">
            <div>{message}</div>
            <time dateTime={new Date(date).toLocaleString()} className="osg-u-padding-top-2">
              <DateTimeFormat
                value={date}
                dateOptions={{
                  day: "numeric",
                  weekday: "long",
                  month: "long"
                }}
              />
            </time>
          </div>
        </div>
      </div>
    </div>
  );
}
