import {
  CREATE_HANDLEKURV_SUCCESS,
  GET_HANDLEKURV_REQUEST,
  GET_HANDLEKURV_SUCCESS,
  SET_LOADING,
  ADD_VARE_TO_HANDLEKURV_REQUEST,
  ADD_VARE_TO_HANDLEKURV_SUCCESS,
  REMOVE_VARE_FROM_HANDLEKURV_REQUEST,
  REMOVE_VARE_FROM_HANDLEKURV_SUCCESS,
  LAGRE_BESTILLING_SUCCESS,
  LAGRE_BESTILLING_FAILURE,
  SJEKK_BETALING_REQUEST,
  SJEKK_BETALING_SUCCESS,
  SJEKK_BETALING_FAILURE,
  GET_VAREKJOP_BY_ID_SUCCESS,
  ADD_VARE_TO_HANDLEKURV_FAILURE,
  ADD_VARER_IN_BULK_SUCCESS,
  IS_HANDLEKURV_OPEN,
  RESET_HANDLEKURV
} from "./handlekurvActions";

export const getState = state => state.handlekurv;
export const getStateHandlekruvopen = state => state.handlekurv.isHandlekurvOpen;

const initState = {
  ordrelinjer: [],
  loading: false,
  handlekurvLoading: false,
  henterOrdrelinjer: false,
  leggerTilOrdrelinje: false,
  sletterOrdrelinje: false,
  bestilling: {},
  varekjop: {},
  leggTilOblatHandlekurvError: null,
  sjekkerBetaling: false,
  betalingsStatus: null,
  betalingsStatusError: null,
  isHandlekurvOpen: false,
  id: ""
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function OrdrelinjeReducer(state = { ...initState }, action) {
  switch (action.type) {
    case RESET_HANDLEKURV: {
      return { ...initState };
    }
    case SET_LOADING: {
      return { ...state, handlekurvLoading: action.payload };
    }
    case CREATE_HANDLEKURV_SUCCESS: {
      return { ...state, id: action.payload };
    }
    case GET_HANDLEKURV_REQUEST: {
      return { ...state, henterOrdrelinjer: true };
    }
    case GET_HANDLEKURV_SUCCESS: {
      return {
        ...state,
        ordrelinjer: action.payload,
        henterOrdrelinjer: false,
        leggTilOblatHandlekurvError: {}
      };
    }
    case LAGRE_BESTILLING_SUCCESS: {
      return { ...state, bestilling: action.payload };
    }
    case LAGRE_BESTILLING_FAILURE: {
      return { ...state, bestilling: { error: action.payload } };
    }
    case ADD_VARE_TO_HANDLEKURV_REQUEST: {
      return {
        ...state,
        leggerTilOrdrelinje: action.payload
      };
    }
    case ADD_VARE_TO_HANDLEKURV_FAILURE: {
      return {
        ...state,
        leggerTilOrdrelinje: false
      };
    }
    case ADD_VARE_TO_HANDLEKURV_SUCCESS: {
      return {
        ...state,
        ordrelinjer: [...state.ordrelinjer, action.payload],
        leggTilOblatHandlekurvError: null,
        leggerTilOrdrelinje: false
      };
    }
    case ADD_VARER_IN_BULK_SUCCESS: {
      return {
        ...state,
        ordrelinjer: [...state.ordrelinjer, ...action.payload],
        leggTilOblatHandlekurvError: null,
        leggerTilOrdrelinje: false
      };
    }
    case REMOVE_VARE_FROM_HANDLEKURV_REQUEST: {
      return { ...state, sletterOrdrelinje: action.payload };
    }
    case REMOVE_VARE_FROM_HANDLEKURV_SUCCESS: {
      return { ...state, sletterOrdrelinje: false };
    }
    case GET_VAREKJOP_BY_ID_SUCCESS: {
      return { ...state, varekjop: action.payload };
    }
    case SJEKK_BETALING_REQUEST: {
      return {
        ...state,
        sjekkerBetaling: true,
        betalingsStatus: null,
        betalingsStatusError: null
      };
    }
    case SJEKK_BETALING_SUCCESS: {
      return {
        ...state,
        sjekkerBetaling: false,
        betalingsStatus: action.payload
      };
    }
    case SJEKK_BETALING_FAILURE: {
      return {
        ...state,
        sjekkerBetaling: false,
        betalingsStatusError: action.payload
      };
    }
    case IS_HANDLEKURV_OPEN: {
      return {
        ...state,
        isHandlekurvOpen: action.payload
      };
    }
    default: {
      return state;
    }
  }
}
