import { piggavService } from "api";
import { parseISO, isWithinInterval, endOfDay } from "date-fns";
import { find } from "lodash-es";

//  GET ORGANISASJON_INFO FOR BRUKER
export const GET_KONFIG_SUCCESS = "GET_KONFIG_SUCCESS";
export const GET_KONFIG_ERROR = "GET_KONFIG_ERROR";

const getSystemkonfigurasjonSuccess = data => ({
  type: GET_KONFIG_SUCCESS,
  payload: data
});

const getSystemkonfigurasjonError = data => ({
  type: GET_KONFIG_ERROR,
  payload: data
});

export const getSystemkonfigurasjon = () => (dispatch, getState) => {
  // const { konfigurasjon } = getState((state) => state.konfigurasjon);
  // if (!isEmpty(konfigurasjon)) return Promise.resolve(konfigurasjon);
  return piggavService
    .getKonfig()
    .then(response => {
      const res = response.data.result;
      const sesongStart = find(res, ["navn", "sesongStart"]) || new Date().toISOString();
      const sesongSlutt = find(res, ["navn", "sesongSlutt"]) || new Date().toISOString();
      const salgStart = find(res, ["navn", "salgStart"]) || new Date().toISOString();
      const salgSlutt = find(res, ["navn", "salgSlutt"]) || new Date().toISOString();
      const minstebelopFaktura = find(res, ["navn", "minstebelopFaktura"]);
      const minutterForsinkelseOblatAktivering = find(res, ["navn", "minutterForsinkelseOblatAktivering"]);
      const forsideBeskjed = find(res, ["navn", "forsideBeskjed"]);
      const konfig = {
        sesongStart,
        sesongSlutt: {
          ...sesongSlutt,
          verdi: endOfDay(parseISO(sesongSlutt.verdi))
        },
        salgStart,
        salgSlutt: {
          ...salgSlutt,
          verdi: endOfDay(parseISO(salgSlutt.verdi))
        },
        salgAapent: isWithinInterval(new Date(), {
          start: parseISO(salgStart.verdi),
          end: endOfDay(parseISO(salgSlutt.verdi))
        }),
        minstebelopFaktura,
        minutterForsinkelseOblatAktivering,
        forsideBeskjed
      };
      dispatch(getSystemkonfigurasjonSuccess(konfig));
    })
    .catch(error => {
      dispatch(getSystemkonfigurasjonError(error));
    });
};

export const getServiceVersjon = () => () => piggavService.getServiceVersion();
