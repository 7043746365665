import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import { LinkButton } from "features/common/OSG";
import { FormattedMessage } from "locale/langUtils";
import RegisterPrivateUser from "features/RegisterPrivateUser/RegisterPrivateUser";

import * as urls from "appConstants/urls";
import { useIntl } from "react-intl";

export default function RegisterPage(): JSX.Element {
  const intl = useIntl();
  return (
    <Page
      metaInfo={{
        titlePageName: intl.formatMessage({ id: "registerpage.heading" }),
        titleDescription: intl.formatMessage({ id: "registerpage.meta.title.description" }),
        description: intl.formatMessage({ id: "registerpage.meta.description" })
      }}
    >
      {{
        pageContent: (
          <Section>
            <h1 className="heading-1 mb-4">
              <FormattedMessage id="registerpage.heading" defaultMessage="Register deg her" />
            </h1>
            <div className="flex justify-between flex-wrap">
              <div className="w-full tablet:w-1/3 mobile-only:mb-5">
                <h2 className="heading-2 mb-2">
                  <FormattedMessage id="registerpage.private.heading" defaultMessage="Privateperson" />
                </h2>
                <div>
                  <RegisterPrivateUser />
                </div>
              </div>
              <div className="w-full tablet:w-1/3">
                <h2 className="heading-2 mb-2">
                  <FormattedMessage id="registerpage.company.heading" defaultMessage="Er du en bedrift?" />
                </h2>
                <p className="mb-2">
                  <FormattedMessage
                    id="registerpage.company.text"
                    defaultMessage="Som bedrift med organisasonsnummer får du tilgang til flere tjenester ved å registrere bedriften."
                  />
                </p>

                <LinkButton
                  to={urls.registerCompany}
                  className="hover:no-underline mb-4 hover:cursor-pointer mobile-only:w-full"
                  modifier="outline"
                  colorOption="yellow"
                >
                  <FormattedMessage id="registerpage.company.linkbutton" defaultMessage="Registrer bedrift her" />
                </LinkButton>
              </div>
            </div>
          </Section>
        )
      }}
    </Page>
  );
}
