import React from "react";
import "./Spinner.scss";

export default function Spinner(): JSX.Element {
  return (
    <div className="spinner-container">
      <span className="dot one" />
      <span className="dot two" />
      <span className="dot 3" />
    </div>
  );
}
