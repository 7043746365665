import { CLIENT_ID } from "appConstants";
import { BymEndpointResult, Vilkaar } from "interfaces";
import { samtykkeService } from "./axiosConfig";

export function getVilkaar(locale = "nb-NO") {
  return samtykkeService.get<BymEndpointResult<Vilkaar[]>>(`/klienter/${CLIENT_ID}/vilkaar?spraak=${locale}`);
}

export function getSamtykke(brukerId: string) {
  return samtykkeService.get(`/klienter/${CLIENT_ID}/samtykker/bruker/${brukerId}`);
}

export function postSamtykke(brukerId: string, vilkaarId: string) {
  return samtykkeService.post(`/klienter/${CLIENT_ID}/samtykker`, { vilkaarId, brukerId });
}

export function deleteSamtykke(samtykkeId: string) {
  return samtykkeService.delete(`/klienter/${CLIENT_ID}/samtykker/${samtykkeId}`);
}
