/* eslint-disable react/prop-types */
import { ReactNode, useEffect, useState } from "react";
import Modal, { ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { Button, MessageBoxWIcon } from "features/common/ComposedComponents/";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import { FormattedMessage } from "locale/langUtils";
import { getState as getAuthState } from "state/Auth/Auth";
import StickerInfoBlock from "features/common/Stickerinfo/StickerInfoBlock";
import { useSelector } from "react-redux";
import { useReduxDispatch } from "utils/Storeutils";
import {
  beregnRefusjonsBelop,
  getOblaterForBruker,
  kansellerOblat,
  kansellerOblatEksternBetaling,
  kansellerOgKrediterOblatFaktura
} from "state/Oblat/oblatActions";
import { StickerWInfo } from "interfaces";
import { useHistory } from "react-router";
import SendKansellerSkjema from "./SendKansellerSkjema";
import { refusjonBekreftet } from "../../appConstants/urls";
import {
  StickerPeriodId,
  bestillingIkkeGjonomfort,
  oblatErPabegynt,
  gjenvaerendeBelopForLite,
  refusjonMotNestsFeilet
} from "../../appConstants/identifiers";
import "./KansellerModal.css";

interface KansellerModalProps {
  oblatInfo: StickerWInfo;
  open: boolean;
  toggleOpen: Function;
  eventKey: string;
}

interface StateType {
  successMessage: ReactNode;
  refusjonsbelop: string;
  refusjonKanIkkeGjennomfoeres: string;
  errorMessage: ReactNode;
  laster: boolean;
  kontonummer: string;
  saksbehandlerNavn: string;
  kontaktinformasjon: string;
  melding: string;
}

export default function KansellerModal({ oblatInfo, open, toggleOpen, eventKey }: KansellerModalProps): JSX.Element {
  const [state, setState] = useState<StateType>({
    successMessage: "",
    refusjonsbelop: "",
    refusjonKanIkkeGjennomfoeres: "",
    errorMessage: "",
    laster: false,
    kontonummer: "",
    saksbehandlerNavn: "",
    kontaktinformasjon: "",
    melding: ""
  });

  const {
    successMessage,
    errorMessage,
    laster,
    kontonummer,
    kontaktinformasjon,
    saksbehandlerNavn,
    melding,
    refusjonsbelop,
    refusjonKanIkkeGjennomfoeres
  } = state;

  const { oblat } = oblatInfo;

  const history = useHistory();

  const { userInfo } = useSelector(getAuthState);

  const dispatch = useReduxDispatch();

  useEffect(() => {
    if (
      oblat.kjopsinfo?.betalingsform.toUpperCase() === "TERMINAL" ||
      oblat.kjopsinfo?.betalingsform.toUpperCase() === "KONTANT"
    ) {
      setState(prev => ({
        ...prev,
        refusjonsbelop: "",
        refusjonKanIkkeGjennomfoeres: ""
      }));
      dispatch(beregnRefusjonsBelop(oblat.id, oblat.kjopsinfo.varekjopId, userInfo?.brukerId)).then((res: any) => {
        if (!res.data.errorMessage && !res.data.result.belop) {
          setState(prev => ({ ...prev, refusjonsbelop: res.data.result }));
        } else {
          setState(prev => ({
            ...prev,
            refusjonKanIkkeGjennomfoeres: res.data ? res.data.errorMessage : ""
          }));
        }
      });
    }
  }, [dispatch, oblat.id, oblat.kjopsinfo, oblat.kjopsinfo?.betalingsform, oblat.kjopsinfo?.varekjopId, userInfo]);

  const closeSelf = () => {
    toggleOpen(eventKey);
    if (successMessage) dispatch(getOblaterForBruker(userInfo?.brukerId, true));
  };

  const handleChange = (field: string, target: string) => {
    setState(prev => ({ ...prev, [field]: target }));
  };

  const handleKansellerOblat = () => {
    setState(prev => ({
      ...prev,
      laster: true,
      successMessage: "",
      errorMessage: ""
    }));
    if (oblat.kjopsinfo?.betalingsform.toUpperCase() === "FAKTURA") {
      // Betalt med Faktura
      dispatch(kansellerOgKrediterOblatFaktura(oblat.id, oblat.kjopsinfo?.varekjopId, userInfo?.brukerId))
        .then((result: any) => {
          if (!result.data.errorkode && result.status === 200) {
            setState(prev => ({
              ...prev,
              laster: false,
              successMessage: <FormattedMessage id="canceldialogue.kansellerSuccess" />
            }));
          } else {
            setState(prev => ({
              ...prev,
              laster: false,
              errorMessage: <FormattedMessage id="canceldialogue.kansellerError0" />
            }));
          }
        })
        .catch(() => {
          setState(prev => ({
            ...prev,
            laster: false,
            errorMessage: <FormattedMessage id="canceldialogue.kansellerError0" />
          }));
        });
    } else if (
      oblat.kjopsinfo?.betalingsform.toUpperCase() === "TERMINAL" ||
      oblat.kjopsinfo?.betalingsform.toUpperCase() === "KONTANT"
    ) {
      if (kontonummer && kontaktinformasjon && saksbehandlerNavn && melding) {
        dispatch(
          kansellerOblatEksternBetaling(oblat.id, oblat.kjopsinfo?.varekjopId, userInfo?.brukerId, {
            kontonummer,
            kontaktinformasjon,
            melding,
            saksbehandlerNavn
          })
        )
          .then(result => {
            if (!result.data.errorkode && result.status === 200) {
              setState(prev => ({
                ...prev,
                laster: false,
                successMessage: <FormattedMessage id="canceldialogue.kansellerResepsjonsoblatSuccess" />
              }));
              dispatch(getOblaterForBruker(userInfo?.brukerId, true));
              history.push({
                pathname: refusjonBekreftet,
                state: {
                  oblat,
                  caseInfo: {
                    refusjonssum: result.data.result.belop,
                    refundertDato: result.data.result.refundertDato,
                    kontonummer,
                    kontaktinformasjon,
                    saksbehandlerNavn,
                    melding
                  }
                }
              });
            } else {
              setState(prev => ({
                ...prev,
                laster: false,
                errorMessage: <FormattedMessage id="canceldialogue.kansellerError0" />
              }));
            }
          })
          .catch(() => {
            setState(prev => ({
              ...prev,
              laster: false,
              errorMessage: <FormattedMessage id="canceldialogue.kansellerError0" />
            }));
          });
      } else {
        setState(prev => ({
          ...prev,
          laster: false,
          errorMessage: <FormattedMessage id="canceldialogue.kansellerResepsjonsoblatErrManglerInput" />
        }));
      }
    } else {
      // Betalt med NETS
      dispatch(kansellerOblat(oblat.id, oblat.kjopsinfo?.varekjopId, userInfo?.brukerId))
        .then(result => {
          if (!result.data.errorkode && result.data.result.netsResponstekst === "OK") {
            setState(prev => ({
              ...prev,
              laster: false,
              successMessage: (
                <span>
                  <FormattedMessage id="canceldialogue.kansellerNetsSuccess" />
                  {` (${oblat.kjopsinfo?.maskertKortnummer ?? ""})`}
                </span>
              )
            }));
          } else {
            const errorCode = result.data.errorkode;
            let errorMsg: ReactNode;
            if (errorCode === refusjonMotNestsFeilet) errorMsg = <FormattedMessage id="canceldialogue.kansellerError4" />;
            else if (errorCode === gjenvaerendeBelopForLite)
              errorMsg = <FormattedMessage id="canceldialogue.kansellerError3" />;
            else if (errorCode === oblatErPabegynt) errorMsg = <FormattedMessage id="canceldialogue.kansellerError2" />;
            else if (errorCode === bestillingIkkeGjonomfort)
              errorMsg = <FormattedMessage id="canceldialogue.kansellerError1" />;
            setState(prev => ({
              ...prev,
              laster: false,
              errorMessage: errorMsg || result.data.errorMessage
            }));
          }
        })
        .catch(e => {
          setState(prev => ({
            ...prev,
            laster: false,
            errorMessage: <FormattedMessage id="canceldialogue.kansellerError0" />
          }));
        });
    }
  };

  return (
    <Modal isOpen={open} toggle={closeSelf} staticModal aria-hidden>
      <ModalSection>
        <h4 className="heading-4">
          <FormattedMessage id="canceldialogue.pageTitle" defaultMessage="  " />
        </h4>
      </ModalSection>
      <ModalSection>
        <div className="mb-3">
          {!(successMessage || errorMessage) && (
            <MessageBoxWIcon type="primary" visible icon="information">
              {oblat.periodeId === StickerPeriodId.Season && (
                <p>
                  <FormattedMessage id="canceldialogue.infoSessongOblat" />
                </p>
              )}
              {oblat.periodeId === StickerPeriodId.Month && (
                <p>
                  <FormattedMessage id="canceldialogue.infoMaanedsOblat" />
                </p>
              )}
            </MessageBoxWIcon>
          )}
          {successMessage !== "" && (
            <div className="bg-state-success p-3">
              <p>{successMessage}</p>
            </div>
          )}

          {errorMessage !== "" && (
            <div className="bg-state-danger p-3">
              <p>{errorMessage}</p>
            </div>
          )}
        </div>
        <StickerInfoBlock oblat={oblatInfo} className="w-full mt-3 tablet:w-1/2" />
        {(oblat.kjopsinfo?.betalingsform.toUpperCase() === "TERMINAL" ||
          oblat.kjopsinfo?.betalingsform.toUpperCase() === "KONTANT") && (
          <div>
            <MessageBox type="primary" className="mt-3">
              <FormattedMessage id="canceldialogue.kansellerResepsjonsoblatInfo" />
            </MessageBox>
            {refusjonsbelop !== "" && (
              <MessageBox type="success" className="mt-3 mb-3">
                <FormattedMessage
                  id="canceldialogue.oblatSkalRefunderesSum"
                  values={{
                    sum: refusjonsbelop
                  }}
                />
              </MessageBox>
            )}

            {refusjonKanIkkeGjennomfoeres !== "" && (
              <MessageBox type="danger" className="mt-3">
                <p>{refusjonKanIkkeGjennomfoeres}</p>
              </MessageBox>
            )}

            <SendKansellerSkjema
              handleChange={handleChange}
              kontonummer={kontonummer}
              kontaktinformasjon={kontaktinformasjon}
              saksbehandlerNavn={saksbehandlerNavn}
              melding={melding}
            />
          </div>
        )}
      </ModalSection>
      <ModalSection className="flex flex-col tablet:flex-row tablet:justify-end">
        {successMessage !== "" ? (
          <Button className="text-white mobile-only:w-full mobile-only:mb-3" onClick={closeSelf} disabled={laster}>
            <FormattedMessage id="canceldialogue.lukkModal" />
          </Button>
        ) : (
          <>
            <Button
              className="bg-blue-light hover:bg-blue mobile-only:w-full mobile-only:mb-3"
              onClick={handleKansellerOblat}
              // icon="save"
              isLoading={laster}
              disabled={laster}
            >
              <FormattedMessage id="canceldialogue.cancelButtonText" />
            </Button>
            <Button
              className="tablet:ml-2 mobile-only:w-full"
              colorOption="green-dark"
              modifier="outline"
              onClick={closeSelf}
              disabled={laster}
            >
              <FormattedMessage id="canceldialogue.abortCancellation" />
            </Button>
          </>
        )}
      </ModalSection>
    </Modal>
  );
}
