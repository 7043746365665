import React, { useState } from "react";
import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { Button } from "features/common/OSG";
import { FormattedMessage } from "locale/langUtils";
import useSamtykkeHook from "features/Hooks/SamtykkeHook";
import { isEmpty } from "lodash-es";
import DeclineTerms from "./WithdrawConsentDialogue";
import VilkaarText from "./VilkaarText";

export default function SamtykkeDialog(): JSX.Element {
  const [trekkSamtykkeConfirmOpen, setTrekkSamtykkeConfirmOpen] = useState(false);
  const {
    state: { vilkaar },
    acceptSamtykke,
    isLoggedInAndNOTAcceptedSamtykke
  } = useSamtykkeHook();

  return (
    <>
      <Modal
        isOpen={isLoggedInAndNOTAcceptedSamtykke && !trekkSamtykkeConfirmOpen}
        toggle={() => setTrekkSamtykkeConfirmOpen(true)}
        staticModal
      >
        <ModalHeader>{{ heading: <FormattedMessage id="termsandconditions.heading" /> }}</ModalHeader>
        <ModalSection>
          {vilkaar && !isEmpty(vilkaar.vilkaarTekster) && <VilkaarText vilkaar={vilkaar.vilkaarTekster[0]} />}
        </ModalSection>
        <ModalSection className="flex col flex-col tablet:flex-row tablet:justify-end">
          <Button
            colorOption="green-light"
            onClick={acceptSamtykke}
            disabled={!isLoggedInAndNOTAcceptedSamtykke}
            className="mx-0 my-1 tablet:my-0 tablet:mr-1"
          >
            <FormattedMessage id="termsandconditions.button.consent" />
          </Button>
          {isLoggedInAndNOTAcceptedSamtykke ? (
            <Button
              modifier="outline"
              onClick={() => {
                setTrekkSamtykkeConfirmOpen(true);
              }}
              className="mx-0 my-1 tablet:my-0 tablet:mr-1"
            >
              <FormattedMessage id="termsandconditions.button.decline" />
            </Button>
          ) : (
            <Button
              modifier="outline"
              onClick={() => {
                setTrekkSamtykkeConfirmOpen(true);
              }}
              className="mx-0 my-1 tablet:my-0 tablet:mr-1"
            >
              {isLoggedInAndNOTAcceptedSamtykke ? (
                <FormattedMessage id="termsandconditions.button.decline" />
              ) : (
                <FormattedMessage id="termsandconditions.button.decline.haspreviouslyaccepted" />
              )}
            </Button>
          )}
        </ModalSection>
      </Modal>
      <DeclineTerms
        isOpen={isLoggedInAndNOTAcceptedSamtykke && trekkSamtykkeConfirmOpen}
        onDeclein={() => {
          setTrekkSamtykkeConfirmOpen(false);
        }}
        cancel={() => {
          setTrekkSamtykkeConfirmOpen(false);
        }}
      />
    </>
  );
}
