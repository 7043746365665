import { OSGPattern } from "features/common/OSG/interfaces";
import React, { forwardRef, ReactNode } from "react";

interface SearchBarProps
  extends Omit<React.HTMLProps<HTMLInputElement>, "type" | "label">,
    OSGPattern<undefined, "left" | "right"> {
  label: ReactNode;
}
export default forwardRef<HTMLInputElement, SearchBarProps>(
  ({ modifier = "right", label, className, ...rest }: SearchBarProps, ref) => {
    return (
      <div className={`osg-searchfield osg-searchfield--${modifier}`}>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <label>
          <span className="osg-sr-only">{label}</span>
          <input
            ref={ref}
            className={`osg-searchfield__input osg-form-component ${className}`}
        style={{fontSize: "16px", fontWeight: "300", paddingLeft:"15px", fontFamily: "oslo sans" }}
            type="search"
            {...rest}
          />
          <span className="osg-searchfield__icon osg-icon osg-icon--magnifying-glass-small" aria-hidden />
        </label>
      </div>
    );
  }
);
