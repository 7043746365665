import { getUser } from "Auth/oidcConfig";
import axios, { AxiosTransformer } from "axios";
import { getEnvironmentVariable } from "utils/environment";

const defaultTransformers = (): AxiosTransformer[] => {
  const { transformRequest } = axios.defaults;
  if (!transformRequest) return [];
  if (transformRequest instanceof Array) return transformRequest;
  return [transformRequest];
};

export const piggavServiceBaseUrl = `${getEnvironmentVariable("URL_PIGGDEKKOBLAT_SERVICE")}/api`;

export const piggavservice = axios.create({
  baseURL: piggavServiceBaseUrl,
  transformRequest: [
    (data, headers) => {
      delete headers.Authorization;
      const user = getUser();
      if (user) headers.Authorization = `Bearer ${user?.access_token}`;
      else headers.Authorization = "";
      // // Set common headers for all types of requests
      headers.common.Pragma = "no-cache";
      return data;
    },
    ...defaultTransformers()
  ]
});

export const samtykkeserviceBaseUrl = `${getEnvironmentVariable("URL_SAMTYKKE_SERVICE")}/api`;

export const samtykkeService = axios.create({
  baseURL: samtykkeserviceBaseUrl,
  transformRequest: [
    (data, headers) => {
      delete headers.Authorization;
      const user = getUser();
      if (user) headers.Authorization = `Bearer ${user?.access_token}`;
      else headers.Authorization = "";
        return data;
    },
    ...defaultTransformers()
  ]
});

export const oneLoginBaseUrl = getEnvironmentVariable("ONELOGIN_AUTHORITY");

/* Set Axios defaults */
// Set common request header
export function setLanguageInRequestHeader(lang = "no-NB") {
  axios.defaults.headers.common["Content-Language"] = lang;
  piggavservice.defaults.headers.common["Content-Language"] = lang;
}
