import { GET_OBLATER_SUCCESS, GET_OBLATER_FAILURE, GET_OBLATER_REQUEST, RESET_OBLATER_STATE } from "./oblatActions";

const oblatReducer = (
  state = {
    lasterOblater: false,
    oblater: []
  },
  action = {}
) => {
  switch (action.type) {
    case RESET_OBLATER_STATE:
      return {
        lasterOblater: false,
        oblater: []
      };
    case GET_OBLATER_FAILURE:
      return {
        ...state,
        oblater: [],
        lasterOblater: false
      };
    case GET_OBLATER_SUCCESS:
      return {
        ...state,
        oblater: action.payload,
        lasterOblater: false
      };
    case GET_OBLATER_REQUEST:
      return { ...state, lasterOblater: true };
    default:
      return state;
  }
};

export default oblatReducer;
