/* eslint-disable no-use-before-define */
/* eslint-disable @typescript-eslint/no-use-before-define */
import { find, map } from "lodash-es";
import hash from "object-hash";
import { StickerStatus, oblattyper } from "appConstants/identifiers";
import { Oblat, StickerWInfo } from "interfaces";
import { StickerWInfoAndSeason } from "features/Hooks/OblaterHook";
import { Row } from "react-table";
import { getMonth, getYear, parseISO, isAfter } from "date-fns";
import { erIFremtiden, erIFortiden, erIdagEllerSenere } from "./dateFnsUtils";

export const getKjoretoy = (kjoretoy: any, kjoretoyid: string) => {
  if (!kjoretoy || kjoretoyid === "") return null;
  return find(kjoretoy, item => item.id === kjoretoyid);
};

export function lagIdString(oblat: any): string {
  if (oblat.statusId !== StickerStatus.Transferred) return oblat.id;
  return hash(oblat, { algorithm: "sha1", encoding: "hex" });
}

export const getOblattype = (oblattypes: any, oblatId: string) => {
  if (!oblatId || oblatId === "") return null;
  return find(oblattypes, item => item.id === oblatId);
};

export function carHaveActiveStickers(oblater: any[], carId: string) {
  return find(oblater, oblat => oblat.statusId === 1 && oblat.kjoretoy.id === carId);
}

export function applyStickerWInfo(oblat: Oblat): StickerWInfo {
  let statusClass = "";
  let statusId = 0;
  let statusText = "";
  const uniqueIdOnlyInFrontend = lagIdString(oblat);
  if (
    oblat.statusId === StickerStatus.Refunded ||
    oblat.statusId === StickerStatus.Credited ||
    oblat.statusId === StickerStatus.Canceled
  ) {
    // Cancelled Refundert or Credited
    statusClass = "bg-grey-dark";
    statusId = StickerStatus.CanceledFrontendOnly;
    statusText = "common.stickerstatus.cancelled";
  } else if (oblat.statusId === StickerStatus.AwaitingPayment) {
    // Awaitingpayment
    statusClass = "bg-state-warning";
    statusId = StickerStatus.AwaitingPayment;
    statusText = "common.stickerstatus.awaitingpayment";
  } else if (oblat.statusId === StickerStatus.Transferred) {
    // Transferred
    statusClass = "bg-grey-dark";
    statusId = StickerStatus.Transferred;
    statusText = "common.stickerstatus.transferred";
  } else if (oblat.statusId === StickerStatus.Expired) {
    // Expired
    statusClass = "bg-state-danger";
    statusId = StickerStatus.Expired;
    statusText = "common.stickerstatus.expired";
  } else if (oblat.statusId === StickerStatus.Inactive && erIdagEllerSenere(oblat.gyldigFra)) {
    // In basket
    statusClass = "i-handle-kurv";
    statusId = StickerStatus.Inactive;
    statusText = "common.stickerstatus.new";
  } else if (erIFremtiden(oblat.gyldigFra)) {
    // Not yet started
    statusClass = "bg-state-warning";
    statusId = StickerStatus.NotStartedFrontendOnly;
    statusText = "common.stickerstatus.notstarted";
  } else if (erIFortiden(oblat.gyldigTil)) {
    // Expired
    statusClass = "bg-red";
    statusId = StickerStatus.Expired;
    statusText = "common.stickerstatus.expired";
  } else {
    // Active
    statusClass = "bg-green";
    statusId = StickerStatus.Active;
    statusText = "common.stickerstatus.active";
  }
  let name;
  if (oblat?.isExtern) name = "";
  else
    name =
      getName(oblat?.periodeId ?? oblat?.oblattype?.periodeId ?? -1, oblat?.kjoretoy?.kjoretoytypeId ?? -1) ??
      getNameByOblattypeId(oblat.oblattypeId);
  return {
    oblat,
    uniqueIdOnlyInFrontend,
    statusClass,
    statusId,
    statusText,
    name
  };
}

export function setStickerInfo(oblater: any[]): StickerWInfo[] {
  return map(oblater, oblat => applyStickerWInfo(oblat));
}

export function getName(periodeId: number, kjoretoytypeId: number): string | undefined {
  if (
    oblattyper.dayStickerLight.periodeId === periodeId &&
    kjoretoytypeId === oblattyper.dayStickerLight.kjoretoytypeId
  )
    return "common.stickertype.lightday";
  if (
    oblattyper.dayStickerHeavy.periodeId === periodeId &&
    kjoretoytypeId === oblattyper.dayStickerHeavy.kjoretoytypeId
  )
    return "common.stickertype.heavyday";
  if (
    oblattyper.monthStickerLight.periodeId === periodeId &&
    kjoretoytypeId === oblattyper.monthStickerLight.kjoretoytypeId
  )
    return "common.stickertype.lightmonth";
  if (
    oblattyper.monthStickerHeavy.periodeId === periodeId &&
    kjoretoytypeId === oblattyper.monthStickerHeavy.kjoretoytypeId
  )
    return "common.stickertype.heavymonth";
  if (
    oblattyper.seasonStickerLight.periodeId === periodeId &&
    kjoretoytypeId === oblattyper.seasonStickerLight.kjoretoytypeId
  )
    return "common.stickertype.lightseason";
  if (
    oblattyper.seasonStickerHeavy.periodeId === periodeId &&
    kjoretoytypeId === oblattyper.seasonStickerHeavy.kjoretoytypeId
  )
    return "common.stickertype.heavyseason";

  return undefined;
}

export function getNameByOblattypeId(oblatTypeId: string): string {
  const ot = find(oblattyper, t => {
    return t.id === oblatTypeId;
  });
  if (!ot) return "unknownstickertype";
  return getName(ot.periodeId, ot.kjoretoytypeId) ?? "unknownstickertype";
}

export function sortOblaterByKey<T extends StickerWInfo | StickerWInfo>(list: T[], prioritationList: number[]): T[] {
  return list.sort((o1, o2) => {
    const op1 = prioritationList.indexOf(o1.statusId);
    const op2 = prioritationList.indexOf(o2.statusId);
    if (op1 < op2) return -1;
    if (op1 === op2) return 0;
    return 1;
  });
}

export function sortByKeys<T>(list: T[], prioritationList: T[]): T[] {
  return list.sort((o1, o2) => {
    const op1 = prioritationList.indexOf(o1);
    const op2 = prioritationList.indexOf(o2);
    if (op1 < op2) return -1;
    if (op1 === op2) return 0;
    return 1;
  });
}

export function convertPaymentmethodToLangId(payment?: string): string {
  let id = "common.";
  switch (payment?.toLowerCase()) {
    case "kort":
      id += "card";
      break;
    case "faktura":
      id += "invoice";
      break;
    case "terminal":
      id += "terminal";
      break;
    case "kontant":
      id += "cash";
      break;
    default:
      id.concat("");
  }
  return id;
}

// Filter functions for react-table
export const filterStatus = (rows: Row<StickerWInfoAndSeason>[], columnIds: string[], filterValue: any) => {
  return rows.filter(r => r.original.statusId.toString() === filterValue || filterValue === "0");
};

export const filterSeason = (rows: Row<StickerWInfoAndSeason>[], [columnId]: string[], filterValue: any) => {
  return rows.filter(r => r.values[columnId] === filterValue || filterValue === "0");
};

export const filterNumberPlate = (rows: Row<StickerWInfoAndSeason>[], [columnId]: string[], filterValue: any) => {
  return rows.filter(
    r => (r.values[columnId] as string)?.toUpperCase().includes(filterValue?.toUpperCase() ?? "") || filterValue === ""
  );
};

export function createSeasonString(validTo: Date) {
  let season = "";
  const seasonYear = getYear(validTo);
  const getSeasonString = (fromYear: number, toYear: number) => `${fromYear}/${toYear}`;
  if (getMonth(validTo) > 6) season = getSeasonString(seasonYear, seasonYear + 1);
  else season = getSeasonString(seasonYear - 1, seasonYear);
  return season;
}

export const isExpired = (oblat: { gyldigTil: string }) => isAfter(new Date(), parseISO(oblat.gyldigTil));
