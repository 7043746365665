import { useLayoutEffect, useRef, useState } from "react";
interface ScrollProps {
  threshold?: number;
  setClassNameOnBody?: boolean;
  classNameDownOnBody?: string;
  classNameUpOnBody?: string;
  activeClassName?: string;
  ref: HTMLElement | null;
}
export function useScrollHook({
  threshold = 40,
  setClassNameOnBody = true,
  classNameDownOnBody = "user-is-scrolling-down",
  classNameUpOnBody = "user-is-scrolling-up",
  activeClassName = "has-active-element",
  ref
}: ScrollProps) {
  const [isScrollingUp, setIsScrollingUp] = useState(false);
  const [isScrollingDown, setIsScrollingDown] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);
  const scrollYPosition = useRef(window.scrollY);

  useLayoutEffect(() => {
    function scrollEvent() {
      if (!(Math.abs(window.scrollY - scrollYPosition.current) >= threshold)) return;
      const scrollingDown = window.scrollY > scrollYPosition.current;
      scrollYPosition.current = window.scrollY;
      if (!(window.scrollY > (0 + threshold))) {
        setIsScrollingDown(false);
        setIsScrollingUp(false);
        document.body.classList.remove(classNameDownOnBody);
        document.body.classList.remove(classNameUpOnBody);
        return;
      }

      setIsScrollingDown(scrollingDown);
      setIsScrollingUp(!scrollingDown);

      if (!setClassNameOnBody) return;
      if (scrollingDown) {
        document.body.classList.remove(classNameUpOnBody);
        document.body.classList.add(classNameDownOnBody);
      } else {
        document.body.classList.remove(classNameDownOnBody);
        document.body.classList.add(classNameUpOnBody);
      }
    }

    window.addEventListener("scroll", scrollEvent);

    return () => window.removeEventListener("scroll", scrollEvent);
  }, [classNameDownOnBody, classNameUpOnBody, setClassNameOnBody, threshold]);

  useLayoutEffect(() => {
    if (!ref) return;

    function focusIn(e: FocusEvent) {
      document.body.classList.add(activeClassName);
      setHasFocus(true);
    }
    function focusOut(e: FocusEvent) {
      document.body.classList.remove(activeClassName);
    }

    ref.addEventListener("focus", focusIn, true);
    ref.addEventListener("blur", focusOut, true);

    return () => {
      ref?.removeEventListener("focusin", focusIn, true);
      ref?.removeEventListener("focusout", focusOut, true);
    };
  }, [activeClassName, ref]);

  return { isScrollingDown, isScrollingUp, hasFocus };
}
