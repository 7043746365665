import { useContext, useMemo } from "react";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import Radio from "features/common/BaseComponents/Radio/Radio";
import { Oblattype } from "interfaces";
import { filter, map } from "lodash-es";
import { getName } from "utils/oblatHelpers";
import { FormattedMessage, useIntl } from "locale/langUtils";
import { useSelector } from "react-redux";
import { OrdreLinjeContext } from "./OrdrelinjeForm";

interface SelectStickerTypeProps {
  onChange?: (stickerType: Oblattype, periodId: number) => void;
}

export default function SelectStickerType({ onChange }: SelectStickerTypeProps): JSX.Element {
  const {
    chosenVehicle,
    chosenStickerType,
    errors: { oblattypeIdError }
  } = useContext(OrdreLinjeContext);
  const intl = useIntl();
  const allStickertypes = useSelector<any, Oblattype[]>(state => state.oblattyper.oblattyper);

  const stickerTypesForVehicle = useMemo(() => {
    if (chosenVehicle)
      return filter(allStickertypes, st => {
        return st.kjoretoytypeId === chosenVehicle.kjoretoytypeId;
      });
    return [];
  }, [allStickertypes, chosenVehicle]);

  const setSelectedStickerType = (newVal: Oblattype, pId: number): void => {
    onChange?.(newVal, pId);
  };

  return (
    <fieldset>
      <legend className="heading-5 mb-2">
        <FormattedMessage id="buystickers.addOrdrelinjeFormType" /> <span aria-hidden> *</span>
        <span className="sr-only">
          <FormattedMessage id="common.requiredfield" />
        </span>
      </legend>
      {map(stickerTypesForVehicle, stickerType => {
        const id = getName(stickerType.periodeId, stickerType.kjoretoytypeId)?.replace("common", "buystickers");

        return (
          <Radio
            checked={stickerType.id === chosenStickerType?.id}
            tabIndex={0}
            key={`OblatTypeGroup${stickerType.id}`}
            value={stickerType.id}
            name="OblatTypeGroup"
            onClick={() => setSelectedStickerType(stickerType, stickerType.periodeId)}
            onChange={() => setSelectedStickerType(stickerType, stickerType.periodeId)}
            aria-label={intl.formatMessage(
              {
                id: `${id}.aria`
              },
              {
                price: stickerType.pris
              }
            )}
            aria-describedby="oblattype-error oblat-valg-tilbakemelding"
          >
            <FormattedMessage
              id={id ?? " "}
              defaultMessage={stickerType.navn}
              values={{
                price: stickerType.pris
              }}
            />
          </Radio>
        );
      })}
      {oblattypeIdError && (
        <MessageBox role="alert" id="oblattype-error" className="mt-2" type="danger">
          <FormattedMessage id={oblattypeIdError} />
        </MessageBox>
      )}
    </fieldset>
  );
}
