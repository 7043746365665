/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-vars */
import { useState, useEffect, createContext, useCallback } from "react";
import { IntlProvider } from "react-intl";
import LocalStoreUtils from "utils/LocalStoreUtils";
import { enUS, nb as bokmaal } from "date-fns/locale";
import { setLanguageInRequestHeader } from "api";
import { nb, en } from "./translations";
import { registerLocale, setDefaultLocale } from "react-datepicker";

registerLocale("nb", bokmaal);
registerLocale("en", enUS);

declare global {
  namespace FormatjsIntl {
    interface Message {
      ids: keyof typeof nb | string;
    }
  }
}

export interface LocaleConfig {
  locale: string;
  messages: any;
  available: string[];
  lCode: string;
}

export const nbConfig: LocaleConfig = {
  locale: "nb",
  messages: nb,
  available: ["en"],
  lCode: "nb-NO"
};

const enConfig: LocaleConfig = {
  locale: "en",
  messages: en,
  available: ["nb"],
  lCode: "en-US"
};

// eslint-disable-next-line @typescript-eslint/no-empty-function
export const LocaleContext = createContext({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onChangeLanguage: (locale: string) => {},
  currentLocale: nbConfig,
  initalized: false
});

const LocaleProvider = (props: { defaultConfig: LocaleConfig; children: JSX.Element }): JSX.Element => {
  const { defaultConfig, children } = props;

  const [config, setConfig] = useState(defaultConfig);

  const [localeInited, setLocaleInited] = useState(false);

  const { getItem, setItem } = LocalStoreUtils();

  const onChangeLanguage = useCallback(
    (lang: string) => {
      console.log("Setting language", lang);
      try {
        if (lang === "en") {
          setItem("locale", "en");
          document.documentElement.setAttribute("lang", "en");
          setConfig(enConfig);
          setLanguageInRequestHeader(enConfig.lCode);
          setDefaultLocale('en');
        } else {
          setItem("locale", "nb");
          document.documentElement.setAttribute("lang", "nb");
          setConfig(defaultConfig);
          setLanguageInRequestHeader(defaultConfig.lCode);
          setDefaultLocale('nb');
        }
      } catch (err) {
        setConfig(defaultConfig);
        setLanguageInRequestHeader(defaultConfig.lCode);
      }
    },
    [defaultConfig, setItem]
  );

  useEffect(() => {
    getItem("locale")
      .then(res => {
        onChangeLanguage(res ?? "");
      })
      .finally(() => {
        setLocaleInited(true);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <IntlProvider locale={config.locale} messages={config.messages}>
      <LocaleContext.Provider
        value={{
          onChangeLanguage,
          currentLocale: config,
          initalized: localeInited
        }}
      >
        {children}
      </LocaleContext.Provider>
    </IntlProvider>
  );
};

export default LocaleProvider;
