// local urls:
export const hjem = "/";
export const landingside = "/hjem";
export const versjon = "/versjon";

// Kjop
export const kjopOblater = "/kjop-piggdekkoblat";
export const summaryAddSingle = `${kjopOblater}/sammendrag`;
export const payment = "/kjop-piggdekkoblat/bekreft-kjop/betaling";
export const betaling = "/betaling";
export const kvittering = "/kvitteringer";
export const kvitteringPath = "/kvitteringer/:key";
export const bulkKjop = "/bulkkjop";
export const kontrollerBulkkjop = "/bulkkjop/kontroller-bulkkjop";
export const summaryAddBulk = "/bulkkjop/sammendrag"; // `${bulkKjop}/sammendrag`;

// Bruker
//export const login = "/logg-inn"; // ikke i bruk?
export const login = "/login";
export const glemtPassord = "/glemt-passord";
export const registrerBruker = "/registrer-bruker";
export const registerCompany = "/registrer-bruker/organisasjon";
export const aktiverBruker = "/aktivering/:key";
export const sentNewPassword = "/linkforglemtpassordersent";
export const createNewPassword = "/konto/passord/:key";
export const activatePassword = "/passordaktivering/:key";
export const takkForRegistrering = "/takkfordinregistrering";
export const takkForRegistreringUrl = registrerBruker + takkForRegistrering;
// FAQ
export const faq = "/faq";

export const betalingGjenomfort = "(/kjop-piggdekkoblat)?/hjem/gjenomfort-betaling/:key";
export const betalingFaktura = "/betaling-faktura/:varekjopId";
export const profil = "/min-profil";

export const oblatoversikt = "/oblatoversikt";
export const refusjonBekreftet = "/oblatoversikt/refusjonBekreftet";
export const kvitteringer = "/kvitteringer";
export const vilkaar = "/vilkaar";
