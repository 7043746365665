import { FormattedMessage, useIntl } from "locale/langUtils";

interface FormProps extends React.DetailedHTMLProps<React.FormHTMLAttributes<HTMLFormElement>, HTMLFormElement> {
  showRequiredNote?: boolean;
  children: React.ReactNode;
}

export default function Form({ showRequiredNote = true, children, ...restFormProps }: FormProps) {
  const intl = useIntl();
  return (
    <form {...restFormProps}>
      {showRequiredNote && (
        <p role="note" style={{ fontSize: 16, marginBottom: "10px" }}>
          <FormattedMessage
            id="common.requiredfield.note"
            values={{
              required: chunks => <span aria-label={intl.formatMessage({ id: "common.star" })}>{chunks}</span>
            }}
          />
        </p>
      )}
      {children}
    </form>
  );
}
