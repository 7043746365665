/* eslint-disable react/static-property-placement */
import React, { useCallback } from "react";
import { Route, Redirect, RouteProps, RouteComponentProps } from "react-router-dom";
import { getState as getAuthState } from "state/Auth/Auth";
import { getState as getCustomerState } from "state/Kunde/kundeReducer";
import { useSelector } from "react-redux";
import { KundeType, Kunde } from "interfaces";
import { getCustomerType } from "utils/authenticationUtils";
import { ApplicationState } from "state/reducers";
import * as urls from "../../appConstants/urls";
import { useAuth } from "react-oidc-context";

interface PrivateRouteProps extends Omit<RouteProps, "render"> {
  restrictToKundeType?: KundeType;
  shouldRenderRoute?: boolean;
}

export default function PrivateRoute({
  restrictToKundeType,
  shouldRenderRoute = true,
  component: Comp,
  ...rest
}: PrivateRouteProps): JSX.Element {
  const auth = useAuth();

  const { kundetypeId } = useSelector<ApplicationState, Kunde>(getCustomerState);
  const RenderRoute = useCallback(
    (props: RouteComponentProps) => {
      const { location } = props;
      const customerType = getCustomerType(kundetypeId);
      const hasRightCustomerId =
        !kundetypeId || !restrictToKundeType || (restrictToKundeType && restrictToKundeType === customerType);

      if (auth.isLoading) return <div />;

      if (auth.isAuthenticated && hasRightCustomerId && shouldRenderRoute && Comp) return <Comp {...props} />;

      return (
        <Redirect
          to={{
            pathname: urls.hjem,
            state: { from: location }
          }}
        />
      );
    },
    [kundetypeId, restrictToKundeType, auth.isAuthenticated, auth.isLoading, shouldRenderRoute, Comp]
  );
  return <Route {...rest} render={RenderRoute} />;
}
