import { useEffect, useState } from "react";
import { Hovedenhet, Underenhet } from "interfaces";
import { piggavService } from "api";

export interface BrregInfo {
  erUnderenhet: boolean;
  underenhet: Underenhet | null;
  hovedenhet: Hovedenhet | null;
  erSlettet?: boolean;
  erGyldig?: boolean;
}

export async function getBrregInfo(orgNummer: string): Promise<BrregInfo> {
  const strippedorgNr = orgNummer.replace(/\s+/g, "");

  let resp = await piggavService.getOrganisasjonsKundeInfo(strippedorgNr);

  var org = resp.data.result;
  return {
    erUnderenhet: org.erUnderenhet,
    hovedenhet: org,
    underenhet: null,
    erGyldig: org.erGyldig
  };
}

export default function BrregHook(orgNummer?: string) {
  const [brregOrgInfo, setBrregOrgInfo] = useState<Underenhet | Hovedenhet | undefined | null>(undefined);
  const [erUnderenhet, setIsUnderEnhet] = useState(false);
  const [erGyldig, setErGyldig] = useState(false);
  const [loadingBrregInfo, setLoadingBrregInfo] = useState(false);

  /**
   * This effect gets brregInfo from bReg-api
   */
  useEffect(() => {
    if (!orgNummer) return;
    const strippedorgNr = orgNummer.replace(/\s+/g, "");
    setLoadingBrregInfo(true);
    getBrregInfo(strippedorgNr)
      .then(res => {
        setIsUnderEnhet(res?.erUnderenhet ?? false);
        setErGyldig(res?.erGyldig ?? false);
        setBrregOrgInfo(res.erUnderenhet ? res.underenhet : res.hovedenhet);
      })
      .finally(() => {
        setLoadingBrregInfo(false);
      });
  }, [orgNummer]);

  const getCompanyInfo = async (orgnummer: string) => {
    const strippedorgNr = orgnummer.replace(/\s+/g, "");
    return getBrregInfo(strippedorgNr)
      .then(res => {
        setIsUnderEnhet(res?.erUnderenhet ?? false);
        setBrregOrgInfo(res.erUnderenhet ? res.underenhet : res.hovedenhet);
        return Promise.resolve(res);
      })
      .catch(e => {
        return Promise.reject(e);
      })
      .finally(() => {
        setLoadingBrregInfo(false);
      });
  };

  return { erUnderenhet, erGyldig, loadingBrregInfo, getCompanyInfo, brregOrgInfo };
}
