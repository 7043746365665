import { piggavService } from "api";
import * as apiResponseUtils from "../../utils/apiResponseUtils";
import { toastError, toastSuccess } from "../Toast/ToasActions";

export const CREATE_VAREKJOP_REQUEST = "CREATE_VAREKJOP_REQUEST";
export const CREATE_VAREKJOP_SUCCESS = "CREATE_VAREKJOP_SUCCESS";
export const CREATE_VAREKJOP_FALIURE = "CREATE_VAREKJOP_FALIURE";

export const GET_VAREKJOP_REQUEST = "GET_VAREKJOP_REQUEST";
export const GET_VAREKJOP_SUCCESS = "GET_VAREKJOP_SUCCESS";
export const GET_VAREKJOP_FALIURE = "GET_VAREKJOP_FALIURE";

export const GET_VAREKJOP_BY_ID_REQUEST = "GET_VAREKJOP_BY_ID_REQUEST";
export const GET_VAREKJOP_BY_ID_SUCCESS = "GET_VAREKJOP_BY_ID_SUCCESS";
export const GET_VAREKJOP_BY_ID_FALIURE = "GET_VAREKJOP_BY_ID_FALIURE";
export const GET_VAREKJOP_BY_ID_NOT_FOUND = "GET_VAREKJOP_BY_ID_NOT_FOUND";

export const GET_OBLATER_FOR_VAREKJOP_REQUEST = "GET_OBLATER_FOR_VAREKJOP_REQUEST";
export const GET_OBLATER_FOR_VAREKJOP_SUCCESS = "GET_OBLATER_FOR_VAREKJOP_SUCCESS";
export const GET_OBLATER_FOR_VAREKJOP_FALIURE = "GET_OBLATER_FOR_VAREKJOP_FALIURE";

export const DELETE_VAREKJOP_SUCCESS = "DELETE_VAREKJOP_SUCCESS";

export const RESET_VAREKJOP = "piggav/varekjop/RESET_VAREKJOPSTATE";

export const resetVarekjopState = () => ({
  type: RESET_VAREKJOP
});

const createVarekjopRequest = () => ({
  type: CREATE_VAREKJOP_REQUEST,
  payload: ""
});

const createVarekjopSuccess = varekjop => ({
  type: CREATE_VAREKJOP_SUCCESS,
  payload: varekjop
});

const createVarekjopFailure = error => ({
  type: CREATE_VAREKJOP_FALIURE,
  payload: error,
  error: true
});

export const createVarekjop = brukerId => dispatch => {
  dispatch(createVarekjopRequest());
  return piggavService
    .createVarekjop(brukerId)
    .then(response => {
      const data = response.data.result;
      dispatch(createVarekjopSuccess(data));
      Promise.resolve(true);
    })
    .catch(error => {
      dispatch(createVarekjopFailure(error));
      const errorMessage = apiResponseUtils.errorData(error);
      Promise.reject(errorMessage);
    });
};

const getVarekjopRequest = () => ({
  type: GET_VAREKJOP_REQUEST,
  payload: ""
});

const getVarekjopSuccess = varekjop => ({
  type: GET_VAREKJOP_SUCCESS,
  payload: varekjop
});

const getVarekjopFailure = error => ({
  type: GET_VAREKJOP_FALIURE,
  payload: error,
  error: true
});

export const getVarekjop = brukerId => dispatch => {
  dispatch(getVarekjopRequest());
  return piggavService
    .getVarekjop(brukerId)
    .then(response => {
      const data = response.data.result;
      // dispatch(getVarekjopSuccess(data));
      return Promise.resolve(response);
    })
    .catch(error => {
      // dispatch(getVarekjopFailure(error));
      const errorMessage = apiResponseUtils.errorData(error);
      return Promise.reject(error);
    });
};

const getOblaterForVarekjopRequest = varekjop => ({
  type: GET_OBLATER_FOR_VAREKJOP_REQUEST,
  payload: varekjop
});

const getOblaterForVarekjopSuccess = varekjop => ({
  type: GET_OBLATER_FOR_VAREKJOP_SUCCESS,
  payload: varekjop
});

const getOblaterForVarekjopFailure = error => ({
  type: GET_OBLATER_FOR_VAREKJOP_FALIURE,
  payload: error
});

export const getOblaterForVarekjop = (brukerId, varekjopId) => dispatch => {
  dispatch(getOblaterForVarekjopRequest());
  return piggavService
    .getOblaterForVarekjop(brukerId, varekjopId)
    .then(response => {
      const data = response.data.result;
      dispatch(getOblaterForVarekjopSuccess(data));
    })
    .catch(error => {
      dispatch(getOblaterForVarekjopFailure(error));
    });
};

const getVarekjopByIdRequest = () => ({
  type: GET_VAREKJOP_BY_ID_REQUEST,
  payload: ""
});

const getVarekjopByIdSuccess = varekjop => ({
  type: GET_VAREKJOP_BY_ID_SUCCESS,
  payload: varekjop
});

const getVarekjopByIdFailure = error => ({
  type: GET_VAREKJOP_BY_ID_FALIURE,
  payload: error,
  error: true
});

export const getVarekjopById = (brukerId, varekjopId) => dispatch => {
  dispatch(getVarekjopByIdRequest());
  return piggavService
    .getVarekjopById(brukerId, varekjopId)
    .then(response => {
      const data = response.data.result;
      dispatch(getVarekjopByIdSuccess(data));
      dispatch(getOblaterForVarekjop(brukerId, data.id));
      return Promise.resolve(response);
    })
    .catch(error => {
      dispatch(getVarekjopByIdFailure(error));
      return Promise.reject(error);
      // const errorMessage = apiResponseUtils.errorData(error);
    });
};

export const sendKvittering = (brukerId, varekjopId) => dispatch =>
  piggavService
    .sendKvitteringToMail(brukerId, varekjopId)
    .then(response => {
      if (response.status === 200) {
        dispatch(toastSuccess("Kvittering er nå sendt til din e-postadresse."));
      } else {
        dispatch(toastError("En feil oppsto ved sending av e-postadresse."));
      }
    })
    .catch(error => {
      dispatch(toastError("En feil oppsto ved sending av e-postadresse."));
      Promise.reject(error);
    });

export const deleteVarekjop = (brukerId, varekjopId) => dispatch =>
  piggavService
    .deleteVarekjop(brukerId, varekjopId)
    .then(() => {
      dispatch({ type: DELETE_VAREKJOP_SUCCESS });
      return Promise.resolve();
    })
    .catch(() => Promise.reject());

export const feilKjop = (brukerId, varekjopId, kommentar) => () =>
  piggavService.avbrytFeilkjop(brukerId, varekjopId, kommentar);
