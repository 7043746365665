import { ReactNode, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import "./SkipLink.scss";

interface SkipLinkProps {
  text?: ReactNode;
  jumpToId?: string;
}

export const SKIP_LINK_ID = "skip-link-id";

export default function SkipLink({ text = "Hopp til hovedinnhold", jumpToId = "maincontent" }: SkipLinkProps) {
  const ref = useRef<HTMLDivElement>(document.createElement("div"));

  useEffect(() => {
    const container = ref.current;
    container.classList.add("skip-link");
    container.role = "navigation";
    container.setAttribute("aria-labelledby", SKIP_LINK_ID);
    document.body.prepend(container);
    document.body.tabIndex = -1;
    document.body.focus();
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  return ReactDOM.createPortal(
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <button
      role="navigation"
      id={SKIP_LINK_ID}
      tabIndex={0}
      onClick={() => {
        document.getElementById(jumpToId)?.focus();
      }}
      className="text-button"
    >
      {text}
    </button>,
    ref.current
  );
}
