import React, { ComponentProps } from "react";
import TermsAndConditionsPopup from "features/TermsAndConditions/VilkaarPopupOnlyText";
import OutgoingLink from "../common/BaseComponents/LinkWIcon/LinkWIcon";

// eslint-disable-next-line @typescript-eslint/naming-convention
type FAQ_NO = ComponentProps<"div">;

const FaqNo = React.forwardRef<HTMLDivElement, FAQ_NO>(({ className, ...props }: FAQ_NO, ref) => {
  return (
    <div className={`osg-content ${className}`} {...props} ref={ref}>
      <h3>Jeg er ny bruker. Hvordan registrerer jeg meg?</h3>
      <p>
        Først og fremst: Velkommen som bruker av piggav.no! Som ny bruker registrerer du deg ved å trykke på knappen
        «Registrer deg».
      </p>
      <h3>Hvordan registrerer jeg meg som privatkunde?</h3>
      <ul>
        <li>
          Når du har trykket på «Registrer deg», vil du få opp registreringsskjema under «privatperson» til venstre på
          siden.
        </li>
        <li>Fyll inn e-post og ønsket passord</li>
        <li>
          Huk av dersom du godkjenner vilkår. For å bruke tjenesten må du godkjenne vilkår,{" "}
          <TermsAndConditionsPopup>
            {{
              openModalElement: setIsOpenModal => (
                <button
                  className="reset-style cursor-pointer osg-u-text-is-medium hover:text-blue-hover"
                  onClick={event => {
                    event.preventDefault();
                    setIsOpenModal(true);
                  }}
                >
                  les mer her.
                </button>
              )
            }}
          </TermsAndConditionsPopup>
        </li>
        <li>Huk av dersom du ønsker å motta påminnelser om sesongstart til din registrerte e-post</li>
        <li>
          Nå skal du ha fått en e-post med aktiveringslink. Hvis du ikke finner e-posten i innboksen din kan du sjekke
          om den har havnet i søppelpost.
        </li>
        <li>Åpne e-post med emne «Piggav.no: Aktivering av konto» og trykk på linken «Aktiver din konto»</li>
        <li>
          Du vil nå få beskjed om at kontoen er aktivert og bli videresendt til piggav.no. Hvis ikke du blir videresendt
          automatisk kan du trykke på knappen «Gå til innlogging».
        </li>
        <li>Du kan nå logge deg inn på piggav.no</li>
      </ul>
      <p>
        Hvis du ikke får e-post med aktiveringslink kan du kontakte oss på e-post post@piggav.no eller{" "}
        <OutgoingLink
          icon="new-window"
          target="_"
          href="https://ed24.chat.oslo.kommune.no/ed24cust/chattemplate/Oslo_Bymiljoetaten/index.html"
          className="external-link"
          title="Gå til Bymiljøetatens Chat"
        >
          <strong>chat med oss her.</strong>
          <span className="sr-only">(åpnes i et nytt vindu)</span>
        </OutgoingLink>
      </p>
      <h3>Hvordan registrerer jeg meg som bedriftskunde?</h3>
      <ul>
        <li>
          Når du har trykket på «Registrer deg», vil du se at det står «Er du bedrift?» til høyre på siden. Trykk på
          knappen «Registrer din bedrift her».
        </li>
        <li>
          Fyll inn organisasjonsnummer til din bedrift og trykk på det gule søkeikonet til høyre. Vi henter informasjon
          fra Brønnøysundregistrene og fyller inn flere av feltene for deg.
        </li>
        <li>Fyll inn den informasjonen som mangler, samt ønsket e-post og passord.</li>
        <li>
          Huk av dersom du godkjenner vilkår. For å bruke tjenesten må du godkjenne vilkår,{" "}
          <TermsAndConditionsPopup>
            {{
              openModalElement: setIsOpenModal => (
                <button
                  className="reset-style cursor-pointer osg-u-text-is-medium hover:text-blue-hover"
                  onClick={event => {
                    event.preventDefault();
                    setIsOpenModal(true);
                  }}
                >
                  les mer her.
                </button>
              )
            }}
          </TermsAndConditionsPopup>
        </li>
        <li>Huk av dersom du ønsker å motta påminnelser om sesongstart til din registrerte e-poste</li>
        <li>klikk så på «Opprett konto»</li>
        <li>
          Nå skal du ha fått en e-post med aktiveringslink. Hvis du ikke finner e-posten i innboksen din kan du sjekke
          om den har havnet i søppelpost.
        </li>
        <li>Åpne e-post med emne «Piggav.no: Aktivering av konto» og trykk på linken «Aktiver din konto».</li>
        <li>
          Du vil nå få beskjed om at kontoen er aktivert og bli videresendt til piggav.no. Hvis ikke du blir videresendt
          automatisk kan du trykke på knappen «Gå til innlogging»
        </li>
      </ul>
      <p>
        Hvis du ikke får e-post med aktiveringslink kan du kontakte oss på e-post post@piggav.no eller{" "}
        <OutgoingLink
          icon="new-window"
          target="_"
          href="https://ed24.chat.oslo.kommune.no/ed24cust/chattemplate/Oslo_Bymiljoetaten/index.html"
          className="external-link"
          title="Gå til Bymiljøetatens Chat"
          // alt="Gå til Bymiljøetatens Chat"
        >
          <strong>chat med oss her.</strong>
        </OutgoingLink>
      </p>
      <h3>Hvordan kan jeg aktivere min konto?</h3>
      <p>
        En e-post med aktiveringslink har blitt sendt til e-postadressen du brukte da du registrerte deg. Hvis du ikke
        har fått denne e-posten, sjekk søppelpost. Om den ikke er der, send en e-post til post@piggav.no eller{" "}
        {
          <OutgoingLink
            icon="new-window"
            target="_"
            href="https://ed24.chat.oslo.kommune.no/ed24cust/chattemplate/Oslo_Bymiljoetaten/index.html"
            className="external-link"
            title="Gå til Bymiljøetatens Chat"
            // alt="Gå til Bymiljøetatens Chat"
          >
            <strong>chat med oss her.</strong>
            <span className="sr-only">(Åpnes i en ny fane)</span>
          </OutgoingLink>
        }
        Vi svarer så fort som mulig.
      </p>

      <h3>Jeg husker ikke passordet mitt. Hva gjør jeg nå?</h3>
      <p>
        Klikk på «Logg inn» og deretter glemt passord når du kommer til vinduet for å legge inn epost. Du vil etter å ha
        lagt inn epost, og valgt å bli kontaktet pr epost få tilsendt en epost fra OneLogin (denne er pr nå på engelsk).
        Når du har valgt ett nytt passord og bekreftet dette, kan du logge inn. Dersom du kommer inn på din OneLogin
        portal, kan du trykke på ikonet til Piggav for å komme tilbake til applikasjonen.
      </p>

      <h3>Jeg har registrert meg, men får feilmelding ved aktivering av konto.</h3>

      <p>
        Hvis du får feilmelding når du trykker på aktiveringslinken i e-posten når du registrerer deg, kan det bety:
      </p>
      <ol>
        <li>
          At du allerede har trykket på linken før og kontoen er allerede aktivert. Da kan du logge inn med valgt e-post
          og passord.
        </li>
        <li>
          Hvis det har gått lang tid mellom da du registrerte konto og du klikket på linken, kan det hende at linken er
          utgått (er for gammel). Hvis dette er tilfelle kan du forsøke å registrere deg på nytt for å motta en ny link.
        </li>
      </ol>
      <p>
        Hvis du har fortsatt problemer kan du sende en e-post til post@piggav.no eller{" "}
        {
          <OutgoingLink
            icon="new-window"
            target="_"
            href="https://ed24.chat.oslo.kommune.no/ed24cust/chattemplate/Oslo_Bymiljoetaten/index.html"
            className="external-link"
            title="Gå til Bymiljøetatens Chat"
            // alt="Gå til Bymiljøetatens Chat"
          >
            <strong>chat med oss her.</strong>
            <span className="sr-only">(Åpnes i en ny fane)</span>
          </OutgoingLink>
        }
        Vi svarer som fort som mulig.
      </p>

      <h3>Hvordan kjøper jeg oblater?</h3>
      <ul>
        <li>Gå til «Velg kjøretøy og type oblat» og velg/legg til kjøretøy.</li>
        <li>Velg type oblat og periode/dato og legg til i handlekurv.</li>
        <li>Trykk på «Bekreft kjøp»</li>
        <li>Velg betalingsmåte, trykk så på «Gå til betaling» og følg instruksjonene.</li>
        <li>
          Om betaling ble gjennomført kan du finne dine nye oblater under «Oversikt over mine oblater». Om transaksjonen
          ikke ble gjennomført, kan du prøve på nytt.
        </li>
      </ul>

      <h3>Jeg har kjøpt ny bil. Hvordan kan jeg overføre sesongoblatet fra den gamle bilen til den nye?</h3>
      <ul>
        <li>I menyen oppe til høyre velger du «oversikt over mine oblater»</li>
        <li>Finn oblatet du ønsker å flytte til den nye bilen og trykk på «Se detaljer» til høyre på tabellraden.</li>
        <li>
          Trykk på «Endre» og velg registreringsnummeret til den nye bilen din i listen eller legg til kjøretøyet du
          ønsker å flytte oblatet til. Avslutt ved å lagre oblatet.
        </li>
      </ul>
      <p>
        For å kunne endre selv, må det være samme type kjøretøy (lett til lett eller tung til tung). Hvis du trenger å
        overføre sesongoblatet fra et lett til et tungt kjøretøy, eller motsatt, må du sende oss en e-post.
      </p>
      <p>
        Du kan overføre oblatet bare en gang. Hvis du er nødt til å bytte bil flere ganger må du fylle ut skjemaet som
        kommer opp når du forsøker å endre. Dette blir behandlet av Bymiljøetaten så fort som mulig.
      </p>

      <h3>Jeg vil kansellere oblatet mitt.</h3>

      <p>
        Gå til «Oversikt over mine piggdekkoblater» i menyen oppe til høyre. Velg «Se detaljer» for den bilen du vil
        endre på. Trykk så på «Kanseller».
      </p>
      <p>
        Kun sesongoblater refunderes, men du kan kansellere alle typer oblater. Ved kansellering av sesongoblat,
        refunderes du for gjenværende periode ned til et minimumsbeløp tilsvarende prisen på ett dagsoblat for samme
        biltype (lett eller tung)
      </p>

      <h3>
        Jeg er en bedriftskunde og vil kjøpe sesongoblater til alle eller flere av bedriftens biler. Hvordan gjør jeg
        det?
      </h3>
      <ul>
        <li>
          Trykk «Gå til mengdekjop» og se på valgene for hvordan du kan importere eller registrere bil listen din.
        </li>
        <li>Hvis du trenger hjelp med å importere filen, trykk på «Se eksempel».</li>
        <li>
          Importer eller skriv inn registreringsnummer og trykk på «Legg til i handlekurv». Hvis vi ikke finner den
          nødvendige informasjonen vi trenger om et eller flere kjøretøy, må du oppdatere det selv – trykk på «rediger»
          og velg vektklassen.
        </li>
        <li>
          Når den nødvendige informasjonen er lagt til kan du gå videre. Før du går til betaling kan du kontrollere at
          all informasjonen er korrekt.
        </li>
        <li>Trykk «Gå videre til betaling» og velg betalingsmåte.</li>
      </ul>
      <p>
        Merk: Om du har betalt med faktura vil ikke oblatene være aktive eller gyldige før fakturaen er registrert som
        betalt hos Bymiljøetaten.
      </p>

      <h3>Hva hvis jeg har personlig bilskilt?</h3>
      <p>
        Når du legger til kjøretøy bør du bruke det opprinnelige registreringsnummeret som bilen er registrert under
        ellers kan du risikere å få gebyr.
      </p>

      <h3>Jeg har en utenlandsk bil, må jeg kjøpe piggdekkoblat? Hvordan?</h3>
      <p>
        Ja, alle biler som kjører med piggdekk innenfor Oslo kommune må kjøpe piggdekkoblat. Se på spørsmål «Hvordan
        kjøper jeg oblater?» om du fortsatt lurer på noe.
      </p>

      <p>
        Når du legger til kjøretøy med utenlandske skilt, skal det ikke være spesialtegn (-) eller mellomrom i
        registreringsnummeret.
      </p>

      <h3>Jeg ønsker å endre epost, hvordan gjør jeg det?</h3>
      <p>Du kan endre epost fra din profilside etter å ha logget inn.</p>
    </div>
  );
});
export default FaqNo;
