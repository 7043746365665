import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import { FormattedMessage, useIntl } from "locale/langUtils";
import SelectOblaterForm from "./OrdrelinjeForm";

export default function BuyStickerPage(): JSX.Element {
  const intl = useIntl();
  return (
    <Page
      metaInfo={{
        titlePageName: intl.formatMessage({ id: "buystickers.meta.title" }),
        titleDescription: intl.formatMessage({ id: "buystickers.meta.title.description" })
      }}
    >
      {{
        heading: {
          pageTitle: <FormattedMessage id="buystickers.page.heading" />
        },
        pageContent: (
          <Section colorClass="">
            <SelectOblaterForm />
          </Section>
        )
      }}
    </Page>
  );
}
