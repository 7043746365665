import React, { useContext, useMemo } from "react";
import { getMonthsInInterval } from "utils/dateFnsUtils";
import { Configurations } from "interfaces";
import { useIntl } from "react-intl";
import Select from "features/common/BaseComponents/Select/CustomReactSelect";
import { useSelector } from "react-redux";
import { isBefore } from "date-fns";

import { OrdreLinjeContext } from "./OrdrelinjeForm";

interface SelectMonthProps {
  onChange?: (value: string) => void;
}

export default function SelectMonth({ onChange }: SelectMonthProps): JSX.Element {
  const { formatDate, formatMessage } = useIntl();

  const { dispatch: dispatchOrdrelinjeformAction } = useContext(OrdreLinjeContext);

  const { sesongSlutt, sesongStart } = useSelector<any, Configurations>(state => state.konfigurasjon);

  const startMonth = useMemo(() => {
    const now = new Date();
    const sesStart = new Date(sesongStart.verdi);
    return isBefore(now, sesStart) ? sesStart : now;
  }, [sesongStart.verdi]);

  const availableMonthsLeft = useMemo(() => {
    try {
      const monthsLeft = getMonthsInInterval(startMonth, sesongSlutt.verdi).map(d => ({
        label: formatDate(d, { year: "numeric", month: "long" }),
        value: d.toISOString()
      }));
      return monthsLeft;
    } catch {
      return [];
    }
  }, [formatDate, startMonth, sesongSlutt.verdi]);

  return (
    <>
      <Select
        id="select-month"
        placeholder={formatMessage({
          id: "buystickers.addOrdrelinjeFormStartPlaceholder"
        })}
        isSearchable={false}
        onChange={e => {
          const dateString = (e as any).value;
          dispatchOrdrelinjeformAction({
            type: "SET_VALID_FROM_DATE_TIME",
            payload: dateString
          });
          onChange?.(dateString);
        }}
        options={availableMonthsLeft}
      />
    </>
  );
}
