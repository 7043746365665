import React from "react";
import { OSGPattern, ColoredOSGPattern } from "../../interfaces";
import "./Button.scss";

type Modifiers = "circle" | "outline" | "ghost" | "active";
type Colors = "blue-dark" | "blue-light" | "green-dark" | "green-light" | "red" | "yellow";

export interface ButtonProps
  extends OSGPattern<undefined, Modifiers>,
    ColoredOSGPattern<Colors>,
    React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: "big" | "normal" | "small";
  iconPosition?: "left" | "right" | "";
}

/**
 * Represents an OSG button
 * active modifyer (only works for together with the outline modifier at the moment)
 */
// eslint-disable-next-line import/prefer-default-export
function Button({
  className,
  children,
  variant = "default",
  modifier = "",
  colorOption = "blue-dark",
  size = "normal",
  iconPosition = "",
  ...rest
}: ButtonProps): JSX.Element {
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      className={`
        osg-button
        osg-v-${variant} ${modifier ? `osg-button--${modifier}` : ""}
        osg-button--${colorOption}
        osg-button--${size}
        osg-button--icon-${iconPosition}
        osg-u-text-4
        ${className ?? ""}
      `}
      {...rest}
      style={{ lineHeight: "0px" }}
    >
      {children}
    </button>
  );
}

export default Button;
