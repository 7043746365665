import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import { Vilkaar, Samtykke } from "interfaces";
import { isEmpty } from "lodash-es";
import { ApplicationState } from "state/reducers";
import * as samtykkeService from "api/samtykkeService";

// Selectors
export function getState(state: ApplicationState): State {
  return state.vilkaarAndSamtykkeState;
}

// State
export interface State {
  isFetchingSamtykke: boolean;
  isFetchingVilkaar: boolean;
  initialized: boolean;
  acceptedSamtykke: boolean;
  samtykke: Samtykke | undefined;
  vilkaar: Vilkaar | undefined;
}

const initialState: State = {
  isFetchingSamtykke: false,
  isFetchingVilkaar: false,
  initialized: false,
  acceptedSamtykke: false,
  samtykke: undefined,
  vilkaar: undefined
};

// Action types
const SET_INITIALIZED = "uge-publikum-client/samtykke/SET_IS_INITIALIZED";
const SET_IS_LOADING = "uge-publikum-client/samtykke/SET_IS_LOADING";
const SET_VILKAAR = "uge-publikum-client/samtykke/SET_VILKAAR";
const SET_SAMTYKKE = "uge-publikum-client/samtykke/SET_SAMTYKKE";
const SET_ACCEPTED_VILKAAR = "uge-publikum-client/samtykke/SET_ACCEPTED_VILKAAR";
const CLEAR_SAMTYKKE_INFO = "uge-publikum-client/samtykke/CLEAR_SAMTYKKE_INFO";
const SET_IS_FETCHING_SAMTYKKE = "uge-publikum-client/samtykke/SET_IS_FETCHING_SAMTYKKE";
const SET_IS_FETCHING_VILKAAR = "uge-publikum-client/samtykke/SET_IS_FETCHING_VILKAAR";

// Action object types
export interface SetInitialized extends Action<typeof SET_INITIALIZED> {
  readonly initialized: boolean;
}

export interface SetIsLoading extends Action<typeof SET_IS_LOADING> {
  readonly isLoading: boolean;
}

export interface SetVilkaar extends Action<typeof SET_VILKAAR> {
  readonly vilkaar: Vilkaar;
}

export interface SetSamtykke extends Action<typeof SET_SAMTYKKE> {
  readonly samtykke: Samtykke | undefined;
}

export interface SetIsFetchingSamtykke extends Action<typeof SET_IS_FETCHING_SAMTYKKE> {
  readonly isFetchingSamtykke: boolean;
}

export interface SetIsFetchingVilkaar extends Action<typeof SET_IS_FETCHING_VILKAAR> {
  readonly isFetchingVilkaar: boolean;
}

export interface SetHasAcceptedVilkaar extends Action<typeof SET_ACCEPTED_VILKAAR> {
  readonly acceptedSamtykke: boolean;
}

export type ClearSamtykkeInfo = Action<typeof CLEAR_SAMTYKKE_INFO>;

export type ActionCreatorsTypes =
  | SetInitialized
  | SetIsLoading
  | SetVilkaar
  | SetSamtykke
  | SetHasAcceptedVilkaar
  | ClearSamtykkeInfo
  | SetIsFetchingSamtykke
  | SetIsFetchingVilkaar;

// Action Creators
export function setInitialized(initialized: boolean): ActionCreatorsTypes {
  return {
    type: SET_INITIALIZED,
    initialized
  };
}

export function setIsLoading(isLoading: boolean): ActionCreatorsTypes {
  return {
    type: SET_IS_LOADING,
    isLoading
  };
}

export function setVilkaar(v: Vilkaar): ActionCreatorsTypes {
  return {
    type: SET_VILKAAR,
    vilkaar: v
  };
}

export function setSamtykke(samtykke: Samtykke | undefined): ActionCreatorsTypes {
  return {
    type: SET_SAMTYKKE,
    samtykke
  };
}

export function setHasAcceptedVilkaar(acceptedSamtykke: boolean): ActionCreatorsTypes {
  return {
    type: SET_ACCEPTED_VILKAAR,
    acceptedSamtykke
  };
}

export function setIsFetchingSamtykke(isFetchingSamtykke: boolean): ActionCreatorsTypes {
  return {
    type: SET_IS_FETCHING_SAMTYKKE,
    isFetchingSamtykke
  };
}

export function setIsFetchingVilkaar(isFetchingVilkaar: boolean): ActionCreatorsTypes {
  return {
    type: SET_IS_FETCHING_VILKAAR,
    isFetchingVilkaar
  };
}

export function clearSamtykkeAndVilkaarInfo(): ActionCreatorsTypes {
  return {
    type: CLEAR_SAMTYKKE_INFO
  };
}
export function getVilkaarData(
  locale: string
): ThunkAction<Promise<Vilkaar[] | undefined>, ApplicationState, null, ActionCreatorsTypes> {
  return async (dispatch, getStates) => {
    // eslint-disable-next-line no-shadow
    const { vilkaar, isFetchingVilkaar } = getState(getStates());
    if (isFetchingVilkaar) return undefined;
    const l = locale === "nb" ? "nb-NO" : "en-US";
    if (vilkaar?.vilkaarTekster[0]?.spraak === l) return [vilkaar];

    dispatch(setIsFetchingVilkaar(true));
    try {
      const response = await samtykkeService.getVilkaar(l);
      dispatch(setVilkaar(response.data.result[0]));
      return response.data.result;
    } catch {
      return [];
    } finally {
      dispatch(setIsFetchingVilkaar(false));
    }
  };
}

export function getSamtykke(
  brukerId: string
): ThunkAction<Promise<Samtykke | undefined>, ApplicationState, null, ActionCreatorsTypes> {
  // eslint-disable-next-line consistent-return
  return async (dispatch, getStates) => {
    const {
      samtykke,
      isFetchingSamtykke,
      isFetchingVilkaar,
      // eslint-disable-next-line no-shadow
      vilkaar
    } = getState(getStates());
    if (samtykke) return samtykke;
    if (isFetchingSamtykke || isFetchingVilkaar) return undefined;
    if (!vilkaar) return undefined;
    dispatch(setIsFetchingSamtykke(true));
    try {
      const response = await samtykkeService.getSamtykke(brukerId);
      if (isEmpty(response.data.result)) {
        dispatch(
          setSamtykke({
            brukerId: "",
            id: "",
            klientId: "",
            opprettetDato: "",
            sistEndretDato: "",
            vilkaarId: ""
          })
        );
        dispatch(setHasAcceptedVilkaar(false));
        return undefined;
      }

      // eslint-disable-next-line @typescript-eslint/no-shadow
      const samtykke = response.data.result[0];
      dispatch(setSamtykke(samtykke));
      return response.data.result[0];
    } catch {
      // dispatch(vilkaarError(error));
      // dispatch(toastError(error));
      return undefined;
    } finally {
      dispatch(setIsFetchingSamtykke(false));
    }
  };
}

export function setAcceptsVilkaar(
  vilkaarId: string,
  brukerId: string
): ThunkAction<Promise<Samtykke>, ApplicationState, null, ActionCreatorsTypes> {
  return async dispatch => {
    dispatch(setIsLoading(true));
    try {
      const response = await samtykkeService.postSamtykke(brukerId, vilkaarId);
      dispatch(setSamtykke(response.data.result));
      dispatch(setHasAcceptedVilkaar(true));
      return response.data.result;
    } catch (e) {
      return await Promise.reject(e);
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function trekkAcceptsSamtykke(
  samtykkeId: string
): ThunkAction<Promise<void>, ApplicationState, null, ActionCreatorsTypes> {
  return async dispatch => {
    dispatch(setIsLoading(true));
    try {
      await samtykkeService.deleteSamtykke(samtykkeId);
      dispatch(setHasAcceptedVilkaar(false));
      dispatch(clearSamtykkeAndVilkaarInfo());
    } catch (error) {
      // dispatch(vilkaarError(error));
      // dispatch(toastError(error));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

// Reducer
export default function reducer(
  // eslint-disable-next-line @typescript-eslint/default-param-last
  state: State = initialState,
  action: ActionCreatorsTypes
): State {
  switch (action.type) {
    case SET_INITIALIZED:
      return { ...state, initialized: action.initialized };
    case SET_VILKAAR:
      return { ...state, vilkaar: action.vilkaar };
    case SET_SAMTYKKE:
      return { ...state, samtykke: action.samtykke };
    case SET_ACCEPTED_VILKAAR:
      return { ...state, acceptedSamtykke: action.acceptedSamtykke };
    case CLEAR_SAMTYKKE_INFO:
      return {
        ...state,
        acceptedSamtykke: false,
        initialized: false,
        samtykke: initialState.samtykke,
        isFetchingSamtykke: false,
        isFetchingVilkaar: false
      };
    case SET_IS_FETCHING_VILKAAR:
      return { ...state, isFetchingVilkaar: action.isFetchingVilkaar };
    case SET_IS_FETCHING_SAMTYKKE:
      return { ...state, isFetchingSamtykke: action.isFetchingSamtykke };
    default:
      return state;
  }
}
