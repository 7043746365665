import { FormattedMessage } from "locale/langUtils";
import CurrencyFormat from "locale/CurrencyFormat";
import DateTimeFormat from "locale/DateTimeFormat";
import { map } from "lodash-es";
import { VarekjopWSticker } from "./ReceiptPage";

interface ReceiptMainContentProps {
  varekjop: VarekjopWSticker;
  oblattyper: any;
  kjoretoy: any;
  className?: string;
}
export function ReceiptMainContent({ varekjop, oblattyper, kjoretoy, className}: ReceiptMainContentProps) {
  return (
      <div className={className}>
        <table className="kvittering-table-borderless">
          <thead>
            <tr>
              <th>
                <FormattedMessage id="receiptpage.receiptThregnumber" />
              </th>
              <th>
                <FormattedMessage id="receiptpage.receiptThType" />
              </th>
              <th>
                <FormattedMessage id="receiptpage.receiptThValidFrom" />
              </th>
              <th className="right-align">
                <FormattedMessage id="receiptpage.receiptThPrice" />
              </th>
            </tr>
          </thead>
          <tbody>
            {varekjop?.oblater &&
              oblattyper &&
              kjoretoy &&
              map(varekjop?.oblater, oblat => {
                return (
                  <tr key={oblat.oblat.id} className="ordrelinje-row">
                    <td>
                      {oblat.oblat.kjoretoy
                        ? `${oblat.oblat.kjoretoy.registreringsnummer}   ${oblat.oblat.kjoretoy.beskrivelse ? oblat.oblat.kjoretoy.beskrivelse : ""}`
                        : ""}
                    </td>
                    <td>
                      <FormattedMessage id={oblat.name} defaultMessage="Ukjent oblattype" />
                    </td>
                    <td>
                      <DateTimeFormat value={oblat.oblat.gyldigFra} />
                      {` - `}
                      <DateTimeFormat value={oblat.oblat.gyldigTil} />
                    </td>

                    <td className="right-align">
                      <CurrencyFormat value={oblat.oblat.oblattype?.pris ?? 0} />
                    </td>
                  </tr>
                );
              })}
            <tr className="total-amount-row">
              <td className="right-align text-2" colSpan={4}>
                <FormattedMessage
                  id="receiptpage.receiptTableFooterSum"
                  values={{
                    sum: <CurrencyFormat value={varekjop?.totalsum ?? 0} />
                  }} />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
  );
}
