import { useMemo } from "react";
import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import { FormattedMessage, useIntl } from "locale/langUtils";
import useHandlekurv from "features/Hooks/HandlekurvHook";
import ShoppingCartLineItems from "features/ShoppingCart/ShoppingCartLineItems";
import useUserHook from "features/Hooks/UserHook";
import PrivateUserCheckout from "./PrivateUserCheckout";
import CompanyUserCheckout from "./CompanyUserCheckout";
import ResepsjonsUserCheckout from "./ResepsjonsUserCheckout";
import { MessageBoxWIcon, MessageBoxWIconProps } from "features/common/ComposedComponents";
import { isExpired } from "utils/oblatHelpers";

export default function CheckoutPage(): JSX.Element {
  const { shoppingCartItems, removeOrdrelinjeFromBasket } = useHandlekurv();

  const { customerType, userType } = useUserHook();
  const intl = useIntl();
  const CheckoutElement = useMemo(() => {
    const hasExpiredItems = shoppingCartItems.filter(item => isExpired(item)).length > 0;
    if (hasExpiredItems) {
      return (props: Omit<MessageBoxWIconProps, "icon">) => (
        <div
          className={`flex flex-col px-4 w-full tablet:w-4/12 bg-grey self-start sticky top-5`}
          style={{ minHeight: "400px" }}
        >
          <h4 className="flex justify-center text-1 border-0 border-b-2 border-solid border-grey-dark py-4">
            <FormattedMessage id="checkoutpage.heading" />
          </h4>
          <div className="border-0 border-b-2 border-solid border-grey-dark  py-4">
            <MessageBoxWIcon icon="exclamation-mark-circle" iconPosition="right" type="danger">
              <p>
                <FormattedMessage id="checkoutpage.expiredItemsMessage" />
              </p>
            </MessageBoxWIcon>
          </div>
        </div>
      );
    }
    switch (true) {
      case userType === "piggav-resepsjon":
        return ResepsjonsUserCheckout;
      case customerType === "Privatkunde":
        return PrivateUserCheckout;
      case customerType === "Bedriftkunde":
        return CompanyUserCheckout;
      default:
        return (props: Omit<MessageBoxWIconProps, "icon">) => (
          <MessageBoxWIcon {...props} icon="exclamation-mark-circle" iconPosition="left" type="danger">
            Oida, her var det noe som gikk galt. Vi klarte ikke å hente nødvendig informasjon for å gå videre. Vennligst
            ta kontakt med Bymiljøetaten på epost <a href="mailto:post@piggav.no">post@piggav.no</a>, eller telefon{" "}
            <a href="tel:+4723482030">23 48 20 30</a>
          </MessageBoxWIcon>
        );
    }
  }, [customerType, userType, shoppingCartItems]);

  return (
    <Page
      metaInfo={{
        titlePageName: intl.formatMessage({ id: "checkoutpage.meta.title" }),
        titleDescription: intl.formatMessage({ id: "checkoutpage.meta.title.description" })
      }}
    >
      {{
        heading: { pageTitle: <FormattedMessage id="checkoutpage.heading" /> },
        pageContent: (
          <Section colorClass="bg-white">
            <div className="flex flex-col tablet:flex-row">
              <div className="w-100 tablet:w-8/12 tablet:mr-3">
                <ShoppingCartLineItems
                  style={{ minHeight: "175px" }}
                  shoppingCartItems={shoppingCartItems}
                  removeItem={removeOrdrelinjeFromBasket}
                />
              </div>
              <CheckoutElement
                style={{ position: "sticky", top: "110px" }}
                className="w-full tablet:w-4/12 bg-grey self-start sticky top-5"
              />
            </div>
          </Section>
        )
      }}
    </Page>
  );
}
