import { useEffect, useState } from "react";
import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import InputElement from "features/common/BaseComponents/InputElement/Inputlement";
import { FormattedMessage } from "locale/langUtils";
import LinkAsButton from "features/common/BaseComponents/LinkAsButton/LinkAsButton";
import { Button as ButtonWSpinner, InputFormField } from "features/common/ComposedComponents";
import { Kjoretoy } from "interfaces";
import { useForm } from "react-hook-form";
import Select from "features/common/BaseComponents/Select/CustomReactSelect";
import { VehicleWeightId } from "appConstants";
import { useIntl } from "react-intl";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import { useReduxDispatch } from "utils/Storeutils";
import { updateKjoretoy } from "state/Kjoretoy/kjoretoyActions";
import { useSelector } from "react-redux";
import { getState } from "state/Auth/Auth";

interface ChangeVehicleDialogueProps {
  vehicle: Kjoretoy;
}

export default function ChangeVehicleDialogue({ vehicle }: ChangeVehicleDialogueProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [successMessage, setSuccessMessage] = useState<undefined | string>(undefined);
  const { formatMessage } = useIntl();

  const dispatch = useReduxDispatch();

  const { userInfo } = useSelector(getState);

  const { errors, register, handleSubmit, reset, setError, watch } = useForm<{
    numberplate: string;
    wightClass: { label: string; value: string };
    description: string;
  }>({
    mode: "onSubmit",
    defaultValues: {
      description: vehicle.beskrivelse ?? "",
      wightClass: {
        label: formatMessage({
          id: `common.vehcile.weightClass.${vehicle.kjoretoytypeId === VehicleWeightId.Light ? "light" : "heavy"}`
        }),
        value: vehicle.kjoretoytypeId.toString()
      },
      numberplate: vehicle.registreringsnummer
    }
  });

  const { description } = watch();

  const toggle = () => {
    if (isOpen) reset({description: vehicle.beskrivelse ?? description});
    setIsOpen(prev => !prev);
  };

  const handleUpdate = handleSubmit(async ({ description: beskrivelse }) => {
    try {
      setIsUpdating(true);
      await dispatch(
        updateKjoretoy(userInfo?.brukerId, vehicle.id, {
          beskrivelse,
          kjoretoytypeId: vehicle.kjoretoytypeId,
          registreringsnummer: vehicle.registreringsnummer
        })
      );
      setSuccessMessage("changeVehicleDialogue.success");
    } catch (e) {
      setError("description", {
        type: "update",
        message: "changeVehicleDialogue.error.updating"
      });
    } finally {
      setIsUpdating(false);
    }
  });

  return (
    <>
      <LinkAsButton
        aria-label={formatMessage(
          { id: "changeVehicleDialogue.button.openDialogue.aria" },
          { vehicle: vehicle.registreringsnummer }
        )}
        onClick={toggle}
      >
        <FormattedMessage id="changeVehicleDialogue.button.openDialogue" />
      </LinkAsButton>
      <Modal isOpen={isOpen} onHide={toggle}>
        <ModalHeader>
          {{
            heading: <FormattedMessage id="changeVehicleDialogue.modal.heading" />
          }}
        </ModalHeader>
        <ModalSection>
          <form onSubmit={handleUpdate} className="flex flex-col tablet:w-1/2">
            <InputElement
              name="numberplate"
              ref={register}
              readOnly
              className="mb-3"
              label={<FormattedMessage id="common.vehcile.numberplate" />}
              value={vehicle.registreringsnummer}
            />
            <Select
              isDisabled
              name="wightClass"
              ref={register}
              className="mb-3"
              label={<FormattedMessage id="common.vehcile.label.weightClass" />}
              placeholder={formatMessage({
                id: "confirmstickersbulkpage.select.placeholder.vehicleWeight"
              })}
              value={{
                label: formatMessage({
                  id: `common.vehcile.weightClass.${
                    vehicle.kjoretoytypeId === VehicleWeightId.Light ? "light" : "heavy"
                  }`
                }),
                value: vehicle.kjoretoytypeId.toString()
              }}
              options={[
                {
                  label: formatMessage({
                    id: "common.vehcile.weightClass.light"
                  }),
                  value: VehicleWeightId.Light as unknown as string
                },
                {
                  label: formatMessage({
                    id: "common.vehcile.weightClass.heavy"
                  }),
                  value: VehicleWeightId.Heavy as unknown as string
                }
              ]}
            />
            <InputFormField
              error={
                errors.description && errors.description.type !== "update" ? (
                  <FormattedMessage id={errors.description?.message ?? " "} />
                ) : undefined
              }
              name="description"
              ref={register}
              label={<FormattedMessage id="changeVehicleDialogue.label.description" />}
            />
            <MessageBox className="mb-3" type="danger" visible={errors.description?.type === "update"}>
              <FormattedMessage
                id={errors.description?.message ?? " "}
                defaultMessage="Noe skjedde da vi prøve å oppdatere informasjon, vennligst prøv igjen."
              />
            </MessageBox>
            <MessageBox className="mb-3" type="success" visible={!!successMessage}>
              <FormattedMessage
                id={successMessage ?? " "}
                defaultMessage="Informasjon om kjøretøyet er nå oppdatert."
              />
            </MessageBox>
            <ButtonWSpinner
              type="submit"
              onClick={handleUpdate}
              className="tablet:self-end"
              icon="save"
              isLoading={isUpdating}
              disabled={isUpdating || (vehicle.beskrivelse ?? "") === description}
            >
              <FormattedMessage id="common.save" />
            </ButtonWSpinner>
          </form>
        </ModalSection>
      </Modal>
    </>
  );
}
