/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useRef, useEffect, SyntheticEvent, forwardRef } from "react";
import { InputDate, Icon } from "features/common/OSG";
import { parse, format, isValid, getMinutes, setMinutes, setHours, getHours } from "date-fns";
import { IconTypes } from "features/common/OSG/Utilities/Icons/Icon";
import "./InputTimePicker.scss";
import { StatePropType } from "../Interface";

interface InputTimePicker extends Omit<React.HTMLProps<HTMLInputElement>, "onChange">, StatePropType {
  minTime: Date;
  maxTime: Date;
  selectedTime: string | Date;
  value?: string;
  onChange?: (date: Date) => void;
  rootClassName?: string;
}

const getTimeObjectHelper = (d: Date | string) => {
  const date = new Date(d);
  return {
    currentDateTime: date,
    hour: format(date, "HH"),
    minutes: format(date, "mm"),
    valueString: format(date, "HH:mm")
  };
};

const getNumberInBounds = (lBound: number, rBound: number, numToCeck: number) => {
  switch (true) {
    case numToCeck < lBound:
      return rBound;

    case numToCeck > rBound:
      return lBound;

    default:
      return numToCeck;
  }
};

function TimePicker(
  { minTime, maxTime, value, selectedTime, onChange, rootClassName, state, ...rest }: InputTimePicker,
  ref: React.Ref<HTMLInputElement>
): JSX.Element {
  const [inputTextValue, setInputTextValue] = useState(format(new Date(selectedTime), "HH:mm"));
  // Time internal to the component
  const [internalTime, setInternalTime] = useState(getTimeObjectHelper(selectedTime));

  useEffect(() => {
    setInternalTime(getTimeObjectHelper(selectedTime));
  }, [selectedTime]);

  const onTimeInputChange = (date: React.FormEvent<HTMLInputElement>) => {
    const newTimeDate = parse(date.currentTarget.value, "H:mm", new Date(selectedTime));
    if (isValid(newTimeDate)) {
      onChange?.(newTimeDate);
    }
    setInputTextValue(date.currentTarget.value);
  };

  /** Updates the time by calling callback. Updates on valid input or when mouseup or keypressed */
  const callOnChangeCallbacks = () => {
    if (isValid(internalTime.currentDateTime)) {
      onChange?.(internalTime.currentDateTime);
    }
  };

  const onTimeControlSelect = (newTime: Date) => {
    setInputTextValue(format(newTime, "H:mm"));
    // onChange?.(newTime);
  };

  const onMinuteChange = (amount: number) => {
    setInternalTime(prev => {
      const prevMinPlusAmount = getMinutes(prev.currentDateTime) + amount;
      const newMin = getNumberInBounds(0, 59, prevMinPlusAmount);
      const newTime = setMinutes(prev.currentDateTime, newMin);
      onTimeControlSelect(newTime);
      return getTimeObjectHelper(newTime);
    });
  };

  const onHourChange = (amount: number) => {
    setInternalTime(prev => {
      const prevHourPlusAmount = getHours(prev.currentDateTime) + amount;
      const newHour = getNumberInBounds(0, 23, prevHourPlusAmount);
      const newTime = setHours(prev.currentDateTime, newHour);
      onTimeControlSelect(newTime);
      return getTimeObjectHelper(newTime);
    });
  };

  const onKeyOrMouseBtnUp = () => {
    callOnChangeCallbacks();
  };

  return (
    <div className={`input-timecomponent-wrapper ${rootClassName ?? ""}`}>
      <InputDate
        className={`time-picker-input state-${state}`}
        icon="clock"
        onChange={onTimeInputChange}
        value={value ?? inputTextValue}
        {...rest}
        ref={ref}
      />
      <div tabIndex={-1} className="time-picker-container">
        <div className="control-container">
          <ControlButton key="h-up" id="button-hour-up" icon="up" fn={() => onHourChange(1)} fnUp={onKeyOrMouseBtnUp} />
          <span>{internalTime.hour}</span>
          <ControlButton key="h-down" icon="down" fn={() => onHourChange(-1)} fnUp={onKeyOrMouseBtnUp} />
        </div>
        <div>:</div>
        <div className="control-container">
          <ControlButton key="m-up" icon="up" fn={() => onMinuteChange(1)} fnUp={onKeyOrMouseBtnUp} />
          <span>{internalTime.minutes}</span>
          <ControlButton key="m-down" icon="down" fn={() => onMinuteChange(-1)} fnUp={onKeyOrMouseBtnUp} />
        </div>
      </div>
    </div>
  );
}

const ControlButton = ({
  icon,
  fn,
  fnUp,
  id,
  key
}: {
  icon: "up" | "down";
  fn: Function;
  fnUp: Function;
} & JSX.IntrinsicElements["i"]) => {
  // Used to clear interval timer between onMouseDown and onMouseUp events.
  const timerId = useRef(0);
  const intervalId = useRef(0);
  const keyDown = useRef(false);

  const onkeyDown = (e: React.KeyboardEvent) => {
    if ((e.key.toLocaleLowerCase() === "enter" || e.key.toLocaleLowerCase() === "space") && !keyDown.current) {
      keyDown.current = true;
      intervalId.current = window.setInterval(fn, 100);
    }
  };

  const onShortPress = () => {
    fn();
  };

  const onLongPressDown = (ms: number) => {
    intervalId.current = window.setInterval(fn, ms);
  };

  const onMouseDown = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    e.stopPropagation();
    if (e.button === 0)
      timerId.current = window.setTimeout(() => {
        onLongPressDown(100);
      }, 300);
  };

  const onTouchDown = (event: React.TouchEvent<HTMLElement>) => {
    // if (shouldPreventDefault && event.currentTarget) {
    //   event.currentTarget.addEventListener("touchend", preventDefault, {
    //     passive: false,
    //   });
    //   if (event.touches.length < 2 && event.preventDefault) {
    //     event.preventDefault();
    //   }
    // }
    timerId.current = window.setTimeout(() => {
      onLongPressDown(100);
    }, 300);
  };

  const clear = (e: SyntheticEvent) => {
    window.clearInterval(intervalId.current);
    window.clearTimeout(timerId.current);
    keyDown.current = false;
    fnUp();
  };

  return (
    <Icon
      key={key}
      id={id}
      tabIndex={0}
      role="button"
      onClick={() => onShortPress()}
      onKeyDown={e => onkeyDown(e)}
      onKeyUp={clear}
      onMouseDown={e => onMouseDown(e)}
      onTouchStart={e => onTouchDown(e)}
      onMouseUp={clear}
      onMouseLeave={clear}
      onTouchEnd={clear}
      icon={`chevron-thin-${icon}` as IconTypes}
    />
  );
};

export default forwardRef(TimePicker);
