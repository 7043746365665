import React from "react";
import { Icon } from "features/common/OSG";
import "./XButton.scss";

export default function XButton({
  children,
  className,
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement>): JSX.Element {
  return (
    <button type="button" className={`x-button ${className}`} {...rest}>
      {children}
      <Icon icon="close" className="hover:color-blue-hover" />
    </button>
  );
}
