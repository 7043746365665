import { useLayoutEffect, useRef, useState } from "react";

interface ModalHookProps {
  initialState: {
    isOpen?: boolean;
  };
  closeOnClickOutside: boolean;
  exludeList?: HTMLElement[];
  closeCallback?: Function;
}

export default function ModalHook<T extends HTMLElement>({
  initialState: { isOpen = false },
  closeOnClickOutside = false,
  exludeList = [] as HTMLElement[],
  closeCallback
}: ModalHookProps) {
  const lastFocusableElement = useRef<HTMLElement | null>(null);
  const focusableElements = useRef<NodeListOf<HTMLElement> | undefined | null>();
  const modalRef = useRef<T>(null);
  const prevOverflow = useRef(document.body.style.overflow);
  const [isModalOpen, setIsModalOpen] = useState(isOpen);

  // Register all focusable elements
  useLayoutEffect(() => {
    focusableElements.current = modalRef.current?.querySelectorAll<HTMLElement>(
      'button:not([disabled]), input:not([disabled]), textarea:not([disabled]), area:not([disabled]),select:not([disabled]), [tabindex="0"], a[href], area[href],  iframe, object, embed, *[tabindex], *[contenteditable]'
    );
  }, [modalRef]);

  // Focus trap
  useLayoutEffect(() => {
    const modalElement = modalRef.current;
    // Handles keypress and make sure focus is trapped while dialog is open.
    const keyDown = (event: KeyboardEvent) => {
      if (!isModalOpen) return;
      const focusableElCount = focusableElements.current?.length ?? 0;
      const firstFocusableEl = focusableElements.current?.[0];
      const lastFocusableEl = focusableElements.current?.[focusableElCount - 1];
      switch (event.key.toUpperCase()) {
        case "ESCAPE":
          setIsModalOpen(false);
          break;
        case "TAB":
          // Only one focusable element, do nothing
          if (focusableElCount < 2 || !focusableElements.current) {
            event.preventDefault();
            break;
          }
          if (event.shiftKey && firstFocusableEl === document.activeElement) {
            lastFocusableEl?.focus();
            event.preventDefault();
          } else if (!event.shiftKey && lastFocusableEl === document.activeElement) {
            firstFocusableEl?.focus();
            event.preventDefault();
          }
          break;
        default:
          break;
      }
    };
    modalElement?.addEventListener("keydown", keyDown);

    return () => {
      modalElement?.removeEventListener("keydown", keyDown);
    };
  }, [isModalOpen]);

  // Add listeners
  useLayoutEffect(() => {
    const modalElement = modalRef.current;

    const documentClickListener = (e: MouseEvent) => {
      if (!modalElement) return;
      if (!modalElement.contains(e.target as Node) && e.target !== modalElement && closeOnClickOutside && isModalOpen)
        setIsModalOpen(false);
    };

    document.addEventListener("click", documentClickListener, false);
    return () => {
      document.removeEventListener("click", documentClickListener, false);
    };
  }, [closeOnClickOutside, exludeList, isModalOpen, modalRef]);

  // Set focusinside
  useLayoutEffect(() => {
    // store last focusable element and set focus on it when modal closes
    if (isModalOpen) {
      lastFocusableElement.current = document.activeElement as HTMLElement;
      focusableElements.current?.[0]?.focus();
    } else {
      lastFocusableElement.current?.focus();
    }
  }, [isModalOpen]);

  // Remove scroll on body element, and put it back on close
  useLayoutEffect(() => {
    if (isModalOpen) {
      prevOverflow.current = document.body.style.overflow;
      document.body.style.setProperty("overflow", "hidden");
    }
    return () => {
      document.body.style.removeProperty("overflow");
      closeCallback?.();
    };
  }, [isModalOpen, closeCallback]);

  return {
    modalRef,
    setIsModalOpen,
    modalState: {
      isModalOpen
    }
  };
}
