import { createStore, applyMiddleware, AnyAction } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer, { ApplicationState } from "./reducers";

const middlewares = applyMiddleware(thunk as ThunkMiddleware<ApplicationState, AnyAction, undefined>);

const composeEnhancers = composeWithDevTools(middlewares);

const store = createStore(rootReducer, process.env.NODE_ENV === "production" ? middlewares : composeEnhancers);

export default store;
