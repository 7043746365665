import React from "react";
import { Button, ButtonProps, Icon, IconTypes } from "features/common/OSG";
import SpinnerCircle from "features/common/BaseComponents/Spinner/SpinnerCircle";
import "./ButtonWIconSpinner.scss";
import { FormattedMessage } from "locale/langUtils";

interface ButtonWSpinnerProps extends Omit<ButtonProps, "iconPosition"> {
  isLoading?: boolean;
  icon?: IconTypes;
}

export default function ButtonWSpinner({
  isLoading = false,
  className,
  children,
  modifier,
  "aria-busy": ariaBusy,
  icon,
  ...rest
}: ButtonWSpinnerProps): JSX.Element {
  return (
    <Button
      className={`button-with-icon-spinner ${modifier ?? ""} ${isLoading ? "loading" : ""} ${icon ? "show-icon" : ""} ${
        className ?? ""
      }`}
      modifier={modifier}
      iconPosition={modifier === "circle" ? "" : "right"}
      aria-busy={ariaBusy ?? isLoading}
      {...rest}
    >
      {children}{" "}
      {isLoading && (
        <span className="sr-only" aria-live="polite">
          <FormattedMessage id="common.button.loading" />
        </span>
      )}
      {icon && !isLoading && <Icon icon={icon} />}
      {isLoading && <SpinnerCircle className="button-with-icon-spinner--spinner osg-icon" />}
    </Button>
  );
}
