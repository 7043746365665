import { useState } from "react";
import { useReduxDispatch } from "utils/Storeutils";
import { useHistory } from "react-router-dom";
import useSamtykkeHook from "features/Hooks/SamtykkeHook";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import { FormattedMessage } from "locale/langUtils";
import InputField from "features/common/ComposedComponents/Inputfield/Inputfield";
import { Button } from "features/common/ComposedComponents";
import { Checkbox } from "features/common/OSG";
import VilkaarPopupOnlyText from "features/TermsAndConditions/VilkaarPopupOnlyText";
import { passwordRegex } from "utils/validationUtils";
import { setAcceptsVilkaar } from "state/TermsAndCondition/SamtykkeAndVilkaar";
import * as urls from "appConstants/urls";
import * as piggdekkoblatserivce from "api/piggavService";
import { OneLoginBrukerOppdateringStatus } from "appConstants";
import { useIntl } from "locale/langUtils";
import Form from "features/common/BaseComponents/Form/Form";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";

const registerPrivateUserSchema = yup.object().shape({
  epost: yup.string().lowercase().required("common.error.requiredfield").email("common.error.notavalidemail"),
  passord: yup.string().required("common.error.requiredfield").matches(passwordRegex, "common.error.invalidpassword"),
  acceptTermsAndConditions: yup.bool().oneOf([true], "common.error.termsandcondition"),
  acceptsRemainderOnEmail: yup.bool()
});

export default function RegisterPrivateUser() {
  const [isCreatingUserRequest, setIsCreatingUserRequest] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const { formatMessage } = useIntl();
  const history = useHistory();
  const {
    state: { vilkaar }
  } = useSamtykkeHook();
  const dispatch = useReduxDispatch();

  const { handleSubmit, register, errors, setError } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(registerPrivateUserSchema)
  });

  const handleRegisterPrivateUserSubmit = handleSubmit(
    async ({ epost, passord, acceptTermsAndConditions, acceptsRemainderOnEmail }) => {
      setIsCreatingUserRequest(true);
      try {
        const response = await piggdekkoblatserivce.registerPrivatBruker({
          epost,
          passord,
          navn: epost,
          samtykkerTilVilkaar: acceptTermsAndConditions,
          samtykkerTilEpostvarsler: acceptsRemainderOnEmail
        });
        if (response.data.errorkode === 409) {
          setError("epost", {
            type: "register",
            message: "registerprivatesuer.error.userallreadyacompanyuser"
          });
          return;
        }
        if (vilkaar) await dispatch(setAcceptsVilkaar(vilkaar.id, response.data.result.id));
        history.push({
          pathname: urls.takkForRegistreringUrl,
          state: {
            email: epost,
            opprettet:
              response.data.result.status === OneLoginBrukerOppdateringStatus[OneLoginBrukerOppdateringStatus.Opprettet]
          }
        });
      } catch (e: any) {
        setError("epost", {
          type: "register",
          message: "common.error.registeruser"
        });
      } finally {
        setIsCreatingUserRequest(false);
      }
    }
  );

  return (
    <>
      <Form id="register-private-user-form" onSubmit={handleRegisterPrivateUserSubmit}>
        <InputField
          name="epost"
          autoComplete="email"
          label={formatMessage({
            id: "common.label.mail",
            defaultMessage: "Din E-post"
          })}
          error={
            errors?.epost?.type === "register" &&
            errors.epost.message &&
            formatMessage({
              id: errors.epost.message,
              defaultMessage: ""
            })
          }
          ref={register}
          required
        />
        <InputField
          name="passord"
          autoComplete="new-password"
          type="password"
          label={formatMessage({
            id: "common.label.password",
            defaultMessage: "Velg et passord"
          })}
          error={
            errors.passord &&
            formatMessage({
              id: errors.passord.message,
              defaultMessage: ""
            })
          }
          ref={register}
          required
        />
        <div className="flex">
          <Checkbox name="acceptTermsAndConditions" ref={register} required>
            <FormattedMessage
              id="common.label.termsandconditions"
              defaultMessage="Jeg godtar vilkårene for bruk av tjenesten."
            />
            <span
              className="cursor-pointer text-4 ml-1 hover:text-blue-hover"
              role="button"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                setShowTermsAndConditions(true);
              }}
              onKeyDown={k => {
                if (k.key === "Enter") {
                  k.preventDefault();
                  k.stopPropagation();
                  setShowTermsAndConditions(true);
                }
              }}
              aria-haspopup="dialog"
              aria-controls="register-private-user-vilkaar"
              aria-expanded={showTermsAndConditions}
              tabIndex={0}
              aria-label={formatMessage({ id: "common.button.readaboutterms.aria" })}
            >
              <FormattedMessage id="common.button.readaboutterms" />
            </span>
          </Checkbox>
        </div>

        <Checkbox name="acceptsRemainderOnEmail" ref={register}>
          <FormattedMessage
            id="common.label.remainderonemail"
            defaultMessage="Jeg ønsker å motta påminnelser om sesongstart til min registrerte e-post"
          />
        </Checkbox>

        <Button
          type="submit"
          form="register-private-user-form"
          className="w-full text-5 mt-3"
          disabled={isCreatingUserRequest}
          isLoading={isCreatingUserRequest}
        >
          <FormattedMessage id="common.button.submit" defaultMessage="Opprett bruker" />
        </Button>
        {errors.acceptTermsAndConditions && (
          <MessageBox role="alert" type="danger" className="mt-2">
            <FormattedMessage
              id={errors.acceptTermsAndConditions.message ?? ""}
              defaultMessage="Du må godta vilkårene før du kan registrere brukerkonto"
            />
          </MessageBox>
        )}
      </Form>
      <VilkaarPopupOnlyText
        id="register-private-user-vilkaar"
        isOpen={showTermsAndConditions}
        toggleOpen={() => {
          setShowTermsAndConditions(prev => !prev);
        }}
      />
    </>
  );
}
