import { useState } from "react";
import { useHistory } from "react-router-dom";
import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import { Button, MessageBoxWIcon } from "features/common/ComposedComponents";
import { FormattedMessage } from "locale/langUtils";
import InputField from "features/common/ComposedComponents/Inputfield/Inputfield";
import { Checkbox } from "features/common/OSG";
import VilkaarPopupOnlyText from "features/TermsAndConditions/VilkaarPopupOnlyText";
import { passwordRegex } from "utils/validationUtils";
import { useIntl } from "react-intl";
import { useReduxDispatch } from "utils/Storeutils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { setAcceptsVilkaar } from "state/TermsAndCondition/SamtykkeAndVilkaar";
import useSamtykkeHook from "features/Hooks/SamtykkeHook";
import { getBrregInfo } from "features/Hooks/Brreg/BrregHook";
import * as yup from "yup";
import * as urls from "appConstants/urls";
import { registrerBedriftsbruker } from "api/piggavService";
import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { OneLoginBrukerOppdateringStatus } from "appConstants";
import Form from "features/common/BaseComponents/Form/Form";

const registerPrivateUserSchema = yup.object().shape({
  orgnummerSearchField: yup
    .string()
    .transform((val: string, orgVal: string) => orgVal.replace(/\s+/g, ""))
    .matches(/\d{9}/, "common.error.orgnummer"),
  companyName: yup.string().required("common.error.requiredfield"),
  organizationNumber: yup.string().required("common.error.requiredfield"),
  adress: yup.string().required("common.error.requiredfield"),
  adress2: yup.string(),
  zipcode: yup
    .string()
    .required("common.error.requiredfield")
    .transform((val: string) => val.replace(/\s+/g, ""))
    .matches(/\d{4}/, "common.error.zipcode"),
  city: yup.string().required("common.error.requiredfield"),
  country: yup.string().required("common.error.requiredfield"),
  contactperson: yup.string().required("common.error.requiredfield"),
  phonenumber: yup.string().required("common.error.requiredfield").min(8, "common.error.phonenumberinvalid"),
  useremail: yup.string().lowercase().required("common.error.requiredfield").email("common.error.notavalidemail"),
  password: yup.string().required("common.error.requiredfield").matches(passwordRegex, "common.error.invalidpassword"),
  acceptTermsAndConditions: yup.bool().oneOf([true], "common.error.termsandcondition"),
  acceptsRemainderOnEmail: yup.bool()
});

export default function RegisterPage(): JSX.Element {
  const [isCreatingUserRequest, setIsCreatingUserRequest] = useState(false);
  const [isFetchingOrgInfo, setIsFetchingOrgInfo] = useState(false);
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [foundOrgInfo, setFoundOrgInfo] = useState(false);
  const [showUnderEnhetWarning, setShowUnderEnhetWarning] = useState(false);

  const { formatMessage } = useIntl();
  const history = useHistory();
  const {
    state: { vilkaar }
  } = useSamtykkeHook();
  const dispatch = useReduxDispatch();

  const { handleSubmit, register, errors, setError, trigger, setValue, getValues, clearErrors } = useForm({
    criteriaMode: "firstError",
    mode: "onSubmit",
    resolver: yupResolver(registerPrivateUserSchema)
  });

  const handleRegisterPrivateUserSubmit = handleSubmit(
    async ({
      companyName,
      organizationNumber,
      adress,
      adress2,
      zipcode,
      city,
      country,
      contactperson,
      phonenumber,
      useremail,
      password,
      acceptTermsAndConditions,
      acceptsRemainderOnEmail
    }) => {
      setIsCreatingUserRequest(true);
      try {
        const response = await registrerBedriftsbruker({
          navn: contactperson,
          epost: useremail,
          passord: password,
          bedriftsnavn: companyName,
          telefonnummer: phonenumber,
          kontaktperson: contactperson,
          organisasjonsnummer: organizationNumber,
          adresse: adress,
          adresse2: adress2,
          postnummer: zipcode,
          poststed: city,
          land: country,
          samtykkerTilVilkaar: acceptTermsAndConditions,
          samtykkerTilEpostvarsler: acceptsRemainderOnEmail
        });

        if (response?.data?.errorkode === 409) {
          setError("useremail", {
            type: "register",
            message: "registercompanypage.error.privateuserexists"
          });
          setIsCreatingUserRequest(false);
        } else {
          if (vilkaar) await dispatch(setAcceptsVilkaar(vilkaar.id, response.data.result.id));
          history.push({
            pathname: urls.takkForRegistreringUrl,
            state: {
              email: useremail,
              opprettet:
                response.data.result.status ===
                OneLoginBrukerOppdateringStatus[OneLoginBrukerOppdateringStatus.Opprettet]
            }
          });
        }
      } catch (e: any) {
        setError("useremail", {
          type: "register",
          message: "common.error.registeruser"
        });
      } finally {
        setIsCreatingUserRequest(false);
      }
    }
  );

  const getOrginfo = async () => {
    try {
      clearErrors();
      const valid = await trigger("orgnummerSearchField");
      if (!valid) return;

      const { orgnummerSearchField } = getValues();
      setIsFetchingOrgInfo(true);
      const res = await getBrregInfo(orgnummerSearchField);
      setShowUnderEnhetWarning(res.erUnderenhet);
      if (res?.erSlettet ?? false) {
        setError("orgnummerSearchField", {
          type: "orgDeletedOrGone",
          message: "common.error.orggone"
        });
        return;
      }

      const orgInfo = res.erUnderenhet ? res.underenhet : res.hovedenhet;
      const orgadress = res.underenhet?.beliggenhetsadresse ?? res.hovedenhet?.forretningsadresse;

      setValue("companyName", orgInfo?.navn ?? "");
      setValue("organizationNumber", orgInfo?.organisasjonsnummer ?? "");
      setValue("adress", orgadress?.adresse?.[orgadress.adresse.length - 1] ?? "");
      setValue("zipcode", orgadress?.postnummer ?? "");
      setValue("city", orgadress?.poststed ?? "");
      setValue("country", orgadress?.land ?? "");
      setFoundOrgInfo(true);
    } catch (e) {
      setError("orgnummerSearchField", {
        type: "regnummernotfound",
        message: "common.error.orgnummernotfound"
      });
      setFoundOrgInfo(false);
    } finally {
      setIsFetchingOrgInfo(false);
    }
  };

  return (
    <Page
      metaInfo={{
        titlePageName: formatMessage({ id: "registercompanypage.heading" }),
        titleDescription: formatMessage({ id: "registercompanypage.meta.title.description" }),
        robots: "noindex, nofollow"
      }}
    >
      {{
        heading: {
          pageTitle: (
            <FormattedMessage id="registercompanypage.heading" defaultMessage="Opprett ny konto for bedriftkunde" />
          ),
          subtitle: <FormattedMessage id="registercompanypage.subtitle" />
        },
        pageContent: (
          <Section>
            <div className="w-full tablet:w-1/2">
              <Form id="register-company-user-form" onSubmit={handleRegisterPrivateUserSubmit} autoComplete="off">
                <div className="flex flex-col tablet:flex-row tablet:items-start mb-2 tablet:2/3">
                  <InputField
                    required
                    rootClassName="mb-none w-full flex-shrink-1"
                    name="orgnummerSearchField"
                    type="text"
                    ref={register()}
                    label={formatMessage({
                      id: "common.organizationnumber",
                      defaultMessage: "Organisasjonsnummer"
                    })}
                    error={
                      errors.orgnummerSearchField &&
                      formatMessage({
                        id: errors.orgnummerSearchField.message,
                        defaultMessage: ""
                      })
                    }
                    autoComplete="new-orgnumber"
                    autoFocus
                  />
                  {/**
                   * Added a dummy input because of chrome autfills searchfield above with mail adress and ignores the autfill flag
                   */}
                  <input
                    aria-hidden
                    tabIndex={-1}
                    style={{
                      width: 0,
                      height: 0,
                      position: "absolute",
                      top: "-700000px"
                    }}
                  />
                  <Button
                    id="getOrgInfo"
                    name="getOrgInfo"
                    className="mt-2 tablet:mt-margin-label-height tablet:ml-2 flex-shrink-0"
                    modifier="outline"
                    colorOption="yellow"
                    type="button"
                    aria-label="Søk og hent informasjon til din organisasjon"
                    onClick={getOrginfo}
                    disabled={isFetchingOrgInfo}
                    isLoading={isFetchingOrgInfo}
                    icon="magnifying-glass-small"
                  >
                    <FormattedMessage id="registercompanypage.button.getorginfo" defaultMessage="Hent info" />
                  </Button>
                </div>
                <div className={isFetchingOrgInfo || !foundOrgInfo || errors.orgnummerSearchField ? "hidden" : ""}>
                  <InputField
                    name="companyName"
                    autoComplete="organization"
                    type="text"
                    label={formatMessage({
                      id: "common.label.companyname",
                      defaultMessage: "Firmanavn"
                    })}
                    ref={register}
                    readOnly
                  />
                  <InputField
                    name="organizationNumber"
                    type="text"
                    label={formatMessage({
                      id: "common.label.organizationnumber",
                      defaultMessage: "Organisasjonsnummer"
                    })}
                    ref={register}
                    readOnly
                  />
                  <InputField
                    name="adress"
                    autoComplete="address-line1"
                    type="text"
                    label={formatMessage({
                      id: "common.label.adress",
                      defaultMessage: "Adresse"
                    })}
                    error={
                      errors?.adress &&
                      formatMessage({
                        id: errors.adress.message,
                        defaultMessage: ""
                      })
                    }
                    ref={register}
                    readOnly
                  />
                  <InputField
                    name="adress2"
                    autoComplete="address-line2"
                    type="text"
                    label={formatMessage({
                      id: "common.label.adress2",
                      defaultMessage: "Adresselinje 2"
                    })}
                    ref={register}
                    readOnly
                  />
                  <InputField
                    name="zipcode"
                    autoComplete="postal-code"
                    type="text"
                    label={formatMessage({
                      id: "common.label.zipcode",
                      defaultMessage: "Postnummer"
                    })}
                    error={
                      errors?.zipcode &&
                      formatMessage({
                        id: errors.zipcode.message,
                        defaultMessage: ""
                      })
                    }
                    ref={register}
                    readOnly
                  />
                  <InputField
                    name="city"
                    type="text"
                    label={formatMessage({
                      id: "common.label.city",
                      defaultMessage: "Poststed"
                    })}
                    error={
                      errors?.city &&
                      formatMessage({
                        id: errors.city.message,
                        defaultMessage: ""
                      })
                    }
                    ref={register}
                    readOnly
                  />
                  <InputField
                    name="country"
                    autoComplete="country-name"
                    type="text"
                    label={formatMessage({
                      id: "common.label.country",
                      defaultMessage: "Land"
                    })}
                    ref={register}
                    error={
                      errors?.country &&
                      formatMessage({
                        id: errors.country.message,
                        defaultMessage: ""
                      })
                    }
                    readOnly
                  />
                  <InputField
                    name="contactperson"
                    autoComplete="name"
                    type="text"
                    label={formatMessage({
                      id: "common.label.contactperson",
                      defaultMessage: "Kontaktperson"
                    })}
                    error={
                      errors?.contactperson &&
                      formatMessage({
                        id: errors.contactperson.message,
                        defaultMessage: ""
                      })
                    }
                    ref={register}
                    required
                  />
                  <InputField
                    name="phonenumber"
                    autoComplete="tel"
                    type="text"
                    label={formatMessage({
                      id: "common.label.phonenumber",
                      defaultMessage: "Telefonnummer"
                    })}
                    error={
                      errors?.phonenumber &&
                      formatMessage({
                        id: errors.phonenumber.message,
                        defaultMessage: ""
                      })
                    }
                    ref={register}
                    required
                  />
                  <p className="heading-3 mt-5 mb-3">
                    <FormattedMessage
                      id="registercompanypage.section.heading.logindetails"
                      defaultMessage="Innloggingsopplysninger"
                    />
                  </p>
                  <InputField
                    name="useremail"
                    autoComplete="email"
                    label={formatMessage({
                      id: "common.label.mail",
                      defaultMessage: "Din E-post"
                    })}
                    ref={register}
                    required
                  />
                  <InputField
                    name="password"
                    type="password"
                    autoComplete="new-password"
                    label={formatMessage({
                      id: "common.label.password",
                      defaultMessage: "Velg et passord"
                    })}
                    error={
                      errors.password &&
                      formatMessage({
                        id: errors.password.message,
                        defaultMessage: ""
                      })
                    }
                    ref={register}
                    required
                  />
                  <Checkbox name="acceptTermsAndConditions" ref={register} required>
                    <FormattedMessage
                      id="common.label.termsandconditions"
                      defaultMessage="Jeg godtar vilkårene for bruk av tjenesten"
                    />
                    <span
                      className="cursor-pointer text-4 ml-1 hover:text-blue-hove"
                      role="button"
                      tabIndex={0}
                      aria-label={formatMessage({ id: "common.button.readaboutterms.aria" })}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setShowTermsAndConditions(true);
                      }}
                      onKeyDown={k => {
                        if (k.key === "Enter" || k.key === " ") {
                          k.preventDefault();
                          k.stopPropagation();
                          setShowTermsAndConditions(true);
                        }
                      }}
                    >
                      <FormattedMessage id="common.button.readaboutterms" />
                    </span>
                  </Checkbox>

                  <Checkbox name="acceptsRemainderOnEmail" ref={register}>
                    <FormattedMessage
                      id="common.label.remainderonemail"
                      defaultMessage="Jeg ønsker å motta påminnelser om sesongstart til min registrerte e-post"
                    />
                  </Checkbox>

                  <Button
                    type="submit"
                    form="register-company-user-form"
                    className="w-full text-5 mt-3"
                    disabled={isCreatingUserRequest || !foundOrgInfo}
                    isLoading={isCreatingUserRequest}
                  >
                    <FormattedMessage id="common.button.submit" defaultMessage="Opprett bruker" />
                  </Button>

                  {errors.acceptTermsAndConditions && (
                    <div className="mt-2 p-3 bg-state-danger">
                      <FormattedMessage
                        id={errors.acceptTermsAndConditions.message ?? ""}
                        defaultMessage="Du må godta vilkårene før du kan registrere brukerkonto"
                      />
                    </div>
                  )}
                  {errors?.useremail?.type === "register" && errors.useremail.message && (
                    <div className="mt-2 p-3 bg-state-danger">
                      <FormattedMessage id={errors.useremail.message} />
                    </div>
                  )}
                </div>
              </Form>
            </div>
            <VilkaarPopupOnlyText
              isOpen={showTermsAndConditions}
              toggleOpen={() => {
                setShowTermsAndConditions(prev => !prev);
              }}
            />
            <Modal isOpen={showUnderEnhetWarning} toggle={() => setShowUnderEnhetWarning(false)}>
              <ModalHeader>
                {{ heading: <FormattedMessage id="registercompanypage.modal.underenhetwarning.heading" /> }}
              </ModalHeader>
              <ModalSection>
                <MessageBoxWIcon iconPosition="left" icon="exclamation-mark-circle" className="osg-u-color-warning">
                  <FormattedMessage
                    id="registercompanypage.modal.underenhetwarning.message"
                    values={{
                      b: (note: string) => <b>{note}</b>
                    }}
                  />
                </MessageBoxWIcon>
              </ModalSection>
              <ModalSection className="flex justify-end">
                <Button className="mobile-only:w-full" onClick={() => setShowUnderEnhetWarning(false)}>
                  <FormattedMessage id="registercompanypage.modal.underenhetwarning.button" />
                </Button>
              </ModalSection>
            </Modal>
          </Section>
        )
      }}
    </Page>
  );
}
