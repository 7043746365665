import React, { useMemo } from "react";
import { find } from "lodash-es";
import { Oblattype, Kjoretoy } from "interfaces";
import { useIntl } from "react-intl";
import { isSameMonth, endOfMonth, isValid, differenceInCalendarDays, differenceInCalendarMonths } from "date-fns";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import { StickerPeriodId } from "../../appConstants/identifiers";

interface OblatValgTilbakemelding {
  id?: string;
  valgtOblatType?: Oblattype;
  oblattyper?: Oblattype[];
  valgtKjoretoy?: Kjoretoy;
  gyldigFra?: string;
  sesongSlutt?: string;
}

function OblatvalgTilbakemelding({
  valgtOblatType,
  oblattyper,
  valgtKjoretoy,
  gyldigFra,
  sesongSlutt,
  id = ""
}: OblatValgTilbakemelding): JSX.Element {
  const { startTid, sesSlutt } = useMemo(
    () => ({
      startTid: new Date(gyldigFra ?? ""),
      sesSlutt: new Date(sesongSlutt ?? "")
    }),
    [gyldigFra, sesongSlutt]
  );

  const { formatMessage } = useIntl();
  const feedBack = useMemo<string | undefined>(() => {
    if (valgtOblatType && valgtKjoretoy && isValid(startTid) && isValid(sesSlutt)) {
      const dagsoblat = find(
        oblattyper,
        type => type.periodeId === StickerPeriodId.Day && type.kjoretoytypeId === valgtKjoretoy.kjoretoytypeId
      );
      const prisForDagsoblat = dagsoblat ? dagsoblat.pris : 0;

      // Hvis månedoblat er valgt
      if (valgtOblatType.periodeId === StickerPeriodId.Month) {
        // Hvis inneværende måneder valgt
        const erInneværendeMåned = isSameMonth(startTid, new Date());
        if (erInneværendeMåned) {
          const resterendeDagerIManed = differenceInCalendarDays(endOfMonth(startTid), new Date());

          const prisPrResterendeDag = valgtOblatType.pris / (resterendeDagerIManed + 1);
          return prisForDagsoblat < prisPrResterendeDag
            ? formatMessage(
                {
                  id: "buystickers.oblatvalgTilbakemelding.tilbakemelding.maaned"
                },
                {
                  daysLeft: resterendeDagerIManed,
                  priceLeft: prisPrResterendeDag,
                  dayStickerPrice: prisForDagsoblat
                }
              )
            : undefined;
        }
        // Hvis siste måned i sesongen er valgt
        const erSisteMånedISesong = isSameMonth(startTid, sesSlutt);
        if (erSisteMånedISesong) {
          const resterendeDagerISisteMaaned = differenceInCalendarDays(sesSlutt, startTid);

          const prisPrResterendeDag = valgtOblatType.pris / (resterendeDagerISisteMaaned + 1);

          return prisPrResterendeDag > prisForDagsoblat
            ? formatMessage(
                {
                  id: "buystickers.oblatvalgTilbakemelding.tilbakemelding.maaned",
                  defaultMessage: ""
                },
                {
                  daysLeft: resterendeDagerISisteMaaned,
                  priceLeft: prisPrResterendeDag,
                  dayStickerPrice: prisForDagsoblat
                }
              )
            : undefined;
        }
      }
      if (valgtOblatType.periodeId === StickerPeriodId.Season) {
        const månedsoblat = find(
          oblattyper,
          type => type.periodeId === StickerPeriodId.Month && type.kjoretoytypeId === valgtKjoretoy.kjoretoytypeId
        );

        const resterendeDagerISesong = differenceInCalendarDays(sesSlutt, startTid);

        const prisPrResterendeDagerSesong = valgtOblatType.pris / (resterendeDagerISesong + 1);

        const resterendeMånederISesong = differenceInCalendarMonths(sesSlutt, new Date());

        const maanedspris = månedsoblat?.pris ?? 0;

        const prisPrResterendeMåned = valgtOblatType.pris / (resterendeMånederISesong + 1);

        const prisPrResterendeDagerIMaaneder =
          (maanedspris * (resterendeMånederISesong + 1)) / (resterendeDagerISesong + 1);

        if (prisForDagsoblat < prisPrResterendeDagerIMaaneder && prisForDagsoblat < prisPrResterendeDagerSesong) {
          return månedsoblat && prisForDagsoblat < prisPrResterendeDagerSesong
            ? formatMessage(
                {
                  id: "buystickers.oblatvalgTilbakemelding.tilbakemelding.sesong_1"
                },
                {
                  daysLeft: resterendeDagerISesong,
                  priceLeft: prisPrResterendeDagerSesong,
                  dayStickerPrice: prisForDagsoblat
                }
              )
            : undefined;
        }

        return månedsoblat && månedsoblat.pris < prisPrResterendeMåned
          ? formatMessage(
              {
                id: "buystickers.oblatvalgTilbakemelding.tilbakemelding.sesong_2",
                defaultMessage: ""
              },
              {
                monthsLeft: resterendeMånederISesong,
                priceLeft: prisPrResterendeMåned,
                pricePrMonth: maanedspris
              }
            )
          : undefined;
      }
    }
    return undefined;
  }, [formatMessage, oblattyper, sesSlutt, startTid, valgtKjoretoy, valgtOblatType]);
  return (
    <MessageBox
      id={id}
      aria-live="polite"
      className="mt-3"
      visible={feedBack !== undefined}
      type="warning"
    >
      {feedBack}
    </MessageBox>
  );
}
export default OblatvalgTilbakemelding;
