import { KundeType, BrukerType } from "interfaces";
import { CustomerTypeId } from "appConstants/identifiers";
import { piggavAdmin, piggavResepsjon, piggavServiceavdeling, piggavKunde } from "appConstants";

export function getCustomerType(kundetypeId: CustomerTypeId | undefined): KundeType | undefined {
  switch (true) {
    case kundetypeId === CustomerTypeId.Private:
      return "Privatkunde";
    case kundetypeId === CustomerTypeId.Company:
      return "Bedriftkunde";
    default:
      return undefined;
  }
}

export function getUserType(roller: string[]): BrukerType | undefined {
  // returner rollen med "høyeste" tilgang først
  if (roller.indexOf(piggavAdmin) > -1) return piggavAdmin;
  if (roller.indexOf(piggavResepsjon) > -1) return piggavResepsjon;
  if (roller.indexOf(piggavServiceavdeling) > -1) return piggavServiceavdeling;
  if (roller.indexOf(piggavKunde) > -1) return piggavKunde;

  return undefined;
}
