import { piggavService } from "api";
import { isEmpty } from "lodash-es";
import { toastSuccess, toastError } from "../Toast/ToasActions";
//  GET KUNDEINFO
export const GET_KUNDE_SUCCESS = "GET_KUNDE_SUCCESS";
export const GET_KUNDE_REQUEST = "GET_KUNDE_REQUEST";
export const GET_KUNDE_ERROR = "GET_KUNDE_ERROR";
export const KUNDE_RESET = "piggav/kunde/KUNDE_RESET";
export const UPDATE_STANDARDKJORETOY_ID_SUCCESS = "UPDATE_STANDARDKJORETOY_ID_SUCCESS";
export const UPDATE_STANDARDKJORETOY_ERROR = "UPDATE_STANDARDKJORETOY_ERROR";

export const resetKunde = () => ({
  type: KUNDE_RESET,
  payload: null
});

const getKundeRequest = () => ({
  type: GET_KUNDE_REQUEST,
  payload: ""
});

const getKundeSuccess = data => ({
  type: GET_KUNDE_SUCCESS,
  payload: data
});

const getKundeError = data => ({
  type: GET_KUNDE_ERROR,
  payload: data
});

export const getKunde = brukerId => (dispatch, getState) => {
  const { loading, ...rest } = getState().kunde;
  if (!isEmpty(rest)) return Promise.resolve(rest);
  dispatch(getKundeRequest());
  return piggavService
    .getKunde(brukerId)
    .then(response => {
      dispatch(getKundeSuccess(response.data.result));
      return Promise.resolve(response);
    })
    .catch(error => {
      dispatch(getKundeError(error));
      return Promise.reject(error);
    });
};

//  UPDATE KUNDEINFO
export const UPDATE_KUNDE_SUCCESS = "UPDATE_KUNDE_SUCCESS";
export const UPDATE_KUNDE_ERROR = "UPDATE_KUNDE_ERROR";

const updateKundeSuccess = data => ({
  type: UPDATE_KUNDE_SUCCESS,
  payload: data
});

const updateKundeError = data => ({
  type: UPDATE_KUNDE_ERROR,
  payload: data
});

export const updateKunde = (brukerId, payload) => dispatch => {
  return piggavService
    .updateKunde(brukerId, payload)
    .then(response => {
      dispatch(updateKundeSuccess(response.data.result));
      return Promise.resolve(response);
    })
    .catch(error => {
      dispatch(updateKundeError(error));
      console.log("First occ", error);
      return Promise.reject(error.response);
    });
};

const updateStandardKjoretoyIdSuccess = kjoretoyId => ({
  type: UPDATE_STANDARDKJORETOY_ID_SUCCESS,
  payload: kjoretoyId
});

const updateStandardKjoretoyError = error => ({
  type: UPDATE_STANDARDKJORETOY_ERROR,
  payload: error
});

export const updateStandardKjoretoy =
  (brukerId, kjoretoyId, errorMsg = "", successMsg = "") =>
  dispatch =>
    piggavService
      .updateStandardKjoretoy(brukerId, kjoretoyId)
      .then(response => {
        dispatch(updateStandardKjoretoyIdSuccess(kjoretoyId));
        dispatch(toastSuccess(successMsg));
        return Promise.resolve(response);
      })
      .catch(error => {
        dispatch(updateStandardKjoretoyError(error));
        dispatch(toastError(errorMsg));
        return Promise.reject(error);
      });

export const TREKK_SAMTYKKE_SUCCESS = "TREKK_SAMTYKKE_SUCCESS";
export const TREKK_SAMTYKKE_ERROR = "TREKK_SAMTYKKE_ERROR";

const trekkSamtykkeSuccess = data => ({
  type: TREKK_SAMTYKKE_SUCCESS,
  payload: data
});
const trekkSamtykkeError = data => ({
  type: TREKK_SAMTYKKE_ERROR,
  payload: data
});

export const trekkSamtykke = (brukerId, payload) => dispatch =>
  piggavService
    .updateKunde(brukerId, payload)
    .then(response => {
      dispatch(trekkSamtykkeSuccess(response.data.result));
      dispatch(toastSuccess("Bruker oppdatert"));
    })
    .catch(error => {
      dispatch(trekkSamtykkeError(error));
      // dispatch(toastError(error));
    });
