import Helmet from "react-helmet";

export interface HelmetMetaProps {
  titlePageName?: string;
  titleDescription?: string;
  brandName?:string;
  description?: string;
  keywords?: string; // Keywords should not be used
  robots?: string;
  canonicalUrl?: string;
}

export default function HelmetMeta({
  titlePageName,
  titleDescription,
  brandName = "Piggav.no",
  description = "Velkommen til Piggav.no, en tjeneste hvor du kan kjøpe piggdekkoblater online.",
  keywords,
  robots,
  canonicalUrl
}: HelmetMetaProps): JSX.Element {
  return (
    <Helmet>
      <title>{`${titlePageName ?? ""} - ${titleDescription ?? ""} - ${brandName}`}</title>
      <meta name="description" content={description} />
      {keywords && <meta name="keywords" content={keywords} />}
      {canonicalUrl && <meta name="canonicalUrl" content={canonicalUrl} />}
      {robots && <meta name="robots" content={robots} />}
    </Helmet>
  );
}
