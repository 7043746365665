import localforage from "localforage";

const LocalStoreUtils = (): {
  setItem: (key: string, value: string) => Promise<string>;
  getItem: (key: string) => Promise<string | null>;
} => {
  let localStorage: LocalForage;
  if (typeof window !== "undefined") {
    localStorage = localforage.createInstance({
      driver: [localforage.INDEXEDDB, localforage.LOCALSTORAGE],
      name: "bymelding-portal-store"
    });
  }

  const getItem = async (key: string) => {
    return localStorage.getItem<any>(key);
  };

  const setItem = async (key: string, value: string): Promise<string> => {
    return localStorage.setItem(key, value);
  };
  return { getItem, setItem };
};

export default LocalStoreUtils;
