import { FormattedMessage } from "locale/langUtils";
import { VarekjopWSticker } from "./ReceiptPage";
import { PaymentDetail, PaymentDetails } from "./PaymentDetail";
import { PURCHASE_STATES } from "appConstants";

interface ReceiptPaymentProps {
    paymentDetails: PaymentDetail[],
    varekjop: VarekjopWSticker,
    className?: string;
}

export function ReceiptPayment({ paymentDetails, varekjop, className }: ReceiptPaymentProps) {
  return (
    <div className={className}>
        <PaymentDetails details={paymentDetails} />
        {varekjop?.betalingsform.toLocaleUpperCase() === "FAKTURA" &&
        varekjop.statusId === PURCHASE_STATES.INVOICE_SENT && (
            <FormattedMessage id="receiptpage.stickersValidNotification" />
        )}
    </div>
  );
}
