import React from "react";
import { Icon, IconTypes } from "features/common/OSG";
import "./FooterLink.scss";
import { FormattedMessage } from "locale/langUtils";

interface FooterLinkProps extends React.HTMLProps<HTMLAnchorElement> {
  icon: IconTypes;
  outgoing?: boolean;
}

export default function FooterLink({
  icon,
  children,
  className,
  outgoing = false,
  ...rest
}: FooterLinkProps): JSX.Element {
  return (
    <a
      className={`footer-link osg-u-text-3 osg-u-padding-top-2 osg-u-padding-bottom-1 ${className}`}
      {...(outgoing ? { target: "_blank", rel: "noopener noreferrer" } : {})}
      {...rest}
    >
      <div className="footer-link--icon">
        <span className={`osg-icon osg-icon--${icon}`} />
      </div>
      <div className="footer-link--text osg-u-margin-left-2">
        <span className="osg-u-text-3">
          {children}
          {outgoing && (
            <>
              <span className="sr-only">
                <FormattedMessage id="link.outgoing.commontext" />
              </span>
              <Icon icon="new-window" style={{ fontSize: "inherit" }} />
            </>
          )}
        </span>
      </div>
    </a>
  );
}
