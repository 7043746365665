import { GET_KONFIG_SUCCESS } from "./konfigurasjonerActions";

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function ordreReducer(state = {}, action) {
  switch (action.type) {
    case GET_KONFIG_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}

export const getState = state => state.konfigurasjon;
