import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { FormattedMessage } from "locale/langUtils";
import { useEffect, useState } from "react";
import { Button, MessageBoxWIcon } from "features/common/ComposedComponents";

const FORCEFULLY_LOGGED_OUT = "wasForcefullyLoggedOut";
export function getWasForcefullyLoggedOut() {
  return window.sessionStorage.getItem(FORCEFULLY_LOGGED_OUT);
}
export function setWasForcefullyLoggedOut(loggedOut: boolean) {
  return window.sessionStorage.setItem(FORCEFULLY_LOGGED_OUT, String(loggedOut));
}

export default function ForcefullyLoggedOutModal() {
  const [isForcefullyLoggedOut, setIsForcefullyLoggedOut] = useState(false);

  useEffect(() => {
    if (JSON.parse(getWasForcefullyLoggedOut()?? "false")) setIsForcefullyLoggedOut(true);
  }, []);

  const onToggle = () => {
    setIsForcefullyLoggedOut(false);
    window.sessionStorage.removeItem(FORCEFULLY_LOGGED_OUT);
  };

  return (
    <Modal isOpen={isForcefullyLoggedOut} toggle={onToggle}>
      <ModalHeader>{{ heading: <FormattedMessage id="forcefullyloggedout.heading" /> }}</ModalHeader>
      <ModalSection>
        <MessageBoxWIcon type="warning" icon="exclamation-mark-circle">
          <FormattedMessage id="forcefullyloggedout.body" />
        </MessageBoxWIcon>
      </ModalSection>
      <ModalSection className="flex justify-end">
        <Button onClick={onToggle}>
          <FormattedMessage id="forcefullyloggedout.button" />
        </Button>
      </ModalSection>
    </Modal>
  );
}
