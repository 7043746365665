/* eslint-disable react/prop-types */
import React, { FunctionComponent } from "react";
import { useHistory } from "react-router";

interface LinkHocProps {
  onClick?: (e: any) => any;
  to: string;
}

/**
 * HOC that makes a component a clickable link with react router.
 * @param Com
 */
const LinkHoc =
  <P,>(Com: FunctionComponent<P>) =>
  ({ to, onClick, ...rest }: LinkHocProps & P) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const history = useHistory();
    const onClickHandler = (e: any) => {
      history.push(to);
      onClick?.(e);
    };
    return <Com {...(rest as unknown as P)} role="link" href={to} onClick={onClickHandler} />;
  };

export default LinkHoc;
