import React from "react";
import { StickerSummaryItem } from "features/Hooks/HandlekurvHook";
import { useIntl } from "react-intl";
import { FormattedMessage } from "locale/langUtils";

interface ShoppingCartSummaryProps extends React.HTMLProps<HTMLDivElement> {
  stickerSummary: StickerSummaryItem[];
  totalPrice?: number;
}
export default function ShoppingCartSummary({
  stickerSummary,
  totalPrice,
  ...divProps
}: ShoppingCartSummaryProps): JSX.Element {
  const { formatNumber, formatMessage } = useIntl();
  return (
    <div {...divProps}>
      <div className="flex flex-col text-5">
        {stickerSummary.map(e => (
          <div className="flex justify-between mt-2 border-0 border-solid border-b border-grey py-1">
            <span>
              <span>{`${e.numberOfType} X `}</span>
              <span>
                <FormattedMessage id={e.name} />
              </span>
            </span>
            <span className="">
              {formatNumber(e.pricePer, {
                currency: "NOK",
                style: "currency",
                compactDisplay: "short"
              })}
            </span>
          </div>
        ))}
      </div>
      {totalPrice && (
        <div className="flex justify-end text-2 mt-2 py-2">
          {`${formatMessage({
            id: "shoppingcart.label.total"
          })}: ${formatNumber(totalPrice, {
            currency: "NOK",
            style: "currency",
            compactDisplay: "short"
          })}`}
        </div>
      )}
    </div>
  );
}
