/* eslint-disable react/prop-types */
import React, { forwardRef, ReactNode, useState } from "react";
import { StatePropType } from "../Interface";
import "../CommonStyles/InputElement.scss";
import { uniqueId } from "lodash-es";
import { FormattedMessage } from "locale/langUtils";

export interface InputElementProps extends Omit<React.HTMLProps<HTMLInputElement>, "label">, StatePropType {
  label?: ReactNode;
  helpText?: ReactNode;
}

const InputField = forwardRef<HTMLInputElement, InputElementProps>(
  (
    { className, label, id, required, children, state, helpText, "aria-describedby": ariaDescribedBy = "", ...rest },
    ref
  ) => {
    const [uid] = useState(id || uniqueId("input-id"));
    return (
      <label htmlFor={uid} className="input-element">
        <span className="label">
          {label ?? children}{" "}
          {required ? (
            <>
              <span aria-hidden> *</span>
              <span className="sr-only">
                <FormattedMessage id="common.requiredfield" />
              </span>
            </>
          ) : (
            ""
          )}
        </span>
        {helpText && (
          <div id={`${uid}--help-text`} style={{ fontSize: 12 }}>
            {helpText}
          </div>
        )}
        <input
          id={uid}
          type="text"
          aria-describedby={`${ariaDescribedBy} ${uid}--help-text `}
          className={`input ${className} ${state ? `state-${state}` : ""}`}
          ref={ref}
          required={required}
          {...rest}
        />
      </label>
    );
  }
);

export default InputField;
