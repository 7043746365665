import { StylesConfig, Theme } from "react-select";

export const reactSelectCustomStyles: StylesConfig<any, any> = {
  control: (provided: any, state: any) => {
    const stateStyle =
      state.isDisabled || state.readOnly
        ? {
            outline: "2px #e1e1e1 solid",
            outlineOffset: "-2px",
            backgroundColor: "#f9f9f9",
            color: "inherit !important"
          }
        : { borderColor: "#2a2859" };
    return {
      ...provided,
      boxSizing: "border-box",
      height: "50px",
      fontWeight: "400",
      "&:hover": { borderColor: "#1f42aa" },
      "&:focused": {
        outline: "4px solid #2a2859",
      },
      borderWidth: "2px",
      ...stateStyle
    };
  },
  placeholder: (provided) => ({
    ...provided,
    color: "#666666",
    fontWeight: "inherit",

  })
};

export const reactSelectCustomTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 0,
  spacing: { baseUnit: 4, controlHeight: 50, menuGutter: 10 },
  colors: {
    ...theme.colors,
    primary25: "#f9f9f9",
    primary: "#2a2859"
  }
});
