import React from "react";
import "./ArrowButton.scss";

export interface OpenCloseArrow extends React.HTMLAttributes<HTMLDivElement> {
  up: boolean;
  upText?: string;
  downText?: string;

  clicked?: () => any;
}

export default function ArrowButton({
  upText,
  downText,
  up = false,
  clicked,
  className,
  ...rest
}: OpenCloseArrow): JSX.Element {
  return (
    <div
      className={`vis-detaljer ${className}`}
      role="button"
      tabIndex={0}
      onKeyUp={e => {
        if (e.key === "Enter" && clicked) {
          clicked();
        }
      }}
      onClick={clicked}
      {...rest}
    >
      <span>{up ? upText : downText}</span>
      <i className={`vis-detaljer-arrow ${up ? "up" : "down"}`} />
    </div>
  );
}
