import React, { useState } from "react";

import useSamtykkeHook from "features/Hooks/SamtykkeHook";
import { FormattedMessage, useIntl } from "locale/langUtils";
import WithdrawConsentDialogue from "features/TermsAndConditions/WithdrawConsentDialogue";
import VilkaarPopupOnlyText from "features/TermsAndConditions/VilkaarPopupOnlyText";
import { Checkbox } from "features/common/OSG";

export default function ChangeRemainderOnMailSetting({ ...rest }: React.HTMLProps<HTMLDivElement>): JSX.Element {
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false);
  const [showWithdrawConsentDialogue, setShowWithdrawConsentDialogue] = useState(false);
  const {
    state: { acceptedSamtykke }
  } = useSamtykkeHook();
  const { formatMessage } = useIntl();
  return (
    <div {...rest}>
      <h2 className="heading-3 mb-3">
        <FormattedMessage id="profile.termsandconditions.heading" />
      </h2>
      <div>
        <Checkbox
          checked={acceptedSamtykke}
          onChange={e => {
            setShowWithdrawConsentDialogue(true);
          }}
          aria-checked={acceptedSamtykke}
          name="withdraw-consent"
          required
          aria-label={formatMessage({ id: "profile.termsandconditions.label.aria" })}
        >
          <FormattedMessage id="profile.termsandconditions.label" />{" "}
        </Checkbox>
      </div>
      <VilkaarPopupOnlyText
        isOpen={showTermsAndConditions}
        toggleOpen={() => {
          setShowTermsAndConditions(prev => !prev);
        }}
      />
      <WithdrawConsentDialogue
        isOpen={showWithdrawConsentDialogue}
        cancel={() => setShowWithdrawConsentDialogue(false)}
      />
    </div>
  );
}
