import React from "react";
import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import { FormattedMessage, useIntl } from "locale/langUtils";

export default function NotAValidPage(): JSX.Element {
  const intl = useIntl();
  return (
    <Page
      metaInfo={{
        titlePageName: intl.formatMessage({ id: "notavalidpage.meta.title" }),
        titleDescription: intl.formatMessage({ id: "notavalidpage.meta.title.description" }),
        robots: "noindex"
      }}
    >
      {{
        pageContent: (
          <Section colorClass="bg-white" className="">
            <h1 className="heading-1 mb-2">
              <FormattedMessage id="notavalidpage.heading" defaultMessage="Beklager vi kunne ikke finne siden (404)" />
            </h1>
            <p className="osg-u-text-3">
              <FormattedMessage
                id="notavalidpage.information"
                defaultMessage="Informasjonen er kanskje flyttet, eller eksisterer ikke lenger."
              />
            </p>
          </Section>
        )
      }}
    </Page>
  );
}
