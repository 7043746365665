import React from "react";
import "./SpinnerCircle.scss";

export default function SpinnerCircle({ className, ...rest }: React.HTMLProps<HTMLDivElement>): JSX.Element {
  return (
    <div role="img" className={`${className} spinner-circle`} {...rest}>
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
      <span />
    </div>
  );
}
