/* eslint-disable react/prop-types */
import React, { forwardRef, FunctionComponent, ReactNode, useState } from "react";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import { isEmpty, uniqueId } from "lodash-es";
import { State, StatePropType } from "../BaseComponents/Interface";

interface FormFieldHocProps {
  rootClassName?: string;
  error?: ReactNode;
  warning?: ReactNode;
  success?: ReactNode;
  helpText?: ReactNode;
}

/**
 * HOC that wraps common feedback messages like error messges and success messages around an input element.
 * @param Input Component that represents an input element. The component must implement StatePropType
 */
const formFieldHOC = <P extends StatePropType>(Input: FunctionComponent<P>) =>
  forwardRef(
    (
      { rootClassName, error, warning, success, ...rest }: FormFieldHocProps & Omit<P, "state">,
      ref: React.Ref<HTMLInputElement>
    ) => {
      const [uid] = useState(rest?.id || uniqueId("input-id--"));
      let msg: ReactNode = "";
      let state: State | undefined;
      let role: string | undefined;

      switch (true) {
        case !!error:
          state = "danger";
          msg = error ?? "";
          role = "alert";
          break;
        case !!warning:
          state = "warning";
          msg = warning ?? "";
          role = "alert";
          break;
        case !!success:
          state = "success";
          msg = success ?? "";
          role = "status";
          break;
        default:
          state = undefined;
          msg = "";
          role = undefined;
      }

      return (
        <div className={`block mb-3 ${rootClassName ?? ""}`}>
          <Input {...(rest as P)} id={uid} state={state} ref={ref} aria-describedby={`${uid}--status-alert ${uid}--info`} />
          <MessageBox
            role={role}
            id={`${uid}--status-alert`}
            className="mt-2 px-3 py-3"
            visible={!isEmpty(msg)}
            type={state}
          >
            {msg}
          </MessageBox>
        </div>
      );
    }
  );

export default formFieldHOC;
