/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable no-param-reassign */
import { ChangeEvent, useEffect, useRef, Ref, useCallback, FunctionComponent } from "react";

import { isEmpty } from "lodash-es";
import { Icon, SearchField } from "features/common/OSG";
import "./DOMTextSearcher.scss";

const TEXT_NODE_TYPE = 3;
const ID_PREFIX = "--string-search-result";

interface SearchFieldComponent {
  className?: string;
  label?: string;
  ariaLabel?: string;
  ariaNext?: string;
  ariaPrev?: string;
}

interface DOMTextSearcherApi {
  handleGoToNextResult: Function;

  handleGoToPreviousResult: Function;

  onChangeSearchHandler: (e: ChangeEvent<HTMLInputElement>) => void;

  registerRefNodeToSearchIn: Ref<HTMLDivElement>;

  SearchFieldComponent: FunctionComponent<SearchFieldComponent>;
  cleanUp: Function;
}

interface ResultObject {
  index: number;
  textNode: ChildNode;
  matchString: string;
  spanElement: HTMLElement | null;
  parentNode: Node;
}

// eslint-disable-next-line @typescript-eslint/naming-convention
const escapeHTML_JS = (unsafeString: string) => {
  return unsafeString
    .replace(/&/g, "&amp;")
    .replace(/</g, "&lt;")
    .replace(/>/g, "&gt;")
    .replace(/"/g, "&quot;")
    .replace(/'/g, "&#039;");
};

export default function useDOMTextSearcher(timeOut = 150, DOMIdToSearchIn = ""): DOMTextSearcherApi {
  const timeOutID = useRef<undefined | number>(undefined);
  const searchStringRef = useRef<null | string>(null);
  const prevSearchResult = useRef<ResultObject[]>([] as ResultObject[]);
  const currentMarkedResult = useRef<number>(0);
  const registerRefNodeToSearchIn = useRef(null);

  useEffect(() => {
    return () => {
      prevSearchResult.current.length = 0;
    };
  }, []);

  const cleanUp = () => {
    unMarkMatchedText(prevSearchResult.current);
    prevSearchResult.current.length = 0;
    searchStringRef.current = "";
  };

  const markMatchedText = (matchedResults: ResultObject[]) => {
    matchedResults.forEach((result, index) => {
      if (result === null) return;
      const lftText = result.textNode.textContent?.slice(0, result.index);
      const rghtText = result.textNode.textContent?.slice(
        result.index + result.matchString.length,
        result.textNode.textContent.length
      );

      const spanElement = document.createElement("span");

      spanElement.innerHTML = result.matchString;
      spanElement.id = `${ID_PREFIX}${index}`;
      spanElement.style.cssText = "background-color: rgba(255,255,51,0.5);";

      result.spanElement = spanElement;

      const leftTextNode = document.createTextNode(lftText ?? "");

      const rightTextNode = document.createTextNode(rghtText ?? "");

      // Insert children
      result.textNode.replaceWith(result.spanElement);
      // result.parentNode.replaceChild(result.spanElement, result.textNode);

      result.parentNode.insertBefore(leftTextNode, result.spanElement);
      result.parentNode.insertBefore(rightTextNode, result.spanElement.nextSibling);
    });

    // mark the first element
    if (isEmpty(matchedResults)) return;
    if (matchedResults[0].spanElement)
      matchedResults[0].spanElement.style.cssText = "background-color: rgba(241, 90, 34, 0.7);";
  };

  const unMarkMatchedText = (matchedResults: ResultObject[]) => {
    matchedResults.forEach(result => {
      if (result.spanElement?.nextSibling) result.parentNode?.removeChild(result.spanElement.nextSibling);
      if (result.spanElement?.previousSibling) result.parentNode.removeChild(result.spanElement.previousSibling);
      // CHange back original text
      result.spanElement?.replaceWith(result.textNode);
    });
  };

  const findSubstringInNode = (HTMLElement: Node, searchString: string): ResultObject | null => {
    if (HTMLElement.nodeType !== TEXT_NODE_TYPE) return null;

    // Search for substring
    const TextNode = HTMLElement as Text;

    if (!TextNode.textContent) return null;

    const startIndex = TextNode.textContent.toLowerCase().indexOf(searchString);

    if (startIndex < 0 || !HTMLElement.parentNode) return null;

    return {
      index: startIndex,
      textNode: TextNode,
      matchString: TextNode.textContent.slice(startIndex, startIndex + searchString.length),
      spanElement: null,
      parentNode: HTMLElement.parentNode
    };
  };

  const searchForStringInNodeTree = (
    resultArray: ResultObject[],
    HTMLElement: ChildNode | Node,
    searchString: string
  ) => {
    const searchResult = findSubstringInNode(HTMLElement, searchString);

    if (searchResult) resultArray.push(searchResult);

    HTMLElement.childNodes.forEach(childElement => {
      searchForStringInNodeTree(resultArray, childElement, searchString);
    });
  };

  const onChangeSearchHandler = (e: ChangeEvent<HTMLInputElement>) => {
    window.clearTimeout(timeOutID.current);

    if (typeof e.target.value !== "string") return;

    if (isEmpty(e.target.value)) {
      unMarkMatchedText(prevSearchResult.current);
      prevSearchResult.current.length = 0;
      currentMarkedResult.current = 0;
      return;
    }

    // First see if component ref is set then check if id is set, then check....
    const DOMElementToSearchIn = registerRefNodeToSearchIn?.current || document.getElementById(DOMIdToSearchIn);

    if (!DOMElementToSearchIn) return;

    // Escape HTML and Script tags.
    const searchString = escapeHTML_JS(e.target.value).toLowerCase();
    const result: ResultObject[] = [];
    // Set timer on the search .
    timeOutID.current = window.setTimeout(() => {
      unMarkMatchedText(prevSearchResult.current);
      prevSearchResult.current.length = 0; // Empty the array
      currentMarkedResult.current = 0;
      searchForStringInNodeTree(result, DOMElementToSearchIn, searchString);
      prevSearchResult.current = result;
      markMatchedText(prevSearchResult.current);
    }, timeOut);
  };

  const handleGoToPreviousResult = () => {
    if (!hasResults(prevSearchResult.current)) return;
    const prevIndex =
      currentMarkedResult.current - 1 < 0 ? prevSearchResult.current.length - 1 : currentMarkedResult.current - 1;

    const currentSpan = prevSearchResult.current[currentMarkedResult.current]?.spanElement;
    if (currentSpan) currentSpan.style.cssText = "background-color: rgba(255,255,51,0.5);";
    const prevSpan = prevSearchResult.current[prevIndex]?.spanElement;
    if (prevSpan) prevSpan.style.cssText = "background-color: rgba(241, 90, 34, 0.7);";
    prevSpan?.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start"
    });

    currentMarkedResult.current = prevIndex;
  };

  const handleGoToNextResult = () => {
    if (!hasResults(prevSearchResult.current)) return;
    const nextIndex = (currentMarkedResult.current + 1) % prevSearchResult.current.length;

    const currentSpan = prevSearchResult.current[currentMarkedResult.current]?.spanElement;
    if (currentSpan) currentSpan.style.cssText = "background-color: rgba(255,255,51,0.5);";

    const nextSpan = prevSearchResult.current[nextIndex]?.spanElement;
    if (nextSpan) nextSpan.style.cssText = "background-color: rgba(241, 90, 34, 0.7);";

    nextSpan?.scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "start"
    });

    currentMarkedResult.current = nextIndex;
  };

  const hasResults = (searchResult: any[]) => {
    return searchResult.length > 0;
  };

  const SearchFieldWrapper = useCallback(
    ({ className, label, ariaLabel, ariaNext, ariaPrev }: SearchFieldComponent): JSX.Element => {
      // eslint-disable-next-line react/no-children-prop
      return (
        <div className={`--DOM-text-searcher-wrapper ${className}`}>
          <SearchField label={label} aria-label={ariaLabel} onChange={onChangeSearchHandler} />
          <span className="--DOM-text-searcher-controll-wrapper">
            <button
              className="--DOM-text-searcher-controll-btn"
              aria-label={ariaPrev}
              onClick={handleGoToPreviousResult}
              type="button"
            >
              <Icon icon="chevron-thin-down" />
            </button>
            <button
              className="--DOM-text-searcher-controll-btn"
              aria-label={ariaNext}
              onClick={handleGoToNextResult}
              type="button"
            >
              <Icon icon="chevron-thin-up" />
            </button>
          </span>
        </div>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return {
    handleGoToNextResult,
    handleGoToPreviousResult,
    onChangeSearchHandler,
    registerRefNodeToSearchIn,
    SearchFieldComponent: SearchFieldWrapper,
    cleanUp
  };
}
