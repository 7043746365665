import { useHistory } from "react-router-dom";
import { useAuth } from "react-oidc-context";
import { useReduxDispatch } from "utils/Storeutils";
import { loginUser as loginUserAction } from "state/Auth/Auth";

function Login() {
  const auth = useAuth();
  const { push } = useHistory();
  const storeDispatch = useReduxDispatch();

  if (!auth.isAuthenticated) return <></>;

  storeDispatch(loginUserAction(auth.user?.access_token!));

  push("/");

  return <div>LoginRedirect</div>;
}

export default Login;
