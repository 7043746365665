import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { FormattedMessage } from "locale/langUtils";
import { useAuth } from "react-oidc-context";
import * as urls from "appConstants/urls";
import { useHistory } from "react-router";
import { ErrorResponse } from "oidc-client-ts";
import { useEffect, useState } from "react";
import Link from "features/common/BaseComponents/Link/Link";
import { MessageBoxWIcon } from "features/common/ComposedComponents";
import { oneLoginBaseUrl } from "api/";

export default function AuthErrorDialogue() {
  const [hasError, setHasError] = useState(false);
  const auth = useAuth();
  const history = useHistory();

  useEffect(() => {
    if ((auth.error as ErrorResponse)?.error === "access_denied") {
      // Logout user via iframe and show error
      setHasError(true);
      const ifrm = document.createElement("iframe");
      ifrm.height = "0";
      ifrm.width = "0";
      ifrm.hidden = true;
      ifrm.src = `${oneLoginBaseUrl}logout`;
      ifrm.onload = function () {
        window.document.body.removeChild(ifrm);
      };
      window.document.body.appendChild(ifrm);
    }
  }, [auth.error]);

  const goToRegister = () => {
    history.push(urls.registrerBruker);
    setHasError(false);
  };

  return (
    <Modal isOpen={hasError} toggle={() => setHasError(false)}>
      <ModalHeader>{{ heading: <FormattedMessage id="authdialogue.heading" /> }}</ModalHeader>
      <ModalSection>
        <MessageBoxWIcon type="danger" icon="exclamation-mark-circle">
          <p className="mb-2">
            <FormattedMessage id="authdialogue.messagebody" />
          </p>
          <p>
            <FormattedMessage
              id="authdialogue.linkmessage"
              values={{
                a: (chunks: string) => (
                  <Link
                    className=""
                    href={urls.registrerBruker}
                    onClick={e => {
                      e.preventDefault();
                      goToRegister();
                    }}
                  >
                    {chunks}
                  </Link>
                )
              }}
            />
          </p>
        </MessageBoxWIcon>
      </ModalSection>
    </Modal>
  );
}
