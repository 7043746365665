import { useContext } from "react";
import { LocaleContext } from "locale/LocaleProvider";
import LanguagePicker from "locale/LanguagePicker";
import { FormattedMessage } from "locale/langUtils";
import TermsAndConditionsPopup from "features/TermsAndConditions/VilkaarPopupOnlyText";
import { useHistory } from "react-router";
import * as urls from "appConstants/urls";
import FooterLink from "features/common/BaseComponents/FooterLink/FooterLink";
import { useIntl } from "react-intl";

interface FooterProps {
  className?: string;
}
export default function Footer({ className = "" }: FooterProps): JSX.Element {
  const { currentLocale } = useContext(LocaleContext);
  const history = useHistory();
  const intl = useIntl();
  return (
    <footer className={`bg-blue-dark flex justify-center ${className}`}>
      <div
        className="bg-blue-dark text-white osg-u-padding-bottom-5 osg-u-padding-top-5 osg-u-padding-top-6-tablet-and-up w-full widescreen:w-widescreen-content-max-width"
        style={{ padding: "0px" }}
      >
        {/** Contact section */}
        <div className="flex flex-wrap ">
          <div className="flex flex-col w-full desktop:w-1/3 osg-u-padding-3 ">
            <h2 id="footer-contactheading" className="osg-u-heading-4 osg-u-padding-bottom-3">
              <FormattedMessage id="footer.contactHeading" defaultMessage="Kontakt" />
            </h2>
            <ul
              aria-describedby="footer-contactheading"
              className="list-none osg-u-margin-bottom-none osg-u-padding-top-small osg-u-padding-top-1-tablet-and-up"
            >
              <li>
                <FooterLink
                  icon="chevron-right"
                  className="text-white text-3 mb-2 hover:text-blue-light"
                  href="mailto:post@piggav.no"
                  title="post@piggav.no"
                  alt="post@piggav.no"
                >
                  <FormattedMessage id="footer.sendUsAnEmail" defaultMessage="Send oss en epost:" />
                  <br />
                  post@piggav.no
                </FooterLink>
              </li>
              <li>
                <FooterLink
                  icon="chevron-right"
                  className="text-white mb-2 text-3 hover:text-blue-light"
                  href="tel:+4723482030"
                >
                  <FormattedMessage id="footer.callUs" defaultMessage="Ring oss på telefonnummer:" />
                  <br />
                  23 48 20 30
                </FooterLink>
              </li>
            </ul>
          </div>
          {/** Help section */}
          <div className="flex flex-col w-full desktop:w-1/3 osg-u-padding-3">
            <h2 id="footer-helpsection" className="osg-u-heading-4 osg-u-padding-bottom-3">
              <FormattedMessage id="footer.helpHeading" defaultMessage="Hjelp" />
            </h2>
            <ul
              aria-describedby="footer-helpsection"
              className="list-none osg-u-margin-bottom-none osg-u-padding-top-small osg-u-padding-top-1-tablet-and-up"
            >
              <li>
                <FooterLink
                  icon="chevron-right"
                  className="text-3 text-white hover:text-blue-light visited:text-white mb-2"
                  href="https://ed24.chat.oslo.kommune.no/ed24cust/chattemplate/Oslo_Bymiljoetaten/index.html"
                  rel="noopener noreferrer"
                  target="_blank"
                  outgoing
                >
                  <FormattedMessage id="footer.chatWithUs" defaultMessage="Chat med oss" />
                </FooterLink>
              </li>
              <li>
                <FooterLink
                  href={urls.faq}
                  icon="chevron-right"
                  className="text-3 text-white visited:text-white hover:text-blue-light mb-2"
                  onClick={e => {
                    e.preventDefault();
                    history.push(urls.faq);
                  }}
                >
                  <FormattedMessage id="footer.faq" defaultMessage="Ofte stilte spørsmål" />
                </FooterLink>
              </li>
            </ul>
          </div>
          {/** Om siden */}
          <div className="flex flex-col w-full desktop:w-1/3 osg-u-padding-3">
            <h2 id="footer-aboutHeading" className="osg-u-heading-4 osg-u-padding-bottom-3">
              <FormattedMessage id="footer.aboutHeading" defaultMessage="Hjelp" />
            </h2>
            <ul
              aria-describedby="footer-aboutHeading"
              className="list-none osg-u-margin-bottom-none osg-u-padding-top-small osg-u-padding-top-1-tablet-and-up"
            >
              <li>
                <FooterLink
                  icon="chevron-right"
                  className="text-white visited:text-white mb-2"
                  href={
                    currentLocale.locale === "nb"
                      ? "https://www.oslo.kommune.no/gate-transport-og-parkering/piggdekkgebyr/"
                      : "https://www.oslo.kommune.no/english/street-transport-and-parking/studded-tyre-fee/"
                  }
                  rel="noopener noreferrer"
                  target="_"
                  outgoing
                >
                  <FormattedMessage id="footer.readMore" defaultMessage="Les mer om piggdekkoblat" />
                </FooterLink>
              </li>
              <li>
                <FooterLink
                  outgoing
                  href="https://www.oslo.kommune.no/personvern-og-informasjonskapsler/informasjonskapsler-analyse-og-sok/"
                  className="text-white visited:text-white mb-2"
                  icon="chevron-right"
                >
                  <FormattedMessage id="footer.privacyAndCookies" defaultMessage="Personvern og informasjonskapsler" />
                </FooterLink>
              </li>
              <li>
                <FooterLink
                  outgoing
                  href="https://uustatus.no/nb/erklaringer/publisert/7ca23ba2-066b-4759-9652-97b1851252da"
                  className="text-white visited:text-white mb-2"
                  icon="chevron-right"
                >
                  <FormattedMessage id="footer.accessibility" />
                </FooterLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex flex-wrap osg-u-padding-3">
          <div className="w-full desktop:w-1/3">
            <TermsAndConditionsPopup id="footer-terms-and-conditions">
              {{
                openModalElement: (setIsOpenModal, isModalOpen) => (
                  <span
                    role="button"
                    tabIndex={0}
                    onClick={() => {
                      setIsOpenModal(true);
                    }}
                    onKeyDown={event => {
                      if (event.key === "Enter" || event.key === " ") setIsOpenModal(true);
                    }}
                    className="text-5 text-white visited:text-white hover:text-blue-light hover:cursor-pointer hover:no-underline focus:text-blue-light underline"
                    aria-haspopup="dialog"
                    aria-controls="footer-terms-and-conditions"
                    aria-expanded={isModalOpen}
                  >
                    <span style={{ color: "inherit" }}>
                      <FormattedMessage id="footer.termsAndConditions" defaultMessage="Vilkår og betingelser" />
                    </span>
                  </span>
                )
              }}
            </TermsAndConditionsPopup>
          </div>
          <div className="w-full desktop:w-2/3 flex mobile-only:flex-col">
            <LanguagePicker
              render={({ onClick, TranslateText, currentLocale: locale }) => {
                return (
                  <span
                    tabIndex={0}
                    role="button"
                    className="text-white text-5 underline hover:cursor-pointer hover:text-blue-light focus:text-blue-light"
                    onClick={onClick}
                    onKeyUp={k => {
                      if (k.key === "Enter" || k.key === " ") {
                        onClick();
                      }
                    }}
                    aria-label={intl.formatMessage({ id: "languagePicker.button.aria" })}
                    aria-describedby="testtest"
                    key="lang-picker"
                  >
                    <TranslateText key="lan-picker-text" />
                    <span id="testtest"  aria-live="assertive" className="sr-only">
                      {locale.locale === "nb" ? "Norsk språk valgt" : "English language chosen"}
                    </span>
                  </span>
                );
              }}
            />
          </div>
        </div>
      </div>
    </footer>
  );
}
