import React from "react";
import { State } from "../Interface";

export interface MessageBoxProps extends React.HTMLProps<HTMLDivElement> {
  type?: State;
  visible?: boolean;
}
export default function MessageBox({
  type = "primary",
  visible = true,
  className,
  children,
  ...rest
}: MessageBoxProps) {
  return visible ? (
    <div
      className={`bg-state-${type ?? ""} bg-opacity-75 osg-u-padding-3 ${(!visible && "hidden") || ""}  ${className}`}
      {...rest}
    >
      {children}
    </div>
  ) : null;
}
