import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getState as getUserState } from "state/Auth/Auth";
import { getState as getKundeState } from "state/Kunde/kundeReducer";
import { Kunde, BrukerType, KundeType, AccessToken } from "interfaces";
import { getCustomerType, getUserType } from "utils/authenticationUtils";
import { isEmpty } from "lodash-es";

export interface UserHookApi {
  userInfo?: AccessToken;
  customerState?: Kunde;
  userType?: BrukerType;
  customerType?: KundeType;
}

export default function UserHook(): UserHookApi {
  const { userInfo } = useSelector(getUserState);
  const customerState = useSelector<any, Kunde & { loading: boolean }>(getKundeState);

  const [customerType, setcustomerType] = useState<KundeType | undefined>(
    getCustomerType(customerState?.kundetypeId ?? -1)
  );

  const [userType, setUserType] = useState<BrukerType | undefined>(getUserType(userInfo?.roles ?? []));

  useEffect(() => {
    const kt = getCustomerType(customerState.kundetypeId);
    setcustomerType(kt);
  }, [customerState.kundetypeId]);

  useEffect(() => {
    if (!userInfo) return;
    const ut = getUserType(userInfo.roles);
    setUserType(ut);
  }, [userInfo]);

  return {
    customerState: isEmpty(customerState) ? undefined : customerState,
    userInfo,
    userType,
    customerType
  };
}
