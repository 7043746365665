import React, { useState } from "react";
import { VilkaarTekst } from "interfaces";
import { useIntl } from "react-intl";
import ArrowButton from "features/common/BaseComponents/ArrowButton/ArrowButton";

interface VilkaarTextProps {
  vilkaar: VilkaarTekst;
}
export default function VilkaarText({ vilkaar }: VilkaarTextProps): JSX.Element {
  const { formatMessage } = useIntl();
  const [seeLongVersion, setSeeLongVersion] = useState(false);

  return (
    <>
      {seeLongVersion ? (
        // eslint-disable-next-line react/no-danger
        <div
          className="osg-content text-black"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: vilkaar.fullTekst
          }}
        />
      ) : (
        // eslint-disable-next-line react/no-danger
        <div
          className="osg-content text-black"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: vilkaar.tekst
          }}
        />
      )}
      <ArrowButton
        className="d-inline"
        up={seeLongVersion}
        upText={formatMessage({ id: "termsandconditions.button.showless" })}
        downText={formatMessage({ id: "termsandconditions.button.showmore" })}
        clicked={() => {
          setSeeLongVersion(prev => !prev);
        }}
      />
    </>
  );
}
