import React, { useState, useMemo } from "react";
import { Button } from "features/common/OSG";
import { Varekjop } from "interfaces";
import { useIntl } from "react-intl";
import TextArea from "features/common/BaseComponents/Textarea/TextArea";
import { piggavService } from "api";
import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { FormattedMessage } from "locale/langUtils";

import { addHours } from "date-fns";
import intervalToDuration from "date-fns/intervalToDuration";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import { isWithinTimeRange } from "utils/dateFnsUtils";
import { isEmpty } from "lodash-es";

interface CancelPurchaseProps {
  varekjop: Varekjop;
  cancelPurchaseCallback: () => void;
}

export default function CancelPurchase({ varekjop, cancelPurchaseCallback }: CancelPurchaseProps): JSX.Element {
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [comment, setComment] = useState("");

  const { formatMessage } = useIntl();

  const handleAvbrytKjop = () => {
    setIsLoading(true);
    setSuccessMessage("");
    setErrorMessage("");
    if (!isWithinTimeRange(new Date(), new Date(varekjop.betalt), addHours(new Date(varekjop.betalt), 24))) {
      setErrorMessage(formatMessage({ id: "receiptpage.cancelpurchase.errorTimesUp" }));
    }
    piggavService
      .avbrytFeilkjop(varekjop.brukerId, varekjop.id, {
        kommentar: comment
      })
      .then(() => {
        setSuccessMessage(
          formatMessage({
            id: "receiptpage.cancelpurchase.successMessage",
            defaultMessage: "Varekjøpet er nå avbrutt og oblatene har blitt deaktivert"
          })
        );
      })
      .catch(() => {
        setErrorMessage(
          formatMessage({
            id: "receiptpage.cancelpurchase.errorMessage",
            defaultMessage: "Noe gikk valt og vi kunne ikke avbryyte varekjøpet"
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const timeLft = useMemo(() => {
    const start = new Date(varekjop.betalt);
    const end = addHours(start, 24);

    return intervalToDuration({ start: new Date(), end });
  }, [varekjop]);

  const toggleOpen = () => {
    if (isOpen && !isEmpty(successMessage))
      // Call the cancel purchase callback on success.
      cancelPurchaseCallback();
    setIsOpen(prev => !prev);
  };

  return (
    <>
      <p className="mb-2">
        <strong>
          <FormattedMessage
            id="receiptpage.cancelpurchase.buttonInstructionText"
            values={{
              hours: timeLft.hours,
              minutes: timeLft.minutes
            }}
          />
        </strong>
      </p>
      <Button className="mobile-only:w-full" colorOption="red" onClick={toggleOpen}>
        <FormattedMessage id="receiptpage.cancelpurchase.cancel" />
      </Button>

      <Modal staticModal isOpen={isOpen} aria-hidden toggle={toggleOpen}>
        <ModalHeader>{{ heading: <FormattedMessage id="receiptpage.cancelpurchase.title" /> }}</ModalHeader>
        <ModalSection>
          <div className="mb-3">
            {errorMessage !== "" && <MessageBox type="danger">{errorMessage}</MessageBox>}
            {successMessage !== "" && <MessageBox type="success">{successMessage}</MessageBox>}
            {!errorMessage && !successMessage && (
              <MessageBox type="primary">
                <FormattedMessage id="receiptpage.cancelpurchase.infoMessage" />
              </MessageBox>
            )}
          </div>

          {!successMessage && (
            <form>
              <TextArea className="w-full" rows={6} onChange={e => setComment(e.currentTarget.value)} />
              {comment === "" && (
                <MessageBox className="mt-2" type="danger">
                  <FormattedMessage id="receiptpage.cancelpurchase.requiredField" />
                </MessageBox>
              )}
            </form>
          )}
        </ModalSection>
        <ModalSection className="flex justify-end">
          {successMessage !== "" ? (
            <Button onClick={toggleOpen} disabled={isLoading}>
              <FormattedMessage id="receiptpage.cancelpurchase.close" />
            </Button>
          ) : (
            <>
              <Button
                className="mr-2"
                colorOption="blue-dark"
                onClick={handleAvbrytKjop}
                disabled={isLoading || comment === ""}
              >
                <FormattedMessage id="receiptpage.cancelpurchase.continue" />
              </Button>
              <Button modifier="outline" onClick={toggleOpen} disabled={isLoading}>
                <FormattedMessage id="receiptpage.cancelpurchase.cancel" />
              </Button>
            </>
          )}
        </ModalSection>
      </Modal>
    </>
  );
}
