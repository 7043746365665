import { useState } from "react";
import { useParams } from "react-router-dom";
import Page from "features/common/layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Section from "features/common/layout/Section";
import { FormattedMessage } from "locale/langUtils";
import InputField from "features/common/ComposedComponents/Inputfield/Inputfield";
import { Button, LinkButton } from "features/common/OSG";
import { useIntl } from "react-intl";
import * as urls from "appConstants/urls";
import { passwordRegex } from "utils/validationUtils";
import { putGlemtPassord } from "api/piggavService";

export default function ForgotPasswordPage(): JSX.Element {
  const [isSendingNewPWDRequest, setIsSendingResetPWDRequest] = useState(false);
  const [isPasswordChanged, setIsPasswordChanged] = useState(false);
  const { formatMessage } = useIntl();
  const { key } = useParams<{ key: string }>();
  const forgotPasswordSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required(
        formatMessage({
          id: "common.error.requiredfield",
          defaultMessage: "Feltet må fylles ut"
        })
      )
      .matches(
        passwordRegex,
        formatMessage({
          id: "common.error.invalidpassword",
          defaultMessage: "Passord må være 8 tegn eller lengre, og inneholde minst 1 stor bokstav og 1 tall"
        })
      ),
    repeatPassword: yup
      .string()
      .required(
        formatMessage({
          id: "common.error.requiredfield",
          defaultMessage: "Feltet må fylles ut"
        })
      )
      .oneOf(
        [yup.ref("newPassword")],
        formatMessage({
          id: "common.error.equalpassword",
          defaultMessage: "Passordene må være like."
        })
      )
  });
  const { handleSubmit, register, errors, setError } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(forgotPasswordSchema)
  });

  const handleNewPasswordSubmit = handleSubmit(async ({ newPassword }) => {
    try {
      setIsSendingResetPWDRequest(true);
      await putGlemtPassord(key ?? "", newPassword);
      setIsPasswordChanged(true);
    } catch {
      setError("repeatPassword", {
        type: "errorWhenUpdating",
        message: formatMessage({
          id: "newpasswordpage.error.whenupdating",
          defaultMessage:
            "Kunne ikke oppdatere passord, prøv igjen eller kontakt oss, se nederst på siden for kontaktinfo"
        })
      });
    } finally {
      setIsSendingResetPWDRequest(false);
    }
  });

  return (
    <Page
      metaInfo={{
        titlePageName: formatMessage({ id: "newpasswordpage.meta.title" }),
        titleDescription: formatMessage({ id: "newpasswordpage.meta.title.description" }),
        robots: "noindex"
      }}
    >
      {{
        heading: {
          pageTitle: isPasswordChanged ? (
            <FormattedMessage id="newpasswordpage.heading.passwordupdated" defaultMessage="Sjekk e-posten din!" />
          ) : (
            <FormattedMessage id="newpasswordpage.heading" defaultMessage="Lag et nytt passord" />
          )
        },
        pageContent: (
          <Section>
            <div className={`${isPasswordChanged ? "hidden" : ""}`}>
              <form id="new-password-form" className="w-full tablet:w-1/2" onSubmit={handleNewPasswordSubmit}>
                <InputField
                  name="newPassword"
                  label={formatMessage({
                    id: "newpasswordpage.label.newpassword",
                    defaultMessage: "Nytt passord"
                  })}
                  error={errors.newPassword?.message}
                  ref={register}
                  type="password"
                />
                <InputField
                  name="repeatPassword"
                  label={formatMessage({
                    id: "newpasswordpage.label.repeatpassword",
                    defaultMessage: "Repetér det nye passordet"
                  })}
                  error={errors.repeatPassword?.message}
                  ref={register}
                  type="password"
                />
                <div className="flex justify-end">
                  <Button className="" form="new-password-form" type="submit" disabled={isSendingNewPWDRequest}>
                    <FormattedMessage id="newpasswordpage.button.save" defaultMessage="Lagre passord" />
                  </Button>
                </div>
              </form>
            </div>
            <div className={`${isPasswordChanged ? "" : "hidden"}`}>
              <p className="text-3 mb-4">
                <FormattedMessage id="newpasswordpage.info" defaultMessage="" />
              </p>

              <LinkButton to={urls.hjem} modifier="outline" colorOption="yellow" className="text-3 hover:no-underline">
                <FormattedMessage
                  id="newpasswordpage.link.gotologin"
                  defaultMessage="Lenke for å gjenopprette passordet ditt er sendt til din e-post."
                />
              </LinkButton>
            </div>
          </Section>
        )
      }}
    </Page>
  );
}
