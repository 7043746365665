import { FormattedMessage } from "react-intl";

interface KvitteringsnummerProps {
    className?: string;
    kvitteringsnummer: number;
  }
export function Kvitteringsnummer({className, kvitteringsnummer}:KvitteringsnummerProps) {
  return (
    <div className={className}>
    <FormattedMessage id="receiptpage.receiptNumner" />: {kvitteringsnummer}
    </div>
  );
}
