import React from "react";
import { useIntl } from "react-intl";
import LinkAsButton from "../BaseComponents/LinkAsButton/LinkAsButton";
import { FormattedMessage } from "locale/langUtils";

interface PagingProps extends React.HTMLProps<HTMLDivElement> {
  totalNumberOfRows: number;
  pageSize: number;
  pageIndex: number;
  canNextPage: boolean;
  canPreviousPage: boolean;
  beginFrom?: number;
  previousPage: (a: any) => void;
  nextPage: (a: any) => void;
  showPaginationControlls?: boolean;
}

export default function Paging({
  totalNumberOfRows,
  pageSize,
  pageIndex,
  canPreviousPage,
  canNextPage,
  beginFrom = 0,
  previousPage,
  nextPage,
  className,
  showPaginationControlls = true,
  ...restDivProps
}: PagingProps): JSX.Element {
  const { formatMessage } = useIntl();

  const from = totalNumberOfRows < 1 ? 0 : pageSize * pageIndex + beginFrom;

  const to = canNextPage ? pageIndex * pageSize + pageSize : totalNumberOfRows;

  return (
    <nav
      aria-label={FormattedMessage({ id: "table.paging.aria" })}
      className={`${showPaginationControlls ? "flex" : "hidden"} items-center mt-3 ${className ?? ""}`}
      {...(restDivProps as any)}
    >
      <span className="w-3/12 tablet:w-1/3 text-6 text-left tablet:text-center">
        <LinkAsButton aria-current={"page"} disabled={!canPreviousPage} onClick={previousPage}>
          <FormattedMessage id="table.paging.button.previous" />
        </LinkAsButton>
      </span>
      <span

        className="w-6/12 tablet:w-1/3 text-center osg-u-text-is-medium"
      >
        <span aria-live="polite" className="sr-only">
          <FormattedMessage id="table.paging.info.aria" values={{ from, to, total: totalNumberOfRows }} />{" "}
        </span>
        {`${from} - ${to} ${formatMessage({
          id: "table.paging.of"
        })} ${totalNumberOfRows}`}
      </span>

      <span className="w-3/12 tablet:w-1/3 text-6 text-right tablet:text-center">
        <LinkAsButton aria-current={"page"} disabled={!canNextPage} onClick={nextPage}>
          <FormattedMessage id="table.paging.button.next" />
        </LinkAsButton>
      </span>
    </nav>
  );
}
