import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import CurrencyFormat from "locale/CurrencyFormat";
import DateTimeFormat from "locale/DateTimeFormat";

import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import { FormattedMessage } from "locale/langUtils";
import { Button } from "features/common/OSG";
import { Oblat } from "interfaces";
import React from "react";
import { useLocation } from "react-router-dom";
import { useIntl } from "react-intl";

export default function RefusjonBekreftet(): JSX.Element {
  const location = useLocation<{
    oblat?: Oblat;
    caseInfo?: {
      saksbehandlerNavn: string;
      kontonummer: string;
      kontaktinformasjon: string;
      melding: string;
      refundertDato?: string;
      refusjonssum: number;
    };
  }>();
  const { caseInfo, oblat } = location.state;
  const intl = useIntl();

  return (
    <Page
      metaInfo={{
        titlePageName: intl.formatMessage({ id: "refundConfirmedPage.meta.title" }),
        titleDescription: intl.formatMessage({ id: "refundConfirmedPage.meta.title.description" })
      }}
    >
      {{
        heading: {
          pageTitle: <FormattedMessage id="refundConfirmedPage.title" />,
          subtitle: <FormattedMessage id="refundConfirmedPage.subtitle" />
        },
        pageContent: (
          <Section>
            <div className="osg-simple-table ">
              <table>
                <thead>
                  <tr>
                    <th className="osg-content">
                      <FormattedMessage id="common.vehicle" />
                    </th>
                    <th className="osg-content">
                      <FormattedMessage id="common.stickertype" />
                    </th>
                    <th className="osg-content">
                      <FormattedMessage id="common.stickerinfo.validfrom" />
                    </th>
                    <th className="osg-content">
                      <FormattedMessage id="common.stickerinfo.validto" />
                    </th>
                    <th className="osg-content">
                      <FormattedMessage id="refundConfirmedPage.KjoptDato" />
                    </th>
                    <th className="osg-content">
                      <FormattedMessage id="refundConfirmedPage.RefundertDato" />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="osg-content">{oblat?.kjoretoy?.registreringsnummer}</td>
                    <td className="osg-content">{oblat?.oblattype}</td>
                    <td className="osg-content">
                      <DateTimeFormat value={oblat?.gyldigFra} />
                    </td>
                    <td className="osg-content">
                      <DateTimeFormat value={oblat?.gyldigTil} />
                    </td>
                    <td className="osg-content">
                      <DateTimeFormat value={oblat?.kjopsinfo?.kjopsdato} />
                    </td>
                    <td className="osg-content">
                      <DateTimeFormat value={caseInfo?.refundertDato} />
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={10} style={{ textAlign: "right" }}>
                      <FormattedMessage id="refundConfirmedPage.Refusjonssum" />:{" "}
                      <CurrencyFormat value={caseInfo?.refusjonssum} />
                    </th>
                  </tr>
                </tfoot>
              </table>
              <MessageBox className="mt-3 osg-content tablet:w-1/2" type="primary">
                <dl>
                  <dt className="text-4">
                    <FormattedMessage id="refundConfirmedPage.Saksbehandler" />
                  </dt>
                  <dd>{caseInfo?.saksbehandlerNavn}</dd>

                  <dt className="text-4">
                    <FormattedMessage id="refundConfirmedPage.Kontonummer" />
                  </dt>
                  <dd>{caseInfo?.kontonummer}</dd>

                  <dt className="text-4">
                    <FormattedMessage id="refundConfirmedPage.Kontaktinformasjon" />
                  </dt>
                  <dd>{caseInfo?.kontaktinformasjon}</dd>

                  <dt className="text-4">
                    <FormattedMessage id="refundConfirmedPage.Melding" />
                  </dt>
                  <dd>{caseInfo?.melding}</dd>
                </dl>
              </MessageBox>
              <MessageBox type="success" className="osg-content mt-3 tablet:w-1/2 print:hidden">
                <p>
                  <FormattedMessage id="refundConfirmedPage.deaktivertISystemet" />
                </p>
              </MessageBox>
              <div className="mt-3 mb-3">
                <h4 className="text-4">Oslo kommune, Bymiljøetaten</h4>
                <ul className="list-none">
                  <li>Karvesvingen 3</li>
                  <li>0579 Oslo</li>
                  <li>Org.nr.: 996 922 766</li>
                </ul>
              </div>
              <span className="print:hidden">
                <Button onClick={() => window.print()}>
                  <FormattedMessage id="refundConfirmedPage.SkrivUt" />
                </Button>
              </span>
            </div>
          </Section>
        )
      }}
    </Page>
  );
}
