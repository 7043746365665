import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import { FormattedMessage, useIntl } from "locale/langUtils";
import React from "react";
import UploadFileSection from "./UploadFileSection";
import UploadTextareaListSection from "./UploadTextareaListSection";

export default function BuyStickersBulkUploadPage(): JSX.Element {
  const intl = useIntl();
  return (
    <Page
      metaInfo={{
        titlePageName: intl.formatMessage({ id: "buystickersbulkpage.meta.title" }),
        titleDescription: intl.formatMessage({ id: "buystickersbulkpage.meta.title.description" })
      }}
    >
      {{
        heading: {
          pageTitle: <FormattedMessage id="buystickersbulkpage.heading" />,
          subtitle: <FormattedMessage id="buystickersbulkpage.subheading" />
        },
        pageContent: (
          <Section>
            <div className="flex mobile-only:flex-col items-stretch">
              <UploadFileSection className="tablet:w-1/2" />
              <UploadTextareaListSection className="tablet:w-1/2" />
            </div>
          </Section>
        )
      }}
    </Page>
  );
}
