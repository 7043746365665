import React, { ReactNode } from "react";
import MessageBox, { MessageBoxProps } from "features/common/BaseComponents/MessageBox/MessageBox";
import { Icon, IconTypes } from "features/common/OSG";
import "./MessageBoxWIcon.scss";

export interface MessageBoxWIconProps extends MessageBoxProps {
  icon: IconTypes;
  iconPosition?: "left" | "right";
  children?: ReactNode;
}

export default function MessageBoxWIcon({
  icon,
  children,
  type,
  iconPosition,
  className
}: MessageBoxWIconProps): JSX.Element {
  return (
    <MessageBox type={type} className={`mbox ${className}`}>
      <span className="mbox-message ">{children}</span>
      <Icon
        className={`mbox-icon ${iconPosition === "left" ? "left osg-u-margin-right-2" : "right osg-u-margin-left-2"}`}
        icon={icon}
      />
    </MessageBox>
  );
}
