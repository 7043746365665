import React, { SyntheticEvent, useContext, useRef, useState } from "react";
import { Button } from "features/common/ComposedComponents";
import { FormattedMessage, useIntl } from "locale/langUtils";
import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import * as yup from "yup";
import { piggavService } from "api";
import { useSelector } from "react-redux";
import { getState as getAuthState } from "state/Auth/Auth";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import eksempelfilExcel from "../../images/eksempelfil-excel.png";
import eksempelfilTxt from "../../images/eksempelfil-txt.png";
import fileIcon from "../../images/file-icon.png";
import { BulkBuyContext } from "./BuyStickersBulk";
import { isEmpty } from "lodash-es";

interface UploadFileSectionProps extends React.HTMLProps<HTMLDivElement> {
  submit?: string;
}

const SUPPORTED_FORMATS = [
  ".txt",
  "text/plain",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet csv",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-excel"
];

const SUPPORTED_FORMATS_STRING = SUPPORTED_FORMATS.join(", ");

const schema = yup.object().shape({
  fileInput: yup
    .mixed()
    .required("buystickersbulkpage.error.fileRequired")
    .test("fileFormat", "buystickersbulkpage.error.format", (value: FileList) => {
      const file = value[0];
      return SUPPORTED_FORMATS.includes(file.type);
    })
});

export default function UploadFileSection({ submit, className, ...rest }: UploadFileSectionProps): JSX.Element {
  const [isExampleDialogueOpen, setIsExampleDialogueOpen] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const intl = useIntl();
  const { userInfo } = useSelector(getAuthState);

  const { checkAndValidateListAndAddToBasket, dispatch } = useContext(BulkBuyContext);

  const { errors, setError, register, clearErrors, handleSubmit, reset, watch } = useForm({
    resolver: yupResolver(schema),
    criteriaMode: "firstError",
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      fileInput: ""
    }
  });

  const onFileUploadClicked = (e: SyntheticEvent) => {
    e.preventDefault();
    reset();
    if (fileInputRef.current) fileInputRef.current.value = "";
    fileInputRef.current?.click();
  };

  const handleUploadAndCheckTheList = handleSubmit(async ({ fileInput }) => {
    try {
      clearErrors();
      setIsUploadingFile(true);
      const fil = fileInput["0"] as unknown as File;
      const data = new FormData();
      data.append("file", fil);
      data.append("name", fil?.name);

      const {
        data: { result: kjoretoyList }
      } = await piggavService.uploadKjoretoyFile(userInfo?.brukerId ?? "", data);

      dispatch({
        type: "BUY_BULK_SET_BEKREFTEDE_AND_UBEKREFTEDE_VEHICLES",
        ...kjoretoyList
      });

      try {
        await checkAndValidateListAndAddToBasket(kjoretoyList.ubekreftedeKjoretoy, kjoretoyList.bekreftedeKjoretoy);
      } catch (e) {
        setError("fileInput", {
          message: "buystickersbulkpage.error.addingToBasket"
        });
      }
    } catch (e: any) {
      const errorMessage: string | undefined = e?.response?.data?.ErrorMessage;
      if (errorMessage?.includes("same key has already been added")) {
        setError("fileInput", {
          message: "buystickersbulkpage.error.not.unique"
        });
      } else {
        setError("fileInput", {
          message: "buystickersbulkpage.error.fileUpload"
        });
      }
    } finally {
      setIsUploadingFile(false);
    }
  });

  const { fileInput } = watch();

  return (
    <>
      <div className={`bg-beige p-4 ${className}`} {...rest}>
        <h2 className="heading-4 mb-4">
          <FormattedMessage id="buystickersbulkpage.heading.uploadfile" />
        </h2>
        <p className="pl-3">
          <FormattedMessage id="buystickersbulkpage.howToUseInstructions_1" />
        </p>
        <ul className="list-inside pl-3">
          <li>
            - <FormattedMessage id="buystickersbulkpage.howToUseInstructions_2" />
          </li>
          <li>
            - <FormattedMessage id="buystickersbulkpage.howToUseInstructions_3" />
          </li>
        </ul>

        <div className="my-5">
          <button
            aria-haspopup="dialog"
            aria-controls="example-dialogue"
            aria-expanded={isExampleDialogueOpen}
            onClick={e => {
              e.preventDefault();
              setIsExampleDialogueOpen(prev => !prev);
            }}
            className="text-button mx-auto text-4"
            aria-label={intl.formatMessage({ id: "buystickersbulkpage.showExample.aria" })}
          >
            <FormattedMessage id="buystickersbulkpage.showExample" />
          </button>
        </div>
        <input
          name="fileInput"
          aria-hidden
          className="hidden"
          ref={r => {
            register(r);
            fileInputRef.current = r; // you can still assign to ref
          }}
          type="file"
          accept={SUPPORTED_FORMATS_STRING}
        />
        <Button onClick={onFileUploadClicked} type="submit" icon="download" colorOption="green-dark">
          <FormattedMessage id="buystickersbulkpage.button.chooseFile" />
        </Button>
        <div>
          {!isEmpty(fileInput) && (
            <>
              <div className="bg-white p-4 flex flex-col justify-center mt-5">
                <img className="bg-white self-center" alt="fil ikon" src={fileIcon} />
                <span className="ml-2 text-4 text-center">
                  <FormattedMessage
                    id="buystickersbulkpage.chosenfile"
                    values={{ chosenFile: (fileInput as unknown as any)[0]?.name }}
                  />
                </span>
              </div>
              <MessageBox className="mt-3" type="danger" visible={errors?.fileInput !== undefined}>
                <FormattedMessage
                  id={errors.fileInput?.message ?? " "}
                  defaultMessage="Noe galt skjedde når vi prøvde å laste opp file, venligst prøv igjen"
                />
              </MessageBox>
              <Button
                onClick={handleUploadAndCheckTheList}
                className="inline-block ml-auto mt-3"
                type="submit"
                colorOption="blue-dark"
                isLoading={isUploadingFile}
                disabled={isUploadingFile}
              >
                <FormattedMessage id="buystickersbulkpage.button.addToBasket" />
              </Button>
            </>
          )}
        </div>
      </div>
      <Modal
        id="example-dialogue"
        toggle={() => {
          setIsExampleDialogueOpen(prev => !prev);
        }}
        isOpen={isExampleDialogueOpen}
      >
        <ModalHeader>
          {{
            heading: <FormattedMessage id="buystickersbulkpage.modal.example.Header" />
          }}
        </ModalHeader>
        <ModalSection>
          <div className="mb-5">
            <span className="inline-block mb-2 text-5">
              <FormattedMessage id="buystickersbulkpage.modal.example.excel" />
            </span>
            <div className="bg-grey-light border border-grey-dark border-solid flex justify-center py-6 px-3">
              <img
                alt={intl.formatMessage({ id: "buystickersbulkpage.showExample.excel.alt" })}
                src={eksempelfilExcel}
              />
            </div>
          </div>
          <div>
            <span className="inline-block mb-2 text-5">
              <FormattedMessage id="buystickersbulkpage.modal.example.txt" />
            </span>
            <div className="bg-grey-light border border-grey-dark border-solid flex justify-center py-6 px-3">
              <img
                className="ml-auto mr-auto"
                alt={intl.formatMessage({ id: "buystickersbulkpage.showExample.txt.alt" })}
                src={eksempelfilTxt}
              />
            </div>
          </div>
        </ModalSection>
      </Modal>
    </>
  );
}
