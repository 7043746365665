import React, { ReactNode } from "react";
import HelmetMeta, { HelmetMetaProps } from "features/common/HelmetMetaInfo/HelmetMeta";
import SkipLink from "./SkipLink";
import { useIntl } from "locale/langUtils";

interface PageProps extends React.HTMLAttributes<HTMLDivElement> {
  metaInfo?: HelmetMetaProps;
  skipLink?: { text: ReactNode; jumptoId: string };
  children: {
    heading?: {
      pageTitle: ReactNode;
      subtitle?: ReactNode;
      backgroundColorClass?: string;
    };
    pageContent?: ReactNode;
  };
}

export default function Page({
  metaInfo,
  skipLink,
  children: { heading, pageContent },
  className
}: PageProps): JSX.Element {
  const intl = useIntl();
  return (
    <>
      <HelmetMeta {...metaInfo} />
      <SkipLink
        text={skipLink?.text ?? intl.formatMessage({ id: "page.skip.link.default" })}
        jumpToId={skipLink?.jumptoId ?? "maincontent"}
      />
      <div tabIndex={-1} id="maincontent" className={`flex flex-col ${className}`}>
        {heading && (
          <div className={`heading-section flex justify-center  ${heading?.backgroundColorClass ?? "bg-white"}`}>
            <div
              className={`w-full osg-u-padding-horizontal-3 widescreen:w-widescreen-content-max-width ${
                heading?.backgroundColorClass ?? "bg-white"
              }`}
            >
              <h1 className="osg-u-heading-1 osg-u-padding-bottom-2 osg-u-padding-top-3 osg-u-padding-bottom-3-desktop-and-up osg-u-padding-top-5-tablet-and-up">
                {heading.pageTitle}
              </h1>

              {heading?.subtitle && <p className="osg-u-text-2">{heading.subtitle}</p>}
            </div>
          </div>
        )}
        {pageContent}
      </div>
    </>
  );
}
