import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useReduxDispatch } from "utils/Storeutils";
import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import { FormattedMessage, useIntl } from "locale/langUtils";
import { getState as getAuthState } from "state/Auth/Auth";
import { getState as getKundeState } from "state/Kunde/kundeReducer";
import { getKunde } from "state/Kunde/kundeActions";

import { Kunde } from "interfaces";
import { isEmpty } from "lodash-es";
import ChangePasswordSetting from "./ChangePasswordSetting";
import ChangeCompanySetting from "./ChangeCompanySetting";
import ChangeEmailSetting from "./ChangeMailSetting";
import ChangeContactInfoSetting from "./ChangeContactInfoSetting";
import KjoretoySetting from "./VehiclesSetting";
import ChangeReminderOnMailSetting from "./ChangeReminderOnMailSetting";
import ChangeConsentSetting from "./ChangeConsentSetting";
import { useAuth } from "react-oidc-context";
import DeleteUserSetting from "./DeleteUserSetting";

export default function ProfilePage(): JSX.Element {
  const auth = useAuth();
  const customerInfo = useSelector<any, Kunde>(getKundeState);
  const { userInfo } = useSelector(getAuthState);
  const dispatch = useReduxDispatch();

  const intl = useIntl();

  /** Effect for getting customer information */
  useEffect(() => {
    if (userInfo?.brukerId) dispatch(getKunde(userInfo.brukerId));
  }, [dispatch, userInfo]);

  function isActiveDirectoryUser() {
    return auth.user?.profile.email && auth.user?.profile.email?.endsWith("@bym.oslo.kommune.no");
  }

  return (
    <Page
      metaInfo={{
        titlePageName: intl.formatMessage({ id: "profile.meta.title" }),
        titleDescription: intl.formatMessage({ id: "profile.meta.title.description" })
      }}
    >
      {{
        heading: { pageTitle: <FormattedMessage id="profile.heading" /> },
        pageContent: (
          <>
            <Section colorClass="even:bg-grey-light" modifier="osg-u-padding-vertical-5">
              <ChangeEmailSetting className="w-full tablet:w-1/2" />
            </Section>
            {
              //Changing the password here on an AD user does not give expected results, so we hide the option.
              !isActiveDirectoryUser() && (
                <Section colorClass="even:bg-grey-light" modifier="osg-u-padding-vertical-5">
                  <ChangePasswordSetting className="w-full tablet:w-1/2" />
                </Section>
              )
            }
            {customerInfo.kundetypeId === 2 && (
              <>
                <Section colorClass="even:bg-grey-light" modifier="osg-u-padding-vertical-5">
                  {!isEmpty(customerInfo) && (
                    <ChangeContactInfoSetting className="w-full tablet:w-1/2" customerInfo={customerInfo} />
                  )}
                </Section>
                <Section colorClass="even:bg-grey-light" modifier="osg-u-padding-vertical-5">
                  {!isEmpty(customerInfo) && (
                    <ChangeCompanySetting className="w-full tablet:w-1/2" customerInfo={customerInfo} />
                  )}
                </Section>
              </>
            )}
            <Section colorClass="even:bg-grey-light" modifier="osg-u-padding-vertical-5">
              <KjoretoySetting />
            </Section>
            <Section colorClass="even:bg-grey-light" modifier="osg-u-padding-vertical-5">
              <ChangeReminderOnMailSetting
                className="w-full"
                userId={userInfo?.brukerId ?? ""}
                customerInfo={customerInfo}
              />
            </Section>
            <Section colorClass="even:bg-grey-light" modifier="osg-u-padding-vertical-5">
              <ChangeConsentSetting className="w-full" />
            </Section>
            <Section colorClass="even:bg-grey-light" modifier="osg-u-padding-vertical-5">
              <DeleteUserSetting />
            </Section>
          </>
        )
      }}
    </Page>
  );
}
