import { useHistory } from "react-router-dom";
import { OSGPattern, ColoredOSGPattern } from "../../interfaces";
import "./LinkButton.scss";
import React from "react";

type Modifiers = "circle" | "outline";
type Colors = "blue-dark" | "green-dark" | "green-light" | "red" | "yellow";
type AnchorProps = JSX.IntrinsicElements["a"];

export interface AnchorLinkButtonProps
  extends AnchorProps,
    OSGPattern<undefined, Modifiers>,
    ColoredOSGPattern<Colors> {
  size?: "big" | "normal" | "small";
}

export const AnchorLinkButton = React.forwardRef<HTMLAnchorElement, AnchorLinkButtonProps>(
  ({ modifier, colorOption = "blue-dark", children, className, size = "normal", ...rest }, ref) => {
    return (
      <a
        className={`osg-link-button osg-v-default osg-link-button--${colorOption} ${
          modifier ? `osg-link-button--${modifier}` : ""
        } osg-link--${size} osg-u-text-4 ${className}`}
        {...rest}
        ref={ref}
      >
        {children}
      </a>
    );
  }
);

export interface LinkButtonPropTypes extends AnchorLinkButtonProps {
  to: string;
}

export default function LinkButton({ to, ref, ...rest }: LinkButtonPropTypes) {
  const history = useHistory();
  return (
    <AnchorLinkButton
      onClick={e => {
        history.push(to);
        e.preventDefault();
      }}
      href={to}
      {...rest}
    />
  );
}
