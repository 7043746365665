import React, { useContext } from "react";
import { Button } from "features/common/OSG";
import { LocaleContext, LocaleConfig } from "./LocaleProvider";
import "./LanguagePicker.scss";
import { FormattedMessage, useIntl } from "./langUtils";

export interface RenderProps {
  onChange: (locale: string) => void;
  currentLocale: LocaleConfig;
  onClick: () => void;
  TranslateText: () => JSX.Element;
  key?: string;
}

interface LanguagePickerProps {
  render?: (renderProps: RenderProps) => JSX.Element;
}

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const LanguagePicker: React.FunctionComponent<LanguagePickerProps> = ({
  // eslint-disable-next-line react/prop-types
  render
}) => {
  const useLocale = useContext(LocaleContext);
  const intl = useIntl();
  const { onChangeLanguage, currentLocale } = useLocale;

  if (render)
    return (
      <>
        {currentLocale.available.map((locale: string) =>
          render({
            currentLocale,
            onChange: onChangeLanguage,
            onClick: () => {
              onChangeLanguage(locale);
            },
            TranslateText: () => <FormattedMessage id={`languagePicker.buttontxt-${locale}`} defaultMessage={locale} />,
            key: locale
          })
        )}
      </>
    );

  return (
    <div className="languageSelector-container">
      {currentLocale.available.map((locale: string) => (
        <div className="languageSelector-element" key={locale}>
          <Button
            modifier="outline"
            onClick={() => onChangeLanguage(locale)}
            aria-label={intl.formatMessage({ id: "languagePicker.button.aria" }) ?? "laksøldkløaskdløaskdløaskdlø"}
          >
            <FormattedMessage id={`languagePicker.buttontxt-${locale}`} defaultMessage={locale} />
          </Button>
        </div>
      ))}
    </div>
  );
};

export default LanguagePicker;
