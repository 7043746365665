import React, { ReactNode, ReactNodeArray, useState } from "react";
import { FormattedMessage, useIntl } from "locale/langUtils";
import LinkAsButton from "features/common/BaseComponents/LinkAsButton/LinkAsButton";
import { uniqueId } from "lodash-es";

interface ProfilSectionProps extends React.HTMLProps<HTMLDivElement> {
  isOpen?: boolean;
  toggle?: () => any;
  children: {
    heading: string;
    closedContent?: ReactNode;
    openContent?: ReactNodeArray | ReactNode;
  };
}

export default function ProfilSection({
  children: { heading, closedContent, openContent },
  toggle,
  isOpen,
  id,
  ref,
  ...rest
}: ProfilSectionProps): JSX.Element {
  const [uId] = useState(id ?? uniqueId("accordion--"));
  const togglerId = `${uId}--toggler`;
  const expanded = isOpen && !!openContent;
  const intl = useIntl();
  return (
    <div {...rest} ref={ref}>
      <div className="flex justify-between items-center ">
        <h2 className="heading-3">{heading}</h2>
        {openContent && (
          <LinkAsButton
            onClick={toggle}
            aria-label={intl.formatMessage({ id: "profile.button.edit.aria" }, { setting: heading })}
            aria-expanded={expanded}
            aria-controls={uId}
            id={togglerId}
          >
            {isOpen ? <FormattedMessage id="profile.button.close" /> : <FormattedMessage id="profile.button.edit" />}{" "}
            <span className="sr-only">{heading.toLowerCase()}</span>
          </LinkAsButton>
        )}
      </div>

      <div
        aria-hidden={!closedContent || expanded}
        hidden={!closedContent || expanded}
        className="w-full tablet:w-10/12 osg-u-margin-top-3 overflow-hidden break-words"
      >
        {closedContent}
      </div>

      <div
        id={uId}
        role="region"
        hidden={!expanded}
        aria-hidden={!expanded}
        aria-labelledby={togglerId}
        className="w-full tablet:w-9/12 osg-u-margin-top-3"
      >
        {openContent}
      </div>
    </div>
  );
}
