import React from "react";
import { FormattedNumber } from "react-intl";

interface DateTimeFormatProps {
  value?: number;
}
export default function CurrencyFormat({ value }: DateTimeFormatProps): JSX.Element {
  return (
    <FormattedNumber
      value={value ?? 0}
      // eslint-disable-next-line react/style-prop-object
      style="currency"
      unitDisplay="short"
      currency="NOK"
    />
  );
}
