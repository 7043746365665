import {
  GET_VAREKJOP_SUCCESS,
  GET_VAREKJOP_BY_ID_SUCCESS,
  GET_OBLATER_FOR_VAREKJOP_SUCCESS,
  GET_VAREKJOP_REQUEST,
  DELETE_VAREKJOP_SUCCESS,
  RESET_VAREKJOP
} from "./varekjopActions";

const INIT_STATE = { varekjopListe: [], varekjop: { oblater: [] } };

// eslint-disable-next-line @typescript-eslint/default-param-last
export default function ordreReducer(state = { ...INIT_STATE }, action) {
  switch (action.type) {
    case RESET_VAREKJOP:
      return { ...INIT_STATE };
    case GET_VAREKJOP_SUCCESS:
      return { ...state, varekjopListe: action.payload };
    case GET_VAREKJOP_BY_ID_SUCCESS:
      return { ...state, varekjop: action.payload };
    case GET_OBLATER_FOR_VAREKJOP_SUCCESS:
      return {
        ...state,
        varekjop: { ...state.varekjop, oblater: action.payload }
      };
    case GET_VAREKJOP_REQUEST:
      return { ...state, varekjop: {} };

    // case VarekjopActions.CREATE_VAREKJOP_SUCCESS:
    //   return { ...state, id: action.payload };
    case DELETE_VAREKJOP_SUCCESS:
      return { ...INIT_STATE };
    // case VarekjopActions.BEREGN_VAREKJOP_SUCCESS:
    //   return state;
    default:
      return state;
  }
}
