/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { FunctionComponent, HTMLProps, ReactNode, useLayoutEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import { Button, Icon } from "features/common/OSG";
import "./modal.scss";
import ReactDOM from "react-dom";
import { useIntl } from "locale/langUtils";
import { uniqueId } from "lodash-es";

type Size = "small" | "medium" | "large";

const SET_FOCUS_TO_FOCUSABLE_CHILD_NR = 1;

export interface ModalProps extends Omit<HTMLProps<HTMLDivElement>, "ref"> {
  toggle?: () => any;
  isOpen?: boolean;
  children: ReactNode;
  staticModal?: boolean;
  onHide?: Function; // Yet to be implemented
  modalSize?: Size;
}

export default function Modal({
  children,
  toggle,
  isOpen = false,
  staticModal = false,
  onHide,
  modalSize = "medium",
  ...restDivProps
}: ModalProps): JSX.Element {
  const focusableElements = useRef<NodeListOf<HTMLElement> | undefined | null>(undefined);
  const lastFocusedElement = useRef<HTMLElement | null>(null);
  const prevOverflow = useRef(document.body.style.overflow);
  const modalRef = useRef<HTMLDivElement>(null);
  const isTransitioning = useRef(false);

  // Give focus back to the last focusable element on close, And set focus to the first focusable element inside modal
  useLayoutEffect(() => {
    if (!isOpen) {
      lastFocusedElement.current?.focus();
      return;
    }
    // Modal is open save the previous active element
    lastFocusedElement.current = document.activeElement as HTMLElement;
    focusableElements.current = modalRef.current?.querySelectorAll<HTMLElement>(
      'button:not([disabled]), input:not([disabled]), textarea:not([disabled]), area:not([disabled]),select:not([disabled]), [tabindex="0"], a[href], area[href],  iframe, object, embed, *[tabindex], *[contenteditable]'
    );

    if (focusableElements.current === undefined) return;

    if (focusableElements.current.length >= SET_FOCUS_TO_FOCUSABLE_CHILD_NR)
      focusableElements.current.item(SET_FOCUS_TO_FOCUSABLE_CHILD_NR - 1).focus();
    else focusableElements.current?.item(0).focus();
  }, [isOpen]);

  // Sets or removes scroll on body
  useLayoutEffect(() => {
    if (isOpen) {
      prevOverflow.current = document.body.style.overflow;
      document.body.style.setProperty("overflow", "hidden");
    }
    return () => {
      document.body.style.removeProperty("overflow");
    };
  }, [isOpen]);

  const onClickOutsideModal = (event: React.MouseEvent<HTMLDivElement>) => {
    if (staticModal || modalRef.current?.contains(event.target as Node) || isTransitioning.current) return;
    toggle?.();
  };

  const hide = () => {
    // If modal is about to exit, do not toggle or hide
    if (isTransitioning.current) return;
    if (onHide) onHide?.();
    else toggle?.();
  };

  // Handles keypress and make sure focus is trapped while dialog is open.
  const keyPressed = (event: React.KeyboardEvent<HTMLDivElement>) => {
    // Prevent bubblig further
    // event.stopPropagation();
    const focusableElCount = focusableElements.current?.length ?? 0;
    const firstFocusableEl = focusableElements.current?.[0];
    const lastFocusableEl = focusableElements.current?.[focusableElCount - 1];
    switch (event.key.toUpperCase()) {
      case "ESCAPE":
        if (onHide) onHide?.();
        else toggle?.();
        event.preventDefault();
        break;
      case "TAB":
        // Only one focusable element, do nothing
        if (focusableElCount < 2 || !focusableElements.current) {
          event.preventDefault();
          break;
        }
        if (event.shiftKey && firstFocusableEl === document.activeElement) {
          lastFocusableEl?.focus();
          event.preventDefault();
        } else if (!event.shiftKey && lastFocusableEl === document.activeElement) {
          firstFocusableEl?.focus();
          event.preventDefault();
        }
        break;
      default:
        break;
    }
  };

  return (
    <CSSTransition
      in={isOpen}
      timeout={400}
      classNames="modal"
      unmountOnExit
      onEntering={() => {
        isTransitioning.current = true;
      }}
      onEntered={() => {
        isTransitioning.current = false;
      }}
      onExiting={() => {
        isTransitioning.current = true;
      }}
      onExited={() => {
        isTransitioning.current = false;
      }}
    >
      <ModalInner
        modalSize={modalSize}
        onClickOutsideModal={onClickOutsideModal}
        onClose={hide}
        onKeyDown={keyPressed}
        {...restDivProps}
        ref={modalRef}
      >
        {children}
      </ModalInner>
    </CSSTransition>
  );
}

interface ModalInnerProps extends ModalDialogueProps {
  onClickOutsideModal?: (e: React.MouseEvent<HTMLDivElement>) => void;
  modalSize: Size;
}

const ModalInner = React.forwardRef<HTMLDivElement, ModalInnerProps>(
  ({ onClickOutsideModal, ...restModalProps }: ModalInnerProps, ref) => {
    return ReactDOM.createPortal(
      <ModalBackDrop onClick={onClickOutsideModal} tabIndex={-1}>
        <ModalDialogue {...restModalProps} ref={ref} />
      </ModalBackDrop>,
      document.body
    );
  }
);

function ModalBackDrop({ className, ...restDivProps }: React.HTMLProps<HTMLDivElement>): JSX.Element {
  return <div {...restDivProps} className={`modal-backdrop z-modalBackdrop bg-black-backdrop ${className ?? ""}`} />;
}

interface ModalDialogueProps extends React.HTMLProps<HTMLDivElement> {
  onClose?: Function;
  modalSize: Size;
}

const ModalDialogue = React.forwardRef<HTMLDivElement, ModalDialogueProps>(
  ({ modalSize, onClose, children, id, ...restDivProps }: ModalDialogueProps, ref) => {
    const [idx] = useState(id ?? uniqueId("modal--"));
    const headerId = `${idx}--header`;
    const intl = useIntl();

    return (
      <div
        id={idx}
        role="dialog"
        aria-modal="true"
        {...restDivProps}
        className={`modal-dialogue  ${modalSize}`}
        ref={ref}
        aria-labelledby={headerId}
      >
        <div className="modal-heading">
          <div className="modal-heading-space" />
          <Button
            className="modal-heading-close-button"
            colorOption="yellow"
            modifier="circle"
            aria-label={intl.formatMessage({ id: "common.modal.btn.close" })}
            onClick={() => {
              onClose?.();
            }}
          >
            <Icon icon="close" className="" />
          </Button>
        </div>
        <div className="modal-content osg-u-padding-horizontal-3 osg-u-padding-horizontal-4-tablet-and-up osg-u-padding-top-1 osg-u-padding-bottom-3 osg-u-padding-bottom-4-tablet-and-up">
          {React.Children.map(children, (child: any) => {
            if (child?.type?.name === "ModalHeader") return React.cloneElement(child, { id: headerId });
            return child;
          })}
        </div>
      </div>
    );
  }
);

interface ModalHeaderProps {
  className?: string;
  id?: string;
  children: {
    heading?: ReactNode;
    subheading?: ReactNode;
    restcontent?: ReactNode;
  };
}
export function ModalHeader({ className = "", children: { heading, subheading, restcontent }, id }: ModalHeaderProps) {
  return (
    <div id={id} className={`modal-header bg-white ${className}`}>
      {typeof heading === "string" ? (
        <h4 className="osg-u-heading-4 mb-2">{heading}</h4>
      ) : (
        <div className="osg-u-heading-4 mb-2">{heading}</div>
      )}
      {typeof subheading === "string" ? <p className="osg-u-text-4 ">{subheading}</p> : subheading}
      {restcontent}
    </div>
  );
}

interface ModalSectionProps {
  className?: string;
}
export const ModalSection: React.FC<ModalSectionProps> = ({
  // eslint-disable-next-line react/prop-types
  children,
  // eslint-disable-next-line react/prop-types
  className
}) => {
  return <div className={`mb-4 last:mb-0 ${className ?? ""}`}>{children}</div>;
};
