import { Action } from "redux";
import { ThunkAction } from "redux-thunk";
import jwtDecode from "jwt-decode";
import { AccessToken,  OneloginAccessToken } from "interfaces";

import { ApplicationState } from "state/reducers";
import { getKunde, resetKunde } from "state/Kunde/kundeActions";
import { resetKjoretoyState } from "state/Kjoretoy/kjoretoyActions";
import { resetOblaterState } from "state/Oblat/oblatActions";
import { resetHandlekurvState } from "state/Handlekurv/handlekurvActions";
import { clearSamtykkeAndVilkaarInfo } from "state/TermsAndCondition/SamtykkeAndVilkaar";
import { resetVarekjopState } from "state/Varekjop/varekjopActions";
export interface State {
  userInfo?: AccessToken;
  isLoggedIn: boolean;
  isLoading: boolean;
  hasTokenExpired: boolean;
}

const initialState: State = {
  userInfo: undefined,
  isLoggedIn: false,
  isLoading: false,
  hasTokenExpired: false
};

// Action types
const SET_USER_INFORMATION = "piggav/auth/SET_IS_INFORMATION";
const SET_IS_LOADING = "piggav/auth/SET_IS_LOADING";
const SET_IS_LOGGED_IN = "piggav/auth/SET_IS_LOGGED_IN";
const CLEAR_USER_INFO = "piggav/auth/CLEAR_USER_INFO";
const LOG_IN_USER = "piggav/auth/LOG_IN_USER";
const TOKEN_EXPIRED = "piggav/auth/TOKEN_EXPIRED";
const LOGG_OUT_USER = "piggav/auth/LOGG_OUT_USER";

// Action object types
export interface SetUserInformation extends Action<typeof SET_USER_INFORMATION> {
  readonly userInfo?: AccessToken;
}

export interface SetIsLoggedIn extends Action<typeof SET_IS_LOGGED_IN> {
  readonly isLoggedIn: boolean;
}

export interface SetIsLoading extends Action<typeof SET_IS_LOADING> {
  readonly isLoading: boolean;
}

export interface LogInUser extends Action<typeof LOG_IN_USER> {
  readonly userInfo: any;
}

export type LogOutUser = Action<typeof LOGG_OUT_USER>;

export interface SetHasTokenExpired extends Action<typeof TOKEN_EXPIRED> {
  readonly hasTokenExpired: boolean;
}

export type ClearUserInfo = Action<typeof CLEAR_USER_INFO>;

export type ActionCreatorsTypes =
  | SetUserInformation
  | SetIsLoading
  | SetIsLoggedIn
  | ClearUserInfo
  | LogInUser
  | SetHasTokenExpired
  | LogOutUser;

// Action Creators

export function setUserInfo(userInfo: AccessToken | undefined): ActionCreatorsTypes {
  return {
    type: SET_USER_INFORMATION,
    userInfo
  };
}

export function setIsLoading(isLoading: boolean): ActionCreatorsTypes {
  return {
    type: SET_IS_LOADING,
    isLoading
  };
}

export function setIsLoggedIn(isLoggedIn: boolean): ActionCreatorsTypes {
  return {
    type: SET_IS_LOGGED_IN,
    isLoggedIn
  };
}

export function logoutUser(): ActionCreatorsTypes {
  return {
    type: LOGG_OUT_USER
  };
}

export function setHasTokenExpired(hasTokenExpired: boolean): ActionCreatorsTypes {
  return {
    type: TOKEN_EXPIRED,
    hasTokenExpired
  };
}

export function clearUserInfo(): ActionCreatorsTypes {
  return {
    type: CLEAR_USER_INFO
  };
}

// Actions with sideeffects
export function loginUser(accessToken: string): ThunkAction<void, any, null, ActionCreatorsTypes> {
  return async dispatch => {
    dispatch(setIsLoading(true));
    try {
      const decodedToken = jwtDecode<OneloginAccessToken>(accessToken);
      // setToken(accessToken);
      const userInfo: AccessToken = { brukerId: decodedToken["bym-id"], ...decodedToken };
      dispatch(setUserInfo(userInfo));
      dispatch(setIsLoggedIn(true));
      await dispatch(getKunde(userInfo.brukerId));
    } catch (e) {
      // Todo handle login error
      throw e;
    } finally {
      dispatch(setIsLoading(false));
    }
  };
}

export function logoutUserAction(): ThunkAction<void, any, null, ActionCreatorsTypes | any> {
  return dispatch => {
    dispatch(setIsLoggedIn(false));
    dispatch(setUserInfo(undefined));
    dispatch(clearUserInfo());
    dispatch(clearSamtykkeAndVilkaarInfo());
    dispatch(resetKunde());
    dispatch(resetKjoretoyState());
    dispatch(resetOblaterState());
    dispatch(resetHandlekurvState());
    dispatch(resetVarekjopState());
  };
}

// Reducer
// eslint-disable-next-line @typescript-eslint/default-param-last
export default function reducer(state: State = initialState, action: ActionCreatorsTypes): State {
  switch (action.type) {
    case SET_USER_INFORMATION:
      return { ...state, userInfo: action.userInfo };
    case SET_IS_LOADING:
      return { ...state, isLoading: action.isLoading };
    case SET_IS_LOGGED_IN:
      return { ...state, isLoggedIn: action.isLoggedIn };
    case TOKEN_EXPIRED:
      return { ...state, hasTokenExpired: action.hasTokenExpired };
    case CLEAR_USER_INFO:
      return { ...initialState };
    default:
      return state;
  }
}

// Selectors
export function getState(state: ApplicationState): State {
  return state.auth;
}
