/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { isEmpty } from "lodash-es";
import { Button, Icon } from "features/common/OSG";
import { FormattedMessage } from "locale/langUtils";
import queryString from "query-string";
// import Spinner from "../Layout/Spinner";
import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
// import exclamationIcon from "../../images/exclamation.svg";
import { useIntl } from "react-intl";
import UserHook from "features/Hooks/UserHook";
import SpinnerCircle from "features/common/BaseComponents/Spinner/SpinnerCircle";
import cancelIcon from "../../images/avbrutt-ikon.svg";
import * as urls from "../../appConstants/urls";
import { PURCHASE_STATES } from "../../appConstants/identifiers";
import "./InvoicePaymentPage.scss";

function FakturaBetalingsside({
  history,
  location,
  match,
  lagreBestillingFaktura,
  lagreBestillingFakturaPrBil,
  getHandlekurvVarekjopById,
  erFritattMinimumFakturabelop,
  handlekurv,
  konfigurasjon,
  kunde,
}) {
  const [error, setErrorState] = useState({
    status: false,
    errorText: "",
    errorBtnText: "",
  });

  const [success, setSuccessState] = useState({
    status: false,
    successText: "",
    successBtnText: "",
  });

  const [loading, setLoadingState] = useState(true);
  const [InvoicePaymentId, setInvoicePaymentIdState] = useState("");
  const { varekjopId } = match.params;
  const { fakturaPrBil, epostPrBil } = queryString.parse(location.search, {
    parseBooleans: true,
  });

  const { customerType } = UserHook();
  const { formatMessage } = useIntl();

  useEffect(() => {
    if (
      isEmpty(handlekurv) ||
      isEmpty(handlekurv.varekjop) ||
      isEmpty(konfigurasjon) ||
      erFritattMinimumFakturabelop === undefined ||
      customerType === "Privatkunde"
    ) {
      history.goBack();
    } else if (handlekurv.varekjop.statusId !== PURCHASE_STATES.NEW_PARCHASE) {
      setLoadingState(false);
      setErrorState({
        status: true,
        errorText: formatMessage({ id: "invoivepaymentpage.errorMessage_2" }),
        errorBtnText: formatMessage({
          id: "invoivepaymentpage.errorMessageBtn_2",
        }),
      });
    } else if (
      customerType !== "Bedriftkunde" ||
      (!erFritattMinimumFakturabelop &&
        konfigurasjon &&
        konfigurasjon.minstebelopFaktura &&
        handlekurv.varekjop.totalsum < konfigurasjon.minstebelopFaktura.verdi)
    ) {
      setLoadingState(false);
      setErrorState({
        status: true,
        errorText: formatMessage(
          { id: "invoivepaymentpage.errorMessage_3" },
          {
            minPrice: konfigurasjon.minstebelopFaktura.verdi,
          }
        ),
        errorBtnText: formatMessage({
          id: "invoivepaymentpage.errorMessageBtn_3",
        }),
      });
    } else if (fakturaPrBil) {
      lagreBestillingFakturaPrBil(kunde?.brukerId, varekjopId, epostPrBil)
        .then(() => {
          setSuccessState({
            status: true,
            successText: formatMessage({
              id: "invoivepaymentpage.successMessage_2_2",
            }),
            successBtnText: formatMessage({
              id: "invoivepaymentpage.toReceiptsBtn",
            }),
          });
          setInvoicePaymentIdState(varekjopId);
        })
        .catch(() => {
          getHandlekurvVarekjopById(kunde?.brukerId, varekjopId);
          setErrorState({
            status: true,
            errorText: formatMessage({
              id: "invoivepaymentpage.errorMessage_1",
            }),
            errorBtnText: formatMessage({
              id: "invoivepaymentpage.errorMessageBtn_1",
            }),
          });
        })
        .finally(() => {
          setLoadingState(false);
        });
    } else {
      lagreBestillingFaktura(kunde?.brukerId, handlekurv.id)
        .then((res) => {
          getHandlekurvVarekjopById(kunde?.brukerId, handlekurv.id);
          setLoadingState(false);
          setSuccessState({
            status: true,
            successText: formatMessage({
              id: "invoivepaymentpage.successMessage_1_2",
            }),
            successBtnText: formatMessage({
              id: "invoivepaymentpage.toReceiptBtn",
            }),
          });
          setInvoicePaymentIdState(res.data.result);
        })
        .catch(() => {
          setLoadingState(false);
          setErrorState({
            status: true,
            errorText: formatMessage({
              id: "invoivepaymentpage.errorMessage_1",
            }),
            errorBtnText: formatMessage({
              id: "invoivepaymentpage.errorMessageBtn_1",
            }),
          });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onGoToReceiptPageBtn = () => {
    if (success && fakturaPrBil) {
      history.push(urls.kvitteringer);
    } else if (success && InvoicePaymentId) {
      history.push(`${urls.kvitteringer}/${InvoicePaymentId}`);
    }
  };

  const onGoToHomePageBtn = () => {
    if (success && InvoicePaymentId) {
      history.push(urls.hjem);
    }
  };

  const onGoToConfirmPurchaseBtn = () => {
    if (
      error.errorText ===
      formatMessage({ id: "invoivepaymentpage.errorMessage_2" })
    )
      history.goForward();
    else history.goBack();
  };

  return (
    <Page>
      {{
        pageContent: (
          <Section>
            <div
              className="faktura-betaling-body"
              style={loading ? { background: "none", border: "none" } : {}}
            >
              {(loading && (
                <>
                  <div className="spinner-div">
                    <SpinnerCircle />
                    <h3 className="faktura-betaling-body-headline">
                      <FormattedMessage id="invoivepaymentpage.loadingHeadline" />
                    </h3>
                    <p className="faktura-betaling-body-text">
                      {/* <FormattedMessage id="invoivepaymentpage.loadingMessage" /> */}
                    </p>
                  </div>
                </>
              )) ||
                (error.status && (
                  <>
                    <img
                      bsClass="faktura-betaling-body-icon"
                      src={cancelIcon}
                      alt="loading icon"
                    />
                    <h3 className="faktura-betaling-body-headline">
                      <FormattedMessage id="invoivepaymentpage.errorHeadline" />
                    </h3>
                    <p className="faktura-betaling-body-text">
                      {error.errorText}
                    </p>
                  </>
                )) ||
                (success.status && (
                  <>
                    <h3 className="faktura-betaling-body-headline">
                      <FormattedMessage id="invoivepaymentpage.successHeadline" />
                    </h3>
                    <p className="faktura-betaling-body-text">
                      <Icon
                        alt="Remark"
                        icon="exclamation-mark-circle"
                        className="faktura-exclamation text-yellow"
                      />
                      {success.successText}
                    </p>
                  </>
                ))}
              <div className="faktura-betaling-button-group">
                {(success.status && (
                  <>
                    <Button
                      role="link"
                      modifier="outline"
                      colorOption="yellow"
                      className="faktura-betaling-button"
                      onClick={onGoToHomePageBtn}
                    >
                      <FormattedMessage id="invoivepaymentpage.toHomePageBtn" />
                    </Button>
                    <Button
                      role="link"
                      modifier="outline"
                      colorOption="yellow"
                      className="faktura-betaling-button"
                      onClick={onGoToReceiptPageBtn}
                    >
                      {success.successBtnText}
                    </Button>
                  </>
                )) ||
                  (error.status && (
                    <Button
                      role="link"
                      modifier="outline"
                      colorOption="yellow"
                      className="faktura-betaling-button"
                      onClick={onGoToConfirmPurchaseBtn}
                    >
                      {error.errorBtnText}
                    </Button>
                  ))}
              </div>
            </div>
          </Section>
        ),
      }}
    </Page>
  );
}

FakturaBetalingsside.propTypes = {
  history: PropTypes.shape({}).isRequired,
  lagreBestillingFaktura: PropTypes.func.isRequired,
  lagreBestillingFakturaPrBil: PropTypes.func.isRequired,
  getHandlekurvVarekjopById: PropTypes.func.isRequired,
  erFritattMinimumFakturabelop: PropTypes.bool.isRequired,
  handlekurv: PropTypes.shape({}).isRequired,
  konfigurasjon: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  kunde: PropTypes.objectOf({}).isRequired,
};

export default withRouter(FakturaBetalingsside);
