import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import { FormattedMessage, useIntl } from "locale/langUtils";
import { isEmpty, some } from "lodash-es";
import React, { useContext, useLayoutEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import * as urls from "appConstants/urls";
import CustomReactSelect from "features/common/BaseComponents/Select/CustomReactSelect";
import { VehicleWeightId } from "appConstants";
import { Button } from "features/common/ComposedComponents/";
import IconButton from "features/common/BaseComponents/IconButton/IconButton";

import { BulkBuyContext } from "./BuyStickersBulk";

export default function BuyStickersBulkConfirmVehiclesPage(): JSX.Element {
  const [isLoading, setIsLoading] = useState(false);
  const {
    state: { unconfirmedVehicles, confirmedVehicles },
    dispatch,
    addToBasket
  } = useContext(BulkBuyContext);

  const history = useHistory();

  const { formatMessage } = useIntl();

  const isWeigthsMissingForSomeVehicles = useMemo(() => {
    return some(unconfirmedVehicles, v => v === null);
  }, [unconfirmedVehicles]);

  useLayoutEffect(() => {
    if (isEmpty(unconfirmedVehicles)) history.push(urls.bulkKjop);
  }, [history, unconfirmedVehicles]);

  const updateWeightIdForVehicle = (reg: string, weightID: VehicleWeightId | null) => {
    dispatch({
      type: "BUY_BULK_PUT_VEHICLE_WEIGHT_UNCONFIRMED",
      vehicle: { reg, weightID }
    });
  };

  const deleteVehicle = (reg: string) => {
    dispatch({
      type: "BUY_BULK_DELETE_VEHICLE_FROM_UNCONFIRMED",
      reg
    });
  };

  const handleAddBulkToBasket = async () => {
    try {
      if (isWeigthsMissingForSomeVehicles) return;
      setIsLoading(true);
      await addToBasket({
        ...unconfirmedVehicles,
        ...confirmedVehicles
      });
      // history.push(urls.checkout);
    } catch (e) {
      // TODO HANDLE ERROR
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Page
      metaInfo={{
        titlePageName: formatMessage({ id: "buystickersbulkpage.meta.title" }),
        titleDescription: formatMessage({ id: "buystickersbulkpage.meta.title.description" })
      }}
    >
      {{
        heading: {
          pageTitle: <FormattedMessage id="confirmstickersbulkpage.heading" />,
          subtitle: <FormattedMessage id="confirmstickersbulkpage.subheading" />
        },
        pageContent: (
          <Section className="flex flex-col">
            <p className="text-5 mb-5">
              <FormattedMessage id="confirmstickersbulkpage.kontrollBulkkjopInstruks" />
            </p>
            <div className="osg-simple-table">
              <table className="">
                <caption className="mr-auto invisible ">
                  <FormattedMessage id="confirmstickersbulkpage.table.caption" />
                </caption>

                <tr>
                  <th style={{ width: "20%" }} className="osg-content">
                    <FormattedMessage id="confirmstickersbulkpage.table.kontrollBulkkjopTh1" />
                  </th>
                  <th style={{ width: "60%" }} className="osg-content">
                    <FormattedMessage id="confirmstickersbulkpage.table.kontrollBulkkjopTh2" />
                  </th>
                  <th aria-label="slett" style={{ width: "10%" }} className="osg-content" />
                </tr>

                {Object.keys(unconfirmedVehicles).map(regNum => (
                  <tr className="even:bg-grey">
                    <td className="osg-content">{regNum}</td>
                    <td className="osg-content">
                      <CustomReactSelect
                        placeholder={formatMessage({
                          id: "confirmstickersbulkpage.select.placeholder.vehicleWeight"
                        })}
                        className="inline-block w-full tablet:w-2/3"
                        isSearchable={false}
                        onChange={val => {
                          updateWeightIdForVehicle(regNum, (val as any)?.value ?? null);
                        }}
                        options={[
                          {
                            label: formatMessage({
                              id: "common.vehcile.weightClass.light"
                            }),
                            value: VehicleWeightId.Light as unknown as string
                          },
                          {
                            label: formatMessage({
                              id: "common.vehcile.weightClass.heavy"
                            }),
                            value: VehicleWeightId.Heavy as unknown as string
                          }
                        ]}
                      />
                    </td>
                    <td className="osg-content">
                      <IconButton onClick={() => deleteVehicle(regNum)} type="button" icon="close" />
                    </td>
                  </tr>
                ))}
              </table>
            </div>
            {isWeigthsMissingForSomeVehicles && (
              <span className="self-end mt-3">
                <FormattedMessage id="confirmstickersbulkpage.kontrollBulkkjopFyllUtErrorPlaceholder" />
              </span>
            )}

            <Button
              className="mt-3 self-end"
              disabled={isWeigthsMissingForSomeVehicles || isLoading}
              isLoading={isLoading}
              onClick={handleAddBulkToBasket}
            >
              <FormattedMessage id="confirmstickersbulkpage.kontrollBulkkjopVelgNeste" />
            </Button>
          </Section>
        )
      }}
    </Page>
  );
}
