import React, { ComponentProps } from "react";
import TermsAndConditionsPopup from "features/TermsAndConditions/VilkaarPopupOnlyText";
import OutgoingLink from "../common/BaseComponents/LinkWIcon/LinkWIcon";

type FaqENProps = ComponentProps<"div">;

const FaqEn = React.forwardRef<HTMLDivElement, FaqENProps>(({ className, ...props }: FaqENProps, ref) => (
  <div className={`osg-content ${className}`} {...props} ref={ref}>
    <h3>I am a new user how do I sign up?</h3>
    <p>
      First and foremost: Welcome as a user at piggav.no! As a new user you register by clicking the button ‘Register
      here’.
    </p>
    <h3>How do I register as a private customer?</h3>
    <ul>
      <li>
        After you have clicked the ‘Register here’ button you will see a registration form under ‘private person’ to the
        left of the page.
      </li>
      <li>Enter e-mail address and a password</li>
      <li>
        Tick the checkbox if you accept the terms and conditions. To use the service you must accept the terms and
        conditions,{" "}
        <TermsAndConditionsPopup>
          {{
            openModalElement: setIsOpenModal => (
              <button
                className="reset-style cursor-pointer osg-u-text-is-medium hover:text-blue-hover"
                onClick={event => {
                  event.preventDefault();
                  setIsOpenModal(true);
                }}
              >
                read more here.
              </button>
            )
          }}
        </TermsAndConditionsPopup>
      </li>
      <li>
        Tick the checkbox if you want to receive reminders about the start of the season to your registered e-mail
      </li>
      <li>Click ‘Create account’</li>
      <li>
        You should receive an email with an activation link. If you can’t find the e-mail in your inbox, check your junk
        or spam folder.
      </li>
      <li>
        Open the e-mail with the subject ‘Piggav.no: Aktivering av konto’ (account activation) and click on the
        ‘Activate your account’ link.
      </li>
      <li>
        You will now be notified that the account has been activated and will be forwarded to piggav.no login page. If
        you are not forwarded automatically, you can press the ‘Go to login’ button.
      </li>
      <li>You can now log in to piggav.no.</li>
    </ul>
    <p>
      If you do not receive an e-mail with an activation link you can contact us by e-mail at post@piggav.no or{" "}
      <OutgoingLink
        icon="new-window"
        target="_"
        href="https://ed24.chat.oslo.kommune.no/ed24cust/chattemplate/Oslo_Bymiljoetaten/index.html"
        className="external-link"
        title="Gå til Bymiljøetatens Chat"
        aria-label="Gå til Bymiljøetatens Chat"
      >
        <strong>chat with us here.</strong>
      </OutgoingLink>
    </p>
    <h3>How do I register as a business customer?</h3>
    <ul>
      <li>
        After you have clicked the ‘Register here’ button you will see to the right of the web page its written ‘Do you
        represent a company?’. Click the button ‘Register your company here’.
      </li>
      <li>
        Fill in your company’s registered number and press the button with magnifying glass icon. We will retrieve
        information from the Brønnøysund Register Centre.
      </li>
      <li>Fill in any missing details, enter your preferred e-mail and password.</li>
      <li>
        Tick the checkbox if you accept the terms and conditions. To use the service you must accept the terms and
        conditions,{" "}
        <TermsAndConditionsPopup>
          {{
            openModalElement: setIsOpenModal => (
              <button
                className="reset-style cursor-pointer osg-u-text-is-medium hover:text-blue-hover"
                onClick={event => {
                  event.preventDefault();
                  setIsOpenModal(true);
                }}
              >
                read more here.
              </button>
            )
          }}
        </TermsAndConditionsPopup>
      </li>
      <li>
        Tick the checkbox if you want to receive reminders about the start of the season to your registered e-mail
      </li>
      <li>Click ‘Create account’</li>
      <li>
        You should receive an email with an activation link. If you can’t find the e-mail in your inbox, check your junk
        or spam folder.
      </li>
      <li>
        Open the e-mail with the subject ‘Piggav.no: Aktivering av konto’ (account activation) and click on the
        ‘Activate your account’ link.
      </li>
      <li>
        You will now be notified that the account has been activated and will be forwarded to piggav.no login page. If
        you are not forwarded automatically, you can press the ‘Go to login’ button.
      </li>
    </ul>
    <p>
      If you do not receive an e-mail with an activation link you can contact us by e-mail at post@piggav.no or{" "}
      <OutgoingLink
        icon="new-window"
        target="_"
        href="https://ed24.chat.oslo.kommune.no/ed24cust/chattemplate/Oslo_Bymiljoetaten/index.html"
        className="external-link"
        title="Gå til Bymiljøetatens Chat"
        aria-label="Gå til Bymiljøetatens Chat"
      >
        <strong>chat with us here.</strong>
        <span className="sr-only">(Opens in a new tab)</span>
      </OutgoingLink>
    </p>
    <h3>How can I activate my account?</h3>
    <p>
      An e-mail was sent to you after you registered. If you have not received this e-mail, please check your junk/spam
      folder. If it is not there, send an e-mail to post@piggav.no or{" "}
      <OutgoingLink
        icon="new-window"
        target="_"
        href="https://ed24.chat.oslo.kommune.no/ed24cust/chattemplate/Oslo_Bymiljoetaten/index.html"
        title="Go to The Agency for Urban Environment chat service"
        aria-label="Go to The Agency for Urban Environment chat service"
      >
        <strong>chat with us here</strong>
        <span className="sr-only">(Opens in a new tab)</span>
      </OutgoingLink>
      . We will respond as soon as possible.
    </p>

    <h3>I don't remember my password. What do I do now?</h3>
    <p>
      Click ‘Log In’ and then forgot password when you get to the window to enter email. You will after having posted
      email, and chosen to be contacted by email receive an email from Onelogin (this is currently in English). Once you
      have selected a new password and confirm this, you can log in. If you enter your Onelogin Portal, you can tap the
      icon of the stud to get back to the application.
    </p>

    <h3>I have registered, but I get an error message when activating my account.</h3>
    <p>If you get an error message when you press the link in the activation e-mail, this could mean:</p>
    <ol>
      <li>
        You have already clicked the link previously and your account has already been activated. If so, you can press
        the ‘Login’ button and log in with your selected e-mail and password.
      </li>
      <li>
        If a long time has passed between creating an account and clicking on the link, the link may have expired (i.e.
        it is too old). If so, you can try registering again to receive a new link
      </li>
    </ol>
    <p>
      If you are still having problems, send an e-mail to post@piggav.no or{" "}
      <OutgoingLink
        icon="new-window"
        target="_"
        href="https://ed24.chat.oslo.kommune.no/ed24cust/chattemplate/Oslo_Bymiljoetaten/index.html"
        title="Go to The Agency for Urban Environment chat service"
        aria-label="Go to The Agency for Urban Environment chat service"
      >
        <strong>chat with us here</strong>
        <span className="sr-only">(Opens in a new tab)</span>
      </OutgoingLink>
      . We will respond as soon as possible.
    </p>
    <h3>How do I buy stickers?</h3>
    <ul>
      <li>
        Go to ‘Choose vehicle and stickers here’ and add a new vehicle or select one from your list (if you have
        previously added one).
      </li>
      <li>Select the type of sticker and period/date, and add it to your basket.</li>
      <li>Choose method of payment and click ‘Proceed to payment’ and follow the instructions.</li>
      <li>
        If the payment was successful, you can find your new stickers under ‘Sticker overview’. If the transaction
        failed, you can always try again.
      </li>
    </ul>

    <h3>I have bought a new car. How do I transfer my Season sticker from my old car to the new one?</h3>
    <ul>
      <li>Click the the menu located in upper right corner, then click ‘My stickers’</li>
      <li>Enter the registration number of the new car and press ‘Add vehicle’.</li>
      <li>Find the sticker you want to change in list, then click ‘See details’ located on the right hand side.</li>
      <li>
        Click ‘Edit’ and select the registration number from the drop down list or add a new vehicle by clicking ‘Add
        vehicle’. Finally click ‘Save changes’.
      </li>
    </ul>
    <p>
      You can only change between the same type of vehicle (light to light, or heavy to heavy). If you need to transfer
      your Season sticker from a light vehicle to a heavy one, or vice versa, you must send us an e-mail.
    </p>
    <p>
      You can only transfer the sticker once. If you have to transfer the sticker several times, you have to fill out
      the form that shows up when you try to edit.
    </p>

    <h3>I want to cancel my sticker.</h3>
    <p>
      Go to ‘Sticker overview’ in the menu located in upper right corner and click ‘See details’ of the vehicle you wish
      to change. Then press ‘Cancel’.
    </p>
    <p>
      Only Season stickers are refundable, but you can cancel any type of sticker. If you cancel a Season sticker, you
      will be refunded for the remaining period to a minimum amount equal to the price of a Day sticker for the same
      vehicle type (light or heavy)
    </p>

    <h3>I am a business user and want to buy stickers for some or all of the company’s vehicles. What do I do?</h3>
    <ul>
      <li>Go to ‘Buy multiple stickers’ and look at the options for importing or registering your list of vehicles</li>
      <li>If you need help importing a file, press ‘See example’</li>
      <li>
        Import or type your registration numbers and click ‘Add to basket’. If we are unable to find the details we need
        about one or more of the vehicles, you will need to update them yourself – select correct wight class for each
        vehicle in the list
      </li>
      <li>
        Once the necessary details have been added, you can proceed to summary. Before you checkout you can make sure
        that all the details are correct
      </li>
      <li>Chose method of payment, and click ‘Proceed to payment’</li>
      <p>
        Note: If you paid with invoice, activation of stickers happens after the invoice has been registered as paid by
        Bymiljøetaten.
      </p>
    </ul>

    <h3>What if I have a personalized number plate?</h3>
    <p>
      When you buy stickers for a vehicle, you should register the vehicle using original number plate, otherwise you
      may risk having to pay a fee.
    </p>

    <h3>I have a foreign car. Do I have to buy a studded tyre sticker? How?</h3>
    <p>
      Yes, everyone who drives with studded tyres within the Oslo city limits must buy a studded tyre sticker. See the
      question ‘How do I buy stickers?’ above if you are still unsure about something.
    </p>

    <p>When registering a vehicle with a foreign number plate, do not include any special characters (-) or spaces.</p>
    <h3>I want to change my e-mail address, how?</h3>
    <p>You can change your email from your profile page after you have signed in</p>
  </div>
));

export default FaqEn;
