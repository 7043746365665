import { FormattedMessage } from "locale/langUtils";
import { uniqueId } from "lodash-es";
import React, { forwardRef, useState } from "react";

export type CheckboxProps = React.HTMLProps<HTMLInputElement>;

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ label, children, id, required, ...rest }: CheckboxProps, ref) => {
    const [idx] = useState(id ?? uniqueId("checkbox"));
    const [isFocused, setIsFocused] = useState(false);
    return (
      // eslint-disable-next-line jsx-a11y/label-has-associated-control
      <label htmlFor={idx} className={`osg-checkbox osg-u-text-5 ${isFocused ? "checkbox--focus--helper" : ""}`}>
        <input
          id={idx}
          type="checkbox"
          ref={ref}
          required={required}
          {...rest}
          onFocus={e => {
            setIsFocused(true);
          }}
          onBlur={e => {
            setIsFocused(false);
          }}
        />
        <span className="osg-checkbox__checkmark" />
        <span style={{ marginLeft: "5px" }} className="osg-u-padding-left-1 osg-checkbox__text">
          {label ?? children}
          {required ? (
            <>
              <span aria-hidden> *</span>
              <span className="sr-only">
                <FormattedMessage id="common.requiredfield" />
              </span>
            </>
          ) : null}
        </span>
      </label>
    );
  }
);

export default Checkbox;
