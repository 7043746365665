import { useContext, useMemo, useEffect } from "react";
import DatePicker from "features/common/ComposedComponents/InputDateField/InputDateField";
import { TimePickerField } from "features/common/ComposedComponents";
import { useIntl } from "react-intl";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { Configurations } from "interfaces";
import { isBefore, isValid } from "date-fns";
import { OrdreLinjeContext } from "./OrdrelinjeForm";

interface SelectDateTimeProps {
  onChange?: (value: string) => void;
}

export default function SelectDateTime({ onChange }: SelectDateTimeProps): JSX.Element {
  const { locale, formatMessage } = useIntl();

  const {
    validFrom,
    errors: { gyldigFraError, datoTidIFortidError },
    showMobileFlow,
    dispatch: dispatchOrdrelinjeformAction
  } = useContext(OrdreLinjeContext);
  const { sesongStart, sesongSlutt } = useSelector<any, Configurations>(state => state.konfigurasjon);
  useEffect(() => {
    if (validFrom) return;
    const now = new Date();
    const sesStart = new Date(sesongStart.verdi);
    const dateTime = isBefore(now, sesStart) ? sesStart : now;
    dispatchOrdrelinjeformAction({
      type: "SET_VALID_FROM_DATE_TIME",
      payload: dateTime.toISOString()
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatchOrdrelinjeformAction, sesongStart.verdi]);

  const minDateTime = useMemo(() => {
    const now = new Date();
    const sesStart = new Date(sesongStart.verdi);
    return isBefore(now, sesStart) ? sesStart : now;
  }, [sesongStart.verdi]);

  const maxDateTime = useMemo(() => new Date(sesongSlutt.verdi), [sesongSlutt.verdi]);

  const handleOnChange = (date: Date | null) => {
    if (!isValid(date)) {
      dispatchOrdrelinjeformAction({
        type: "SET_VALID_FROM_DATE_TIME",
        payload: undefined
      });
      return;
    }
    const isoDateString = date?.toISOString();
    dispatchOrdrelinjeformAction({
      type: "SET_VALID_FROM_DATE_TIME",
      payload: isoDateString
    });
    onChange?.(isoDateString ?? "");
  };

  return (
    <>
      <DatePicker
        error={
          (gyldigFraError &&
            formatMessage({
              id: gyldigFraError
            })) ||
          undefined
        }
        locale={locale}
        openToDate={minDateTime}
        minDate={minDateTime}
        maxDate={maxDateTime}
        onChange={handleOnChange}
        withPortal={showMobileFlow}
        onChangeRaw={e => {
          handleOnChange(new Date(e?.target?.value));
        }}
      />
      <TimePickerField
        error={
          (datoTidIFortidError &&
            formatMessage({
              id: datoTidIFortidError
            })) ||
          undefined
        }
        minTime={minDateTime}
        maxTime={maxDateTime}
        selectedTime={validFrom ?? minDateTime}
        onChange={handleOnChange}
      />
    </>
  );
}
