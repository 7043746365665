import React, { ReactNode, FunctionComponent, useContext } from "react";
import { Icon, IconTypes } from "features/common/OSG";
import "./MobileMenu.scss";
import ModalHook from "features/Hooks/ModalHook";
import { NavLink, NavLinkProps } from "react-router-dom";
import { useIntl } from "react-intl";

interface MenuSlideInProps {
  children: ReactNode;
  className?: string;
  loggedInAs?: string;
}

interface MenuItemProps extends NavLinkProps {
  // to?: string;
  icon?: IconTypes;
  children: ReactNode;
  disabled?: boolean;
}

interface MenuContextT {
  setIsMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const MenuContext = React.createContext<MenuContextT>({} as MenuContextT);

export const MenuItem: FunctionComponent<MenuItemProps> = ({
  icon = "chevron-right",
  children,
  to,
  disabled = false,
  ...rest
}: MenuItemProps): JSX.Element => {
  const { setIsMenuOpen } = useContext(MenuContext);
  return (
    <li
      style={{ padding: "5px 0px" }}
      className={`${
        disabled
          ? "text-grey-40 bg-grey hover:bg-grey hover:text-grey-40 cursor-default"
          : "bg-grey hover:bg-grey-dark cursor-pointer "
      }`}
    >
      <NavLink
        role="listitem"
        to={to}
        onClick={e => {
          if (disabled) {
            e.preventDefault();
            return;
          }
          setIsMenuOpen(false);
        }}
        onKeyUp={e => {
          if (e.key === "Enter") {
            setIsMenuOpen(false);
          }
        }}
        activeClassName="text-blue-hover"
        className={`flex items-center osg-u-heading-5 osg-u-padding-horizontal-2-tablet-and-up  osg-u-padding-vertical-small osg-u-padding-vertical-4-tablet-and-up focus:text-blue-hover `}
        {...rest}
      >
        <Icon style={{ color: "inherit" }} icon={icon} className=" osg-u-margin-right-2" />
        <span style={{ color: "inherit" }} className="truncate">
          {children}
        </span>
      </NavLink>
    </li>
  );
};

export function MobileMenu({ children, className, loggedInAs }: MenuSlideInProps): JSX.Element {
  const {
    modalRef,
    setIsModalOpen,
    modalState: { isModalOpen }
  } = ModalHook<HTMLDivElement>({
    initialState: { isOpen: false },
    closeOnClickOutside: true,
    exludeList: []
  });

  const { formatMessage } = useIntl();

  return (
    <nav
      role="navigation"
      aria-label={formatMessage({ id: "menu.aria.label" })}
      className={`ok-menu ${isModalOpen ? "open" : "closed"} z-menu ${className}`}
    >
      <div ref={modalRef}>
        <div
          title="Meny"
          aria-label="Meny"
          aria-expanded={isModalOpen}
          className={`ok-menu-hamburger ${isModalOpen ? "open" : ""}`}
          onClick={() => {
            setIsModalOpen(prev => !prev);
          }}
          onMouseDown={e => {
            e.preventDefault();
          }}
          role="button"
          tabIndex={0}
          onKeyUp={k => {
            k.stopPropagation();
            if (k.key === "Enter" || k.key === "Space") {
              setIsModalOpen(prev => !prev);
            }
          }}
        >
          <span />
          <span />
          <span />
        </div>

        <MenuContext.Provider value={{ setIsMenuOpen: setIsModalOpen }}>
          <div className="ok-menu-wrapper">
            {loggedInAs && (
              <div className="flex items-center osg-u-heading-6 osg-u-padding-horizontal-1 text-left ok-menu-name">
                <Icon className="mr-2 flex-shrink-0" icon="user" />
                <span className="w-full truncate">{loggedInAs}</span>
              </div>
            )}
            <ul className="ok-menu-content flex flex-col">{children}</ul>
          </div>
        </MenuContext.Provider>
      </div>
    </nav>
  );
}
