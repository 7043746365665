// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { BymEndpointResult, Hovedenhet, VehicleDictionary } from "interfaces";
import * as serviceInfo from "appConstants/serviceInfo";
import { getEnvironmentVariable } from "utils/environment";
import { piggavservice, piggavServiceBaseUrl } from "./axiosConfig";
import axios from "axios";
import { Applikasjon } from "interfaces/Applikasjon";

interface RegistrerBrukerResult {
  id: string;
  status: string;
}

const service = `${getEnvironmentVariable("URL_PIGGDEKKOBLAT_SERVICE")}/api`;

// - Brukerhåndtering

export function registerPrivatBruker(payload: any) {
  return piggavservice.post<BymEndpointResult<RegistrerBrukerResult>>("innloggetkunde/brukere", payload);
}

export function registrerBedriftsbruker(payload: any) {
  return piggavservice.post<BymEndpointResult<RegistrerBrukerResult>>("/innloggetbedriftskunde/brukere", payload);
}

export function aktiverBruker(key: string) {
  return piggavservice.put(`/brukeraktiveringer/${key}`);
}

export function endrePassordAktivering(key: string, passord: string) {
  return piggavservice.put(`/brukeraktiveringer/${key}/passord`, { passord });
}

export function putGlemtPassord(key: string, nyttPassord: string) {
  return piggavservice.put(`/brukere/passord`, {
    key,
    nyttPassord
  });
}

export function postGlemtPassord({ epost }: any) {
  return piggavservice.post(`/brukere/passord`, {
    epost,
    serviceId: serviceInfo.SERVICE_ID,
    clientId: serviceInfo.CLIENT_ID
  });
}

export function putEndrePassord(passord: string, gammeltPassord: string) {
  return piggavservice.put(`/kunder/passord`, { passord, gammeltPassord });
}

export function putEndreEpost(nyEpost: string, passord: string) {
  return piggavservice.put(`/brukere/epost`, { nyEpost, passord });
}

export function putVerifiserEpost(kode: number) {
  return piggavservice.put(`brukere/epost/verifiser`, { kode: kode});
}

export function getBrukerApplikasjoner() {
  return piggavservice.get<BymEndpointResult<Applikasjon[]>>("/brukere/applikasjoner");
}

export function deleteKunde(brukerId: string) {
  return piggavservice.delete(`/kunder/${brukerId}`)
}

// - KJØRETØYTYPER
export function getKjoretoytyper() {
  return piggavservice.get("/kjoretoytyper");
}

// - OBLATTYPER
export function getOblattyper() {
  return piggavservice.get("/oblattyper");
}

// - KJORETOY
export function createKjoretoy(brukerId: string, kjoretoy: any) {
  return piggavservice.post(`/kunder/${brukerId}/kjoretoy`, kjoretoy);
}

export function getKjoretoyForBruker(brukerId: string) {
  return piggavservice.get(`/kunder/${brukerId}/kjoretoy`);
}

export function deleteKjoretoy(brukerId: string, kjoretoyId: string) {
  return piggavservice.delete(`/kunder/${brukerId}/kjoretoy/${kjoretoyId}`);
}

export function updateKjoretoy(brukerId: string, kjoretoyId: string, kjoretoy: any) {
  return piggavservice.put(`/kunder/${brukerId}/kjoretoy/${kjoretoyId}`, kjoretoy);
}

export function updateStandardKjoretoy(brukerId: string, StandardKjoretoyId: string) {
  return piggavservice.put(`/kunder/${brukerId}/standardkjoretoy`, { StandardKjoretoyId });
}

// - BULKKJOP
export type BymEndpointResultVehicleList = BymEndpointResult<{
  bekreftedeKjoretoy: VehicleDictionary;
  ubekreftedeKjoretoy: VehicleDictionary;
}>;

export function postKjoretoyListe(brukerId: string, kjoretoyListe: string[]) {
  return piggavservice.post<BymEndpointResultVehicleList>(`/kunder/${brukerId}/kjoretoyliste`, {
    kjoretoyListe
  });
}

export function uploadKjoretoyFile(brukerId: string, data: FormData) {
  return piggavservice.post<BymEndpointResult<any>>(`/kunder/${brukerId}/filer/kjoretoyliste`, data, {
    headers: { "Content-Type": "multipart/form-data" }
  });
}

export function createBulkkjop(brukerId: string, kjoretoyMedKjoretoytype: VehicleDictionary, VarekjopId: string) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/oblater/bulk2`, {
    kjoretoyMedKjoretoytype,
    VarekjopId
  });
}

// - VAREKJOP

export function getVarekjop(brukerId: string, query = "") {
  return piggavservice.get(`/kunder/${brukerId}/varekjop/${query}`);
}

export function getVarekjopById(brukerId: string, varekjopId: string) {
  return piggavservice.get(`/kunder/${brukerId}/varekjop/${varekjopId}`);
}

export function createVarekjop(brukerId: string) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/`);
}

export function deleteVarekjop(brukerId: string, varekjopId: string) {
  return piggavservice.delete(`/kunder/${brukerId}/varekjop/${varekjopId}/oblater`);
}

export function beregnVarekjop(brukerId: string) {
  return piggavservice.get(`/kunder/${brukerId}/varekjop/calculate`);
}

export function sendKvitteringToMail(brukerId: string, varekjopId: string, data?: FormData) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/${varekjopId}/kvittering`, data, {
    headers: { "Content-Type": "application/pdf" }
  });
}

export function sendKvitteringFromResepsjonToMail(brukerId: string, varekjopId: string, email: string,) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/${varekjopId}/kvittering/epost`, { epost: email});
}

// - BESTILLINGER

export function lagreBestilling(brukerId: string, varekjopId: string) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/${varekjopId}/bestillinger`);
}

export function lagreBestillingFaktura(brukerId: string, varekjopId: string) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/${varekjopId}/faktura`);
}

export function lagreBestillingFakturaPerBil(brukerId: string, varekjopId: string, sendEpostKvittering: boolean) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/${varekjopId}/fakturaprkjoretoy`, {
    sendEpostKvittering
  });
}

export function lagreBestillingKontanter(brukerId: string, varekjopId: string) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/${varekjopId}/kontant`);
}

export function lagreBestillingTerminal(brukerId: string, varekjopId: string) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/${varekjopId}/terminal`);
}

// - BETALINGER
export function sjekkBetalingsstatus(transaksjonsId: string) {
  return piggavservice.post(`/kunder/varekjop/betalinger/${transaksjonsId}`);
}

// - OBLATER
export function getOblatLog(brukerId: string, oblatId: string) {
  return piggavservice.get(`/kunder/${brukerId}/oblater/${oblatId}/logg`);
}

export function getOblaterForVarekjop(brukerId: string, varekjopId: string) {
  return piggavservice.get(`/kunder/${brukerId}/varekjop/${varekjopId}/oblater`);
}

export function getOblaterForKunde(brukerId: string) {
  return piggavservice.get(`/kunder/${brukerId}/oblater`);
}

export function postOblat(brukerId: string, varekjopId: string, payload: any) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/${varekjopId}/oblater`, payload);
}

export function deleteOblat(brukerId: string, varekjopId: string, oblatId: string) {
  return piggavservice.delete(`/kunder/${brukerId}/varekjop/${varekjopId}/oblater/${oblatId}`);
}

export function kansellerOblat(brukerId: string, varekjopId: string, oblatId: string) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/${varekjopId}/oblater/${oblatId}/refusjon`);
}

export function kansellerOgKrediterOblat(brukerId: string, varekjopId: string, oblatId: string) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/${varekjopId}/oblater/${oblatId}/kreditnota`);
}

export function beregnRefusjonsbelop(brukerId: string, varekjopId: string, oblatId: string) {
  return piggavservice.get(`/kunder/${brukerId}/varekjop/${varekjopId}/oblater/${oblatId}/kalkuler`);
}

export function kansellerObblatEksternBetaling(brukerId: string, varekjopId: string, oblatId: string, payload: any) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/${varekjopId}/oblater/${oblatId}/ekstern-refusjon`, payload);
}

export function endreOblatKjoretoy(brukerId: string, oblatId: string, kjoretoyId: string, kommentar = "") {
  return piggavservice.put(`/kunder/${brukerId}/oblater/${oblatId}/kjoretoy`, {
    kjoretoyId,
    kommentar
  });
}

export function endreOblatKjoretoySkjema(brukerId: string, oblatId: string, payload: any) {
  return piggavservice.post(`${service}/kunder/${brukerId}/oblater/${oblatId}/endrekjoretoy`, payload);
}

export function avbrytFeilkjop(brukerId: string, varekjopId: string, kommentar: any) {
  return piggavservice.post(`/kunder/${brukerId}/varekjop/${varekjopId}/avbryt-betaling/ekstern`, kommentar);
}

// - ORGANISASJONSKUNDERINFO
export function getOrganisasjonsKundeInfo(orgnummer: string) {
  return axios.get<BymEndpointResult<Hovedenhet>>(`${piggavServiceBaseUrl}/organisasjonskunderinfo/${orgnummer}`);
}
// - SETTINGS

export function getKonfig() {
  return piggavservice.get("/konfigurasjoner");
}

export function getServiceVersion() {
  return piggavservice.get(`/versjon`);
}

// - KUNDE
export function getKunde(brukerId: string) {
  return piggavservice.get(`/kunder/${brukerId}`);
}

export function updateKunde(brukerId: string, payload: any) {
  return piggavservice.put(`/kunder/${brukerId}`, payload);
}
