import React, { useEffect, useMemo, useState } from "react";

import InputField from "features/common/ComposedComponents/Inputfield/Inputfield";
import { useIntl } from "react-intl";
import { Button } from "features/common/ComposedComponents";
import { FormattedMessage } from "locale/langUtils";
import ProfilSection from "./ProfileSection";
import ProfileDetails, { ProfileDetail } from "./ProfileDetails";
import { useAuth } from "react-oidc-context";

import * as yup from "yup";
import { emailRegex } from "utils/validationUtils";
import { useForm } from "react-hook-form";
import { getBrukerApplikasjoner, putEndreEpost } from "api/piggavService";
import { yupResolver } from "@hookform/resolvers";
import { Applikasjon } from "interfaces/Applikasjon";
import VerifyNewMailForm from "./VerifyNewMailForm";
import Form from "features/common/BaseComponents/Form/Form";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";

export const sessionStorageUpdatedEmail = "updated-email";

export default function ChangeMailSetting({ ...rest }: React.HTMLProps<HTMLDivElement>): JSX.Element {
  const profileSectionId = "mailsettings";
  const [isOpen, setIsOpen] = useState(false);
  const [isSendingUpdateEmailRequest, setIsSendingUpdateEmailRequest] = useState(false);
  const [verificationCodeSent, setVerificationCodeSent] = useState(false);
  const [applikasjoner, setApplikasjoner] = useState<Applikasjon[]>();
  const { formatMessage } = useIntl();
  const auth = useAuth();

  const isBymEmailCheck = (email: string | undefined) => !!email && email.endsWith("@bym.oslo.kommune.no");
  const isBymUser = isBymEmailCheck(auth.user?.profile?.email);

  useEffect(() => {
    getBrukerApplikasjoner().then(resp => {
      setApplikasjoner(resp.data.result);
    });
  }, []);

  const changeEmailScheme = useMemo(
    () =>
      yup.object().shape({
        newEmail: yup
          .string()
          .required(
            formatMessage({
              id: "common.error.requiredfield",
              defaultMessage: "Feltet må fylles ut"
            })
          )
          .matches(
            emailRegex,
            formatMessage({
              id: "profile.changeemail.invalidemail"
            })
          )
          .test("bym test", formatMessage({ id: "profile.changeemail.emailisbym" }), email => !isBymEmailCheck(email)),
        password: yup.string().required(
          formatMessage({
            id: "common.error.requiredfield",
            defaultMessage: "Feltet må fylles ut"
          })
        )
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const { handleSubmit, register, errors, setError } = useForm<any>({
    mode: "onSubmit",
    resolver: yupResolver(changeEmailScheme)
  });

  const handleUpdateEmailSubmit = handleSubmit(async ({ newEmail, password }) => {
    try {
      setIsSendingUpdateEmailRequest(true);
      await putEndreEpost(newEmail, password);
      setVerificationCodeSent(true);
    } catch (e: any) {
      if (e.response?.data?.ErrorMessage) {
        setError("password", {
          type: "errorWhenUpdating",
          message: e.response?.data?.ErrorMessage
        });
      } else {
        setError("password", {
          type: "errorWhenUpdating",
          message: formatMessage({
            id: "profile.changeemail.errorwhenupdating"
          })
        });
      }
    } finally {
      setIsSendingUpdateEmailRequest(false);
    }
  });

  const toggle = () => {
    if (isOpen) {
      const accordionTogglerBtn = document.getElementById(`${profileSectionId}--toggler`);
      accordionTogglerBtn?.focus();
    }

    setIsOpen(prev => !prev);
  };

  const changeEmailDetails = useMemo<ProfileDetail[]>(
    () => [
      {
        name: formatMessage({
          id: "common.label.mail",
          defaultMessage: "E-post"
        }),
        value: auth.user?.profile.email ?? ""
      }
    ],
    [auth.user?.profile.email, formatMessage]
  );
  return (
    <ProfilSection id={profileSectionId} isOpen={isOpen} toggle={toggle} {...rest}>
      {{
        heading: formatMessage({
          id: "profile.logindetails.heading",
          defaultMessage: "Email"
        }),
        closedContent: <ProfileDetails details={changeEmailDetails} />,
        openContent: (
          <>
            <div>
              {!verificationCodeSent && (
                <Form id="new-email-form" className="mt-3" onSubmit={handleUpdateEmailSubmit}>
                  {applikasjoner && applikasjoner.length > 1 && !isBymUser && (
                    <div>
                      <div className="">
                        <FormattedMessage id="profile.changeemail.header" />
                      </div>
                      <div className="mt-2">
                        {applikasjoner.map(a => (
                          <div key={a.id} className="flex items-center mt-1">
                            {a.iconUrl && !a.iconUrl.includes("missing_connector_icon") && (
                              <img className="w-4 h-4" src={a.iconUrl} alt="icon" />
                            )}
                            {a.iconUrl && a.iconUrl.includes("missing_connector_icon") && (
                              <div className="w-4 h-4 flex items-center justify-center font-bold bg-blue-dark text-white">
                                {a.name.substring(0, 1)}
                              </div>
                            )}
                            <div className="ml-1 flex">{a.name}</div>
                          </div>
                        ))}
                      </div>
                      <div className="italic mt-4">
                        <FormattedMessage id="profile.changeemail.logoutinfo" />
                      </div>
                    </div>
                  )}
                  <InputField
                    name="newEmail"
                    autoComplete="email"
                    label={formatMessage({
                      id: "profile.changeemail.new.email",
                      defaultMessage: "Ny e-post"
                    })}
                    defaultValue={auth.user?.profile?.email ?? ""}
                    type="email"
                    error={errors.newEmail?.message}
                    required
                    ref={register}
                    disabled={isBymUser}
                  />
                  <InputField
                    name="password"
                    autoComplete="current-password"
                    label={formatMessage({ id: "profile.verify.password", defaultMessage: "Verifiser passord" })}
                    type="password"
                    error={errors.password?.message}
                    required
                    ref={register}
                    disabled={isBymUser}
                  />

                  {isBymUser && (
                    <MessageBox role="alert" type="danger">
                      <FormattedMessage id="profile.changeemail.userisbym" />
                    </MessageBox>
                  )}
                  {!isBymUser && (
                    <MessageBox type="primary">
                      <FormattedMessage id="profile.changeemail.verify.label" />
                    </MessageBox>
                  )}

                  <div className="flex justify-between flex-wrap mt-4">
                    <Button
                      aria-expanded={isOpen}
                      aria-label={formatMessage({ id: "profile.cancel.button" })}
                      className="w-full tablet:w-1/2 mobile-only:mb-2"
                      modifier="outline"
                      disabled={isSendingUpdateEmailRequest}
                      onClick={toggle}
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="Avbryt" />
                    </Button>
                    <Button
                      className="w-full tablet:w-1/2 "
                      form="new-email-form"
                      type="submit"
                      disabled={isSendingUpdateEmailRequest || isBymUser}
                      isLoading={isSendingUpdateEmailRequest}
                    >
                      <FormattedMessage id="common.ok" defaultMessage="Send verifikasjonskode" />
                    </Button>
                  </div>
                </Form>
              )}
              {verificationCodeSent && (
                <VerifyNewMailForm
                  onClose={() => {
                    setVerificationCodeSent(false);
                    toggle();
                  }}
                />
              )}
            </div>
          </>
        )
      }}
    </ProfilSection>
  );
}
