import { combineReducers } from "redux";
import authanticationReducer from "./Auth/Auth";
import kjoretoyReducer from "./Kjoretoy/kjoretoyReducer";
import kjoretoytyperReducer from "./Kjoretoytyper/kjoretoytyperReducer";
import oblattyperReducer from "./Oblattype/oblattypeReducer";
import handlekurvReducer from "./Handlekurv/handlekurvReducer";
import varekjopReducer from "./Varekjop/varekjopReducer";
import oblatReducer from "./Oblat/oblatreducer";
import konfig from "./Konfigurasjoner/konfigurasjonerReducer";
import kundeReducer from "./Kunde/kundeReducer";
import toastReducer from "./Toast/ToasActions";
import samtykkeReducer from "./TermsAndCondition/SamtykkeAndVilkaar";

const rootReducer = combineReducers({
  auth: authanticationReducer,
  kjoretoy: kjoretoyReducer,
  kjoretoytyper: kjoretoytyperReducer,
  oblattyper: oblattyperReducer,
  handlekurv: handlekurvReducer,
  varekjop: varekjopReducer,
  oblater: oblatReducer,
  konfigurasjon: konfig,
  kunde: kundeReducer,
  toast: toastReducer,
  vilkaarAndSamtykkeState: samtykkeReducer
});

export default rootReducer;

export type ApplicationState = ReturnType<typeof rootReducer>;
