import { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import { FormattedMessage } from "locale/langUtils";
import { piggavService } from "api";
import { LinkButtonReactRouter as LinkButton } from "features/common/ComposedComponents";
import * as urls from "appConstants/urls";

interface ActivateUserRouteParams {
  key: string;
}

export default function ActivateUserPage(): JSX.Element {
  const [isActivating, setIsActivating] = useState(true);
  const [activationSuccess, setActivationSuccess] = useState(false);
  const [activationFailed, setActivationFailed] = useState(false);
  const { key: activationKey } = useParams<ActivateUserRouteParams>();
  const history = useHistory();

  useEffect(() => {
    piggavService.aktiverBruker(activationKey)
      .then(() => {
        setActivationSuccess(true);
        setTimeout(() => history.push(urls.hjem), 15000);
      })
      .catch(() => {
        setActivationFailed(true);
      })
      .finally(() => {
        setIsActivating(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headingText =
    (isActivating && "activateuserpage.heading.activating") ||
    (activationSuccess && "activateuserpage.heading.success") ||
    (activationFailed && "activateuserpage.heading.failed") ||
    "";

  return (
    <Page metaInfo={{ robots: "noindex, nofollow" }}>
      {{
        heading: { pageTitle: <FormattedMessage id={headingText} defaultMessage="" /> },
        pageContent: (
          <Section colorClass="bg-white" className="">
            {activationSuccess && (
              <div className="flex flex-col tablet:w-10/12">
                <p className="osg-u-text-3 mb-2">
                  <FormattedMessage id="activateuserpage.info.success1" defaultMessage="Din konto er aktivert" />
                </p>
                <p className="osg-u-text-3 mb-3">
                  <FormattedMessage id="activateuserpage.info.success2" defaultMessage="Din konto er aktivert" />
                </p>

                <LinkButton
                  to={urls.hjem}
                  modifier="outline"
                  colorOption="yellow"
                  className="no-underline tablet:self-end"
                >
                  <FormattedMessage id="activateuserpage.button.gotologin" defaultMessage="Gå til login" />
                </LinkButton>
              </div>
            )}
            {activationFailed && (
              <p className="osg-u-text-3 bg-state-danger p-4">
                <FormattedMessage id="activateuserpage.info.failed" defaultMessage="Din aktiverings nøkkel er ikke gyldig" />
              </p>
            )}
          </Section>
        )
      }}
    </Page>
  );
}
