/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker";
import { InputDate as OSGInputDate } from "features/common/OSG";
import "./DatePicker.scss";
import { StatePropType } from "../Interface";
import { useIntl } from "react-intl";

interface DPProps extends Omit<ReactDatePickerProps, "showPopperArrow">, StatePropType {
  reactDatePickerRef?: string | ((instance: ReactDatePicker | null) => void) | React.RefObject<ReactDatePicker> | null;
}

export default forwardRef<HTMLInputElement, DPProps>(({ state, reactDatePickerRef, ...rest }: DPProps, refInput) => {
  const intl = useIntl();
  return (
    <>
      <span className="sr-only" id="datepicker-default-label">
        Velg dato
      </span>
      <ReactDatePicker
        ariaLabelledBy="datepicker-default-label"
        ref={reactDatePickerRef}
        nextMonthButtonLabel=""
        previousMonthButtonLabel=""
        nextYearButtonLabel=""
        previousYearButtonLabel=""
        weekLabel=""
        ariaLabelClose={intl.formatMessage({ id: "datepicker.ariaLabelClose" })}
        nextMonthAriaLabel={intl.formatMessage({ id: "datepicker.nextMonthAriaLabel" })}
        previousMonthAriaLabel={intl.formatMessage({ id: "datepicker.previousMonthAriaLabel" })}
        nextYearAriaLabel={intl.formatMessage({ id: "datepicker.nextYearAriaLabel" })}
        previousYearAriaLabel={intl.formatMessage({ id: "datepicker.previousYearAriaLabel" })}
        chooseDayAriaLabelPrefix={intl.formatMessage({ id: "datepicker.chooseDayAriaLabelPrefix" })}
        weekAriaLabelPrefix={intl.formatMessage({ id: "datepicker.weekAriaLabelPrefix" })}
        monthAriaLabelPrefix={intl.formatMessage({ id: "datepicker.monthAriaLabelPrefix" })}
        {...rest}
        showPopperArrow={false}
        customInput={<OSGInputDate ref={refInput} className={`date-picker-input state-${state}`} />}
      />
    </>
  );
});
