import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

export async function exportPageAsPdfFile(page: HTMLDivElement, fileName: string) {

  const doc = new jsPDF({
    format: 'a4',
    unit: 'pt',
  });
  var width = doc.internal.pageSize.getWidth();
  doc.setFont("sans-serif");
  doc.html(page, {
    width: width - 20,
    windowWidth:width,
    margin:10,
    async callback(pdf) {
      await pdf.save(fileName);
    },
  });
}
export async function exportPageAsPdfAsBlob(page: HTMLDivElement) {
  const element = page;
  if (!element) {
    return "";
  }
  const canvas = await html2canvas(element);
  const data = canvas.toDataURL('image/png');
  const pdf = new jsPDF();  
  const imgProperties = pdf.getImageProperties(data);
  const pdfWidth = pdf.internal.pageSize.getWidth();
  const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
  pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
  const blob = pdf.output('blob');
  return blob;
}

export async function exportPageAsImage(page: HTMLDivElement, callback: Function) {
  const doc = new jsPDF({
    format: 'a4',
    unit: 'pt',
  });
  var width = doc.internal.pageSize.getWidth();
  doc.setFont("sans-serif");
  doc.html(page, {
    width: width - 20,
    windowWidth:width,
    margin:10,
    async callback(pdf) {
      var data = await pdf.output('datauristring');
      callback(data.split('base64,')[1]);
    },
  });
}
