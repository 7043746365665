import React, { useState } from "react";
import { useReduxDispatch } from "utils/Storeutils";
import { FormattedMessage } from "locale/langUtils";
import { Checkbox } from "features/common/OSG";
import { updateKunde } from "state/Kunde/kundeActions";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import { Kunde } from "interfaces";

interface WithdrawConsentSettingProps extends React.HTMLProps<HTMLDivElement> {
  userId: string;
  customerInfo?: Kunde;
}

export default function WithdrawConsentSetting({
  userId,
  customerInfo,
  ...rest
}: WithdrawConsentSettingProps): JSX.Element {
  const [updatingUserInformation, setUpdatingUserInformation] = useState(false);
  const [userUpdateSuccess, setUserUpdateSuccess] = useState(false);
  const [userUpdatedError, setUserUpdateError] = useState(false);

  const dispatch = useReduxDispatch();

  const timeoutCall = (functionToCall: () => any, delayedFunctionCall: () => any, timeout: number) => {
    functionToCall();
    setTimeout(delayedFunctionCall, timeout);
  };

  const handleUpdatesConsentToMailWarnings = async (e: React.FormEvent<HTMLInputElement>) => {
    if (!customerInfo) return;
    try {
      setUpdatingUserInformation(true);
      setUserUpdateSuccess(false);
      setUserUpdateError(false);
      await dispatch(
        updateKunde(userId, {
          ...customerInfo,
          samtykkerTilEpostvarsler: !customerInfo.samtykkerTilEpostvarsler
        })
      );
      timeoutCall(
        () => setUserUpdateSuccess(true),
        () => setUserUpdateSuccess(false),
        20000
      );
    } catch {
      timeoutCall(
        () => setUserUpdateError(true),
        () => setUserUpdateError(false),
        20000
      );
      setUserUpdateError(true);
    } finally {
      setUpdatingUserInformation(false);
    }
  };

  return (
    <div {...rest}>
      <h2 className="heading-3 mb-3">
        <FormattedMessage id="profile.warningseasonstart.heading" />
      </h2>
      <div className="inline-block">
        <Checkbox
          id="notification-check"
          className="block"
          aria-busy={updatingUserInformation}
          checked={customerInfo?.samtykkerTilEpostvarsler ?? false}
          aria-checked={customerInfo?.samtykkerTilEpostvarsler ?? false}
          onChange={handleUpdatesConsentToMailWarnings}
          aria-describedby="notification-check--alert notification-check--success"
        >
          <FormattedMessage id="common.label.remainderonemail" />
        </Checkbox>
        <MessageBox
          className="mt-3"
          visible={userUpdatedError}
          aria-hidden={userUpdateSuccess}
          id="notification-check--alert"
          role="alert"
          type="danger"
        >
          <FormattedMessage id="profile.error.general" />
        </MessageBox>
        <MessageBox
          className="mt-3"
          aria-hidden={userUpdatedError}
          visible={userUpdateSuccess}
          id="notification-check--success"
          aria-live="polite"
          aria-atomic="true"
          type="success"
        >
          <FormattedMessage id="profile.success.general" />
        </MessageBox>
      </div>
    </div>
  );
}
