import Section from "features/common/layout/Section";
import { LinkButton } from "features/common/OSG";
import { FormattedMessage, useIntl } from "locale/langUtils";
import * as urls from "appConstants/urls";
import Page from "features/common/layout/Page";
import { Button } from "features/common/ComposedComponents";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { Configurations } from "interfaces/Configuration";
import { getState } from "state/Konfigurasjoner/konfigurasjonerReducer";
import { format } from "date-fns";

export default function LandingPage(): JSX.Element {
  const auth = useAuth();
  const intl = useIntl();

  const { sesongStart, sesongSlutt, salgStart } = useSelector<any, Configurations>(getState);

  return (
    <Page
      metaInfo={{
        titlePageName: intl.formatMessage({ id: "landingpageloggedoutcontent.meta.title" }),
        titleDescription: intl.formatMessage({ id: "landingpageloggedoutcontent.meta.title.description" }),
        description: intl.formatMessage({ id: "landingpageloggedoutcontent.meta.description" })
      }}
    >
      {{
        pageContent: (
          <>
            <Section
              colorClass="osg-u-color-bg-green-dark"
              className="osg-u-color-text-green-light "
              modifier="osg-u-padding-top-5 osg-u-padding-vertical-6-tablet-and-up "
            >
              <h1 className="osg-u-heading-1 osg-u-margin-bottom-4">
                <FormattedMessage id="landingpageloggedoutcontent.heading" defaultMessage="Kjøp av piggdekkoblat" />
              </h1>
              <p className="osg-u-text-1">
                <FormattedMessage id="landingpageloggedoutcontent.subheading" />
              </p>
              {sesongStart && sesongSlutt && (
                <>
                  <p className="osg-u-text-2 mt-5">
                    <FormattedMessage
                      id="landingpageloggedoutcontent.season.text"
                      values={{
                        sesongStart: format(new Date(sesongStart.verdi), "dd.MM.yyyy"),
                        sesongSlutt: format(new Date(sesongSlutt.verdi), "dd.MM.yyyy")
                      }}
                    />
                  </p>
                  <p className="osg-u-text-5 ml-3">
                    <FormattedMessage
                      id="landingpageloggedoutcontent.season.salestart.text"
                      values={{ salgStart: format(new Date(salgStart.verdi), "dd.MM.yyyy") }}
                    />
                  </p>
                </>
              )}
            </Section>
            <Section>
              <div className="flex justify-between flex-wrap">
                <div className="w-full tablet:w-5/12 mobile-only:mb-5">
                  <h2 className="osg-u-heading-2 osg-u-margin-bottom-2">
                    <FormattedMessage
                      id="landingpageloggedoutcontent.login.heading"
                      defaultMessage="Logg inn på Piggav"
                    />
                  </h2>
                  <Button
                    isLoading={auth.isLoading}
                    disabled={auth.isLoading}
                    className="w-full tablet:w-4/6"
                    onClick={() => auth.signinRedirect()}
                  >
                    {auth.isLoading ? (
                      <FormattedMessage id="landingpageloggedoutcontent.login.button.loading" />
                    ) : (
                      <FormattedMessage id="landingpageloggedoutcontent.login.button" />
                    )}
                  </Button>
                </div>
                <div className="w-full tablet:w-5/12">
                  <h2 className="osg-u-heading-2 osg-u-margin-bottom-2">
                    <FormattedMessage id="landingpageloggedoutcontent.register.heading" defaultMessage="Ny bruker?" />
                  </h2>

                  <LinkButton
                    to={urls.registrerBruker}
                    className="hover:no-underline hover:cursor-pointer mobile-only:w-full"
                    modifier="outline"
                    colorOption="yellow"
                    data-cy="registrer-deg"
                  >
                    <FormattedMessage
                      id="landingpageloggedoutcontent.register.button"
                      defaultMessage="Registrer deg her"
                    />
                  </LinkButton>
                </div>
              </div>
            </Section>
          </>
        )
      }}
    </Page>
  );
}
