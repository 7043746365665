import { useEffect, useState, useMemo, createRef } from "react";
import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import { FormattedMessage, useIntl } from "locale/langUtils";
import { piggavService } from "api";
import axios, { AxiosResponse } from "axios";
import { useParams } from "react-router";
import { useReduxDispatch } from "utils/Storeutils";
import { getKjoretoyForBruker } from "state/Kjoretoy/kjoretoyActions";
import { useSelector } from "react-redux";
import CurrencyFormat from "locale/CurrencyFormat";
import { Varekjop, BymEndpointResult, Oblat, StickerWInfo } from "interfaces";
import Loader from "features/common/ComposedComponents/Loader/Loader";
import { getOblattyper } from "state/Oblattype/oblattypeActions";
import DateTimeFormat from "locale/DateTimeFormat";
import { ApplicationState } from "state/reducers";
import { map } from "lodash-es";
import * as urls from "appConstants/urls";
import { setStickerInfo, convertPaymentmethodToLangId } from "utils/oblatHelpers";
import "./Receipt.scss";
import { piggavResepsjon, PURCHASE_STATES } from "appConstants";
import { addHours } from "date-fns";
import { isWithinTimeRange } from "utils/dateFnsUtils";
import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import useUserHook from "features/Hooks/UserHook";
import { LinkButtonReactRouter as LinkButton } from "features/common/ComposedComponents";
import { Button } from "features/common/ComposedComponents/";
import SendFromReceiptFromReception from "./SendReciptFromReception";
import CancelPurchase from "./CancelPurchase";
import { exportPageAsImage, exportPageAsPdfFile } from "utils/exportPdfUtil";
import { ReceiptMainContent } from "./ReceiptMainContent";
import { PaymentDetail } from "./PaymentDetail";
import React from "react";
import { BYMAddress } from "./BYMAddress";
import { ReceiptPayment } from "./ReceiptPayment";
import { Kvitteringsnummer } from "./Kvitteringsnummer";
import { ReceiptPrintContent } from "./ReceiptPrintContent";

export interface VarekjopWSticker extends Varekjop {
  oblater: StickerWInfo[];
}

export default function ReceiptsPage(): JSX.Element {
  const [varekjop, setVarekjop] = useState<VarekjopWSticker | null>(null);
  const [sendingReceipt, setSendingReceipt] = useState(false);
  const [showEpostMessage, setShowEpostMessage] = useState(false);
  const [showEpostSentMessage, setShowEpostSentMessage] = useState(false);
  const [sendEpostError, setSendEpostError] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { key: receiptId } = useParams<{ key: string }>();

  const receiptRef = createRef<HTMLDivElement>();
  const intl = useIntl();

  const dispatch = useReduxDispatch();
  const { userInfo, userType } = useUserHook();
  const { oblattyper, kjoretoy } = useSelector<ApplicationState, ApplicationState>(s => s);

  useEffect(() => {
    if (!userInfo?.brukerId || !receiptId) return;
    // Get receipt with receiptId
    setIsLoading(true);
    axios
      .all([
        piggavService.getVarekjopById(userInfo.brukerId, receiptId),
        piggavService.getOblaterForVarekjop(userInfo.brukerId, receiptId),
        dispatch(getKjoretoyForBruker(userInfo?.brukerId)),
        dispatch(getOblattyper())
      ])
      .then(res => {
        const varekjopRes = res[0] as AxiosResponse<BymEndpointResult<Varekjop>>;
        const varekjopOblaterRes = res[1] as AxiosResponse<BymEndpointResult<Oblat[]>>;
        const varekjopOblaterWInfo = setStickerInfo(varekjopOblaterRes.data.result);
        // setReceipt(res.data?.result as Varekjop);
        setVarekjop({
          ...varekjopRes.data.result,
          oblater: varekjopOblaterWInfo
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [userInfo, receiptId, dispatch]);

  const cancelPurchaseCallback = () => {
    if (!userInfo?.brukerId) return;
    setIsLoading(false);
    piggavService
      .getVarekjopById(userInfo?.brukerId, receiptId)
      .then((res: any) => {
        const varekjopRes = res as AxiosResponse<BymEndpointResult<Varekjop>>;
        setVarekjop(prev => {
          if (prev) return { ...prev, ...varekjopRes.data.result };
          return prev;
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const saveReceipt = async () => {
    if (receiptRef.current !== null) {
      await exportPageAsPdfFile(receiptRef.current, "kvittering.pdf");
    }
  };
  const sendReceipt = async () => {
    try {
      if (!userInfo?.brukerId || !varekjop?.id) return;
      setSendingReceipt(true);
      var formData: FormData | undefined | string = undefined;

      if (receiptRef.current !== null) {
        await exportPageAsImage(receiptRef.current, async (attachment: string) => {
          console.log("attachment", attachment);
          formData = new FormData();
          formData.append("file", attachment);
          const resp = await piggavService.sendKvitteringToMail(userInfo?.brukerId, varekjop?.id, formData);
          if (resp.status === 200) setShowEpostSentMessage(true);
        });
      }
    } catch (e) {
      setSendEpostError(true);
    } finally {
      setShowEpostSentMessage(true);
      setSendingReceipt(false);
    }
  };

  const paymentDetails = useMemo<PaymentDetail[]>(() => {
    if (varekjop?.statusId === 130)
      return [
        {
          label: (
            <strong>
              <FormattedMessage id="receiptpage.varekjopAvbrutt" />{" "}
            </strong>
          ),
          value: ""
        }
      ];

    const details: PaymentDetail[] = [
      {
        label: <FormattedMessage id="receiptpage.purchaseDetailHeader" />,
        value: <DateTimeFormat value={varekjop?.kjopsinfo?.kjopsdato ?? varekjop?.betalt} />
      },
      {
        label: <FormattedMessage id="receiptpage.paymentMethod" />,
        value: <FormattedMessage id={convertPaymentmethodToLangId(varekjop?.betalingsform)} defaultMessage="" />
      }
    ];

    if (varekjop?.betalingsform.toUpperCase() === "KORT") {
      details.push({
        label: <FormattedMessage id="receiptpage.receiptCardNumberlabel" />,
        value: (
          <>{`${varekjop?.kjopsinfo?.kortutsteder ?? ""}
  (${varekjop?.kjopsinfo?.maskertKortnummer ?? ""})`}</>
        )
      });
    } else if (varekjop?.betalingsform.toUpperCase() === "FAKTURA") {
      details.push({
        label: <FormattedMessage id="receiptpage.paymentStatus" />,
        value: (PURCHASE_STATES.INVOICE_SENT === varekjop.statusId && (
          <FormattedMessage id="receiptpage.statusInvoiceSent" />
        )) ||
          (PURCHASE_STATES.OK === varekjop.statusId && <FormattedMessage id="receiptpage.statusPayed" />) || <></>
      });
      if (varekjop?.kjopsinfo?.betalingsdato) {
        details.push({
          label: <FormattedMessage id="receiptpage.paymentDate" />,
          value: <DateTimeFormat value={varekjop?.kjopsinfo?.betalingsdato} />
        });
      }
    }

    details.push({
      label: (
        <strong>
          <FormattedMessage id="receiptpage.receiptOrgNamelabel" />
        </strong>
      ),
      value: null
    });
    details.push({
      label: <FormattedMessage id="receiptpage.receiptOrgNrlabel" />,
      value: null
    });

    return details;
  }, [varekjop]);

  let pageTitle = null;

  switch (varekjop?.statusId) {
    case PURCHASE_STATES.OK:
      pageTitle = <FormattedMessage id="receiptpage.title.ok" />;
      break;
    case PURCHASE_STATES.INVOICE_SENT:
      pageTitle = <FormattedMessage id="receiptpage.title.onvoicesent" />;
      break;
    default:
      pageTitle = <FormattedMessage id="receiptpage.title" />;
      break;
  }

  return (
    <Page
      metaInfo={{
        titlePageName: intl.formatMessage({ id: "receiptpage.meta.title" }),
        titleDescription: intl.formatMessage({ id: "receiptpage.meta.title.description" })
      }}
    >
      {{
        heading: {
          pageTitle: pageTitle
        },
        pageContent: (
          <Loader isLoading={isLoading}>
            {{
              loadingText: <FormattedMessage id="common.loading" />,
              renderLoader: loader => (
                <Section>
                  <div className="flex justify-center">{loader}</div>
                </Section>
              ),
              loadingContent: (
                <Section>
                  <div className="flex flex-col tablet:hidden print:hidden">
                    {map(varekjop?.oblater, oblat => (
                      <div className="bg-grey-light p-2 mb-2">
                        <div className="py-1 flex">
                          <span className="font-semibold w-1/3">
                            <FormattedMessage id="receiptpage.receiptThregnumber" />
                          </span>
                          <span>{oblat.oblat.kjoretoy?.registreringsnummer}</span>
                        </div>
                        <div className="py-1 flex">
                          <span className="font-semibold w-1/3">
                            <FormattedMessage id="receiptpage.receiptThType" />
                          </span>
                          <span>
                            <FormattedMessage id={oblat.name} defaultMessage="Ukjent oblattype" />
                          </span>
                        </div>
                        <div className="py-1 flex">
                          <span className="font-semibold w-1/3">
                            <FormattedMessage id="common.stickerinfo.validfrom" />
                          </span>
                          <span>
                            <DateTimeFormat value={oblat.oblat.gyldigFra} />
                          </span>
                        </div>
                        <div className="py-1 flex">
                          <span className="font-semibold w-1/3">
                            <FormattedMessage id="common.stickerinfo.validto" />
                          </span>
                          <span>
                            <DateTimeFormat value={oblat.oblat.gyldigTil} />
                          </span>
                        </div>
                        <div className="py-1 flex">
                          <span className="font-semibold w-1/3">
                            <FormattedMessage id="receiptpage.receiptThPrice" />
                          </span>
                          <span className="ml-auto font-semibold">
                            <CurrencyFormat value={oblat.oblat.oblattype?.pris ?? 0} />
                          </span>
                        </div>
                      </div>
                    ))}
                    <div className="flex border-solid border-0 border-b-2 py-4 border-grey-dark">
                      <span className="ml-auto mr-0 text-3 font-semibold">
                        <FormattedMessage
                          id="receiptpage.receiptTableFooterSum"
                          values={{
                            sum: <CurrencyFormat value={varekjop?.totalsum ?? 0} />
                          }}
                        />
                      </span>
                    </div>
                  </div>
                  {varekjop && (
                    <div className="mobile-only:hidden print:block">
                      <BYMAddress className="address-for-print" />
                      <Kvitteringsnummer
                        className="kvittering-kvitteringsnummer"
                        kvitteringsnummer={varekjop?.kjopsnummer}
                      />
                      <ReceiptMainContent
                        varekjop={varekjop}
                        kjoretoy={kjoretoy}
                        oblattyper={oblattyper}
                        className="kvittering-oblater"
                      />
                    </div>
                  )}
                  <div className="flex flex-col">
                    <div className="flex mobile-only:flex-col tablet:justify-end tablet:flex-row pb-2 pt-2 mobile-only:order-last print:hidden kvittering-knapperad">
                      <LinkButton
                        modifier="outline"
                        colorOption="yellow"
                        role="link"
                        to={urls.kvitteringer}
                        className="mobile-only:mb-2"
                      >
                        <FormattedMessage id="receiptpage.backBtn" />
                      </LinkButton>
                      {userType === piggavResepsjon ? (
                        <SendFromReceiptFromReception
                          varekjopId={varekjop?.id ?? ""}
                          brukerId={userInfo?.brukerId ?? ""}
                          buttonClassName="mobile-only:mb-2 tablet:ml-2"
                        />
                      ) : (
                        <Button
                          onClick={() => setShowEpostMessage(true)}
                          disabled={sendingReceipt}
                          isLoading={sendingReceipt}
                          icon="email"
                          className="mobile-only:mb-2 tablet:ml-2"
                        >
                          <FormattedMessage id="receiptpage.sendToEmailBtn" />
                        </Button>
                      )}
                      <Button onClick={() => window.print()} icon="print" className="tablet:ml-2">
                        <FormattedMessage id="receiptpage.printBtn" />
                      </Button>
                    </div>
                    <div className="kvittering-knapperad-avbryt order-last print:hidden">
                      {varekjop &&
                        isWithinTimeRange(
                          new Date(),
                          new Date(varekjop.betalt),
                          addHours(new Date(varekjop.betalt), 24)
                        ) &&
                        userType === piggavResepsjon && (
                          <CancelPurchase cancelPurchaseCallback={cancelPurchaseCallback} varekjop={varekjop} />
                        )}
                    </div>
                    {varekjop && (
                      <ReceiptPayment
                        className="text-5 mobile-only:py-5 p-3"
                        varekjop={varekjop}
                        paymentDetails={paymentDetails}
                      />
                    )}
                  </div>
                  <Modal
                    toggle={() => {
                      setSendEpostError(false);
                      setShowEpostSentMessage(false);
                      setShowEpostMessage(false);
                    }}
                    isOpen={showEpostMessage}
                  >
                    <ModalHeader className="flex flex-col justify-end">
                      {{
                        heading: (
                          <div className="flex justify-end gap-2">
                            <Button
                              modifier="outline"
                              onClick={() => {
                                setSendEpostError(false);
                                setShowEpostSentMessage(false);
                                sendReceipt();
                              }}
                            >
                              <FormattedMessage id="receiptpage.button.Send" />
                            </Button>
                            <Button
                              modifier="outline"
                              onClick={() => {
                                setSendEpostError(false);
                                setShowEpostSentMessage(false);
                                saveReceipt();
                              }}
                            >
                              <FormattedMessage id="receiptpage.button.Lagre" />
                            </Button>
                          </div>
                        )
                      }}
                    </ModalHeader>
                    <ModalSection className="flex">
                      {varekjop && (
                        <div ref={receiptRef} className="flex flex-col gap-3">
                          <h1 className="flex justify-start text-2">
                            {sendEpostError ? (
                              <FormattedMessage id="receiptpage.receiptError" />
                            ) : showEpostSentMessage ? (
                              <FormattedMessage id="receiptpage.receiptSent" />
                            ) : (
                              <FormattedMessage id="receiptpage.title.ok" />
                            )}
                          </h1>
                          <ReceiptPrintContent
                            className="kvittering-print-oblater"
                            varekjop={varekjop}
                            kjoretoy={kjoretoy}
                            oblattyper={oblattyper}
                            paymentDetails={paymentDetails}
                          />
                        </div>
                      )}
                      {sendEpostError ? (
                        <MessageBox type="danger">
                          <FormattedMessage id="receiptpage.receiptErrorMsg" />
                        </MessageBox>
                      ) : showEpostSentMessage ? (
                        <MessageBox type="success">
                          <FormattedMessage id="receiptpage.receiptSentMsg" />
                        </MessageBox>
                      ) : (
                        <div></div>
                      )}
                    </ModalSection>
                  </Modal>
                </Section>
              )
            }}
          </Loader>
        )
      }}
    </Page>
  );
}
