import { piggavService } from "api";
import { isEmpty } from "lodash-es";
import * as apiResponseUtils from "../../utils/apiResponseUtils";

export const GET_OBLATER_REQUEST = "GET_OBLATER_REQUEST";
export const GET_OBLATER_SUCCESS = "GET_OBLATER_SUCCESS";
export const GET_OBLATER_FAILURE = "GET_OBLATER_FAILURE";
export const RESET_OBLATER_STATE = "piggav/oblat/RESET_OBLATER_STATE";

const getOblaterSuccess = oblat => ({
  type: GET_OBLATER_SUCCESS,
  payload: oblat
});

const getOblaterLoading = oblat => ({
  type: GET_OBLATER_REQUEST,
  payload: oblat
});

const getOblaterFailure = error => ({
  type: GET_OBLATER_FAILURE,
  payload: error,
  error: true
});

export const resetOblaterState = () => ({
  type: RESET_OBLATER_STATE,
  payload: ""
});

export const getOblaterForBruker =
  (brukerId, overrideReturnOld = false) =>
  (dispatch, getState) => {
    const { lasterOblater, oblater } = getState().oblater;
    if (!isEmpty(oblater) && !overrideReturnOld) return Promise.resolve(oblater);
    if (lasterOblater) return undefined;
    dispatch(getOblaterLoading());
    return piggavService
      .getOblaterForKunde(brukerId)
      .then(response => {
        const data = response.data.result;
        dispatch(getOblaterSuccess(data));
        return Promise.resolve(data);
      })
      .catch(error => {
        const errorMessage = apiResponseUtils.errorData(error);
        dispatch(getOblaterFailure(errorMessage));
        return Promise.reject(error);
      });
  };

export const kansellerOblatEksternBetaling = (oblatId, varekjopId, kundeId, payload) => () =>
  piggavService.kansellerObblatEksternBetaling(kundeId, varekjopId, oblatId, payload);

export const kansellerOgKrediterOblatFaktura = (oblatId, varekjopId, brukerId) => () =>
  piggavService.kansellerOgKrediterOblat(brukerId, varekjopId, oblatId);

export const beregnRefusjonsBelop = (oblatId, varekjopId, brukerId) => () =>
  piggavService.beregnRefusjonsbelop(brukerId, varekjopId, oblatId);

export const kansellerOblat = (oblatId, varekjopId, brukerId) => () =>
  piggavService.kansellerOblat(brukerId, varekjopId, oblatId);

export const endreOblat = (oblatId, varekjopId, brukerId, kjoretoyId) => () =>
  piggavService.endreOblatKjoretoy(brukerId, oblatId, kjoretoyId);

export const endreOblatSkjema = (oblatId, brukerId, payload) => () =>
  piggavService.endreOblatKjoretoySkjema(brukerId, oblatId, payload);

export const SEARCH_OBLAT = "SEARCH_OBLAT";
