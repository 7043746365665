import { useState } from "react";
import { useParams } from "react-router-dom";
import Page from "features/common/layout/Page";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import * as yup from "yup";
import Section from "features/common/layout/Section";
import { FormattedMessage } from "locale/langUtils";
import InputField from "features/common/ComposedComponents/Inputfield/Inputfield";
import { LinkButton } from "features/common/OSG";
import { Button } from "features/common/ComposedComponents";
import { useIntl } from "react-intl";
import * as urls from "appConstants/urls";
import { passwordRegex } from "utils/validationUtils";
import { endrePassordAktivering } from "api/piggavService";

export default function ForgotPasswordPage(): JSX.Element {
  const [isActivatingNewPWDRequest, setIsActivatingNewPWDRequest] = useState(false);
  const [isActivatedAndPasswordChanged, setIsActivatedAndPasswordChanged] = useState(false);
  const { formatMessage } = useIntl();
  const { key } = useParams<{ key: string }>();
  const passwordActivationSchema = yup.object().shape({
    newPassword: yup
      .string()
      .required(
        formatMessage({
          id: "common.error.requiredfield",
          defaultMessage: "Feltet må fylles ut"
        })
      )
      .matches(
        passwordRegex,
        formatMessage({
          id: "common.error.invalidpassword",
          defaultMessage: "Passord må være 8 tegn eller lengre, og inneholde minst 1 stor bokstav og 1 tall"
        })
      ),
    repeatPassword: yup
      .string()
      .required(
        formatMessage({
          id: "common.error.requiredfield",
          defaultMessage: "Feltet må fylles ut"
        })
      )
      .oneOf(
        [yup.ref("newPassword")],
        formatMessage({
          id: "common.error.equalpassword",
          defaultMessage: "Passordene må være like."
        })
      )
  });
  const { handleSubmit, register, errors, setError } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(passwordActivationSchema)
  });

  const handleActivateChangePasswordSubmit = handleSubmit(async ({ newPassword }) => {
    try {
      setIsActivatingNewPWDRequest(true);
      await endrePassordAktivering(key ?? "", newPassword);
      setIsActivatedAndPasswordChanged(true);
    } catch {
      setError("repeatPassword", {
        type: "errorWhenUpdating",
        message: formatMessage({
          id: "activateuserpasswordpage.error.whenupdating",
          defaultMessage:
            "Kunne ikke oppdatere passord, prøv igjen eller kontakt oss, se nederst på siden for kontaktinfo"
        })
      });
    } finally {
      setIsActivatingNewPWDRequest(false);
    }
  });

  return (
    <Page
      metaInfo={{
        titlePageName: formatMessage({ id: "activateuserpasswordpage.meta.title" }),
        description: formatMessage({ id: "activateuserpasswordpage.meta.title.description" })
      }}
    >
      {{
        heading: {
          pageTitle: isActivatedAndPasswordChanged ? (
            <FormattedMessage
              id="activateuserpasswordpage.heading.passwordupdated"
              defaultMessage="Sjekk e-posten din!"
            />
          ) : (
            <FormattedMessage id="activateuserpasswordpage.heading" defaultMessage="Lag nytt passord" />
          )
        },
        pageContent: (
          <Section>
            {!isActivatedAndPasswordChanged ? (
              <div>
                <form
                  id="new-password-form"
                  className="w-full tablet:w-1/2"
                  onSubmit={handleActivateChangePasswordSubmit}
                >
                  <InputField
                    name="newPassword"
                    label={formatMessage({
                      id: "activateuserpasswordpage.label.newpassword",
                      defaultMessage: "Nytt passord"
                    })}
                    error={errors.newPassword?.message}
                    ref={register}
                    type="password"
                  />
                  <InputField
                    name="repeatPassword"
                    label={formatMessage({
                      id: "activateuserpasswordpage.label.repeatpassword",
                      defaultMessage: "Repetér det nye passordet"
                    })}
                    error={errors.repeatPassword?.message}
                    ref={register}
                    type="password"
                  />
                  <div className="flex justify-end">
                    <Button
                      className=""
                      form="new-password-form"
                      type="submit"
                      disabled={isActivatingNewPWDRequest}
                      isLoading={isActivatingNewPWDRequest}
                    >
                      <FormattedMessage id="activateuserpasswordpage.button.save" defaultMessage="Lagre passord" />
                    </Button>
                  </div>
                </form>
              </div>
            ) : (
              <div>
                <p className="text-3 mb-4">
                  <FormattedMessage id="activateuserpasswordpage.info" defaultMessage="" />
                </p>

                <LinkButton
                  to={urls.hjem}
                  modifier="outline"
                  colorOption="yellow"
                  className="hover:no-underline text-3"
                >
                  <FormattedMessage id="activateuserpasswordpage.link.gotologin" defaultMessage="" />
                </LinkButton>
              </div>
            )}
          </Section>
        )
      }}
    </Page>
  );
}
