import { useContext, useEffect } from "react";
import Select from "features/common/BaseComponents/Select/CustomReactSelect";
import { Kjoretoy, BymEndpointResult, Kunde } from "interfaces";
import { map } from "lodash-es";
import { useIntl } from "react-intl";
import Radio from "features/common/BaseComponents/Radio/Radio";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import { FormattedMessage } from "locale/langUtils";
import { findVehicleInList } from "utils/kjoretoyUtils";
import { useSelector } from "react-redux";
import Addvehicle from "features/AddVehiclesDialogue/AddVehicle";
import { getKjoretoyForBruker } from "state/Kjoretoy/kjoretoyActions";
import UserHook from "features/Hooks/UserHook";
import { useReduxDispatch } from "utils/Storeutils";
import { getState as getKundeState } from "state/Kunde/kundeReducer";
import { ApplicationState } from "state/reducers";
import { OrdreLinjeContext } from "./OrdrelinjeForm";

interface VehicleSelectProps {
  onChange?: (kjoretoyId: string) => void;
}

export default function SelectVehicle({ onChange }: VehicleSelectProps): JSX.Element {
  const { formatMessage } = useIntl();
  const {
    chosenVehicle,
    isFetchingVehicles,
    errors: { kjoretoyIdError },
    dispatch: dispatchOrdrelinjeformAction
  } = useContext(OrdreLinjeContext);
  const kjoretoy = useSelector<any, Kjoretoy[]>(state => state.kjoretoy.kjoretoy);
  const { standardKjoretoyId } = useSelector<ApplicationState, Kunde>(getKundeState);
  const dispatchReduxAction = useReduxDispatch();
  const { userInfo } = UserHook();

  useEffect(() => {
    if (!userInfo) return;
    dispatchOrdrelinjeformAction({
      type: "SET_IS_FETCHING_VEHICLES",
      payload: true
    });
    dispatchReduxAction(getKjoretoyForBruker(userInfo?.brukerId ?? ""))
      .then((data: BymEndpointResult<Kjoretoy[]>) => {
        const vehicleList = data.result;
        // Just set selected vehicle to first in list
        if (!standardKjoretoyId)
          dispatchOrdrelinjeformAction({
            type: "SET_VEHICLE",
            payload: vehicleList?.[0] ?? undefined
          });
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        else onSelectVehicleChange(standardKjoretoyId);
      })
      .finally(() => {
        dispatchOrdrelinjeformAction({
          type: "SET_IS_FETCHING_VEHICLES",
          payload: false
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo]);

  const onSelectVehicleChange = (v: string): void => {
    const vehicle = findVehicleInList(v, kjoretoy);
    if (!vehicle) return;
    dispatchOrdrelinjeformAction({
      type: "SET_VEHICLE",
      payload: vehicle
    });
    onChange?.(v);
  };

  const leggTilKjoreotySuccessCallback = (vehicle: any) => {
    dispatchOrdrelinjeformAction({
      type: "SET_VEHICLE",
      payload: vehicle
    });
  };

  return (
    <>
      <fieldset>
        <legend id="velg-kjoretoy-label" className="heading-5 mb-2">
          <FormattedMessage id="buystickers.addOrdrelinjeFormCarNumber" /> <span aria-hidden> *</span>
          <span className="sr-only">
            <FormattedMessage id="common.requiredfield" />
          </span>
        </legend>
        {kjoretoy.length > 9 ? (
          <Select
            placeholder={formatMessage({
              id: "buystickers.addOrdrelinjeFormCarNumberList"
            })}
            isLoading={isFetchingVehicles}
            aria-label={formatMessage({
              id: "buystickers.addOrdrelinjeFormCarNumberList"
            })}
            options={map(kjoretoy, k => ({
              label: `${k.registreringsnummer} ${k.beskrivelse || ""}`,
              value: k.id
            }))}
            value={
              chosenVehicle
                ? {
                    value: chosenVehicle.id ?? "",
                    label: `${chosenVehicle.registreringsnummer ?? ""} ${chosenVehicle.beskrivelse ?? ""}`
                  }
                : { value: "", label: "" }
            }
            onChange={e => {
              onSelectVehicleChange((e as any).value);
            }}
            isSearchable
            aria-describedby="kjoretoyerror velg-kjoretoy-label"
          />
        ) : (
          map(kjoretoy, k => (
            <Radio
              checked={chosenVehicle?.id === k.id}
              tabIndex={0}
              key={`KjoretoyGroup${k.id}`}
              value={k.id}
              onClick={e => onSelectVehicleChange(e.currentTarget.value)}
              onChange={e => onSelectVehicleChange(e.currentTarget.value)}
              aria-describedby="kjoretoyerror velg-kjoretoy-label"
            >
              {`${k.registreringsnummer} ${k.beskrivelse ? k.beskrivelse : ""}`}
            </Radio>
          ))
        )}

        {kjoretoyIdError && (
          <MessageBox id="kjoretoyerror" role="alert" type="danger">
            <FormattedMessage id={kjoretoyIdError} />
          </MessageBox>
        )}
      </fieldset>
      <Addvehicle className="mt-2 ml-auto" leggTilKjoreotySuccessCallback={leggTilKjoreotySuccessCallback} />
    </>
  );
}
