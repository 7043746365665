import React, { ReactNode } from "react";
import Spinner from "features/common/BaseComponents/Spinner/Spinner";

interface LoaderProps {
  isLoading: boolean;
  children: {
    renderLoader?: (Loader: JSX.Element) => JSX.Element;
    loadingText?: ReactNode;
    loadingContent: ReactNode;
  };
}

interface SpinnerAndTextProps {
  loadingText?: ReactNode;
}
function SpinnerAndText({ loadingText }: SpinnerAndTextProps): JSX.Element {
  return (
    <div className="flex flex-col items-center heading-5 w-full w-100">
      <Spinner />
      {loadingText && (
        <span
          aria-live="polite"
          style={{
            fontStyle: "inherit",
            font: "inherit",
            fontFamily: "inherit",
            fontSize: "inherit"
          }}
        >
          {loadingText}
        </span>
      )}
    </div>
  );
}

export default function Loader({
  isLoading,
  children: { loadingText, loadingContent, renderLoader }
}: LoaderProps): JSX.Element {
  if (isLoading) {
    if (renderLoader) {
      return renderLoader(<SpinnerAndText loadingText={loadingText} />);
    }
    return <SpinnerAndText loadingText={loadingText} />;
  }
  return <>{loadingContent}</>;
}
