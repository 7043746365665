import { useState, ReactNode, useEffect, useMemo } from "react";
import Modal, { ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { map, filter } from "lodash-es";
import { FormattedMessage } from "locale/langUtils";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import Inputfield from "features/common/ComposedComponents/Inputfield/Inputfield";
import Select from "features/common/BaseComponents/Select/CustomReactSelect";
import { Button, MessageBoxWIcon } from "features/common/ComposedComponents";
import TextArea from "features/common/BaseComponents/Textarea/TextArea";
import Addvehicle from "features/AddVehiclesDialogue/AddVehicle";
import StickerInfoBlock from "features/common/Stickerinfo/StickerInfoBlock";
import { getState as getKjoretoyState } from "state/Kjoretoy/kjoretoyReducer";
import { Kjoretoy, StickerWInfo } from "interfaces";
import { ApplicationState } from "state/reducers";
import { useReduxDispatch } from "utils/Storeutils";
import { endreOblat, endreOblatSkjema, getOblaterForBruker } from "state/Oblat/oblatActions";
import { getState as getAuthState } from "state/Auth/Auth";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import { getKjoretoy } from "../../utils/oblatHelpers";
import "./EditTagModal.css";

interface ChangeStickerModalProps {
  oblatInfo: StickerWInfo;
  open: boolean;
  toggleOpen: (eventKey: string) => void;
  eventKey: string;
}

interface ChangeStickerState {
  kjoretoyId: string;
  kjoretoyIdError: string;
  laster: boolean;
  successmessage: ReactNode;
  errormessage: ReactNode;
  errors: any;
  endringsskjema: {
    kjoretoyFra: string;
    kjoretoyTil: string;
    begrunnelse: string;
    telefonnummer: string;
  };
}

const initialState = {
  kjoretoyId: "",
  kjoretoyIdError: "",
  laster: false,
  successmessage: "",
  errormessage: "",
  errors: {},
  endringsskjema: {
    kjoretoyFra: "",
    kjoretoyTil: "",
    begrunnelse: "",
    telefonnummer: ""
  }
};

export default function ChangeStickerModal({
  oblatInfo,
  open,
  toggleOpen,
  eventKey
}: ChangeStickerModalProps): JSX.Element {
  const [state, setState] = useState<ChangeStickerState>(initialState);

  const { errors, kjoretoyId, kjoretoyIdError, laster, successmessage, errormessage, endringsskjema } = state;

  const { oblat } = oblatInfo;

  const { kjoretoy } = useSelector<ApplicationState, { kjoretoy: Kjoretoy[] }>(getKjoretoyState);

  const { userInfo } = useSelector(getAuthState);

  const dispatch = useReduxDispatch();

  const { formatMessage } = useIntl();

  useEffect(() => {
    setState(prev => ({
      ...prev,
      kjoretoyId: oblat.kjoretoy?.id ?? "",
      endringsskjema: {
        ...prev.endringsskjema,
        kjoretoyFra: oblat?.kjoretoy?.registreringsnummer ?? "",
        kjoretoyTil: oblat?.kjoretoy?.registreringsnummer ?? ""
      }
    }));
  }, [oblat]);

  const setInitalState = () => {
    setState({
      ...initialState,
      kjoretoyId: oblat.kjoretoy?.id ?? "",
      endringsskjema: {
        ...initialState.endringsskjema,
        kjoretoyFra: oblat?.kjoretoy?.registreringsnummer ?? "",
        kjoretoyTil: oblat?.kjoretoy?.registreringsnummer ?? ""
      }
    });
  };

  const onKjoretoySelect = (kId: string) => {
    setState(prev => ({
      ...prev,
      kjoretoyId: kId,
      errors: { ...errors, kjoretoyIdError: null }
    }));
  };

  const handleChangeOblat = () => {
    setState(prev => ({
      ...prev,
      laster: true,
      successmessage: "",
      errormessage: ""
    }));

    dispatch(endreOblat(oblat.id, oblat?.kjopsinfo?.varekjopId, userInfo?.brukerId ?? "", kjoretoyId))
      .then(() => {
        setState(prev => ({
          ...prev,
          laster: false,
          errormessage: "",
          successmessage: <FormattedMessage id="edittag.endreOblatSuccess" />
        }));
      })
      .catch(() => {
        setState(prev => ({
          ...prev,
          laster: false,
          successmessage: "",
          errormessage: <FormattedMessage id="edittag.endreOblatErrror" />
        }));
      });
  };

  const handleSendForm = () => {
    setState(prev => ({
      ...prev,
      laster: true,
      successmessage: "",
      errormessage: ""
    }));

    dispatch(
      endreOblatSkjema(oblat.id, userInfo?.brukerId ?? "", {
        fraRegistreringsnummer: oblat?.kjoretoy?.registreringsnummer,
        tilRegistreringsnummer: endringsskjema.kjoretoyTil,
        begrunnelse: endringsskjema.begrunnelse,
        telefonnummer: endringsskjema.telefonnummer
      })
    )
      .then(() => {
        setState(prev => ({
          ...prev,
          laster: false,
          errormessage: "",
          successmessage: <FormattedMessage id="edittag.endreOblatSkjemaSuccess" />
        }));
      })
      .catch(() => {
        setState(prev => ({
          ...prev,
          laster: false,
          successmessage: "",
          errormessage: <FormattedMessage id="edittag.endreOblatSkjemaErrror" />
        }));
      });
  };

  const handleEndringsskjemaChange = (felt: string, verdi: string) => {
    setState(prev => ({
      ...prev,
      endringsskjema: {
        ...endringsskjema,
        [felt]: verdi
      }
    }));
  };

  const closeSelf = () => {
    toggleOpen(eventKey);
    setInitalState();
    if (successmessage && successmessage !== "") dispatch(getOblaterForBruker(userInfo?.brukerId, true));
  };

  const valgtKjoretoy = useMemo(() => getKjoretoy(kjoretoy, kjoretoyId), [kjoretoy, kjoretoyId]);

  const tiljengeligeKjoretoy = useMemo(
    () => filter(kjoretoy, k => k.kjoretoytypeId === oblat?.kjoretoy?.kjoretoytypeId && k.id !== oblat?.kjoretoy?.id),
    [kjoretoy, oblat]
  );

  return (
    <Modal isOpen={open} toggle={closeSelf} staticModal>
      <ModalSection>
        <h4 className="heading-4">
          <FormattedMessage id="edittag.pageTitle" />
        </h4>
      </ModalSection>
      <ModalSection>
        <div>
          <div className="">
            {(errormessage && errormessage !== "") || (successmessage && successmessage !== "")
              ? (errormessage && (
                  <MessageBoxWIcon icon="exclamation-mark-circle" type="danger">
                    <p>{errormessage}</p>
                  </MessageBoxWIcon>
                )) ||
                (successmessage && (
                  <MessageBox type="success">
                    <p>{successmessage}</p>
                  </MessageBox>
                ))
              : (oblat.erOverfort && (
                  <MessageBoxWIcon icon="exclamation-mark-circle" type="warning">
                    <p>
                      <FormattedMessage id="edittag.erOverfortInfo" />
                    </p>
                  </MessageBoxWIcon>
                )) || (
                  <MessageBoxWIcon icon="information" type="primary">
                    <p>
                      <FormattedMessage id="edittag.info" />
                    </p>
                  </MessageBoxWIcon>
                )}
          </div>
        </div>
        <div>
          <StickerInfoBlock className="w-full tablet:w-1/2 mt-3" oblat={oblatInfo} />
          {!(
            /* (errormessage && errormessage !== "") || */
            (successmessage && successmessage !== "")
          ) &&
            (!oblat.erOverfort ? (
              <div className="flex tablet:justify-end">
                <div className="w-full tablet:w-1/2  mt-3 mb-3">
                  <Select
                    label={<FormattedMessage id="edittag.select.toVehicle" />}
                    inputId="kjoretoyId"
                    name="kjoretoyId"
                    noOptionsMessage={() => formatMessage({ id: "edittag.noOptions" })}
                    options={map(tiljengeligeKjoretoy, k => ({
                      label: `${k.registreringsnummer} ${k.beskrivelse || ""}`,
                      value: k.id
                    }))}
                    value={
                      valgtKjoretoy
                        ? {
                            value: valgtKjoretoy.registreringsnummer,
                            label: `${valgtKjoretoy.registreringsnummer} ${valgtKjoretoy.beskrivelse || ""}`
                          }
                        : { value: "", label: "" }
                    }
                    onChange={(e: any) => onKjoretoySelect(e?.value ?? "")}
                    isSearchable
                  />
                  {kjoretoyIdError && <div className="bg-state-danger p2">{kjoretoyIdError}</div>}
                  <div className="flex justify-end mt-2">
                    <Addvehicle />
                  </div>
                </div>
              </div>
            ) : (
              <div className="endre-kjoretoy-skjema mt-3">
                <form>
                  <Inputfield
                    rootClassName="hidden"
                    label={<FormattedMessage id="edittag.endreSkjemakjoretoyFra" />}
                    type="text"
                    value={oblat?.kjoretoy?.registreringsnummer ?? ""}
                    disabled
                    name="kjoretoyFra"
                  />

                  <div className="mb-3 flex flex-col">
                    <Select
                      rootClassName="mb-2"
                      label={<FormattedMessage id="edittag.select.toVehicle" />}
                      inputId="kjoretoyId"
                      name="kjoretoyId"
                      noOptionsMessage={() => formatMessage({ id: "edittag.noOptions" })}
                      options={map(tiljengeligeKjoretoy, k => ({
                        label: `${k.registreringsnummer} ${k.beskrivelse || ""}`,
                        value: k.registreringsnummer
                      }))}
                      value={
                        endringsskjema.kjoretoyTil
                          ? {
                              value: endringsskjema.kjoretoyTil,
                              label: endringsskjema.kjoretoyTil
                            }
                          : { label: "", value: "" }
                      }
                      onChange={(e: any) => handleEndringsskjemaChange("kjoretoyTil", e?.value ?? "")}
                      isSearchable
                    />

                    {kjoretoyIdError && <div className="p2 bg-state-danger">{kjoretoyIdError}</div>}
                    <Addvehicle className="ml-auto" />
                  </div>

                  <div>
                    <TextArea
                      className="mb-3"
                      label={<FormattedMessage id="edittag.endreSkjemaBegrunnelse" />}
                      name="begrunnelse"
                      rows={10}
                      value={endringsskjema.begrunnelse}
                      onChange={e => handleEndringsskjemaChange("begrunnelse", e.currentTarget.value)}
                    />
                  </div>

                  <div>
                    <Inputfield
                      label={<FormattedMessage id="edittag.endreSkjemaTelefonnummer" />}
                      type="text"
                      name="telefonnummer"
                      value={endringsskjema.telefonnummer}
                      onChange={e => handleEndringsskjemaChange("telefonnummer", e.currentTarget.value)}
                    />
                    <div>
                      <FormattedMessage id="edittag.endreSkjemaTelefonnummerInfo" />
                    </div>
                  </div>
                </form>
              </div>
            ))}
        </div>
      </ModalSection>
      <ModalSection className="flex flex-col tablet:flex-row tablet:justify-end">
        {
          /* (errormessage && errormessage !== "") || */
          successmessage && successmessage !== "" ? (
            <Button disabled={laster} onClick={closeSelf}>
              <FormattedMessage id="edittag.lukk" />
            </Button>
          ) : (
            (!oblat.erOverfort && (
              <>
                <Button
                  disabled={laster || kjoretoyId === oblat.kjoretoy?.id}
                  className="mobile-only:mb-2 tablet:mr-2"
                  onClick={handleChangeOblat}
                  isLoading={laster}
                >
                  <FormattedMessage id="edittag.editButtonText" />
                </Button>
                <Button disabled={laster} modifier="outline" onClick={closeSelf}>
                  <FormattedMessage id="edittag.cancel" />
                </Button>
              </>
            )) || (
              <>
                <Button
                  className="mobile-only:mb-2 tablet:mr-2"
                  disabled={
                    laster ||
                    !endringsskjema.begrunnelse ||
                    endringsskjema.begrunnelse === "" ||
                    !endringsskjema.kjoretoyTil ||
                    endringsskjema.kjoretoyTil === "" ||
                    endringsskjema.kjoretoyTil === oblat.kjoretoy?.registreringsnummer
                  }
                  isLoading={laster}
                  onClick={handleSendForm}
                >
                  <FormattedMessage id="edittag.sendSkjemaButtonText" />
                </Button>
                <Button disabled={laster} modifier="outline" onClick={closeSelf}>
                  <FormattedMessage id="edittag.cancel" />
                </Button>
              </>
            )
          )
        }
      </ModalSection>
    </Modal>
  );
}
