import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { format } from "date-fns";
import { isEmpty } from "lodash-es";
import useOblaterHook, { StickerWInfoAndSeason } from "features/Hooks/OblaterHook";

import { getState as getOblattypeState } from "state/Oblattype/oblattypeReducer";

import { ApplicationState } from "state/reducers";
import { FormattedMessage } from "locale/langUtils";
import Link from "features/common/BaseComponents/Link/Link";
import { filterSeason, sortOblaterByKey } from "utils/oblatHelpers";
import * as urls from "appConstants/urls";
import { StickerStatus } from "appConstants/identifiers";
import "./MiniOverview.scss";
import Loader from "features/common/ComposedComponents/Loader/Loader";
import { Column, TableState, useFilters, usePagination, useSortBy, useTable } from "react-table";
import { useIntl } from "react-intl";
import Paging from "features/common/TableComponents/Paging";

export default function MiniOverview(): JSX.Element {
  const { oblater, isLoading: isLoadingStickers, currentSeason } = useOblaterHook();
  const { oblattyper } = useSelector<ApplicationState, any>(getOblattypeState);
  const history = useHistory();

  const sortedItemsWithStickerInfo = useMemo(() => {
    if (isEmpty(oblater) || isEmpty(oblattyper)) return [];
    return sortOblaterByKey(oblater, [
      StickerStatus.Active,
      StickerStatus.NotStartedFrontendOnly,
      StickerStatus.AwaitingPayment,
      StickerStatus.Expired,
      StickerStatus.CanceledFrontendOnly,
      StickerStatus.Transferred,
      StickerStatus.Inactive
    ]);
  }, [oblater, oblattyper]);

  const columns = useMemo<Column<StickerWInfoAndSeason>[]>(
    () => [
      {
        accessor: "statusClass",
        id: "status",
        disableSortBy: true,
        Cell: ({ row, value }: any) => {
          const { formatMessage: formatMessageInnner } = useIntl();
          const statusName = formatMessageInnner({
            id: row.original.statusText
          });
          return <span className={`status-indicator ${value}`} aria-label={`Status ${statusName}`} />;
        },
        filter: "status"
      },
      {
        Header: <span>Status</span>,
        accessor: r => r.statusText,
        id: "type",
        Cell: (v: any) => <FormattedMessage id={v.value} />
      },
      {
        Header: <FormattedMessage id="overviewstickers.type" defaultMessage="" />,
        accessor: "name",
        Cell: v => <FormattedMessage id={v.value} />
      },
      {
        Header: <FormattedMessage id="overviewstickers.validfrom" defaultMessage="" />,
        accessor: v => v.oblat.gyldigFra,
        id: "validFrom",
        Cell: (v: any) => format(new Date(v.value), "dd.MM.yyyy HH:mm")
      },
      {
        Header: <FormattedMessage id="overviewstickers.validto" defaultMessage="" />,
        accessor: v => v.oblat.gyldigTil,
        id: "validTo",
        Cell: (v: any) => format(new Date(v.value), "dd.MM.yyyy HH:mm")
      },
      {
        Header: <FormattedMessage id="overviewstickers.season" defaultMessage="" />,
        accessor: "season",
        Cell: v => v.value,
        disableSortBy: true,
        filter: "season"
      },
      {
        Header: <FormattedMessage id="overviewstickers.vehicles" defaultMessage="" />,
        accessor: v => v.oblat.kjoretoy?.registreringsnummer,
        id: "registreringsnummer",
        filter: "numberPlate"
      }
    ],
    []
  );

  const initialState = useMemo<Partial<TableState<StickerWInfoAndSeason>>>(() => {
    const hiddenColumns = ["season"];

    return {
      pageIndex: 0,
      pageSize: 3,
      hiddenColumns,
      filters: [{ id: "season", value: currentSeason }]
    };
  }, [currentSeason]);

  const filterTypes = useMemo(
    () => ({
      season: filterSeason
    }),
    []
  );

  const {
    getTableProps,
    headerGroups,
    preFilteredRows,
    rows,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageCount,
    state: { pageIndex, pageSize }
  } = useTable<StickerWInfoAndSeason>(
    {
      columns,
      data: sortedItemsWithStickerInfo,
      initialState,
      expandSubRows: false,
      defaultCanSort: true,
      filterTypes
    },
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="flex flex-col justify-between ">
      <Loader isLoading={isLoadingStickers}>
        {{
          loadingText: <FormattedMessage id="common.loading" />,
          loadingContent: (
            <div role="form" {...getTableProps({ className: "stickersection flex flex-col" })} aria-describedby="table-description">
              <div id="table-description" className="sr-only">
                <FormattedMessage id="overviewstickers.table.caption" />
              </div>
              <div role="rowgroup" className="sr-only">
                {headerGroups.map(headerGroup => (
                  <div role="row"  {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <div role="columnheader" {...column.getHeaderProps()}>{column.render("Header")}</div>
                    ))}
                  </div>
                ))}
              </div>
              <div role="rowgroup">
                {isEmpty(rows) ? (
                  <div
                    role="row"
                    style={{ margin: "auto auto", transform: "translateY(-50%)" }}
                    className="flex justify-center just osg-u-text-4 py-4"
                  >
                    <FormattedMessage id="overviewstickers.table.nosticker.purchased" />
                  </div>
                ) : (
                  page.map((row, index) => {
                    prepareRow(row);
                    return (
                      <div role="row" {...row.getRowProps()}>
                        {(page?.[index - 1]?.original?.statusId ?? -1000) !== row.original.statusId && (
                          <span className="sticker-group osg-u-text-4 mb-1 ml-2 tablet:ml-3">
                            <FormattedMessage id={row.original.statusText ?? "unkownid"} defaultMessage=" " />
                          </span>
                        )}
                        <div
                          key={row.original.uniqueIdOnlyInFrontend}
                          className="sticker-container
                        osg-u-margin-bottom-1
                        osg-u-margin-bottom-3-tablet-and-up
                        bg-beige tablet:bg-white
                        p-1
                        tablet:px-4 "
                        >
                          <span role="cell" className={`status-indicator ${row.original.statusClass}`} />
                          <span role="cell" className="vehicle-regnumber osg-u-text-5 truncate osg-u-padding-right-1">
                            {row.original.oblat.kjoretoy?.registreringsnummer}
                            <span className="mobile-only:hidden">
                              {" "}
                              {row.original.oblat.kjoretoy?.beskrivelse ?? ""}
                            </span>
                          </span>
                          <span role="cell" className="vehicle-description osg-u-text-5 truncate">
                            {row.original.oblat.kjoretoy?.beskrivelse ?? ""}
                          </span>

                          <div role="cell" className="sticker-name osg-u-text-4">
                            <FormattedMessage id={row.original.name} />
                          </div>
                          <div role="cell" className="date-of-expire">
                            <span className="osg-u-text-6 self-end">
                              <FormattedMessage id="overviewstickers.expires" />:
                            </span>
                            <div className="self-end">
                              {format(new Date(row.original.oblat.gyldigTil), "dd.MM.yyyy HH:mm")}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>
          )
        }}
      </Loader>
      <Paging
        showPaginationControlls={pageCount > 1}
        beginFrom={1}
        pageSize={pageSize}
        pageIndex={pageIndex}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        previousPage={previousPage}
        totalNumberOfRows={rows.length}
      />
      {sortedItemsWithStickerInfo.length > 0 && (
        <Link
          className="osg-u-text-5 self-end osg-u-margin-top-3"
          href={urls.oblatoversikt}
          onClick={e => {
            e.preventDefault();
            history.push(urls.oblatoversikt);
          }}
        >
          <FormattedMessage id="overviewstickers.link.seeall" />
        </Link>
      )}
    </div>
  );
}
