import Page from "features/common/layout/Page";
import { useHistory, useLocation } from "react-router-dom";
import Section from "features/common/layout/Section";
import { FormattedMessage } from "locale/langUtils";
import OutgoingLink from "../common/BaseComponents/LinkWIcon/LinkWIcon";
import * as urls from "appConstants/urls";
import { useIntl } from "react-intl";

interface LocationState {
  email?: string;
  opprettet?: boolean;
}
export default function NotAValidPage(): JSX.Element {
  const { state } = useLocation<LocationState>();
  const history = useHistory();
  const intl = useIntl();
  return (
    <Page
      metaInfo={{
        titlePageName: intl.formatMessage({ id: "landingpageloggedoutcontent.meta.title" }),
        titleDescription: intl.formatMessage({ id: "landingpageloggedoutcontent.meta.title.description" }),
        robots: "noindex"
      }}
    >
      {state?.opprettet
        ? {
            heading: {
              pageTitle: <FormattedMessage id="registerusersuccesspage.heading" defaultMessage="Nesten ferdig..." />
            },
            pageContent: (
              <Section colorClass="bg-white" className="section content">
                <p className="">
                  <FormattedMessage
                    id="registerusersuccesspage.info.completeregistration"
                    defaultMessage=""
                    values={{
                      mail: () => <b>{state?.email ?? ""}</b>
                    }}
                  />
                </p>
                <p className="mb-2">
                  <FormattedMessage
                    id="registerusersuccesspage.info.notreceivingemail"
                    defaultMessage=""
                    values={{ mail: () => <b>noreply@bymoslo.no</b> }}
                  />
                </p>
                <p className="mb-2">
                  <FormattedMessage id="registerusersuccesspage.info.experienceproblems" defaultMessage="" />{" "}
                  <OutgoingLink
                    icon="new-window"
                    className="text-5"
                    href="https://ed24.chat.oslo.kommune.no/ed24cust/chattemplate/Oslo_Bymiljoetaten/index.html"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    <FormattedMessage id="registerusersuccesspage.link.chatwithus" defaultMessage="" />
                  </OutgoingLink>
                </p>
              </Section>
            )
          }
        : {
            heading: {
              pageTitle: <FormattedMessage id="registerusersuccesspage.oppdatert.heading" defaultMessage="" />
            },
            pageContent: (
              <Section colorClass="bg-white" className="section content">
                <p className="">
                  <FormattedMessage id="registerusersuccesspage.oppdatert.info" />
                </p>
                <p className="">
                  <FormattedMessage
                    id="registerusersuccesspage.oppdatert.loginlink"
                    defaultMessage=""
                    values={{
                      a: (a: string) => (
                        <a
                          className="font-medium"
                          href={urls.hjem}
                          onClick={e => {
                            e.preventDefault();
                            history.replace(urls.hjem);
                          }}
                        >
                          {a}
                        </a>
                      )
                    }}
                  />
                </p>
              </Section>
            )
          }}
    </Page>
  );
}
