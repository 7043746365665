import React, { ReactNode } from "react";
import { OSGPattern } from "../../interfaces";

type Modifiers = "circle";
type Variants = "image";

interface ShapeProps extends OSGPattern<Variants, Modifiers> {
  children: ReactNode;
  className?: string;
}

export default function Shape({ variant = "default", modifier = "", className, children }: ShapeProps): JSX.Element {
  return <div className={`osg-shape osg-v-${variant} osg-shape--${modifier} ${className ?? ""}`}>{children}</div>;
}
