import navbar from "features/navbar/Navbar.text.json";
import footer from "features/footer/Language.txt.json";
import login from "features/login/Language.text.json";
import landingpage from "features/landingpage/LandingPage.text.json";
import landingPagePublicContent from "features/landingpage/LandingPageLoggedoutContent.text.json";
import landingPageLoggedInContent from "features/landingpage/LandingPageLoggedInContent.text.json";
import notavalidpage from "features/NotAValidPage/Language.text.json";
import registerpage from "features/RegisterPage/Language.text.json";
import registerorgpage from "features/RegisterCompanyPage/Language.text.json";
import privateuserregistration from "features/RegisterPrivateUser/Language.text.json";
import forgotPasswordPage from "features/ForgotPasswordPage/Language.text.json";
import newpasswordpage from "features/NewPasswordPage/Language.text.json";
import registerUserSuccessPage from "features/RegisterUserSuccessPage/Language.text.json";
import activateUserPage from "features/ActivateUserPage/Language.text.json";
import buystickerPage from "features/BuyStickers/Language.text.json";
import buystickersBulk from "features/BuyStickersBulk/Language.text.json";
import profilePage from "features/ProfilePage/Language.text.json";
import overviewStickers from "features/OverviewStickersPage/Language.text.json";
import canceldialog from "features/CancelDialog/Language.text.json";
import samtykke from "features/TermsAndConditions/Language.text.json";
import edittag from "features/EditTagModal/Language.text.json";
import vehicle from "features/ProfilePage/VehicleSetting.json";
import shoppingcart from "features/ShoppingCart/Language.text.json";
import checkoutpage from "features/CheckoutPage/Language.text.json";
import addVehicleDialogue from "features/AddVehiclesDialogue/Language.text.json";
import completePaymentPage from "features/CompletePaymentPage/Language.text.json";
import invoicePaymentPage from "features/CompleteInvoicePaymentPage/Language.text.json";
import receipts from "features/Receipts/Language.texts.json";
import ChangeVehicleDialogue from "features/ChangeVehicleDialogue/Language.text.json";
import breadcrumb from "features/Breadcrumb/Language.txt.json";
import ActivateUserPassword from "features/ActivateUserPasswordPage/Language.text.json";
import refundConfirmedPage from "features/RefundConfirmedPage/Language.tsx.json";
import ieNotification from "features/IENotification/Language.text.json";
import tableTexts from "features/common/TableComponents/Language.texts.json";
import commonTexts from "./common.text.json";
import languagePicker from "./LanguagePicker.txt.json";
import authErrorDialogue from "Auth/language.text.json";
import faq from "features/Faq/Language.text.json";
import layout from "features/common/layout/Language.txt.json";
import select from "features/common/BaseComponents/Select/Language.txt.json";
import datePicker from "features/common/BaseComponents/DatePicker/Language.text.json";
import menu from "features/MobileMenu/Language.text.json";
import link from "features/common/BaseComponents/FooterLink/Language.text.json";

const nb = {
  ...languagePicker.no,
  ...navbar.no,
  ...footer.no,
  ...login.no,
  ...landingpage.no,
  ...landingPagePublicContent.no,
  ...landingPageLoggedInContent.no,
  ...notavalidpage.no,
  ...registerpage.no,
  ...registerorgpage.no,
  ...privateuserregistration.no,
  ...forgotPasswordPage.no,
  ...newpasswordpage.no,
  ...registerUserSuccessPage.no,
  ...activateUserPage.no,
  ...buystickerPage.no,
  ...buystickersBulk.no,
  ...profilePage.no,
  ...overviewStickers.no,
  ...samtykke.no,
  ...canceldialog.no,
  ...edittag.no,
  ...vehicle.no,
  ...commonTexts.no,
  ...shoppingcart.no,
  ...checkoutpage.no,
  ...addVehicleDialogue.no,
  ...receipts.no,
  ...completePaymentPage.no,
  ...invoicePaymentPage.no,
  ...ActivateUserPassword.no,
  ...refundConfirmedPage.no,
  ...ieNotification.no,
  ...tableTexts.no,
  ...ChangeVehicleDialogue.no,
  ...breadcrumb.no,
  ...authErrorDialogue.no,
  ...faq.no,
  ...layout.no,
  ...select.no,
  ...datePicker.no,
  ...menu.no,
  ...link.no
};

const en = {
  ...languagePicker.en,
  ...navbar.en,
  ...footer.en,
  ...login.en,
  ...landingpage.en,
  ...landingPagePublicContent.en,
  ...landingPageLoggedInContent.en,
  ...notavalidpage.en,
  ...registerpage.en,
  ...registerorgpage.en,
  ...privateuserregistration.en,
  ...forgotPasswordPage.en,
  ...commonTexts.en,
  ...registerUserSuccessPage.en,
  ...activateUserPage.en,
  ...buystickerPage.en,
  ...profilePage.en,
  ...buystickersBulk.en,
  ...overviewStickers.en,
  ...newpasswordpage.en,
  ...canceldialog.en,
  ...edittag.en,
  ...vehicle.en,
  ...samtykke.en,
  ...shoppingcart.en,
  ...checkoutpage.en,
  ...addVehicleDialogue.en,
  ...receipts.en,
  ...completePaymentPage.en,
  ...invoicePaymentPage.en,
  ...ActivateUserPassword.en,
  ...refundConfirmedPage.en,
  ...ieNotification.en,
  ...tableTexts.en,
  ...ChangeVehicleDialogue.en,
  ...breadcrumb.en,
  ...authErrorDialogue.en,
  ...faq.en,
  ...layout.en,
  ...select.en,
  ...datePicker.en,
  ...menu.en,
  ...link.en
};

export { nb, en };
