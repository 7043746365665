import { piggavService } from "api";
import { isEmpty } from "lodash-es";

export const OBLATTYPE_REQUEST = "OBLATTYPE_REQUEST";
export const OBLATTYPE_SUCCESS = "OBLATTYPE_SUCCESS";
export const OBLATTYPE_FAILURE = "OBLATTYPE_FAILURE";

const getOblattypeRequest = () => ({
  type: OBLATTYPE_REQUEST,
  payload: ""
});

const getOblattypeSuccess = getOblattype => ({
  type: OBLATTYPE_SUCCESS,
  payload: getOblattype
});

export const getOblattyper = () => (dispatch, getState) => {
  const {
    oblattyper: { oblattyper }
  } = getState();
  if (!isEmpty(oblattyper)) return Promise.resolve(oblattyper); // Allready loaded;
  dispatch(getOblattypeRequest());
  return piggavService.getOblattyper().then(response => {
    const data = response.data.result;
    dispatch(getOblattypeSuccess(data));
  });
};
