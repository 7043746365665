/* eslint-disable consistent-return */
import React, { useState, useMemo } from "react";
import { useReduxDispatch } from "utils/Storeutils";
import { useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import useBrreg, { BrregInfo } from "features/Hooks/Brreg/BrregHook";
import { updateKunde as updateKundeAction } from "state/Kunde/kundeActions";
import { Icon } from "features/common/OSG";
import { Button } from "features/common/ComposedComponents";
import { FormattedMessage } from "locale/langUtils";
import InputField from "features/common/ComposedComponents/Inputfield/Inputfield";
import Modal, { ModalSection, ModalHeader } from "features/common/BaseComponents/Modal/Modal";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { Kunde } from "interfaces";
import ProfileDetails from "./ProfileDetails";
import ProfilSection from "./ProfileSection";
import Form from "features/common/BaseComponents/Form/Form";

const kundeSchema = yup.object().shape({
  organizationNumber: yup
    .string()
    .transform((val: string, orgVal: string) => orgVal.replace(/\s+/g, ""))
    .matches(/\d{9}/, "common.error.orgnummer"),
  companyName: yup.string().required("common.error.requiredfield"),
  adress: yup.string().required("common.error.requiredfield"),
  adress2: yup.string(),
  zipcode: yup
    .string()
    .required("common.error.requiredfield")
    .transform((val: string) => val.replace(/\s+/g, ""))
    .matches(/\d{4}/, "common.error.zipcode"),
  city: yup.string().required("common.error.requiredfield"),
  country: yup.string().required("common.error.requiredfield"),
  erUnderenhet: yup.boolean()
});

interface ChangeCompanySettings extends React.HTMLProps<HTMLDivElement> {
  customerInfo: Kunde;
}
export default function ChangeCompanySetting({ customerInfo, ...rest }: ChangeCompanySettings): JSX.Element {
  const profileSectionId = "companysettings";
  const [isOpen, setIsOpen] = useState(false);
  const [formUpdateError, setformUpdateError] = useState("");
  const [formUpdateSuccess, setformUpdateSuccess] = useState(false);
  const [isUpdatingUserRequest, setIsUpdatingUserRequest] = useState(false);
  const [showUnderenhetWarning, setShowUnderenhetWarning] = useState(false);
  const [showChangeOrgnumberWarning, setShowChangeOrgnumberWarning] = useState(false);

  const { erUnderenhet, getCompanyInfo } = useBrreg(customerInfo.organisasjonsnummer);

  const { handleSubmit, register, errors, setError, trigger, setValue, getValues, reset, watch, formState } = useForm({
    criteriaMode: "firstError",
    mode: "onChange",
    shouldFocusError: true,
    resolver: yupResolver(kundeSchema),
    defaultValues: {
      organizationNumber: customerInfo.organisasjonsnummer,
      companyName: customerInfo.bedriftsnavn,
      adress: customerInfo.adresse,
      adress2: customerInfo.adresse2,
      zipcode: customerInfo.postnummer,
      city: customerInfo.poststed,
      country: customerInfo.land,
      erUnderenhet: erUnderenhet
    }
  });

  const { isDirty, isValid } = formState;

  const orgnummerval = watch("organizationNumber");

  const { formatMessage, locale } = useIntl();

  const dispatch = useReduxDispatch();

  const handleUpdateBrregInfo = async (): Promise<BrregInfo | undefined> => {
    try {
      const valid = await trigger("organizationNumber");
      if (!valid) return;

      const { organizationNumber } = getValues();

      const res = await getCompanyInfo(organizationNumber);
      const orgInfo = res.erUnderenhet ? res.underenhet : res.hovedenhet;
      const orgadress = res.underenhet?.beliggenhetsadresse ?? res.hovedenhet?.forretningsadresse;

      setValue("companyName", orgInfo?.navn ?? "");
      setValue("organizationNumber", orgInfo?.organisasjonsnummer ?? "");
      setValue("adress", orgadress?.adresse?.[orgadress.adresse.length - 1] ?? "");
      setValue("zipcode", orgadress?.postnummer ?? "");
      setValue("city", orgadress?.poststed ?? "");
      setValue("country", orgadress?.land ?? "");
      setValue("erUnderenhet", res.erUnderenhet);

      return res;
    } catch (e) {
      setError("organizationNumber", {
        type: "regnummernotfound",
        message: "common.error.orgnummernotfound"
      });

      return undefined;
    }
  };

  const handleUpdate = async ({ ignoreUnderEnhetWarning = false, ignoreChangeOrgnumberWarning = false }) => {
    const {
      companyName,
      organizationNumber,
      adress,
      adress2,
      zipcode,
      city,
      country,
      erUnderenhet: formErUnderenhet
    } = getValues();
    setShowUnderenhetWarning(false);
    setIsUpdatingUserRequest(true);
    try {
      const formValid = await trigger(undefined);
      if (!formValid) return;

      if (organizationNumber !== customerInfo.organisasjonsnummer) {
        if (!ignoreChangeOrgnumberWarning) {
          setShowChangeOrgnumberWarning(true);
          return;
        }
        // const orginfo = await getOrginfo();
        // if (!orginfo) return;
        if (formErUnderenhet && !ignoreUnderEnhetWarning) {
          setShowUnderenhetWarning(true);
          return;
        }
      }

      await dispatch(
        updateKundeAction(customerInfo.brukerId, {
          ...customerInfo,
          organisasjonsnummer: organizationNumber,
          adresse: adress,
          adresse2: adress2,
          postnummer: zipcode,
          poststed: city,
          land: country,
          bedriftsnavn: companyName
        })
      );
      setformUpdateSuccess(true);
    } catch (e: any) {
      switch (e?.status) {
        case 412: // User has unpaid invoices
          setformUpdateError("profile.changecompany.error.hasunpaidinvoices");
          break;
        case 405: // Can't update organization number more than one time a day
          setformUpdateError("profile.changecompany.error.orgnumberonceaday");
          break;
        default:
          // Something else happened, try again.
          setformUpdateError("profile.error.general");
      }
    } finally {
      setIsUpdatingUserRequest(false);
    }
  };

  const handleUpdateUserSubmit = handleSubmit(async () => {
    handleUpdate({
      ignoreChangeOrgnumberWarning: false,
      ignoreUnderEnhetWarning: false
    });
  });

  const toggle = () => {
    if (isOpen) {
      const accordionTogglerBtn = document.getElementById(`${profileSectionId}--toggler`);
      accordionTogglerBtn?.focus();
    }

    setIsOpen(prev => !prev);
  };

  const customerDetails = useMemo(
    () => [
      {
        name: formatMessage({ id: "common.label.organizationnumber.short" }),
        value: customerInfo.organisasjonsnummer
      },
      {
        name: formatMessage({ id: "common.label.companyname" }),
        value: customerInfo.bedriftsnavn
      },
      {
        name: formatMessage({ id: "common.label.adress" }),
        value: customerInfo.adresse
      },
      {
        name: formatMessage({ id: "common.label.adress2" }),
        value: customerInfo.adresse2
      },
      {
        name: formatMessage({ id: "common.label.zipcode" }),
        value: customerInfo.postnummer
      },
      {
        name: formatMessage({ id: "common.label.city" }),
        value: customerInfo.poststed
      },
      {
        name: formatMessage({ id: "common.label.country" }),
        value: customerInfo.land
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      locale,
      customerInfo.adresse,
      customerInfo.adresse2,
      customerInfo.bedriftsnavn,
      customerInfo.land,
      customerInfo.organisasjonsnummer,
      customerInfo.postnummer,
      customerInfo.poststed
    ]
  );

  return (
    <ProfilSection
      id={profileSectionId}
      onChange={() => {
        if (formUpdateSuccess) setformUpdateSuccess(false);
        if (formUpdateError) setformUpdateError("");
      }}
      isOpen={isOpen}
      toggle={toggle}
      {...rest}
    >
      {{
        heading: formatMessage({
          id: "profile.changecompany.heading",
          defaultMessage: "Passord"
        }),
        closedContent: <ProfileDetails details={customerDetails} />,
        openContent: (
          <div>
            <Form id="update-company-user-form" onSubmit={handleUpdateUserSubmit}>
              <div className="flex ">
                <InputField
                  className=""
                  rootClassName="w-full"
                  name="organizationNumber"
                  type="text"
                  required
                  ref={register()}
                  // helpText={formatMessage({ id: "profile.organizationnumber.helptext" })}
                  label={formatMessage({
                    id: "common.organizationnumber",
                    defaultMessage: "Organisasjonsnummer"
                  })}
                  error={
                    errors.organizationNumber &&
                    formatMessage({
                      id: errors.organizationNumber.message,
                      defaultMessage: ""
                    })
                  }
                  warning={
                    erUnderenhet && customerInfo.organisasjonsnummer === orgnummerval
                      ? formatMessage({ id: "common.warning.underenhet" })
                      : ""
                  }
                  autoCorrect="off"
                  autoComplete="off"
                />
                <Button
                  type="button"
                  className="margin-label-height w-100 flex-shrink-0"
                  onClick={handleUpdateBrregInfo}
                >
                  <FormattedMessage id="profile.changecompany.btn.getbrreginfo" />
                </Button>
              </div>

              <InputField
                name="companyName"
                autoComplete="organization"
                type="text"
                label={formatMessage({
                  id: "common.label.companyname",
                  defaultMessage: "Firmanavn"
                })}
                ref={register}
                readOnly
              />
              <InputField
                name="adress"
                autoComplete="address-line1"
                type="text"
                label={formatMessage({
                  id: "common.label.adress",
                  defaultMessage: "Adresse"
                })}
                error={
                  errors?.adress &&
                  formatMessage({
                    id: errors.adress.message,
                    defaultMessage: ""
                  })
                }
                ref={register}
                readOnly
              />
              <InputField
                name="adress2"
                autoComplete="address-line2"
                type="text"
                label={formatMessage({
                  id: "common.label.adress2",
                  defaultMessage: "Adresselinje 2"
                })}
                ref={register}
                readOnly
              />
              <InputField
                name="zipcode"
                autoComplete="postal-code"
                type="text"
                label={formatMessage({
                  id: "common.label.zipcode",
                  defaultMessage: "Postnummer"
                })}
                error={
                  errors?.zipcode &&
                  formatMessage({
                    id: errors.zipcode.message,
                    defaultMessage: ""
                  })
                }
                ref={register}
                readOnly
              />
              <InputField
                name="city"
                type="text"
                label={formatMessage({
                  id: "common.label.city",
                  defaultMessage: "Poststed"
                })}
                error={
                  errors?.city &&
                  formatMessage({
                    id: errors.city.message,
                    defaultMessage: ""
                  })
                }
                ref={register}
                readOnly
              />
              <InputField
                name="country"
                autoComplete="country-name"
                type="text"
                label={formatMessage({
                  id: "common.label.country",
                  defaultMessage: "Land"
                })}
                error={
                  errors?.country &&
                  formatMessage({
                    id: errors.country.message,
                    defaultMessage: ""
                  })
                }
                ref={register}
                readOnly
              />
              {formUpdateError && (
                <div className="bg-state-danger p-3">
                  <FormattedMessage id={formUpdateError} values={{ orgnummer: customerInfo.organisasjonsnummer }} />
                </div>
              )}
              {formUpdateSuccess && (
                <div className="bg-state-success p-3">
                  <FormattedMessage id="profile.changecompany.successupdating" />
                </div>
              )}

              <div className="flex justify-between flex-wrap mt-4">
                <Button
                  aria-expanded={isOpen}
                  aria-label={formatMessage({ id: "profile.cancel.button" })}
                  className="w-full tablet:w-1/2 mobile-only:mb-2"
                  modifier="outline"
                  onClick={() => {
                    setformUpdateError("");
                    setformUpdateSuccess(false);
                    reset();
                    toggle();
                  }}
                  disabled={isUpdatingUserRequest}
                >
                  {formUpdateSuccess ? (
                    <FormattedMessage id="profile.button.close" defaultMessage="Lukk" />
                  ) : (
                    <FormattedMessage id="common.cancel" defaultMessage="Avbryt" />
                  )}
                </Button>
                <Button
                  className="w-full tablet:w-1/2 "
                  form="update-company-user-form"
                  type="submit"
                  disabled={isUpdatingUserRequest || !isDirty || !isValid}
                  isLoading={isUpdatingUserRequest}
                >
                  <FormattedMessage id="common.save" />
                </Button>
              </div>
            </Form>
            <Modal isOpen={showUnderenhetWarning}>
              <ModalHeader>
                {{
                  heading: <FormattedMessage id="common.warning.underenhet.heading" />
                }}
              </ModalHeader>
              <ModalSection className="">
                <div className="flex items-start bg-state-warning p-3">
                  <FormattedMessage id="common.warning.underenhet" />
                  <Icon icon="exclamation-mark-circle" />
                </div>
              </ModalSection>
              <ModalSection className="flex flex-col tablet:flex-row tablet:justify-end">
                <Button
                  onClick={() => {
                    handleUpdate({
                      ignoreChangeOrgnumberWarning: true,
                      ignoreUnderEnhetWarning: true
                    });
                  }}
                >
                  <FormattedMessage id="profile.changecompany.button.updateeanyway" />
                </Button>
                <Button
                  modifier="outline"
                  className="mobile-only:mt-2 tablet:ml-2"
                  onClick={() => setShowUnderenhetWarning(false)}
                >
                  <FormattedMessage id="common.cancel" />
                </Button>
              </ModalSection>
            </Modal>
            <Modal isOpen={showChangeOrgnumberWarning}>
              <ModalHeader>
                {{
                  heading: <FormattedMessage id="profile.changecompany.warning.changeorgnumberwarning.heading" />
                }}
              </ModalHeader>
              <ModalSection className="">
                <div className="flex items-start bg-state-warning p-3">
                  <FormattedMessage
                    id="profile.changecompany.warning.changeorgnumberwarning.message"
                    values={{ orgnummer: customerInfo.organisasjonsnummer }}
                  />
                  <Icon icon="exclamation-mark-circle" />
                </div>
              </ModalSection>
              <ModalSection className="flex flex-col tablet:flex-row tablet:justify-end">
                <Button
                  onClick={() => {
                    handleUpdate({ ignoreChangeOrgnumberWarning: true });
                    setShowChangeOrgnumberWarning(false);
                  }}
                >
                  <FormattedMessage id="profile.changecompany.button.continueanyway" />
                </Button>
                <Button
                  modifier="outline"
                  className="mobile-only:mt-2 tablet:ml-2"
                  onClick={() => setShowChangeOrgnumberWarning(false)}
                >
                  <FormattedMessage id="common.cancel" />
                </Button>
              </ModalSection>
            </Modal>
          </div>
        )
      }}
    </ProfilSection>
  );
}
