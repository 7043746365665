import React, { useState, useEffect } from "react";
import Radio from "features/common/BaseComponents/Radio/Radio";
import useHandlekurv from "features/Hooks/HandlekurvHook";
import { FormattedMessage } from "locale/langUtils";
import * as urls from "appConstants/urls";
import { useHistory } from "react-router";
import { map, isEmpty } from "lodash-es";
import { useSelector } from "react-redux";
import { Kjoretoy } from "interfaces";
import { getKjoretoy as getKjoretoyState } from "state/Kjoretoy/kjoretoyReducer";
import useUserHook from "features/Hooks/UserHook";
import { useReduxDispatch } from "utils/Storeutils";
import { deleteKjoretoy, getKjoretoyForBruker } from "state/Kjoretoy/kjoretoyActions";
import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { Button as SimpleButton } from "features/common/OSG";
import { Button } from "features/common/ComposedComponents";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import CheckoutSection from "./CheckoutSection";
import { piggavResepsjon } from "appConstants";

type ComanyUserCheckout = React.HTMLProps<HTMLDivElement>;
type PaymentMethods = "cash" | "terminal";
export default function ResepsjonsUserCheckout({ ...divProps }: ComanyUserCheckout): JSX.Element {
  const [betalingsform, setBetalingsformState] = useState<PaymentMethods>("cash");
  const [confirmResepsjonKjøpOpen, setConfirmResepsjonKjøpOpenState] = useState(false);

  const [loading, setLoadingState] = useState(false);
  const [betalingErrorMessage, setBetalingErrorMessageState] = useState("");
  const kjoretoy = useSelector<any, Kjoretoy[]>(getKjoretoyState);
  const { totalPrice, handlePayByCash, handlePayByCardTerminal, stickerSummary } = useHandlekurv();

  const { userInfo, userType } = useUserHook();

  const history = useHistory();

  const dispatch = useReduxDispatch();

  useEffect(() => {
    if (!userInfo?.brukerId) return;
    dispatch(getKjoretoyForBruker(userInfo?.brukerId));
  }, [dispatch, userInfo]);

  const onPaymentMethodClicked = (method: PaymentMethods) => {
    setBetalingsformState(method);
  };

  const toggleConfirmResepsjonKjøp = () => {
    setConfirmResepsjonKjøpOpenState(prev => !prev);
  };

  const deleteVehicle = (vehicleId: string, brukerId: string) => {
    dispatch(deleteKjoretoy(vehicleId, brukerId));
  };

  const deleteVehicles = () => {
    if (!userInfo?.brukerId) return;
    map(kjoretoy, k => deleteVehicle(k.id, userInfo?.brukerId));
  };

  const onCheckoutClicked = () => {
    toggleConfirmResepsjonKjøp();
  };

  const confirmPurchase = () => {
    if (betalingsform === "cash") {
      setLoadingState(true);
      setBetalingErrorMessageState("");
      handlePayByCash()
        .then(res => {
          if (userType === piggavResepsjon) deleteVehicles();
          setLoadingState(false);
          history.push(`${urls.kvitteringer}/${res.data.result}`);
        })
        .catch(error => {
          setLoadingState(false);
          setBetalingErrorMessageState("checkoutpage.paymentError");
        });
    } else if (betalingsform === "terminal") {
      setLoadingState(true);
      setBetalingErrorMessageState("");
      handlePayByCardTerminal()
        .then(res => {
          if (userType === piggavResepsjon) deleteVehicles();
          history.push(`${urls.kvitteringer}/${res.data.result}`);
          setLoadingState(false);
        })
        .catch(() => {
          setLoadingState(false);
          setBetalingErrorMessageState("checkoutpage.paymentError");
        });
    }
  };
  return (
    <>
      <CheckoutSection
        onCheckoutClicked={onCheckoutClicked}
        stickerSummary={stickerSummary}
        // isAddingToBasket={loading}
        {...divProps}
      >
        {{
          heading: <FormattedMessage id="checkoutpage.heading" />,
          totalPrice,
          paymentSection: (
            <div className="flex flex-col items-center">
              <span className="text-2 mb-3">
                <FormattedMessage id="checkoutpage.paymentmethod" />
              </span>
              <form className="flex flex-col bg-white px-5 py-4 text-3">
                <Radio onClick={() => onPaymentMethodClicked("cash")} checked={betalingsform === "cash"}>
                  <FormattedMessage id="checkoutpage.paymentmethod.cash" />
                </Radio>
                <Radio onClick={() => onPaymentMethodClicked("terminal")} checked={betalingsform === "terminal"}>
                  <FormattedMessage id="checkoutpage.paymentmethod.terminal" />
                </Radio>
              </form>
              <MessageBox className="mt-3" type="danger" visible={!isEmpty(betalingErrorMessage)}>
                <FormattedMessage id="betalingErrorMessage" defaultMessage="En feil med betalingen oppsto, vennligst prøv igjen" />
              </MessageBox>
            </div>
          )
        }}
      </CheckoutSection>
      <Modal isOpen={confirmResepsjonKjøpOpen} toggle={toggleConfirmResepsjonKjøp}>
        <ModalHeader>{{ heading: <FormattedMessage id="checkoutpage.BekreftKjøpModalTitle" /> }}</ModalHeader>
        <ModalSection>
          <MessageBox type="warning">
            <FormattedMessage id="checkoutpage.BekreftKjøpModalText" />
          </MessageBox>
        </ModalSection>
        <ModalSection className="flex mobile-only:flex-col tablet:justify-end">
          <Button onClick={confirmPurchase} isLoading={loading}>
            <FormattedMessage id="checkoutpage.BekreftKjøpModalConfirm" />
          </Button>
          <SimpleButton className="tablet:ml-2" modifier="outline" onClick={toggleConfirmResepsjonKjøp}>
            <FormattedMessage id="checkoutpage.BekreftKjøpModalCancel" />
          </SimpleButton>
        </ModalSection>
      </Modal>
    </>
  );
}
