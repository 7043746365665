import React, { useContext } from "react";
import { FormattedMessage } from "locale/langUtils";
import { Configurations } from "interfaces";
import { StickerPeriodId } from "appConstants/identifiers";
import { useSelector } from "react-redux";
import MessageBox from "features/common/BaseComponents/MessageBox/MessageBox";
import { useIntl } from "react-intl";
import InputElement from "features/common/BaseComponents/InputElement/Inputlement";
import { OrdreLinjeContext } from "./OrdrelinjeForm";
import SelectMonth from "./SelectMonth";
import SelectDayTime from "./SelectDateTime";

interface SelectValidFromProps {
  onChange?: (v: string) => any;
}

export default function SelectValidFrom({ onChange }: SelectValidFromProps): JSX.Element {
  const {
    startNow,
    chosenPeriodeId,
    errors: { gyldigFraError }
  } = useContext(OrdreLinjeContext);

  const { sesongSlutt } = useSelector<any, Configurations>(state => state.konfigurasjon);

  const { formatMessage } = useIntl();

  const Heading = () => (
    <legend className="heading-5 mb-2">
      {chosenPeriodeId === StickerPeriodId.Day && (
        <>
          <FormattedMessage id="buystickers.addOrdrelinjeFormStartDay" />
          <span aria-hidden> *</span>
          <span className="sr-only">
            <FormattedMessage id="common.requiredfield" />
          </span>
        </>
      )}
      {chosenPeriodeId === StickerPeriodId.Month && (
        <>
          <FormattedMessage id="buystickers.addOrdrelinjeFormStartMonth" />
          <span aria-hidden> *</span>
          <span className="sr-only">
            <FormattedMessage id="common.requiredfield" />
          </span>
        </>
      )}
      {chosenPeriodeId === StickerPeriodId.Season && (
        <>
          <FormattedMessage id="buystickers.addOrdrelinjeFormStartSeason" />
        </>
      )}
      {chosenPeriodeId === undefined && (
        <span className="italic color-grey-dark">
          <FormattedMessage id="buystickers.velgOblattyper" />
        </span>
      )}
    </legend>
  );

  /* If daysticker is chosen */
  return (
    <fieldset>
      <Heading />

      <div // formgroup
        id="gyldigFra"
      >
        {chosenPeriodeId === StickerPeriodId.Day && (
          <div key="select-dateTime">{!startNow && <SelectDayTime onChange={onChange} />}</div>
        )}
        {chosenPeriodeId === StickerPeriodId.Month && (
          <div>
            <SelectMonth onChange={onChange} />
          </div>
        )}
        {
          /* If month sticker is chosen */
          chosenPeriodeId === StickerPeriodId.Season && (
            <InputElement
              disabled
              value={formatMessage(
                {
                  id: "buystickers.sesongoblat"
                },
                {
                  endDate: new Date(sesongSlutt.verdi)
                }
              )}
              aria-describedby="timeinputerror"
            />
          )
        }
        {gyldigFraError && chosenPeriodeId && (
          <MessageBox id="timeinputerror" role="alert" className="mt-3" type="danger">
            <FormattedMessage id={gyldigFraError} />
          </MessageBox>
        )}
      </div>
    </fieldset>
  );
  /* If month sticker is chosen */
}
