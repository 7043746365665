import React from "react";
import { CustomFormatConfig, FormattedDate, FormattedTime } from "react-intl";

interface DateTimeFormatProps {
  value?: string | number | Date;
  dateOptions?: Intl.DateTimeFormatOptions & CustomFormatConfig;
  timeOptions?: Intl.DateTimeFormatOptions & CustomFormatConfig;
}
export default function DateTimeFormat({ value, dateOptions, timeOptions }: DateTimeFormatProps): JSX.Element {
  return (
    <>
      <FormattedDate value={value} {...dateOptions} /> {` `}
      <FormattedTime value={value} {...timeOptions} />
    </>
  );
}
