/* eslint-disable react/prop-types */
import React, { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";
import DP from "features/common/BaseComponents/DatePicker/DatePicker";
import asInputField from "features/common/InputfieldHOC/InputfieldHOC";
import { isBefore } from "date-fns";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface InputDateTexts {
  placeholder: string;
  invalidDate: string;
  beforeMinDateOrCurrentDate: string;
  afterMaxDate: string;
  validFormat: string;
}

export interface DatePickerBlurProps {
  value: string;
}

// Transforms Datepicker component to a form field with feedback messages see HOC
const CustomDatePicker = asInputField(DP);

interface InputDateProps
  extends Pick<
    React.ComponentProps<typeof CustomDatePicker>,
    "minDate" | "maxDate" | "selected" | "onChange" | "onChangeRaw" | "error" | "openToDate" | "locale" | "withPortal"
  > {
  inputDateTexts?: InputDateTexts;
}

const df = "dd/MM/y";

/** This component is made spesific to piggav */
export default function InputDate({
  minDate,
  maxDate,
  selected,
  onChange,
  onChangeRaw,
  error,
  inputDateTexts,
  openToDate,
  locale,
  ...rest
}: InputDateProps): JSX.Element {
  const [currentDate, setCurrentDate] = useState<Date>(() => {
    if (openToDate) return openToDate;
    if (minDate && isBefore(new Date(), minDate)) return minDate;
    return new Date();
  });
  const reactDateTimeRef = useRef<ReactDatePicker>(null);

  const [errorMessage, setErrorMessage] = useState("");

  const showError = (input: string) => {
    setErrorMessage(input);
    // setCurrentDate(undefined);
  };

  const validate = (input: string) => {
    if (error) return;
    showError("");
    const re = /^([0-9]{1,2})[/.]([0-9]{1,2})[/.]([0-9]{2,4})$/;
    const match = input.match(re);
    if ((match && match.length !== 4) || match === null) {
      showError(inputDateTexts?.validFormat ?? (locale === "en" ? "Format of date is invalid" : "Ikke gyldig format"));
      return;
    }
    const day = parseInt(match[1], 10);
    const month = parseInt(match[2], 10);
    const year = parseInt(match[3], 10);

    let invalid = month < 1 || month > 12 || year < 2000 || day > 31 || day < 1;
    invalid = invalid || ([4, 6, 9, 11].includes(month) && day > 30);
    invalid =
      invalid ||
      (month === 2 && day > 29) ||
      (month === 2 && !((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) && day > 28);

    const newDate = new Date(year, month - 1, day);

    if (!newDate || invalid) {
      showError(inputDateTexts?.invalidDate ?? (locale === "en" ? "Invalid date" : "Ugyldig dato."));
    } else if (minDate && newDate.valueOf() < minDate.valueOf()) {
      showError(
        inputDateTexts?.beforeMinDateOrCurrentDate ??
          (locale === "en"
            ? "Date is before first available date."
            : "Dato er tidligere enn første tilgjengelige dato.")
      );
    } else if (maxDate && newDate.valueOf() > maxDate.valueOf()) {
      showError(
        inputDateTexts?.afterMaxDate ??
          (locale === "en" ? "Date is after last possible date" : "Dato er etter siste mulige dato.")
      );
    } else {
      setCurrentDate(newDate);
    }
  };

  const update = (userSelectedDate: Date, event: React.SyntheticEvent<any, Event> | undefined) => {
    setErrorMessage("");
    setCurrentDate(userSelectedDate);
    onChange?.(userSelectedDate, event);
  };

  const updatedText = ({ target: { value } }: { target: DatePickerBlurProps }) => {
    reactDateTimeRef.current?.setState(prev => ({ ...prev, open: false }));
    validate(value);
  };

  return (
    <CustomDatePicker
      locale={locale}
      minDate={minDate}
      maxDate={maxDate}
      error={error || errorMessage}
      {...rest}
      reactDatePickerRef={reactDateTimeRef}
      strictParsing
      adjustDateOnChange
      selected={currentDate}
      openToDate={currentDate}
      dateFormat={df}
      onChange={(newDate: Date, e) => newDate && update(newDate, e)}
      onSelect={(newDate: Date, e) => newDate && update(newDate, e)}
      onChangeRaw={e => {
        if (e?.target?.value) updatedText(e);
        onChangeRaw?.(e);
      }}
    />
  );
}
