import { connect } from "react-redux";
import {
  lagreBestillingFaktura,
  getHandlekurvVarekjopById,
  lagreBestillingFakturaPerBil
} from "../../state/Handlekurv/handlekurvActions";
import FakturaBetalingsside from "./InvoicePaymentPage";

const mapStateToProps = state => ({
  konfigurasjon: state.konfigurasjon,
  erFritattMinimumFakturabelop: state.kunde.fritattMinstebelopFaktura,
  handlekurv: state.handlekurv,
  kunde: state.kunde
});

const mapDispatchToProps = dispatch => ({
  lagreBestillingFaktura: (brukerId, varekjopId) => dispatch(lagreBestillingFaktura(brukerId, varekjopId)),
  lagreBestillingFakturaPrBil: (brukerId, varekjopId, kvitteringPaaEpost) =>
    dispatch(lagreBestillingFakturaPerBil(brukerId, varekjopId, kvitteringPaaEpost)),
  getHandlekurvVarekjopById: (kundeId, varekjopId) => dispatch(getHandlekurvVarekjopById(kundeId, varekjopId))
});

export default connect(mapStateToProps, mapDispatchToProps)(FakturaBetalingsside);
