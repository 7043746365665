import { useEffect, useState, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { find, isEmpty } from "lodash-es";
import { piggavService } from "api";
import { Button } from "features/common/OSG";
import { FormattedMessage } from "locale/langUtils";
import { StickerWInfo } from "interfaces";
import CurrencyFormat from "locale/CurrencyFormat";
import EditTagModal from "features/EditTagModal/EditTagModal";
import KansellerModal from "../CancelDialog/KansellerModal";
import { erIFremtiden } from "../../utils/dateFnsUtils";
import { StickerPeriodId, StickerStatus } from "../../appConstants/identifiers";
import * as urls from "../../appConstants/urls";
import DateTimeFormat from "locale/DateTimeFormat";

interface OblatDetaljerProps {
  oblat: StickerWInfo;
  brukerId: string;
}

function OblatDetaljer({ oblat, brukerId }: OblatDetaljerProps) {
  const [showEndreModal, setEndreModal] = useState(false);
  const [showKansellerModal, setKansellerModal] = useState(false);
  const [oblatLogg, setOblatLogg] = useState<any[]>([]);
  const history = useHistory();
  const editKey = "1";
  const cancelKey = "2";
  const cancelledLine = useMemo(
    () =>
      find(oblatLogg, linje => {
        const kreditertOrRefunfertRegex =
          /^(?=Oblatet er manuelt refundert|Oblatet er refundert av kunde|Oblatet er manuelt kreditert|Oblatet er kreditert av kunde)/;
        // Check both in systemlogg and kommentar field for comments
        return kreditertOrRefunfertRegex.test(linje.kommentar) || kreditertOrRefunfertRegex.test(linje.systemlogg);
      }),
    [oblatLogg]
  );

  useEffect(() => {
    if (!brukerId || !oblat) return;
    if (oblat.statusId !== StickerStatus.CanceledFrontendOnly) return;
    piggavService.getOblatLog(brukerId, oblat.oblat.id).then((res: any) => {
      const {
        data: { result }
      } = res;
      setOblatLogg(result);
    });
  }, [brukerId, oblat, oblat.oblat.id]);

  const toggleOpen = (eventKey: any) => {
    if (eventKey === editKey) {
      setEndreModal(prev => !prev);
      setKansellerModal(false);
    }
    if (eventKey === cancelKey) {
      setKansellerModal(prev => !prev);
      setEndreModal(false);
    }
  };

  const harOblatStatusOverfoert = () => oblat.statusId === StickerStatus.Transferred;

  const kanKaselleres = () => {
    if (!oblat) return false;
    if (harOblatStatusOverfoert()) return false;
    if (oblat.statusId === StickerStatus.Expired) return false;
    if (oblat.oblat.periodeId === StickerPeriodId.Season) return true;
    if (
      (oblat.oblat.periodeId === StickerPeriodId.Month || oblat.oblat.periodeId === StickerPeriodId.Day) &&
      erIFremtiden(oblat.oblat.gyldigFra)
    )
      return true;

    return false;
  };

  const kanEndres = () => {
    return erIFremtiden(oblat.oblat.gyldigTil) && !harOblatStatusOverfoert() && !isEmpty(oblat.oblat.kjopsinfo);
  };

  const viseKnapper = () => {
    return oblat.statusId !== StickerStatus.CanceledFrontendOnly && !harOblatStatusOverfoert();
  };

  const isInvoice = () => {
    return oblat.oblat.kjopsinfo?.betalingsform.toUpperCase() === "FAKTURA";
  };

  const awaitingPayment = () => {
    return oblat.statusId === StickerStatus.AwaitingPayment;
  };

  return (
    <>
      <div className="oblat-detaljer-row ">
        {oblat.oblat.kjopsinfo && (
          <div className="oblat-detaljer-betaling ">
            <span>
              <FormattedMessage id="overviewstickers.label.price" />: <CurrencyFormat value={oblat.oblat.kjopsinfo?.price} />
            </span>
            <span>
              <FormattedMessage id="overviewstickers.label.receiptnumber" />: {oblat.oblat.kjopsinfo?.kjopsnummer}
            </span>
            <span>
              <FormattedMessage id="overviewstickers.label.paymentmethod" />: {oblat.oblat.kjopsinfo?.betalingsform}
            </span>
            {isInvoice() && awaitingPayment() && (
              <span>
                <FormattedMessage id="overviewstickers.label.paymentstatus" />:{" "}
                <FormattedMessage id="common.stickerstatus.awaitingpayment" />
              </span>
            )}
            <span>
              <FormattedMessage id="overviewstickers.label.purchaseddate" />:{" "}
              <DateTimeFormat value={oblat.oblat.kjopsinfo?.kjopsdato} />
            </span>
            {oblat.oblat.kjopsinfo?.betalingsform === "Faktura" && oblat.oblat.kjopsinfo?.betalingsdato && (
              <span>
                <FormattedMessage id="overviewstickers.label.paymentdate" />:{" "}
                <DateTimeFormat value={oblat.oblat.kjopsinfo?.betalingsdato} />
              </span>
            )}
            {cancelledLine && (
              <span>
                <FormattedMessage id="overviewstickers.label.cancelleddate" />:{" "}
                <DateTimeFormat value={cancelledLine.opprettet} />
              </span>
            )}
            {oblat.oblat.overfortFraTidspunkt && (
              <span>
                <FormattedMessage id="overviewstickers.label.transferredfrom" />: <strong>{oblat.oblat.overfortFra}</strong>
                {`, `}
                <DateTimeFormat value={oblat.oblat.overfortFraTidspunkt} />
              </span>
            )}
            {oblat.oblat.overfortTilTidspunkt && (
              <span>
                <FormattedMessage id="overviewstickers.label.transferredto" />: <strong>{oblat.oblat.overfortTil}</strong>
                {`, `}
                <DateTimeFormat value={oblat.oblat.overfortTilTidspunkt} />
              </span>
            )}
          </div>
        )}
        {viseKnapper() && (
          <div className="flex flex-col items-center tablet:flex-row mobile-only:mt-3">
            <Button
              role="link"
              className=" mobile-only:mb-2 mobile-only:w-full"
              colorOption="yellow"
              modifier="outline"
              disabled={oblat.oblat.kjopsinfo?.varekjopId === undefined}
              onClick={() => history.push(`${urls.kvitteringer}/${oblat.oblat.kjopsinfo?.varekjopId}`)}
            >
              <FormattedMessage id="overviewstickers.button.receipts" defaultMessage="Kvitteringer" />
            </Button>
            <Button
              className="mobile-only:mb-2 mobile-only:w-full tablet:ml-2"
              onClick={() => toggleOpen(cancelKey)}
              disabled={!kanKaselleres()}
              title={
                !kanKaselleres()
                  ? "kan ikke kanselleres" // texts.cancelButtonDisabledText
                  : "kanseller" // texts.cancelButtonTitle
              }
            >
              <FormattedMessage id="overviewstickers.button.cancelsticker" defaultMessage="Kanseller" />
            </Button>
            <Button
              className="mobile-only:w-full tablet:ml-2"
              onClick={() => toggleOpen(editKey)}
              disabled={!kanEndres()}
            >
              <FormattedMessage id="overviewstickers.button.edit" defaultMessage="Endre" />
            </Button>
          </div>
        )}
      </div>
      <KansellerModal oblatInfo={oblat} open={showKansellerModal} toggleOpen={toggleOpen} eventKey={cancelKey} />
      <EditTagModal oblatInfo={oblat} open={showEndreModal} toggleOpen={toggleOpen} eventKey={editKey} />
    </>
  );
}

export default OblatDetaljer;
