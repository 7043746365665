/* eslint-disable @typescript-eslint/no-use-before-define */
import React from "react";
import { OrdreLinje } from "interfaces";
import { FormattedMessage, useIntl } from "locale/langUtils";
import { getName } from "utils/oblatHelpers";
import XButton from "features/common/BaseComponents/XButton/XButton";
import DateTimeFormat from "locale/DateTimeFormat";

import CurrencyFormat from "locale/CurrencyFormat";
import { isExpired } from "utils/oblatHelpers";

interface ShoppingCartLineItemProps extends React.HTMLProps<HTMLDivElement> {
  shoppingcartItem: OrdreLinje;
  removeItem: (oblatId: string) => Promise<any>;
}

export default function ShoppingCartLineItem({
  className,
  shoppingcartItem,
  ...rest
}: ShoppingCartLineItemProps): JSX.Element {
  const stickerstypeinfoId =
    getName(shoppingcartItem.oblattype.periodeId, shoppingcartItem.oblattype.kjoretoytypeId) ?? "unknowstickertype";
  return (
    <>
      <ShoppingCartLineItemMobile
        className={`tablet:hidden  ${className}`}
        shoppingcartItem={shoppingcartItem}
        stickerstypeinfoId={stickerstypeinfoId}
        {...rest}
      />
      <ShoppingCartLineItemDesktop
        className={`mobile-only:hidden  ${className}`}
        shoppingcartItem={shoppingcartItem}
        stickerstypeinfoId={stickerstypeinfoId}
        {...rest}
      />
    </>
  );
}

const ShoppingCartLineItemMobile = ({
  shoppingcartItem,
  className,
  removeItem,
  stickerstypeinfoId,
  ...rest
}: ShoppingCartLineItemProps & { stickerstypeinfoId: string }) => {
  const intl = useIntl();
  const itemIsExpired = isExpired(shoppingcartItem);
  const stickertype = intl.formatMessage({ id: stickerstypeinfoId });
  return (
    <div className={`border-solid border-grey-dark px-2 py-3 border ${className}`}>
      <div className={`flex relative    `} {...rest}>
        <XButton
          aria-label={intl.formatMessage(
            { id: "shoppingcartlineitem.removesticker.aria-label" },
            { numberplate: shoppingcartItem.kjoretoy.registreringsnummer, stickertype: stickertype }
          )}
          className="absolute right-0 top-0 mr-2 mt-2 cursor-pointer"
          tabIndex={0}
          onClick={() => removeItem(shoppingcartItem.id)}
        />
        <div className="flex flex-col space-y-2">
          <span>
            <b>
              <FormattedMessage id="common.label.numberplate.short" />
            </b>
          </span>
          <span>
            <b>
              <FormattedMessage id="common.stickerinfo.stickertype" />
            </b>
          </span>
          <span>
            <b>
              <FormattedMessage id="common.stickerinfo.validfrom" />
            </b>
          </span>
          <span>
            <b>
              <FormattedMessage id="common.stickerinfo.validto" />
            </b>
          </span>
        </div>
        <div className="flex flex-col justify-between pl-2">
          <span>{shoppingcartItem.kjoretoy.registreringsnummer}</span>
          <span>{stickertype}</span>
          <span>
            <DateTimeFormat value={shoppingcartItem.gyldigFra} />
          </span>
          <span className={`${itemIsExpired ? "bg-state-danger px-1" : ""}`}>
            <DateTimeFormat value={shoppingcartItem.gyldigTil} />
          </span>
        </div>
        <span className="text-4 absolute bottom-0 right-0 mb-3 mr-3">
          <CurrencyFormat value={shoppingcartItem.oblattype?.pris ?? 0} />
        </span>
      </div>
      {itemIsExpired && (
        <div className="bg-state-danger bg-opacity-75 osg-u-padding-3 mt-2 mbox">
          <span className="mbox-message osg-u-margin-right-2">
            <FormattedMessage id="checkoutpage.expiredItemMessage" />
          </span>{" "}
          <i className="osg-icon osg-icon--exclamation-mark-circle mbox-icon right" aria-hidden="true"></i>
        </div>
      )}
    </div>
  );
};

const ShoppingCartLineItemDesktop = ({
  shoppingcartItem,
  className,
  removeItem,
  stickerstypeinfoId,
  ...rest
}: ShoppingCartLineItemProps & { stickerstypeinfoId: string }) => {
  const intl = useIntl();
  const itemIsExpired = isExpired(shoppingcartItem);
  const stickertype = intl.formatMessage({ id: stickerstypeinfoId });
  return (
    <>
      <div className={`flex flex-col relative border border-solid border-grey-dark px-2 py-4  ${className}`} {...rest}>
        <XButton
          className="absolute right-0 top-0 mr-2 mt-2 cursor-pointer"
          tabIndex={0}
          onClick={() => removeItem(shoppingcartItem.id)}
          aria-label={intl.formatMessage(
            { id: "shoppingcartlineitem.removesticker.aria-label" },
            { numberplate: shoppingcartItem.kjoretoy.registreringsnummer, stickertype: stickertype }
          )}
        />
        <div className="text-3 px-1 mb-2">{shoppingcartItem.kjoretoy.registreringsnummer}</div>
        <div className="flex">
          <span className="flex flex-col w-1/4 pl-1">
            <span>{stickertype}</span>
          </span>
          <span className={`w-2/4 px-1`}>
            <DateTimeFormat value={shoppingcartItem.gyldigFra} /> {` - `}{" "}
            <span className={`${itemIsExpired ? "bg-state-danger px-1" : ""}`}>
              <DateTimeFormat value={shoppingcartItem.gyldigTil} />
            </span>
          </span>
          <span className="flex w-1/4 px-1">
            <CurrencyFormat value={shoppingcartItem.oblattype?.pris ?? 0} />
          </span>
        </div>
        {itemIsExpired && (
          <div className="bg-state-danger bg-opacity-75 osg-u-padding-3 mt-2 mbox">
            <span className="mbox-message osg-u-margin-right-2">
              <FormattedMessage id="checkoutpage.expiredItemMessage" />
            </span>{" "}
            <i className="osg-icon osg-icon--exclamation-mark-circle mbox-icon right" aria-hidden="true"></i>
          </div>
        )}
      </div>
    </>
  );
};
