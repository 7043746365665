import { useContext, useMemo, useState } from "react";
import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { MessageBoxWIcon, Button } from "features/common/ComposedComponents";
import OblatListeTabell from "features/common/OblatListeTabell/OblatListeTabell";
import { FormattedMessage } from "locale/langUtils";
import { isEmpty } from "lodash-es";
import { BulkBuyContext } from "./BuyStickersBulk";

export default function OverlappingStickerDialogueBulkBuy(): JSX.Element {
  const [isRemovingAndAddingToBasket, setIsRemovingAndAddingToBasket] = useState(false);

  const {
    addToBasket,
    dispatch,
    state: { confirmedVehicles, unconfirmedVehicles, overlappingStickerDict }
  } = useContext(BulkBuyContext);

  const handleRemoveOverlappingStickers = async () => {
    // TODO implement
    try {
      setIsRemovingAndAddingToBasket(true);

      const vehiclesConfirmedLstCopy = { ...confirmedVehicles };
      const vehiclesUnconfirmedLstCopy = { ...unconfirmedVehicles };
      Object.keys(overlappingStickerDict).forEach(reg => {
        delete vehiclesConfirmedLstCopy[reg];
        delete vehiclesUnconfirmedLstCopy[reg];
      });
      dispatch({
        type: "BUY_BULK_SET_BEKREFTEDE_AND_UBEKREFTEDE_VEHICLES",
        bekreftedeKjoretoy: vehiclesConfirmedLstCopy,
        ubekreftedeKjoretoy: vehiclesUnconfirmedLstCopy
      });
      await addToBasket({
        ...vehiclesConfirmedLstCopy,
        ...vehiclesUnconfirmedLstCopy
      });
    } catch {

    } finally {
      setIsRemovingAndAddingToBasket(false);
    }
    // Remove stickers from list
    // Set add to list
  };

  const overlappingSeasonStickers = useMemo(() => {
    return Object.values(overlappingStickerDict).flat(1);
  }, [overlappingStickerDict]);

  const allVehiclesHasDuplicates = useMemo(() => {
    const allVehiclesInList = {
      ...unconfirmedVehicles,
      ...confirmedVehicles
    };
    return Object.values(overlappingStickerDict).length === Object.values(allVehiclesInList).length;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [overlappingStickerDict]);

  const handleClose = () => {
    dispatch({ type: "BUY_BULK_RESET_ALL" });
  };

  return (
    <Modal modalSize="large" staticModal isOpen={!isEmpty(overlappingStickerDict)} onHide={handleClose}>
      <ModalHeader>
        {{
          heading: <FormattedMessage id="buystickersbulkpage.overlappingStickerDialogue.heading" />
        }}
      </ModalHeader>
      <ModalSection>
        <MessageBoxWIcon type="warning" icon="exclamation-mark-circle" className="mb-3">
          {allVehiclesHasDuplicates ? (
            <FormattedMessage id="buystickersbulkpage.overlappingStickerDialogue.messageBody.AllVehiclesHasSeasonDuplicates" />
          ) : (
            <FormattedMessage
              id="buystickersbulkpage.overlappingStickerDialogue.messageBody.default"
              defaultMessage="Det finne sesongoblater for en eller flere kjøretøy i listen."
            />
          )}
        </MessageBoxWIcon>
        <OblatListeTabell oblaterList={overlappingSeasonStickers} />
      </ModalSection>
      <ModalSection className="flex mobile-only:flex-col tablet:justify-end">
        {allVehiclesHasDuplicates ? (
          <Button onClick={handleClose}>
            <FormattedMessage id="buystickersbulkpage.overlappingStickerDialogue.button.close" defaultMessage="Lukk" />
          </Button>
        ) : (
          <Button
            isLoading={isRemovingAndAddingToBasket}
            disabled={isRemovingAndAddingToBasket}
            onClick={handleRemoveOverlappingStickers}
          >
            <FormattedMessage
              id="buystickersbulkpage.overlappingStickerDialogue.button.confirm"
              defaultMessage="Fjern kjøretøy og gå videre"
            />
          </Button>
        )}
      </ModalSection>
    </Modal>
  );
}
