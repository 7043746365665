import { piggavService } from "api";
import { isEmpty } from "lodash-es";

export const GET_KJORETOY_REQUEST = "GET_KJORETOY_REQUEST";
export const GET_KJORETOY_SUCCESS = "GET_KJORETOY_SUCCESS";
export const GET_KJORETOY_FAILURE = "GET_KJORETOY_FAILURE";
export const CREATE_KJORETOY_SUCCESS = "CREATE_KJORETOY_SUCCESS";
export const CREATE_KJORETOY_FALIURE = "CREATE_KJORETOY_FALIURE";
export const DELETE_KJORETOY_SUCCESS = "DELETE_KJORETOY_SUCCESS";
export const DELETE_KJORETOY_FALIURE = "DELETE_KJORETOY_FALIURE";
export const UPDATE_KJORETOY_SUCCESS = "UPDATE_KJORETOY_SUCCESS";
export const UPDATE_KJORETOY_FALIURE = "UPDATE_KJORETOY_FALIURE";
export const RESET_KJORETOY_ERROR = "RESET_KJORETOY_ERROR";
export const RESET_KJORETOY = "piggav/kjoretoy/KJORETOY_RESET";

export const resetKjoretoyState = () => ({
  type: RESET_KJORETOY,
  payload: ""
});

const updateKjoretoySuccess = data => ({
  type: UPDATE_KJORETOY_SUCCESS,
  payload: data
});
const updateKjoretoyFailure = error => ({
  type: UPDATE_KJORETOY_FALIURE,
  payload: error
});

const deleteKjoretoySuccess = data => ({
  type: DELETE_KJORETOY_SUCCESS,
  payload: data
});
const deleteKjoretoyFailure = error => ({
  type: DELETE_KJORETOY_FALIURE,
  payload: error
});

const getKjoretoyRequest = () => ({
  type: GET_KJORETOY_REQUEST,
  payload: ""
});
const getKjoretoySuccess = kjoretoy => ({
  type: GET_KJORETOY_SUCCESS,
  payload: kjoretoy
});
const getKjoretoyFailure = error => ({
  type: GET_KJORETOY_FAILURE,
  payload: error,
  error: true
});

const createKjoretoySuccess = kjoretoy => ({
  type: CREATE_KJORETOY_SUCCESS,
  payload: kjoretoy
});

const createKjoretoyFailure = error => ({
  type: CREATE_KJORETOY_FALIURE,
  payload: error
});

const createKjoretoyReset = () => ({
  type: RESET_KJORETOY_ERROR,
  payload: ""
});

export const getKjoretoyForBruker =
  (brukerId, forceFetching = false) =>
  (dispatch, getState) => {
    const { kjoretoy } = getState();
    if (!isEmpty(kjoretoy.kjoretoy) && !forceFetching)
      // If vehilces allready loaded, just resolve it with a bym endpoint result
      return Promise.resolve({
        result: kjoretoy.kjoretoy,
        errorMessage: "",
        timeGenerated: new Date().toISOString
      });
    dispatch(getKjoretoyRequest());
    return piggavService
      .getKjoretoyForBruker(brukerId)
      .then(response => {
        const data = response.data.result;
        dispatch(getKjoretoySuccess(data));
        return Promise.resolve(response.data);
      })
      .catch(error => {
        dispatch(getKjoretoyFailure(error));
        return Promise.reject(error);
      });
  };

export const resetKjoretoyError = () => dispatch => dispatch(createKjoretoyReset());

export const createKjoretoy = (kjoretoy, brukerId, successCallback, errorCallback) => dispatch => {
  dispatch(resetKjoretoyError());
  return piggavService
    .createKjoretoy(brukerId, kjoretoy)
    .then(response => {
      const data = response.data.result;
      dispatch(createKjoretoySuccess(data));
      dispatch(getKjoretoyForBruker(brukerId, true));
      successCallback(data);
    })
    .catch(error => {
      const e = {
        data: error.response.data,
        status: error.response.status
      };
      dispatch(createKjoretoyFailure(e));
      errorCallback(e);
    });
};
export const deleteKjoretoy = (kjoretoyId, brukerId) => dispatch =>
  piggavService
    .deleteKjoretoy(brukerId, kjoretoyId)
    .then(response => {
      const data = response.data.result;
      dispatch(deleteKjoretoySuccess(kjoretoyId));
      // dispatch(getKjoretoyForBruker(kundeId));
    })
    .catch(error => {
      dispatch(deleteKjoretoyFailure(error));
    });

export const updateKjoretoy = (brukerId, kjoretoyId, kjoretoy) => dispatch =>
  piggavService
    .updateKjoretoy(brukerId, kjoretoyId, kjoretoy)
    .then(response => {
      const data = response.data.result;
      dispatch(updateKjoretoySuccess(data));
      // dispatch(getKjoretoyForBruker(kundeId));
    })
    .catch(error => {
      dispatch(updateKjoretoyFailure(error));
    });
