/* eslint-disable react/static-property-placement */
import { Component, ReactNode } from "react";
import { connect } from "react-redux";
import { StaticContext } from "react-router";
import { Route, Redirect, RouteProps, RouteComponentProps } from "react-router-dom";

class PublicRoute extends Component<RouteProps & { innlogget: boolean; component?: Function }> {
  renderRoute = (
    props: JSX.IntrinsicAttributes & RouteComponentProps<any, StaticContext, unknown> & { children?: ReactNode }
  ) => {
    const { component: Comp, innlogget } = this.props;
    if (!innlogget && Comp) {
      return <Comp {...props} />;
    }
    return (
      <Redirect
        to={{
          pathname: "/",
          state: { from: props.location }
        }}
      />
    );
  };

  render() {
    const { component, ...rest } = this.props;
    return <Route {...rest} render={this.renderRoute} />;
  }
}

function mapStateToProps(state: { login: { brukerId: null } | null }) {
  return {
    innlogget: state.login != null && state.login.brukerId != null
  };
}

export default connect(mapStateToProps)(PublicRoute);
