import React from "react";
import "./Section.scss";

interface SectionProps extends React.HTMLAttributes<HTMLDivElement> {
  colorClass?: string;
  modifier?: string;
}

export default function Section({
  className,
  children,
  colorClass = "bg-white",
  modifier,
  ...restDivProps
}: SectionProps): JSX.Element {
  return (
    <div className={`flex justify-center section ${modifier ?? ""} ${colorClass} `}>
      <div
        className={`osg-u-padding-horizontal-3 w-full widescreen:w-widescreen-content-max-width ${
          className ?? ""
        }  ${colorClass}`}
        {...restDivProps}
      >
        {children}
      </div>
    </div>
  );
}
