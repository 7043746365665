import React, { forwardRef, ReactNode, useLayoutEffect, useRef } from "react";
import { StatePropType } from "../Interface";
import "./TextArea.scss";

export interface TextAreaElementProps extends Omit<React.HTMLProps<HTMLTextAreaElement>, "label">, StatePropType {
  label?: ReactNode;
}

const TextArea = forwardRef<HTMLTextAreaElement, TextAreaElementProps>(
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ({ className, label, name, required, children, state, type = "", rows, ...rest }: TextAreaElementProps, ref) => {
    const wrapperRef = useRef<HTMLLabelElement | null>(null);

    useLayoutEffect(() => {
      // Bug is causing wrong initial height of textarea element
      const textareaElement = wrapperRef.current?.getElementsByTagName("textarea")[0];
      if (!textareaElement) return;
      const lineHeight: number = parseFloat(textareaElement.style.lineHeight.replace(/[a-zA-Z]{2}/, ""));
      const unit = textareaElement.style.lineHeight.match(/([a-zA-Z]{2})/)?.[0];
      textareaElement.style.height = `${(!lineHeight ? 1.2 : lineHeight) * (rows ?? 1)}${unit ?? "em"}`;
    }, [rows, wrapperRef]);

    return (
      <label ref={wrapperRef} className="input-element" htmlFor={name}>
        <span className="label">
          {label ?? children} {`${required ? " *" : ""}`}
        </span>
        <textarea
          name={name}
          className={`input ${className} ${state ? `state-${state}` : ""}`}
          ref={ref}
          rows={rows}
          {...rest}
        />
      </label>
    );
  }
);

export default TextArea;
