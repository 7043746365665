import { useState } from "react";
import { Button } from "features/common/OSG";
import { FormattedMessage } from "locale/langUtils";
import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { MessageBoxWIcon } from "features/common/ComposedComponents";
import { deleteKunde } from "api/piggavService";
import { useAuth } from "react-oidc-context";
import { useSelector } from "react-redux";
import { getState as getAuthState } from "state/Auth/Auth";
import { useIntl } from "react-intl";

export default function DeleteUserSetting(): JSX.Element {
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const auth = useAuth();
  const { userInfo } = useSelector(getAuthState);
  const intl = useIntl();
  const onDelete = () => {
    if (!userInfo?.brukerId) return;
    deleteKunde(userInfo?.brukerId)
      .then(() => {
        auth.signoutRedirect();
      })
      .catch(e => {
        setErrorMessage(e.response?.data?.ErrorMessage);
      });
  };

  return (
    <div>
      <h2 className="heading-3 mb-3">
        <FormattedMessage id="profile.deleteuser.header" />
      </h2>
      <div className="inline-block">
        <Button
          aria-haspopup="dialog"
          aria-controls="delete-user-dialogue"
          aria-expanded={showConfirmDialog}
          aria-label={intl.formatMessage({ id: "profile.deleteuser.button.aria" })}
          colorOption="red"
          size="small"
          type="button"
          onClick={() => setShowConfirmDialog(true)}
        >
          <FormattedMessage id="profile.deleteuser.button" />
        </Button>
      </div>
      {showConfirmDialog && (
        <Modal id="delete-user-dialogue" isOpen={showConfirmDialog} toggle={() => setShowConfirmDialog(false)}>
          <ModalHeader>
            {{
              heading: <FormattedMessage id="profile.deleteuser.confirm.header" />
            }}
          </ModalHeader>
          <ModalSection>
            <MessageBoxWIcon type="warning" icon="exclamation-mark-circle" className="mb-3">
              <FormattedMessage id="profile.deleteuser.confirm.warning" />
            </MessageBoxWIcon>
          </ModalSection>
          <ModalSection className="">
            <div className="w-full flex space-x-2 mobile-only:space-x-none mobile-only:space-y-2 mobile-only:flex-col tablet:justify-end">
              <Button type="button" modifier="outline" onClick={() => setShowConfirmDialog(false)}>
                <FormattedMessage id="common.cancel" />
              </Button>
              <Button type="button" onClick={onDelete}>
                <FormattedMessage id="common.save" />
              </Button>
            </div>
            {errorMessage && <div className="p-3 mt-3 bg-state-danger">{errorMessage}</div>}
          </ModalSection>
        </Modal>
      )}
    </div>
  );
}
