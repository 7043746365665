import { OBLATTYPE_SUCCESS, OBLATTYPE_REQUEST } from "./oblattypeActions";

const oblattypeReducer = (state = { oblattyper: [], loading: false }, action = {}) => {
  switch (action.type) {
    case OBLATTYPE_REQUEST:
      return { ...state, loading: true };
    case OBLATTYPE_SUCCESS:
      return { ...state, oblattyper: action.payload, loading: false };
    default:
      return state;
  }
};

export function getState(state) {
  return state.oblattyper;
}

export default oblattypeReducer;
