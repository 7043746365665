/* eslint-disable react/prop-types */
import React, { forwardRef } from "react";
import { IconTypes } from "../../index";
import "./InputDate.scss";

interface InputDateTimeProps extends React.HTMLProps<HTMLInputElement> {
  icon?: IconTypes;
}

const InputDateTime = forwardRef<HTMLInputElement, InputDateTimeProps>(
  ({ className, label, name, required, children, type = "text", icon = "calendar", ...rest }, ref) => (
    <div className="osg-input osg-input-date">
      <label htmlFor={name} className="osg-input-date__label">
        {label}
        <input
          className={`osg-input__input osg-input-date__input ${className}`}
          name={name}
          type={type}
          autoComplete="off"
          ref={ref}
          {...rest}
        />
        <span className={`osg-input-date__icon osg-icon osg-icon--${icon}`} aria-hidden />
      </label>
      {children}
    </div>
  )
);

export default InputDateTime;
