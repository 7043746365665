import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "locale/langUtils";
import { useSelector } from "react-redux";
import { ReactComponent as OsloLogo } from "images/Oslo-logo-sort-til-skjerm-og-utskrift/Oslo-logo-sort-RGB.svg";
import { MobileMenu, MenuItem } from "features/MobileMenu/MoblieMenu";
import { getState } from "state/Konfigurasjoner/konfigurasjonerReducer";
import * as urls from "appConstants/urls";
import HandlekurvNavbar from "features/ShoppingCart/ShoppingCartNavbar";
import IENotification from "features/IENotification/IENotification";
import useUserHook from "features/Hooks/UserHook";
import useWindowUtilsHook from "features/Hooks/WindowUtilsHook";
import { Configurations } from "interfaces";
import { useAuth } from "react-oidc-context";
import Tooltip from "features/common/BaseComponents/Tooltip/Tooltip";
import { getEnvironment, isProduction, isTestEnvironment } from "utils/environment";
import { capitalize } from "lodash-es";
import "./Navbar.scss";
import { useScrollHook } from "features/Hooks/scrollHook";
import Breadcrumb from "features/Breadcrumb/Breadcrumb";
import { useRef } from "react";

export default function Navbar(): JSX.Element {
  const auth = useAuth();
  const history = useHistory();
  const { customerType } = useUserHook();
  const { currentBreakpoint } = useWindowUtilsHook();
  const intl = useIntl();
  const navbarRef = useRef<HTMLElement | null>(null);
  const onLogoutUserClicked = async (): Promise<void> => {
    try {
      history.push(urls.hjem);
      await auth.signoutRedirect();
    } catch {
      console.log("Kunne ikke logge ut bruker");
    }
  };

  const { isScrollingDown, hasFocus: navbarHasFocus } = useScrollHook({
    threshold: 40,
    ref: navbarRef.current,
    activeClassName: "navbar-has-focus"
  });

  const { salgAapent } = useSelector<any, Configurations>(getState);

  return (
    <header
      ref={navbarRef}
      className="fixed top-0 z-nav box-border w-screen  piggav-navbar"
      aria-hidden={isScrollingDown && !navbarHasFocus}
    >
      <nav className=" w-full border-0 ">
        <IENotification />
        <div className="navbar flex bg-beige-dark osg-u-padding-horizontal-3 osg-u-padding-horizontal-5-tablet-and-up osg-u-padding-vertical-small justify-between">
          <div className="flex items-center">
            <div className="w-1/2 h-logo-height-small " style={{ height: "40px" }}>
              <Link to={urls.hjem} aria-label={intl.formatMessage({ id: "navbar.logo.aria" })}>
                <OsloLogo height="100%" />
              </Link>
            </div>
            {!isProduction() && (
              <Tooltip
                tooltip={`${process.env.REACT_APP_GITHUB_BRANCH ?? "null"} - ${
                  process.env.REACT_APP_GITHUB_SHA ?? "null"
                }`}
                className="tooltip-badge "
              >
                <span className={`badge ${isTestEnvironment() ? "test" : "development"}`}>
                  {capitalize(getEnvironment())}
                </span>
              </Tooltip>
            )}
          </div>
          {auth.isAuthenticated && (
            <div className="w-1/2 flex items-center justify-end">
              {salgAapent && <HandlekurvNavbar className="mr-2 tablet:mr-6" />}

              <MobileMenu className="z-menu" loggedInAs={auth.user?.profile?.preferred_username}>
                <MenuItem exact to={urls.profil} icon="chevron-right">
                  {currentBreakpoint === "mobile" ? (
                    <FormattedMessage id="navbar.menuitem.profile.mobile" defaultMessage="Profil og innstillinger" />
                  ) : (
                    <FormattedMessage id="navbar.menuitem.profile" defaultMessage="Profil og innstillinger" />
                  )}
                </MenuItem>
                <MenuItem exact icon="chevron-right" to={urls.oblatoversikt}>
                  {currentBreakpoint === "mobile" ? (
                    <FormattedMessage
                      id="navbar.menuitem.overviewstickers.mobile"
                      defaultMessage="Oversikt mine oblater"
                    />
                  ) : (
                    <FormattedMessage id="navbar.menuitem.overviewstickers" defaultMessage="Oversikt mine oblater" />
                  )}
                </MenuItem>
                <MenuItem exact icon="chevron-right" to={urls.kvittering}>
                  <FormattedMessage id="navbar.menuitem.receipts" defaultMessage="Kvitteringer" />
                </MenuItem>
                <MenuItem
                  exact
                  icon="chevron-right"
                  to={urls.summaryAddSingle}
                  disabled={!salgAapent}
                  aria-disabled={!salgAapent}
                >
                  <FormattedMessage id="navbar.menuitem.shoppingcart" />
                </MenuItem>
                <MenuItem
                  exact
                  icon="chevron-right"
                  to={urls.kjopOblater}
                  disabled={!salgAapent}
                  aria-disabled={!salgAapent}
                >
                  <FormattedMessage id="navbar.menuitem.buystickers" />
                </MenuItem>
                {customerType === "Bedriftkunde" && (
                  <MenuItem
                    exact
                    icon="chevron-right"
                    to={urls.bulkKjop}
                    disabled={!salgAapent}
                    aria-disabled={!salgAapent}
                  >
                    <FormattedMessage id="navbar.menuitem.buymultiplestickers" />
                  </MenuItem>
                )}
                <MenuItem exact icon="chevron-right" to={urls.faq}>
                  {currentBreakpoint === "mobile" ? (
                    <FormattedMessage id="navbar.menuitem.faq.mobile" />
                  ) : (
                    <FormattedMessage id="navbar.menuitem.faq" />
                  )}
                </MenuItem>
                <MenuItem
                  exact
                  to={`${urls.hjem}logout`}
                  icon="chevron-right"
                  onClick={e => {
                    e.preventDefault();
                    onLogoutUserClicked();
                  }}
                  role="button"
                  onKeyDown={e => {
                    e.preventDefault();
                    if (e.key === "Enter" || e.key === "Space") {
                      onLogoutUserClicked();
                    }
                  }}
                >
                  <FormattedMessage id="navbar.menuitem.logout" />
                </MenuItem>
              </MobileMenu>
            </div>
          )}
        </div>
      </nav>
      <Breadcrumb />
    </header>
  );
}
