import React, { useState } from "react";
import Modal, { ModalHeader, ModalSection } from "features/common/BaseComponents/Modal/Modal";
import { FormattedMessage } from "locale/langUtils";
import { Button, InputFormField } from "features/common/ComposedComponents";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers";
import { useForm } from "react-hook-form";
import * as piggavService from "api/piggavService";
interface SendFromReceiptFromReceptionProps {
  varekjopId: string;
  brukerId: string;
  buttonClassName?: string;
}

const sendReceiptOnMailSchema = yup.object().shape({
  email: yup.string().lowercase().required("common.error.requiredfield").email("common.error.notavalidemail")
});

export default function SendFromReceiptFromReception({
  varekjopId,
  brukerId,
  buttonClassName
}: SendFromReceiptFromReceptionProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [isSendingEmail, setIsSendingEmail] = useState(false);
  const [successMsg, setSuccessMsg] = useState<string | undefined>(undefined);
  const { errors, setError, handleSubmit, register, getValues } = useForm<
    yup.InferType<typeof sendReceiptOnMailSchema>
  >({
    resolver: yupResolver(sendReceiptOnMailSchema),
    criteriaMode: "firstError",
    mode: "onSubmit"
  });

  const handleSendToEpostSubmit = handleSubmit(async ({ email }) => {
    try {
      setIsSendingEmail(true);
      await piggavService.sendKvitteringFromResepsjonToMail(brukerId, varekjopId, email);

      setSuccessMsg("receiptpage.SendReceiptFromReception.successMessage");
    } catch (e) {
      setError("email", {
        type: "errorSending",
        message: "receiptpage.SendReceiptFromReception.error.sending"
      });
    } finally {
      setIsSendingEmail(false);
    }
  });

  return (
    <>
      <Button icon="email" onClick={() => setIsOpen(prev => !prev)} className={buttonClassName}>
        <FormattedMessage id="receiptpage.SendReceiptFromReception.button.openModal" />
      </Button>
      <Modal isOpen={isOpen} staticModal toggle={() => setIsOpen(prev => !prev)}>
        <ModalHeader>
          {{
            heading: <FormattedMessage id="receiptpage.SendReceiptFromReception.heading" />
          }}
        </ModalHeader>
        <ModalSection>
          <FormattedMessage id="receiptpage.SendReceiptFromReception.subheading" />
        </ModalSection>
        <ModalSection>
          <form
            id="send-receipt-on-email-form"
            onSubmit={handleSendToEpostSubmit}
            className="flex flex-col justify-start tablet:w-2/3"
          >
            <InputFormField
              name="email"
              ref={register}
              error={errors?.email?.message && <FormattedMessage id={errors.email.message} />}
              success={
                successMsg && (
                  <FormattedMessage
                    id={successMsg}
                    values={{
                      email: getValues("email")
                    }}
                  />
                )
              }
              label={<FormattedMessage id="receiptpage.SendReceiptFromReception.label.epost" defaultMessage="epost til kunde" />}
            />
            <Button
              type="submit"
              form="send-receipt-on-email-form"
              className="self-end"
              icon="email"
              disabled={isSendingEmail}
              isLoading={isSendingEmail}
              onClick={handleSendToEpostSubmit}
            >
              <FormattedMessage id="receiptpage.SendReceiptFromReception.button.submit" />
            </Button>
          </form>
        </ModalSection>
      </Modal>
    </>
  );
}
