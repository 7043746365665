import React, { useState, useEffect, useReducer, useMemo, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "locale/langUtils";
import { some, isEmpty, find } from "lodash-es";
import ButtonWSpinner from "features/common/ComposedComponents/ButtonWIconSpinner/ButtonWIconSpinner";
import { useReduxDispatch } from "utils/Storeutils";
import { isValid } from "date-fns";
import axios from "axios";
import UserHook from "features/Hooks/UserHook";
import useHandlekurvHook from "features/Hooks/HandlekurvHook";
import { Kjoretoy, Oblattype, Configurations } from "interfaces";
import { StickerPeriodId } from "appConstants";
import useWindowUtilsHook from "features/Hooks/WindowUtilsHook";
import { findVehicleInList } from "utils/kjoretoyUtils";
import { validerOrdrelinje } from "utils/validationUtils";
import { LinkButtonReactRouter } from "features/common/ComposedComponents";
import { getOblattyper } from "../../state/Oblattype/oblattypeActions";
import { getSystemkonfigurasjon } from "../../state/Konfigurasjoner/konfigurasjonerActions";
import { hjem, summaryAddSingle } from "../../appConstants/urls";
import OblatvalgTilbakemelding from "./OblatvalgTilbakemelding";
import OverlappingStickerDialogue from "./OverlappingStickerDialogue";
import VehicleSelect from "./SelectVehicle";
import SelectStickerType from "./SelectStickerType";
import SelectValidFrom from "./SelectValidFrom";
import "./OrdrelinjeForm.scss";

export interface Errors {
  kjoretoyIdError: string | null;
  gyldigFraError: string | null;
  datoTidIFortidError: string | null;
  datoFørSesongstartError: string | null;
  oblattypeIdError: string | null;
}

interface StateType {
  validFrom?: string;
  chosenVehicle?: Kjoretoy;
  chosenPeriodeId?: number;
  chosenStickerType?: Oblattype;
  errors: Errors;
  addingToBasket: boolean;
  startNow: boolean;
  showMobileFlow: boolean;
  isLoading: boolean;
  isFetchingVehicles: boolean;
  isFetchingStandardVehicle: boolean;
  isFetchingResources: boolean;
}

const initialState: StateType = {
  validFrom: undefined,
  chosenVehicle: undefined,
  chosenStickerType: undefined,
  chosenPeriodeId: undefined,
  addingToBasket: false,
  isLoading: false,
  isFetchingStandardVehicle: false,
  isFetchingResources: false,
  isFetchingVehicles: false,
  startNow: false,
  showMobileFlow: false,
  errors: {
    kjoretoyIdError: null,
    gyldigFraError: null,
    datoTidIFortidError: null,
    datoFørSesongstartError: null,
    oblattypeIdError: null
  }
};

type OrdreLinjeContextType = StateType & { dispatch: React.Dispatch<ActionType> };

export const OrdreLinjeContext = React.createContext<OrdreLinjeContextType>({} as OrdreLinjeContextType);

interface ActionType {
  type:
    | "SET_VEHICLE"
    | "SET_STICKER_PERIODE_ID"
    | "SET_STICKERTYPE"
    | "SET_STICKERTYPE_ID"
    | "SET_VALID_FROM_DATE_TIME"
    | "SET_START_NOW"
    | "SET_IS_LOADING"
    | "SET_SHOW_START_NOW"
    | "SET_IS_ADDING_TO_BASKET"
    | "SET_IS_FETHCING_STANDARD_VEHICLE"
    | "SET_IS_FETCHING_VEHICLES"
    | "SET_SHOW_MOBILE_FLOW"
    | "SET_IS_FETCHING_RESOURCES"
    | "SET_ERRORS";
  payload: any;
}

const reducer = (prevState: StateType, action: ActionType): StateType => {
  switch (action.type) {
    case "SET_VALID_FROM_DATE_TIME":
      return { ...prevState, validFrom: action.payload };
    case "SET_VEHICLE":
      return { ...prevState, chosenVehicle: action.payload };
    case "SET_STICKER_PERIODE_ID":
      return { ...prevState, chosenPeriodeId: action.payload };
    case "SET_STICKERTYPE":
      return { ...prevState, chosenStickerType: action.payload };
    case "SET_SHOW_MOBILE_FLOW":
      return { ...prevState, showMobileFlow: action.payload };
    case "SET_SHOW_START_NOW":
      return { ...prevState, startNow: action.payload };
    case "SET_IS_LOADING":
      return { ...prevState, isLoading: action.payload };
    case "SET_IS_ADDING_TO_BASKET":
      return { ...prevState, addingToBasket: action.payload };
    case "SET_IS_FETCHING_VEHICLES":
      return { ...prevState, isFetchingVehicles: action.payload };
    case "SET_IS_FETCHING_RESOURCES":
      return { ...prevState, isFetchingResources: action.payload };
    case "SET_ERRORS":
      return {
        ...prevState,
        errors: { ...prevState.errors, ...action.payload }
      };
    default:
      return prevState;
  }
};

export default function SelectStickerForm(): JSX.Element {
  const [selectStickerState, dispatchStateAction] = useReducer(reducer, initialState);
  const { validFrom, chosenVehicle, chosenStickerType, isFetchingVehicles, isFetchingResources, showMobileFlow } =
    selectStickerState;

  const [showOverlappingStickerDialogue, setShowOverlappingStickerDialogue] = useState(false);
  const [overlappingStickersList, setOverlappingStickersList] = useState<any[]>([]);

  const oblattyper = useSelector<any, Oblattype[]>(state => state.oblattyper.oblattyper);

  const kjoretoy = useSelector<any, Kjoretoy[]>(state => state.kjoretoy.kjoretoy);

  const konfigurasjon = useSelector<any, Configurations>(state => state.konfigurasjon);

  const handlekurv = useSelector<any, any>(state => state.handlekurv);

  const dispatch = useReduxDispatch();

  const history = useHistory();

  const { addStickerToBasket, isAddingStickerToBasker, isHandlekurvOpen } = useHandlekurvHook();

  const { customerState } = UserHook();

  const { currentBreakpoint } = useWindowUtilsHook();

  useLayoutEffect(() => {
    dispatchStateAction({
      type: "SET_SHOW_MOBILE_FLOW",
      payload: currentBreakpoint === "mobile"
    });
  }, [currentBreakpoint]);

  useEffect(() => {
    dispatchStateAction({ type: "SET_IS_FETCHING_RESOURCES", payload: true });
    axios.all([dispatch(getOblattyper()), dispatch(getSystemkonfigurasjon())]).finally(() => {
      dispatchStateAction({
        type: "SET_IS_FETCHING_RESOURCES",
        payload: false
      });
    });
  }, [dispatch]);

  const resetForm = () => {
    dispatchStateAction({
      type: "SET_STICKERTYPE",
      payload: undefined
    });
    dispatchStateAction({
      type: "SET_STICKER_PERIODE_ID",
      payload: undefined
    });
    dispatchStateAction({
      type: "SET_VALID_FROM_DATE_TIME",
      payload: undefined
    });
    // If standard vehicle exists use that
    const vehicle = customerState?.standardKjoretoyId
      ? findVehicleInList(customerState?.standardKjoretoyId ?? "", kjoretoy)
      : kjoretoy?.[0];

    dispatchStateAction({
      type: "SET_VEHICLE",
      payload: vehicle
    });
  };

  const resetOverlappingDialogue = () => {
    setShowOverlappingStickerDialogue(false);
    dispatchStateAction({
      type: "SET_IS_ADDING_TO_BASKET",
      payload: false
    });
    resetForm();
    setOverlappingStickersList([]);
    dispatchStateAction({ type: "SET_START_NOW", payload: false });
  };

  const resetErrors = () =>
    dispatchStateAction({
      type: "SET_ERRORS",
      payload: {
        kjoretoyIdError: null,
        gyldigFraError: null,
        datoTidIFortidError: null,
        datoFørSesongstartError: null,
        oblattypeIdError: null
      }
    });

  const onVehicleChange = () => {
    // Remove selected sticker type
    dispatchStateAction({
      type: "SET_STICKER_PERIODE_ID",
      payload: undefined
    });
    dispatchStateAction({
      type: "SET_STICKERTYPE",
      payload: undefined
    });
    resetErrors();
  };

  const onStickerTypeChange = (stickerType: Oblattype, pId: number) => {
    resetErrors();
    dispatchStateAction({
      type: "SET_VALID_FROM_DATE_TIME",
      payload: pId === StickerPeriodId.Season ? new Date(konfigurasjon?.sesongStart.verdi).toISOString() : undefined
    });
    dispatchStateAction({
      type: "SET_STICKERTYPE",
      payload: stickerType
    });
    dispatchStateAction({
      type: "SET_STICKER_PERIODE_ID",
      payload: pId
    });
  };

  const onStartidSelect = (value: string) => {
    if (isValid(new Date(value))) {
      dispatchStateAction({
        type: "SET_ERRORS",
        payload: {
          gyldigFraError: null,
          datoTidIFortidError: null,
          datoFørSesongstartError: null
        }
      });
    }
  };

  const handleSave = () => {
    const validation = validerOrdrelinje(
      {
        gyldigFra: validFrom,
        kjoretoyId: chosenVehicle?.id,
        oblattypeId: chosenStickerType?.id
      },
      konfigurasjon,
      find(oblattyper, o => o.id === chosenStickerType?.id)
    );

    if (some(validation, error => !isEmpty(error))) dispatchStateAction({ type: "SET_ERRORS", payload: validation });
    else {
      // dispatchStateAction({ type: "SET_IS_ADDING_TO_BASKET", payload: true });
      resetErrors();
      addStickerToBasket(
        {
          oblattypeId: chosenStickerType?.id ?? "",
          kjoretoyId: chosenVehicle?.id ?? "",
          gyldigFra: validFrom ?? "",
          tillatOverlapp: false
        },
        !showMobileFlow
      )
        .then(res => {
          const { errorkode, result } = res.data;
          if (errorkode === 409) {
            setOverlappingStickersList(result);
            setShowOverlappingStickerDialogue(true);
          } else {
            resetForm();
            dispatchStateAction({ type: "SET_START_NOW", payload: false });
            if (showMobileFlow) history.push(summaryAddSingle);
          }
        })
        .finally(() => {
          dispatchStateAction({
            type: "SET_IS_ADDING_TO_BASKET",
            payload: false
          });
        });
    }
  };

  const confirmAddToBasket = () => {
    dispatchStateAction({
      type: "SET_IS_ADDING_TO_BASKET",
      payload: true
    });
    // setAddingToBasket(true);
    setShowOverlappingStickerDialogue(false);

    addStickerToBasket(
      {
        oblattypeId: chosenStickerType?.id ?? "",
        kjoretoyId: chosenVehicle?.id ?? "",
        gyldigFra: validFrom || "",
        tillatOverlapp: true
      },
      !showMobileFlow
    )
      .then(() => {
        resetOverlappingDialogue();
        if (showMobileFlow) history.push(summaryAddSingle);
      })
      .finally(() => {
        dispatchStateAction({
          type: "SET_IS_ADDING_TO_BASKET",
          payload: false
        });
      });
  };

  const isAddToBasketBtnDisabled = useMemo(() => {
    validerOrdrelinje(
      {
        gyldigFra: validFrom,
        kjoretoyId: chosenVehicle?.id,
        oblattypeId: chosenStickerType?.id
      },
      konfigurasjon,
      find(oblattyper, o => o.id === chosenStickerType?.id)
    );
    // dispatchStateAction({ type: "SET_ERRORS", payload: validation });
    // const anyErrors = some(validation, (error) => !isEmpty(error));

    return (
      // anyErrors ||
      handlekurv.isLoading || isAddingStickerToBasker || isFetchingResources || isFetchingVehicles
    );
  }, [
    validFrom,
    chosenVehicle,
    chosenStickerType,
    konfigurasjon,
    oblattyper,
    handlekurv.isLoading,
    isAddingStickerToBasker,
    isFetchingResources,
    isFetchingVehicles
  ]);

  return (
    <OrdreLinjeContext.Provider value={{ ...selectStickerState, dispatch: dispatchStateAction }}>
      <form
        onSubmit={e => {
          e.preventDefault();
          handleSave();
        }}
      >
        <div className=" p-1 bg-grey-light tablet:py-5 tablet: px-2">
          <p className="w-full mb-4 italic tablet:px-4" style={{ fontSize: 14 }}>
            <FormattedMessage id="buystickers.ordrelinjeform" />
          </p>
          <div className="formfield-container flex flex-col  bg-grey-light tablet:flex-row tablet:justify-center ">
            <div id="kjoretoy_input" className="flex flex-col py-3 tablet:py-4 w-full tablet:w-1/3 tablet:px-4">
              <VehicleSelect onChange={onVehicleChange} />
            </div>
            <div className="tablet:border-grey-dark tablet:border-solid tablet:border" />
            <div className="py-3 tablet:py-4 w-full tablet:w-1/3 tablet:px-4">
              <SelectStickerType onChange={onStickerTypeChange} />
            </div>
            <div className="tablet:border-grey-dark tablet:border-solid tablet:border" />
            <div className="py-3 tablet:py-4 w-full tablet:w-1/3 tablet:px-4">
              <SelectValidFrom onChange={val => onStartidSelect(val)} />
            </div>
          </div>
        </div>

        <OblatvalgTilbakemelding
          id="oblat-valg-tilbakemelding"
          valgtOblatType={chosenStickerType}
          valgtKjoretoy={chosenVehicle}
          gyldigFra={validFrom}
          sesongSlutt={konfigurasjon?.sesongSlutt?.verdi}
          oblattyper={oblattyper}
        />
        <div className="flex flex-col tablet:flex-row tablet:justify-end py-4 ">
          <ButtonWSpinner
            isLoading={isAddingStickerToBasker}
            type="submit"
            id="lagre-ordrelinje-submit-knapp"
            className="mobile-only:w-full"
            disabled={isAddToBasketBtnDisabled}
            aria-haspopup
            aria-expanded={isHandlekurvOpen}
          >
            <div className="button-text-div">
              <FormattedMessage
                id={
                  showMobileFlow ? "buystickers.addOrderlineSubmitButtonMobile" : "buystickers.addOrderlineSubmitButton"
                }
              />
            </div>
            <div className="button-spinner-div">{/* <Spinner show={false} width="25px" height="25px" /> */}</div>
          </ButtonWSpinner>
          {showMobileFlow && (
            <LinkButtonReactRouter className="mt-3" modifier="outline" to={hjem} colorOption="yellow">
              <FormattedMessage id="buystickers.backBtn" />
            </LinkButtonReactRouter>
          )}
        </div>
      </form>
      <OverlappingStickerDialogue
        show={showOverlappingStickerDialogue}
        onConfirm={confirmAddToBasket}
        onHide={resetOverlappingDialogue}
        onCancel={resetOverlappingDialogue}
        varekjopId={handlekurv.varekjop && handlekurv.varekjop.id}
        overlappendeOblaterList={overlappingStickersList}
        chosenStickerId={chosenStickerType?.id ?? ""}
        kjoretoy={chosenVehicle}
      />
    </OrdreLinjeContext.Provider>
  );
}
