import React, { useState, useEffect, useCallback, createContext, ReactNode, useContext } from "react";
import { MOBILE_BREAK_POINT, TABLET_BREAK_POINT, DESKTOP_BREAKPOINT } from "appConstants/breakpoints";

type BreakPoints = "mobile" | "tablet" | "desktop";

const isClient = typeof window === "object";

function getSize() {
  return {
    width: isClient ? window.innerWidth : undefined,
    height: isClient ? window.innerHeight : undefined
  };
}

function getBreakpoint(): BreakPoints {
  const windowSize = getSize();
  const width = windowSize?.width ?? 0;
  switch (true) {
    case width <= MOBILE_BREAK_POINT:
      return "mobile";

    case TABLET_BREAK_POINT >= width && width < DESKTOP_BREAKPOINT:
      return "tablet";

    default:
      return "desktop";
  }
}

const WindowUtilContext = createContext<{
  windowSize: { width?: number; height?: number };
  currentBreakpoint: BreakPoints;
}>({ windowSize: getSize(), currentBreakpoint: "mobile" });

interface WindowUtilsProviderProps {
  children: ReactNode;
}
export function WindowUtilsProvider({ children }: WindowUtilsProviderProps): JSX.Element {
  const [windowSize, setWindowSize] = useState(getSize);

  const [currentBreakpoint, setCurrentBreakpoint] = useState<BreakPoints>(getBreakpoint());

  useEffect(() => {
    if (!isClient) {
      return () => undefined;
    }

    function handleResize() {
      const ws = getSize();
      const bp = getBreakpoint();
      setCurrentBreakpoint(bp);
      setWindowSize(ws);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <WindowUtilContext.Provider value={{ windowSize, currentBreakpoint }}>{children}</WindowUtilContext.Provider>;
}

export default function useWindowUtilsHook() {
  const { currentBreakpoint, windowSize } = useContext(WindowUtilContext);
  const disableScrollOnBody = useCallback(() => {
    document.body.style.setProperty("overflow", "hidden");
  }, []);
  const enableScrollOnBody = useCallback(() => {
    document.body.style.removeProperty("overflow");
  }, []);

  return {
    currentBreakpoint,
    windowSize,
    disableScrollOnBody,
    enableScrollOnBody
  };
}
