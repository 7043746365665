/* eslint-disable react/prop-types */
/* eslint-disable react/static-property-placement */
import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";
import { FormattedMessage } from "locale/langUtils";
import { Link, withRouter } from "react-router-dom";
import Page from "features/common/layout/Page";
import Section from "features/common/layout/Section";
import LinkButton from "features/common/ComposedComponents/LinkButton/LinkButton";
import SpinnerCircle from "features/common/BaseComponents/Spinner/SpinnerCircle";
import * as urls from "../../appConstants/urls";
import "./CompletePaymentPage.css";
import successIkon from "../../images/success-ikon.svg";
import errorIkon from "../../images/avbrutt-ikon.svg";
class SjekkBetalingsstatusside extends React.Component {
  static propTypes = {
    history: PropTypes.shape({}).isRequired,
    sjekkBetalingstatus: PropTypes.func.isRequired,
    location: PropTypes.shape({}).isRequired,
    handlekurv: PropTypes.shape({}).isRequired
  };

  componentDidMount() {
    const { location, sjekkBetalingstatus, history } = this.props;
    const values = queryString.parse(location.search);
    sjekkBetalingstatus(values.transactionId).then(() => {
      if (values.responseCode === "Cancel") history.push(urls.summaryAddSingle);
    });
  }

  render() {
    const { handlekurv } = this.props;
    return (
      <Page title="">
        {{
          pageContent: (
            <Section>
              {handlekurv && handlekurv.sjekkerBetaling ? (
                <div className="spinner-body">
                  <SpinnerCircle />
                  <p className="gjenomført-betaling-body-header">
                    <FormattedMessage id="completepaymentpage.oneMoment" />
                  </p>
                </div>
              ) : (
                <div className="gjenomført-betaling-body">
                  {(handlekurv &&
                    handlekurv.sjekkerBetaling && [
                      <h3 className="gjenomført-betaling-body-header" key="sjekker1">
                        <FormattedMessage id="completepaymentpage.processingHeader" />
                      </h3>,
                      <p className="gjenomført-betaling-body-text" key="sjekker2">
                        <FormattedMessage id="completepaymentpage.processingText" />
                      </p>
                    ]) ||
                    (handlekurv &&
                      handlekurv.betalingsStatusError &&
                      handlekurv.betalingsStatusError.status && [
                        <img className="gjenomført-betaling-body-icon" key="error1" src={errorIkon} alt="success" />,
                        <h3 className="gjenomført-betaling-body-header" key="error2">
                          <FormattedMessage id="completepaymentpage.serverErrorHeader" />
                        </h3>,
                        <p className="gjenomført-betaling-body-text" key="error3">
                          <FormattedMessage id="completepaymentpage.serverErrorText" />
                        </p>,
                        <div className="gjenomført-betaling-button-group">
                          <LinkButton className="btn-bym-standard gjenomført-betaling-button">
                            <Link to={urls.summaryAddSingle}>
                              <FormattedMessage id="completepaymentpage.linkTilKjøp" />
                            </Link>
                          </LinkButton>
                        </div>,
                        <p className="" key="error5">
                          {handlekurv.betalingsStatusError.data.errorMessage || ""}
                        </p>
                      ]) ||
                    (handlekurv &&
                      handlekurv.betalingsStatus &&
                      (handlekurv.betalingsStatus.netsStatuskode === 1 ||
                        handlekurv.betalingsStatus.netsStatuskode === 2 ||
                        handlekurv.betalingsStatus.netsStatuskode === 3) && [
                        <h3 className="gjenomført-betaling-body-header" key="success2">
                          <FormattedMessage id="completepaymentpage.ikkeGenomførtHeader" />
                        </h3>,
                        <p className="gjenomført-betaling-body-text" key="success3">
                          <FormattedMessage id="completepaymentpage.ikkeGenomførtText" />
                        </p>,
                        <div className="gjenomført-betaling-button-group">
                          <LinkButton
                            modifier="outline"
                            colorOption="yellow"
                            to={urls.summaryAddSingle}
                            className="btn-bym-standard gjenomført-betaling-button"
                          >
                            <FormattedMessage id="completepaymentpage.linkTilKjøp" />
                          </LinkButton>
                        </div>,
                        <p className="" key="error6">
                          {handlekurv.betalingsStatus.netsResponsetekst}
                        </p>
                      ]) ||
                    (handlekurv &&
                      handlekurv.betalingsStatus &&
                      (handlekurv.betalingsStatus.netsStatuskode === 4 ||
                        handlekurv.betalingsStatus.netsStatuskode === 5 ||
                        handlekurv.betalingsStatus.netsStatuskode === 6) && [
                        <h3 className="gjenomført-betaling-body-header" key="success2">
                          <FormattedMessage id="completepaymentpage.behandlesHosNetsHeader" />
                        </h3>,
                        <p className="gjenomført-betaling-body-text" key="success3">
                          <FormattedMessage id="completepaymentpage.behandlesHosNetsText" />
                        </p>,
                        <div className="gjenomført-betaling-button-group">
                          <LinkButton className="btn-bym-standard gjenomført-betaling-button">
                            <Link to={urls.summaryAddSingle}>
                              <FormattedMessage id="completepaymentpage.linkTilKjøp" />
                            </Link>
                          </LinkButton>
                        </div>,
                        <p className="" key="error5">
                          {handlekurv.betalingsStatus.netsResponsetekst}
                        </p>
                      ]) ||
                    (handlekurv && handlekurv.betalingsStatus && handlekurv.betalingsStatus.netsStatuskode === 8 && (
                      <>
                        <img className="gjenomført-betaling-body-icon" key="success1" src={successIkon} alt="success" />
                        <h3 className="gjenomført-betaling-body-header" key="success2">
                          <FormattedMessage id="completepaymentpage.successHeader" />
                        </h3>
                        <p className="gjenomført-betaling-body-text" key="success3">
                          <FormattedMessage id="completepaymentpage.successText" />
                        </p>
                        <div className="gjenomført-betaling-button-group">
                          <LinkButton modifier="outline" colorOption="yellow" to={urls.hjem} className="">
                            <FormattedMessage id="completepaymentpage.linkTilForside" />
                          </LinkButton>
                          <LinkButton
                            modifier="outline"
                            colorOption="yellow"
                            to={`${urls.kvitteringer}/${handlekurv.betalingsStatus.varekjopId}`}
                            className="ml-5"
                          >
                            <FormattedMessage id="completepaymentpage.linkTilKvittering" />
                          </LinkButton>
                        </div>
                      </>
                    )) ||
                    (handlekurv &&
                      handlekurv.betalingsStatus &&
                      handlekurv.betalingsStatus.netsStatuskode === 7 && [
                        <img className="gjenomført-betaling-body-icon" key="error1" src={errorIkon} alt="error" />,
                        <h3 className="gjenomført-betaling-body-header" key="error2">
                          <FormattedMessage id="completepaymentpage.netsErrorHeader" />
                        </h3>,
                        <p className="gjenomført-betaling-body-text" key="error3">
                          <FormattedMessage id="completepaymentpage.netsErrorText" />
                        </p>,
                        <div className="gjenomført-betaling-button-group">
                          <LinkButton modifier="outline" colorOption="yellow" to={urls.summaryAddSingle} className="">
                            <FormattedMessage id="completepaymentpage.linkTilKjøp" />
                          </LinkButton>
                        </div>,
                        <p className="" key="error6">
                          {handlekurv.betalingsStatus.netsResponsetekst}
                        </p>
                      ])}
                </div>
              )}
            </Section>
          )
        }}
      </Page>
    );
  }
}

export default withRouter(SjekkBetalingsstatusside);
