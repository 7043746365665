export function successData(response) {
  if (response.data) {
    if (response.status === 200) {
      return response.data.result;
    }
  }
  return response;
}

export function errorData(err) {
  if (err.response) {
    console.log("​errorData -> err.response", err.response);
    if (err.response.status && err.response.status === 500) {
      return "Feil oppstod med feilkode(500). Vennligst kontakt systemadministrator.";
    }
    if (err.response.data) {
      const res = err.response.data.errorMessage;
      return res;
    }
  }
  return err;
}
